import React, { useState, useRef, useEffect, useContext } from "react";
import { useLocation, useHistory, useParams, Link, NavLink } from "react-router-dom";
import search from "../images/search.png";
import down from "../images/down.png";
import bin from "../images/bin.png";
import { getUnitSizeUtil, time1 } from "../common/Method";
import {
  deletelist,
  deleteTempNotice,
  getFileSize,
  getNos,
  getNoticeBook,
  getStudents,
  hideNotice,
  postNotice,
  postNotification,
  postUploadFile,
  postUploadImage,
  putModifyNoticeBook,
} from "../common/Action";
import { MainContext } from "../common/Context";
import { IMainContext, IStudent } from "../interface/Interface";
import NoticeBook from "../component/NoticeBook";
import { t } from "i18next";

export default function Notice(): JSX.Element {
  const [noticeData, setNoticeData] = useState<any>([]);
  const [noticeAdd, setNoticeAdd] = useState<boolean>(false);
  const [noticeEdit, setNoticeEdit] = useState<boolean>(false);
  const [noticeTitle, setNoticeTitle] = useState<string>(time1(Date.now()));
  const [noticeBody, setNoticeBody] = useState<string>("");
  const [targetIdx, setTargetIdx] = useState<number>(-1);
  const [searchInput, setSearchInput] = useState<string>("");
  const [studentData, setStudentData] = useState<IStudent[]>([]);
  const [noticeStudents, setNoticeStudents] = useState<any>([]);
  const [files, setFiles] = useState<any[]>([]);
  const [fileUrls, setFileUrls] = useState<string[]>([]);
  const context = useContext<IMainContext>(MainContext);
  const location = useLocation();
  const classId = location.pathname.split("/")[3];
  const [filter, setFilter] = useState<string[]>([
    t(`Not Marked`), // 미확인 순
    t(`Newest to Oldest`), // 최신 순 - 알림장 생성 날짜 순
    t(`Alphabetical A Z`), // 가나다 순
  ]);
  const [filterSelected, setFilterSelected] = useState<string>(t(`Newest to Oldest`)); // 최신 순 - 알림장 생성 날짜 순
  const [filterPopup, setFilterPopup] = useState<boolean>(false);
  const [deleteState, setDeleteState] = useState<boolean>(false);
  const [allSelect, setAllSelect] = useState<boolean>(false);
  const params: any = useParams();

  useEffect(() => {
    init();
    initClassNotice();
  }, []);

  // useEffect(() => {},[])

  const init = async (): Promise<void> => {
    let studentResult: any = await getStudents(classId);
    for (let i in studentResult) {
      studentResult[i].checked = true;
    }
    context.handleStateChange("students", studentResult);
    // console.log(studentResult);
    setStudentData(studentResult);
  };

  const getSizeFromServer = async () => {
    const sizeResult: any = await getFileSize();
    const reduceData: any = getUnitSizeUtil(sizeResult.totalFilesize);

    context.handleStateChange("fileSize", {
      ...reduceData,
      originalFileSize: sizeResult.totalFilesize,
    });
  };

  // const handleAddProject = () => {
  //   if (context.userInfo?.fileSizeLimit < context.fileSize.originalFileSize) {
  //     return alert("제한 용량을 초과하셨습니다. '과제 삭제' 또는 '학급 삭제'를 통해 저장 용량을 조절하시거나, '결제 관리' 페이지에서 저장 용량 업그레이드 후 이용해 주세요.");
  //   }
  //   setPopup(true);
  // };

  const initClassNotice = async (): Promise<void> => {
    let noticeResult: any = await getNoticeBook(classId, "created", -1);
    setNoticeData(noticeResult);
  };

  const handleFilterSelect = async (index: number): Promise<void> => {
    let temp = "";
    temp = filter[index];
    setFilterSelected(temp);
    let filterData: any = [];

    if (filter[index] === t(`Not Marked`)) {
      // 미확인 순
      filterData = await getNoticeBook(classId, "status", -1);
    } else if (filter[index] === t(`Newest to Oldest`)) {
      // 최신 순
      filterData = await getNoticeBook(classId, "created", -1);
    } else if (filter[index] === t(`Alphabetical A Z`)) {
      // 가나다 순
      filterData = await getNoticeBook(classId, "title", 1);
    }

    setNoticeData(filterData);
    setFilterPopup(false);
  };

  const handleSearch = (e: any) => {
    setSearchInput(e.target.value);
    let temp: any = [...noticeData];
    for (let i in temp) {
      if (temp[i].title.includes(e.target.value) || temp[i].body.includes(e.target.value)) {
        temp[i].searchHide = false;
      } else {
        temp[i].searchHide = true;
      }
    }

    setNoticeData(temp);
  };

  const handleEachPush = async (student: any, i: number): Promise<void> => {
    if (!student.noticeChecked) return;
    // const confirm = window.confirm(`${student.name}에게 푸시 알림을 전송합니다.`);
    // TODO:: 고유번역
    const confirm = window.confirm(
      localStorage.getItem("language") === "ko"
        ? `${student.name}에게 푸시 알림을 전송합니다.`
        : `Send push notification to${student.name}`,
    );
    if (confirm) {
      if (!student.noticeChecked) return;
      let body = {
        to: student.deviceToken2,
        title: `${noticeTitle}`,
        // body: "알림장이 도착했습니다. 확인해 주세요!",
        body: t(`You have a new notice Please check it out`),
        data: {
          code: student.code,
          type: "notice",
        },
      };
      await postNotification(body);
    } else {
      return;
    }
  };

  const handleDeleteCheck = async (item: any, i: number): Promise<void> => {
    let tempCards = [...noticeData];
    tempCards[i].check = !noticeData[i].check;
    let checkCount = 0;
    for (let i in tempCards) {
      if (tempCards[i].check) checkCount += 1;
    }
    if (checkCount === tempCards.length) setAllSelect(true);
    else setAllSelect(false);
    setNoticeData(tempCards);
  };

  const handleHide = async (): Promise<void> => {
    if (deleteState) {
      const confirm = window.confirm(
        // "알림장을 숨기기합니다.\n⛔️ 체크된 임시저장 알림장은 삭제됩니다.",
        `${t(`Archive the notice`)}\n⛔️ ${t(`The selected notice draft will be deleted`)}`,
      );
      if (confirm) {
        const hideData = noticeData.filter((item: any, i: number) => item.check && !item.temp);
        const tempNotice = noticeData.filter((item: any, i: number) => item.check && item.temp);

        let tempId: string[] = [];
        for (let i in hideData) {
          tempId.push(hideData[i]._id);
        }

        // 임시저장 삭제
        for (let i in tempNotice) {
          let deleteTemp: any = await deleteTempNotice({
            _id: tempNotice[i]._id,
          });
        }

        // 숨김으로 변경
        const hideResult: any = await hideNotice({
          _ids: tempId,
          hide: true,
          classId: classId,
        });
        // 숨김 완료시
        const filterData: any[] = noticeData.filter((item: any, i: number) => !item.check);
        setNoticeData(filterData);
        setDeleteState(false);
        // alert("숨긴 알림장으로 이동되었습니다!");
        alert(t(`It has been moved to your archived notices`));
        init();
      } else {
        setDeleteState(false);
      }
    } else {
      setDeleteState(true);
    }
  };

  const handleAllCheck = () => {
    setAllSelect((prev: boolean) => !prev);
    let tempCards = [...noticeData];
    for (let i in tempCards) {
      tempCards[i].check = !allSelect;
    }
    setNoticeData(tempCards);
  };

  const handleAddNotice = async () => {
    let fileLimit = 0;
    if (context.userInfo.fileSizeLimit === 0 || !context.userInfo.fileSizeLimit) {
      fileLimit = 100000000;
    } else {
      fileLimit = context.userInfo?.fileSizeLimit;
    }
    if (fileLimit < context.fileSize.originalFileSize) {
      return alert(
        // "제한 용량을 초과하셨습니다. '과제 삭제' 또는 '학급 삭제'를 통해 저장 용량을 조절하시거나, '결제 관리' 페이지에서 저장 용량 업그레이드 후 이용해 주세요.",
        t(`You've exceeded your storage limit...`),
      );
    }

    setNoticeAdd(true);
    setNoticeTitle(time1(Date.now()));
    setNoticeBody("");
    setFileUrls([]);
    setFiles([]);
  };

  return (
    <div className="col-md-12 category_container">
      <div className="display-f justify-between mb-20 pl-10 pr-10">
        <section className="display-f">
          <div className="display-f justify-center">
            <p className="font-23 font-700">{t(`Notice`)}</p>
          </div>

          {!noticeAdd && (
            <button onClick={handleAddNotice} className="ml-30 cursor notice-plus">
              <p className="font-34 font-900">+</p>
            </button>
          )}
        </section>

        {!noticeAdd && (
          <div className="display-f" style={{ flexDirection: "column" }}>
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  // alignItems: "center",
                  marginLeft: 20,
                }}
              >
                {!deleteState && (
                  <NavLink
                    className={`${window.location.pathname.includes("hidden")}`}
                    to={`/page/hiddens/notice/${params.classId}`}
                  >
                    <div className="cursor hidden_btn mr-10 select_btn display-f align-center">
                      <img
                        style={{ width: 18, height: "auto", marginRight: 5 }}
                        src={bin}
                        alt="bin"
                      />
                      <p>
                        {/* 숨긴 알림장 */}
                        {t(`Archived Notices`)}
                      </p>
                    </div>
                  </NavLink>
                )}
                {!deleteState && (
                  <button onClick={handleHide} className="select_btn">
                    {/* 수정 */}
                    {t(`Edit`)}
                  </button>
                )}

                {deleteState && (
                  <div>
                    <button
                      onClick={handleHide}
                      className="select_submit_btn mr-10"
                      style={{ width: 160 }}
                    >
                      {/* 숨기기 / 임시저장 삭제 */}
                      {t(`Delete the draft Archive`)}
                    </button>
                    <button
                      onClick={() => {
                        setDeleteState(false);
                        setAllSelect(false);
                      }}
                      className="select_submit_btn"
                    >
                      {/* 확인 */}
                      {t(`Confirm`)}
                    </button>
                  </div>
                )}
              </div>

              <div className="display-f mt-10">
                <form className="mr-28 position-r">
                  <img className="search_icon position-a cursor" src={search} alt="search" />
                  <input
                    value={searchInput}
                    onChange={handleSearch}
                    type="text"
                    className="category_searchInput pl-50 pr-10"
                  />
                </form>

                <button
                  onClick={() => setFilterPopup((prev) => !prev)}
                  className="filter_btn_notice text-left pl-17 pr-12 display-f justify-between align-center"
                >
                  {filterSelected} <img className="filter_icon" src={down} alt="down" />
                </button>
                {filterPopup && (
                  <div className="position-a filter_contents_notice">
                    {filter.map((data: string, index: number) => (
                      <div
                        onClick={() => handleFilterSelect(index)}
                        className="filter_inner cursor"
                        key={index}
                      >
                        <p className="pl-17 pt-11 pb-11 font-14">{data}</p>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>

            {/* <div
              className="display-f mt-10"
              style={{ justifyContent: "flex-end", marginRight: 28 }}
            >
              <button>버튼1</button>
              <button>버튼2</button>
            </div> */}
          </div>
        )}
      </div>

      {deleteState && (
        <div
          className="display-f align-center"
          style={{ marginLeft: 10, marginTop: -30, marginBottom: 10 }}
        >
          <button onClick={handleAllCheck} className="select_all_btn mr-10 position-r">
            {allSelect && (
              <span className={`check__inside select_all position-a bg-checked`}></span>
            )}
          </button>
          <p onClick={handleAllCheck} className="font-16 mt-9 mb-10">
            {/* 모두 선택 */}
            {t(`Select All`)}
          </p>
        </div>
      )}

      {!noticeAdd && !noticeEdit && (
        <div className="notice-item-wrapper">
          {noticeData.map((item: any, i: number) => (
            <div key={i}>
              {!item.searchHide && !deleteState && !item.temp && (
                <div className="notice-item-container">
                  <Link to={`/page/noticedetail/${classId}/${item._id}/false`}>
                    <button className={`notice-item ${item.status} cursor`}>
                      <p style={{ fontSize: 16, fontWeight: 500 }}>{item.title}</p>
                    </button>
                  </Link>
                </div>
              )}

              {!item.searchHide && !deleteState && item.temp && (
                <div className="notice-item-container">
                  <Link to={`/page/noticedetail/${classId}/${item._id}/true`}>
                    <button
                      style={{ border: "6px solid #ffd37e" }}
                      className={`notice-item-tempsave gray cursor`}
                    >
                      <p
                        className="position-a"
                        style={{ top: 2, left: 3, color: "#979797", fontSize: 13 }}
                      >
                        {/* 미발송 */}
                        {t(`Unsent`)}
                      </p>
                      <p style={{ fontSize: 16, fontWeight: 500, color: "#979797" }}>
                        {item.title}
                      </p>
                    </button>
                  </Link>
                </div>
              )}

              {!item.searchHide && deleteState && !item.temp && (
                <div
                  onClick={() => handleDeleteCheck(item, i)}
                  className="notice-item-container"
                  style={{ position: "relative" }}
                >
                  <button className={`notice-item ${item.status} cursor`}>
                    <p style={{ fontSize: 16, fontWeight: 500 }}>{item.title}</p>
                    <span className="position-a check">
                      {item.check && (
                        <span className={`check__inside position-a bg-checked`}></span>
                      )}
                    </span>
                  </button>
                </div>
              )}
              {!item.searchHide && deleteState && item.temp && (
                <div
                  onClick={() => handleDeleteCheck(item, i)}
                  className="notice-item-container"
                  style={{ position: "relative" }}
                >
                  <button
                    style={{ border: "6px solid #ffd37e" }}
                    className={`notice-item-tempsave gray cursor`}
                  >
                    <p
                      className="position-a"
                      style={{ top: 2, left: 26, color: "#979797", fontSize: 13 }}
                    >
                      {/* 미발송 */}
                      {t(`Unsent`)}
                    </p>
                    <p style={{ fontSize: 16, fontWeight: 500, color: "#979797" }}>{item.title}</p>
                    <span className="position-a check" style={{ left: 5 }}>
                      {item.check && (
                        <span className={`check__inside position-a bg-checked`}></span>
                      )}
                    </span>
                  </button>
                </div>
              )}
            </div>
          ))}
        </div>
      )}

      {/* 알림장 생성 */}
      {noticeAdd && (
        <>
          <NoticeBook
            state={"add"}
            classId={params.classId}
            init={initClassNotice}
            onClose={setNoticeAdd}
          />
        </>
      )}
    </div>
  );
}

import OutsideClickHandler from "react-outside-click-handler";
import React, { useState, useEffect, useContext, useRef, Children } from "react";
import {
  Redirect,
  Link,
  Route,
  useHistory,
  useLocation,
  Switch,
  useRouteMatch,
} from "react-router-dom";
import { MainContext } from "../common/Context";
import { IClass, IMainContext } from "../interface/Interface";
import logo from "../images/logo-center.png";
import user from "../images/user.png";
import qr from "../images/qr.png";

import { getAdminNotice, getFileSize } from "../common/Action";
import Main from "./Main";
import PayPlan from "./PayPlan";
import PayPlanMain from "./PayPlanMain";
import PayPlanOrganization from "./PayPlanOrganization";
import AuthEdit from "./AuthEdit";
import AddSerialNum from "./AddSerialNum";
import { getUnitSizeUtil } from "../common/Method";
import Payment from "./Payment";
import Footer from "../component/Footer";
import { reduce } from "lodash";
import { t } from "i18next";

const MainHeader: React.FC = ({ children }: any) => {
  const history = useHistory();
  const location = useLocation();
  const askRef = useRef<HTMLDivElement>(null);
  const logoutRef = useRef<HTMLDivElement>(null);

  const context = useContext<IMainContext>(MainContext);
  let match = useRouteMatch();

  useEffect(() => {
    // console.log(match);
  }, []);

  const [askPopup, setAskPopup] = useState<boolean>(false);
  const [logout, setLogout] = useState<boolean>(false);
  const [notice, setNotice] = useState<any>({});
  const [modal, setModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  // 파일사이즈
  const [sizeLoading, setSizeLoading] = useState<boolean>(false);
  const [fileLimit, setFileLimit] = useState<any>({});
  const [P, setP] = useState<any>({});

  useEffect(() => {
    // fileSizeLimit을 bite 단위로 변환해서 백엔드에서 받아온다.
    const maxLimit =
      !context.userInfo.fileSizeLimit || context.userInfo.fileSizeLimit === 0
        ? 100000000
        : context.userInfo.fileSizeLimit;

    if (Object.keys(context.fileSize).length === 0) {
      getSizeFromServer();
    }

    const maxLimitUnit = getUnitSizeUtil(maxLimit);

    let totalPercent = 0;
    let percent = 0;
    let virtualSpacePercent = 0;

    if (context.fileSize.originalFileSize) {
      totalPercent = (context.fileSize.originalFileSize / maxLimit) * 100;
      percent = (context.fileSize.sizeToBite / maxLimit) * 100;
      virtualSpacePercent = 100 * ((totalPercent - percent) / totalPercent);
    }

    setP({
      totalPercent,
      percent,
      virtualSpacePercent,
    });

    setFileLimit(maxLimitUnit);
  }, [context.fileSize, context.userInfo]);

  const getSizeFromServer = async () => {
    const sizeResult: any = await getFileSize();
    // console.log(sizeResult);
    const reduceData: any = getUnitSizeUtil(sizeResult.totalFilesize);

    context.handleStateChange("fileSize", {
      ...reduceData,
      originalFileSize: sizeResult.totalFilesize,
    });
  };

  useEffect(() => {
    const clickOutside = (e: any) => {
      // 모달이 열려 있고 모달의 바깥쪽을 눌렀을 때 창 닫기
      if (logout && logoutRef.current && !logoutRef.current.contains(e.target)) {
        setLogout(false);
      }
    };

    document.addEventListener("click", clickOutside);

    return () => {
      document.removeEventListener("click", clickOutside);
    };
  }, [logout]);

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    setLoading(true);

    // 팝업
    // const confirm = window.confirm(
    //   `서비스 이용약관 정책이 변경되었습니다.${"\n"}동의하시겠습니까?`,
    // );

    // if (confirm) {
    if (location.pathname === "/") {
      const popupToday = localStorage.getItem("popup");
      if (popupToday !== new Date().toLocaleDateString()) {
        const notice: any = await getAdminNotice();
        setNotice(notice);
        if (notice.show) setModal(true);
      }
    } else setModal(false);

    setLoading(false);
    // } else return;
  };

  const handleClickOutside = (e: React.ChangeEvent<HTMLDivElement>): void => {
    if (askRef.current !== null) {
      if (!askRef.current.contains(e.target)) setAskPopup(false);
    }
  };

  const handleLogout = () => {
    if (!context.userInfo.kakaoId || context.userInfo.kakaoId === "") {
      localStorage.removeItem("token");
      window.location.reload();
      // context.handleUserDestroy();
      // window.location.reload();
    }
  };

  const Modal: React.FC = () => {
    return (
      <div className="modal-trans2">
        {/* modal mobile */}
        <div className="modal-in2-m">
          <div style={{ padding: "0 20px" }}>
            <h3>
              {/* 공지사항 */}
              {t(`Announcements`)}
            </h3>
            <hr />
          </div>

          <div
            style={{ wordBreak: "break-all", padding: "0 20px" }}
            dangerouslySetInnerHTML={{ __html: notice.body }}
          ></div>

          <div className="modal_mobile">
            <div
              onClick={() => {
                setModal(false);
                localStorage.setItem("popup", new Date().toLocaleDateString());
              }}
              className="text-center"
              style={{ padding: "20px 0", flex: 1, borderRight: "1px solid #ececec" }}
            >
              <p>
                {/* 오늘 하루 보지 않기 */}
                {t(`Don't show today`)}
              </p>
            </div>
            <div
              onClick={() => setModal(false)}
              className="text-center"
              style={{ padding: "20px 0", flex: 1 }}
            >
              <p>
                {/* 닫기 */}
                {t(`Close`)}
              </p>
            </div>
          </div>
        </div>

        {/* modal pc */}
        <div className="modal-in2-p">
          <div>
            <div style={{ padding: "20px 40px 0 40px" }}>
              <h2>
                {/* 공지사항 */}
                {t(`Announcements`)}
              </h2>
              <hr />
            </div>

            <div
              style={{ padding: "0 40px" }}
              className="cursor"
              dangerouslySetInnerHTML={{ __html: notice.body }}
            />
          </div>

          <div className="modal-btn-box" style={{ borderTop: "1px solid #ececec", marginTop: 50 }}>
            <div
              onClick={() => {
                setModal(false);
                localStorage.setItem("popup", new Date().toLocaleDateString());
              }}
              className="display-f justify-center align-center modal-btn-left"
            >
              <p className="cursor">
                {/* 오늘 하루 보지 않기 */}
                {t(`Don't show today`)}
              </p>
            </div>
            <div
              onClick={() => setModal(false)}
              className="display-f justify-center align-center cursor"
              style={{ flex: 1, padding: "20px 0" }}
            >
              <p className="cursor">
                {/* 닫기 */}
                {t(`Close`)}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  if (!context.isUser) return <Redirect to="/login" />;

  return (
    <div onClick={(e: any) => handleClickOutside(e)}>
      {/* 메인 헤더 pc */}
      <div className="main_header display-f">
        <div className="container" style={{ display: "flex", alignItems: "center" }}>
          <div className="col-md-4 display-f align-center font-15">
            <div className="display-f mr-20">
              <p className="font-700 color-dark-blue">?</p>&nbsp;
              <p onClick={() => setAskPopup((prevState) => !prevState)} className="cursor">
                {/* 문의하기 */}
                {t("Contact us")}
              </p>
              {askPopup && (
                <div onClick={(e: any) => handleClickOutside(e)} className="popup-main">
                  <div ref={askRef} style={{ zIndex: 100 }} className="main_popup scroll">
                    <p className="text-center font-23 mb-26">
                      {/* 문의하기 */}
                      {t("Contact us")}
                    </p>
                    {/* <p className="text-center font-20 mt-120 mb-40">dahandinkr@gmail.com</p> */}
                    <div className="qr_box margin-auto mb-10">
                      <img src={qr} alt="qr-code" className="qr" />
                    </div>
                    <a href="https://open.kakao.com/me/dahandin" target="blank">
                      <p className="text-center font-16 mb-26 cursor">
                        {/* 바로가기 */}
                        {t(`Shortcuts`)}
                      </p>
                    </a>
                    <div style={{ padding: "0 20px" }}>
                      <p className="notice-text">
                        {/* &nbsp;저 역시 현직 교사이므로 오전 수업시간에는 답변이 어려우니 이 점
                        양해부탁드립니다. */}
                        {t(`I’m also a current teacher...`)}
                        <br />
                        {/* &nbsp;자주 연락주시는 부분과 그에 대한 답변을 아래에 정리하여 두었습니다. */}
                        {t(`I’ve organized the most common questions...`)}
                        <br />
                        {/* &nbsp;'문의하기'로 카톡을 보내주시기 전에, 아래 글을 먼저 확인 부탁드립니다. */}
                        {t(`Before sending an inquiry please check the Q&A section beloww`)}
                        <br /> <br />
                      </p>
                      <p className="text-center font-500 font-16 mb-8">
                        {/* 자주 하시는 QnA */}
                        {t(`Frequently QNA`)}
                      </p>
                      <a target="_blank" href="https://blog.naver.com/dahandin/222660614302">
                        <p className="text-center font-16 mb-26 cursor">
                          {/* QnA 바로가기 */}
                          {t(`Link to Q&A`)}
                        </p>
                      </a>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <a
              target="_blank"
              href={
                "https://youtube.com/playlist?list=PL_RwrWFHnSbSFWykyCW1NtcI5gwbWVnb1&si=SckrQsYPDkBs-glM"
              }
              className="display-f align-center text-deco-none"
            >
              <div style={{ marginTop: -4 }}>
                <p className="font-700 color-dark-blue" style={{ fontSize: 21 }}>
                  ℹ
                </p>
              </div>
              &nbsp;
              <p className="cursor text-deco-none">
                {/* 튜토리얼 */}
                {t("Tutorial")}
              </p>
            </a>
          </div>

          <div className="col-md-4 display-f justify-center">
            <img
              onClick={() => history.push("/")}
              className="main_logo cursor"
              src={logo}
              alt="logo"
            />
          </div>
          <div className="col-md-4 display-f align-center justify-end">
            <div style={{ flex: 4 }}>
              <div
                className="display-f align-center justify-between"
                style={{ marginRight: 8, position: "relative" }}
              >
                <div style={{ marginRight: 4 }}>
                  {!sizeLoading && (
                    <p style={{ fontSize: 12, fontWeight: "bold" }}>
                      {/* 전체 저장 용량 */}
                      {t("Total Storage Capacity")}
                    </p>
                  )}
                  <p style={{ fontSize: 11 }}>
                    {/* {sizeLoading && "용량 측정중..."} */}
                    {sizeLoading && t(`Measuring capacity`)}
                  </p>
                  {/* <p style={{ fontSize: 10 }}>(베타테스트입니다.)</p> */}
                </div>
                <div>
                  <div className="text-right">
                    <p style={{ fontSize: 11 }}>
                      {fileLimit.size}
                      {fileLimit.unit}
                    </p>
                  </div>
                  <div
                    style={{
                      width: 160,
                      height: 16,
                      border: "1px solid #979797",
                      overflow: "hidden",
                    }}
                  >
                    <div
                      className={`filesize-animation`}
                      style={{
                        width: `${P.totalPercent}%`,
                        backgroundColor: "red",
                        height: "100%",
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      {/* 가상으로 확보될 공간 */}
                      <div
                        style={{
                          width: `${P.virtualSpacePercent}%`,
                          height: "100%",
                          // backgroundColor:"red"
                          backgroundColor: "#ececec",
                        }}
                      ></div>
                    </div>
                  </div>
                  <div className="text-center">
                    <p style={{ fontSize: 11 }}>
                      {context.fileSize.size}
                      {context.fileSize !== 0 && context.fileSize.unit}
                    </p>
                    {context.sizeLoading && (
                      <p style={{ fontSize: 10 }}>
                        {/* 용량 측정중... */}
                        {t(`Measuring capacity`)}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div
              className="display-f align-center justify-end"
              style={{ flex: 1, textAlign: "end" }}
            >
              <img className="main_user mr-6" src={user} alt="user" />
              <div onClick={() => setLogout((prevState) => !prevState)}>
                <p className="font-15 cursor">{context.userName}</p>
                <p className="cursor" style={{ fontSize: 10 }}>
                  {/* 선생님 */}
                  {t("Teacher")}
                </p>
              </div>
              {logout && (
                <div ref={logoutRef} style={{ zIndex: 10 }} className="logout text-center">
                  <p
                    onClick={() => {
                      setLogout(false);
                      history.push("/oauth/edit");
                    }}
                    className="cursor logout__text light-black"
                  >
                    {/* 정보수정 */}
                    {t(`Modify Info`)}
                  </p>

                  {context.userInfo.kakaoId && context.userInfo.kakaoId !== "" && (
                    <a
                      className="link_signout"
                      href={`https://kauth.kakao.com/oauth/logout?client_id=6cadd7e874b389249dbed4eb27fbf71f&logout_redirect_uri=https://dahandin.com/auth/kakaosignout`}
                    >
                      <p onClick={handleLogout} className="cursor logout__text light-black">
                        {/* 로그아웃 */}
                        {t(`Sign Out`)}
                      </p>
                    </a>
                  )}
                  {!context.userInfo.kakaoId && (
                    <p onClick={handleLogout} className="cursor logout__text light-black">
                      {/* 로그아웃 */}
                      {t(`Sign Out`)}
                    </p>
                  )}
                  {/* <p className="cursor logout__text light-black">
                  <a target="blank" href="https://blog.naver.com/dahandin/223171578706">
                    개인정보 처리방침
                  </a>
                </p> */}

                  <div style={{ borderTop: "1px dashed #ececec" }}>
                    <div
                      onClick={() => history.push("/payment")}
                      className="font-blue cursor"
                      style={{ padding: "6px 0" }}
                    >
                      {/* 결제내역 */}
                      {t(`Payment History`)}
                    </div>
                    <div
                      onClick={() => history.push("/payplan")}
                      className="font-blue cursor"
                      style={{ padding: "6px 0" }}
                    >
                      {/* 결제관리 */}
                      {t(`Payment Management`)}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* 메인 헤더 모바일 */}
      <div className="main_header_m">
        <p
          onClick={() => setAskPopup((prevState) => !prevState)}
          className="font-700 color-dark-blue"
          style={{ fontSize: 20, flex: 1 }}
        >
          ?
        </p>
        &nbsp;
        {askPopup && (
          <div onClick={(e: any) => handleClickOutside(e)} className="popup-main">
            <div ref={askRef} className="main_popup scroll">
              <p className="text-center font-23 mb-26">
                {/* 문의하기 */}
                {t(`Contact us`)}
              </p>
              {/* <p className="text-center font-20 mt-120 mb-40">dahandinkr@gmail.com</p> */}
              <div className="qr_box margin-auto mb-10">
                <img src={qr} alt="qr-code" className="qr" />
              </div>
              <a href="https://open.kakao.com/me/dahandin" target="blank">
                <p className="text-center font-16 mb-26 cursor">
                  {/* 바로가기 */}
                  {t(`Shortcuts`)}
                </p>
              </a>
              <div style={{ padding: "0 20px" }}>
                <p className="notice-text">
                  {/* &nbsp;저 역시 현직 교사이므로 오전 수업시간에는 답변이 어려우니 이 점
                  양해부탁드립니다. */}
                  {t(`I’m also a current teacher...`)}
                  <br />
                  {/* &nbsp;자주 연락주시는 부분과 그에 대한 답변을 아래에 정리하여 두었습니다. */}
                  {t(`I’ve organized the most common questions...`)}
                  <br />
                  {/* &nbsp;'문의하기'로 카톡을 보내주시기 전에, 아래 글을 먼저 확인 부탁드립니다. */}
                  {t(`Before sending an inquiry please check the Q&A section below`)}
                  <br /> <br />
                </p>
                <p className="text-center font-500 font-16 mb-8">
                  {/* 자주 하시는 QnA */}
                  {t(`Frequently QNA`)}
                </p>
                <a href="https://blog.naver.com/dahandin/222660614302">
                  <p className="text-center font-16 mb-26 cursor">
                    {/* QnA 바로가기 */}
                    {t(`Link to Q&A`)}
                  </p>
                </a>
              </div>
            </div>
          </div>
        )}
        <img className="main_logo" src={logo} alt="logo" />
        <div className="display-f align-center justify-end position-r" style={{ flex: 1 }}>
          <img
            onClick={() => setLogout((prevState) => !prevState)}
            className="main_user"
            src={user}
            alt="user"
          />

          {logout && (
            <div ref={logoutRef} className="logout" style={{ zIndex: 100 }}>
              <p
                onClick={() => {
                  setLogout(false);
                }}
                className="cursor logout__text light-black"
              >
                {/* 정보수정 */}
                {t(`Modify Info`)}
              </p>
              <p onClick={handleLogout} className="cursor logout__text light-black">
                {/* 로그아웃 */}
                {t(`Sign Out`)}
              </p>
            </div>
          )}
        </div>
      </div>

      <Switch>
        <Route path="/" exact component={Main} />
        <Route path="/hiddenclass" exact component={Main} />
        <Route path="/payment" component={Payment} />
        <Route path="/payplan" component={PayPlanMain} />
        <Route path="/plan/private" component={PayPlan} />
        <Route path="/plan/organization" component={PayPlanOrganization} />
        <Route path="/plan/addserialnum" component={AddSerialNum} />

        <Route path="/oauth/edit" exact component={AuthEdit} />
      </Switch>

      <Footer />
      {modal && <Modal />}
    </div>
  );
};

export default MainHeader;

import React, { useState, useEffect } from "react";
import {
  getFileSize,
  getUserInfo,
  putModifyUserAgreementPolicy,
  putModifyUserInfo,
} from "./common/Action";
import { MainContext } from "./common/Context";
import { IMainContext } from "./interface/Interface";
import Navigation from "./Navigation";
import { Quill } from "react-quill";
import ReactGA from "react-ga";
import ModalComponent from "./component/ModalComponent";
import logo from "./images/logo-top.png";
import { getUnitSizeUtil } from "./common/Method";
import { useTranslation } from "react-i18next";
import { Languages, languages } from "./Locales/i18n";
import { googleLogout } from "@react-oauth/google";

const SizeStyle = Quill.import("attributors/style/size");
SizeStyle.whitelist = ["10px", "16px", "20px", "30px", "40px"];
Quill.register(SizeStyle, true);

const App: React.FC = () => {
  const { t, i18n } = useTranslation();

  const handleChangeLanguage = (lang: Languages) => {
    i18n.changeLanguage(lang);
  };

  useEffect(() => {
    // googleLogout();
    ReactGA.initialize("G-078NN1L9S1");
    ReactGA.set({ page: window.location.pathname });
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  ReactGA.event({
    category: "User",
    action: "Created an Account",
  });
  ReactGA.exception({
    description: "An error ocurred",
    fatal: true,
  });

  const _handleStateChange = (state: string, value: any): void => {
    setData((prevState) => {
      return {
        ...prevState,
        [state]: value,
      };
    });
  };

  const _handleGetUserInfo = async (): Promise<void> => {
    const result: any = await getUserInfo();
    const sizeResult: any = await getFileSize();
    // console.log(sizeResult);
    const reduceData: any = getUnitSizeUtil(sizeResult.totalFilesize);

    if (!result) {
      _handleUserDestroy();
    } else {
      _handleStateChange("isUser", true);
      _handleStateChange("userName", result.name);
      _handleStateChange("userInfo", result);
      _handleStateChange("agreementAi", result.agreementAi);
      _handleStateChange("fileSize", { ...reduceData, originalFileSize: sizeResult.totalFilesize });

      if (result.agreementPolicy) {
        setModal(false);
      } else setModal(true);
    }
    setLoading(false);
  };

  const _handleUserDestroy = (): void => {
    localStorage.removeItem("token");
    _handleStateChange("isUser", false);
    _handleStateChange("userName", "");
    _handleStateChange("userInfo", {});
    _handleStateChange("agreementAi", false);
  };

  const _handleSelectClassCookie = (students: any) => {
    const selectStudents = students.filter((el: any) => el.checked);
    // console.log(selectStudents);
    // if (selectStudents.length === 0) {
    //   setData((prev: any) => {
    //     return {
    //       ...prev,
    //       selectClassCookie: true,
    //     };
    //   });
    // } else {
    //   setData((prev: any) => {
    //     return {
    //       ...prev,
    //       selectClassCookie: false,
    //     };
    //   });
    // }
  };

  const [modal, setModal] = useState<boolean>(false);
  const [data, setData] = useState<IMainContext>({
    isUser: false,
    class: "",
    classId: "",
    userName: "",
    userInfo: {},
    agreementAi: false,
    students: [],
    setNoticeState: {
      viewState: false,
      editState: false,
    },
    userLevel: "beginner",
    easyCookieEdit: false,
    easyReport: false,
    easyCookieUse: false,
    easyConfirmPos: false,
    showHiddensStudentPage: false,
    showHiddenChecklistsStudentPage: false,
    selectClassCookie: false,
    noticeBookBody: "",
    fileSize: {},
    sizeLoading: false,
    handleSelectClassCookie: _handleSelectClassCookie,
    handleStateChange: _handleStateChange,
    handleGetUserInfo: _handleGetUserInfo,
    handleUserDestroy: _handleUserDestroy,
  });

  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    window.onbeforeunload = function pushRefresh() {
      window.scrollTo(0, 0);
    };

    _handleGetUserInfo();
    return () => {};
  }, []);

  const handleAgreementPolicy = async () => {
    setModal(false);
    const updateUserInfo: any = await putModifyUserAgreementPolicy({
      agreementPolicy: true,
    });

    await _handleGetUserInfo();
  };

  if (loading) return <p>로딩중...</p>;

  return (
    <MainContext.Provider value={data}>
      {modal && (
        <ModalComponent
          modalStyles={{
            width: "40%",
            minHeight: "10vh",
            maxHeight: "80vh",
            overflowY: "scroll",
            borderRadius: 16,
            backgroundColor: "#efefef",
          }}
          disableOutsideClose={true}
          onClose={() => setModal(false)}
          title={""}
          open={modal}
        >
          <div style={{ padding: 25 }}>
            <div>
              <div className="display-f align-center justify-center mb-20">
                <img src={logo} style={{ width: 100, height: "auto" }} />
              </div>
              <h3 className="text-center margin-0">
                {/* 다했니 서비스 이용약관 및 개인정보보호 정책이 아래와 같이 수정되었습니다. */}
                {t(`The terms of use and Privacy Policy have been updated as follows`)}
              </h3>
              <h3 className="text-center margin-0">
                {/* 아래의 '동의합니다'를 누르시면 약관 및 정책에 동의하는 것으로 간주됩니다. */}
                {t(`By clicking I Agree below...`)}
              </h3>
            </div>

            <div className="text-center mt-20">
              <div>
                <a
                  target="_blank"
                  className="text-underline cursor"
                  href="https://blog.naver.com/dahandin/223384555362"
                >
                  {/* 이용약관 보기 */}
                  {t(`View Terms of Use`)}
                </a>
              </div>
              <div className="mt-8">
                <a
                  target="_blank"
                  className="text-underline cursor"
                  href="https://blog.naver.com/dahandin/223384561843"
                >
                  {/* 개인정보보호 정책 보기 */}
                  {t(`View Privacy Policy`)}
                </a>
              </div>
            </div>

            <div className="mt-20">
              <button
                onClick={handleAgreementPolicy}
                style={{ width: "100%", border: "none", padding: 8 }}
                className="bg-yellow font-600"
              >
                {/* 동의합니다. */}
                {t(`I agree`)}
              </button>
            </div>
          </div>
        </ModalComponent>
      )}

      {/* <p>{t("hello")}</p>
      <p>{t("bye")}</p>
      {languages.map((lang) => (
        <button key={lang} onClick={() => handleChangeLanguage(lang)}>
          {t(`language_${lang}`)}
        </button>
      ))} */}
      <Navigation />
    </MainContext.Provider>
  );
};

export default App;

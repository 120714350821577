import React, {
  useState,
  useEffect,
  useContext,
  useLayoutEffect,
  useRef,
  useCallback,
} from "react";
import { MainContext } from "../common/Context";
import { TableBody, TableHead } from "../component/Table";
import { IProject, IMainContext, IStudent } from "../interface/Interface";
import { Link, NavLink, Route, useHistory, useParams } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import TextareaAutosize from "react-textarea-autosize";
import {
  deleteRecordTag,
  deleteStackRecords,
  deleteStackRecords2,
  getCategorys,
  getClassReportChecklist,
  getEachProjectInfo,
  getFoldersOfChecklist,
  getIsValidGenerateAI,
  getRecordTags,
  getReportProjects,
  getSavedAiFeedback,
  getStackRecords,
  getStudents,
  getUserInfo,
  modifyStackRecord,
  postAIFeedback,
  postChecklist,
  postGenerateAI,
  postGenerateAIMulti,
  postNotification,
  postNotificationAll,
  postRecordTags,
  postSaveAiFeedback,
  postStackRecord,
  postUploadImage,
  putConfirmProject,
  putModifyUserAgreement,
  putModifyUserInfo,
  putMoodifySavedAiFeedback,
  putRejectProject,
} from "../common/Action";

import cookie1 from "../images/cookie1.png";
import cookie2 from "../images/cookie2.png";
import cookie3 from "../images/cookie3.png";
import cookie1S from "../images/cookie1S.png";
import cookie2S from "../images/cookie2S.png";
import cookie3S from "../images/cookie3S.png";
import cookie1G from "../images/cookie1G.png";
import cookie2G from "../images/cookie2G.png";
import cookie3G from "../images/cookie3G.png";
import file_icon from "../images/file_icon.png";
import down_b from "../images/down_b.png";
import hide from "../images/hide.png";
import numbers from "../images/numbers.png";
import calendar from "../images/calendar.png";
import excel from "../images/excel.png";
import search from "../images/search.png";
import close from "../images/close.png";
import Modal from "../component/Modal";
import down from "../images/down_b.png";
import copy from "../images/copy.png";

import {
  time1,
  time3,
  timeFormat10,
  initialChecklist,
  initialChecklistReduced,
  initProjectForC,
  sortedImageFileArr,
  timeFormat11,
} from "../common/Method";
import CheckList from "./CheckList";
import PopupModal from "../component/PopupModal";
import * as xlsx from "xlsx";
import PopupAlbum from "../component/PopupAlbum";
import Hidden from "./Hidden";
import { resourceLimits } from "worker_threads";
import { APDateRangePicker } from "../component/DateRangePicker";
import { start } from "repl";
import moment from "moment";
import OutsideClickHandler from "react-outside-click-handler";
import ReactQuill from "react-quill";
import { formatStyle, quillStyle } from "../common/QuillStyle";
import _ from "lodash";
import { filter } from "jszip";
import CheckListFolder from "./ChecklistFolder";
import { t } from "i18next";

const Classreport: React.FC = () => {
  const context = useContext<IMainContext>(MainContext);
  const params: any = useParams();
  const [tab, setTab] = useState<string>(t(`Anecdotal Record`)); // 누가기록
  const [projectReports, setProjectReports] = useState<any>([]);
  const [originalProjectReports, setOriginalProjectReports] = useState<any>([]);
  const [students, setStudents] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [stackRecords, setStackRecords] = useState<any>([]);
  const [stackRecordsCopy, setStackRecordsCopy] = useState<any>([]);
  const [tagEdit, setTagEdit] = useState<boolean>(false);
  const [dateRange, setDateRange] = useState<any>({
    startDate: new Date(),
    dueDate: new Date(),
  });
  const [checklistData, setChecklistData] = useState<any>([]);
  const [studentRowData, setStudentRowData] = useState<any>({});
  const [checklistTableWidth, setChecklistTableWidth] = useState<number>(150);
  const [projectTableWidth, setProjectTableWidth] = useState<number>(100);
  const [projectTableHeight, setProjectTableHeight] = useState<number>(0);
  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);
  const [originalChecklistData, setOriginalChecklistData] = useState<any>([]);
  const [studentsOb, setStudentsOb] = useState<any>({});
  const [hasAiFeedback, setHasAiFeedback] = useState<boolean>(false);

  // useEffect(() => {
  //   if (params.type === "tab1") {
  //     setTab("누가기록");
  //   }
  //   if (params.type === "tab2") {
  //     setTab("과제");
  //   }
  //   if (params.type === "tab3") {
  //     setTab("체크리스트");
  //   }
  // }, []);

  useLayoutEffect(() => {
    if (tab === t(`Anecdotal Record`)) {
      // 누가기록
      init();
    }
    if (tab === t(`Assignment`)) {
      // 과제
      initProjects();
    }
    if (tab === t(`Checklist`)) {
      // 체크리스트
      initChecklist();
    }

    if (tab === t(`AI Comment`)) {
      // AI 쫑알이
      initAIFeedback();
    }
  }, [tab]);

  const init = async () => {
    const stackRecordsResult: any = await getStackRecords(params.classId);

    if (stackRecordsResult.length !== 0) {
      const sorted: any = stackRecordsResult.sort((a: any, b: any) => b.reportDate - a.reportDate);
      setStackRecords(sorted);
      setStackRecordsCopy(sorted);
      setDateRange(() => {
        return {
          dueDate: sorted[0]?.reportDate,
          startDate: sorted[sorted.length - 1]?.reportDate,
        };
      });

      if (sorted[sorted.length - 1]?.reportDate && moment(sorted[0]?.reportDate)) {
        setStartDate(moment(sorted[sorted.length - 1]?.reportDate));
        setEndDate(moment(sorted[0]?.reportDate));
      } else {
        setStartDate(moment(Date.now()));
        setEndDate(moment(Date.now()));
      }
    }
  };

  const initProjects = async () => {
    const projectsResult: any = await getReportProjects(params.classId);
    setStudents(projectsResult?.students);
    setProjectReports(projectsResult?.projects);
    setProjectTableWidth(() => 80 * projectsResult.students?.length + 100);
    setProjectTableHeight(() => 32 * projectsResult.projects?.length);
    setOriginalProjectReports(projectsResult?.projects);
  };

  const initChecklist = async () => {
    if (loading) return;
    setLoading(true);
    const result: any = await initialChecklist(params.classId, "");
    setOriginalChecklistData(result.checklistResult.checklist);
    setChecklistTableWidth(() => 150 + 100 * result.checklistResult.checklist.length);
    setStudentRowData(result.tempChecklistRowData);
    setChecklistData(result.checklistResult.checklist);

    return () => {};
  };

  const initAIFeedback = async () => {
    let studentsOb: any = {};
    const { result }: any = await getSavedAiFeedback(params.classId);
    // console.log(result);
    if (result && Object.keys(result.students).length !== 0) {
      studentsOb = result.students;
      setHasAiFeedback(true);
    } else {
      const results: any = await getReportProjects(params.classId);

      for (let i = 0; i < results?.students?.length; i++) {
        studentsOb[results.students[i]?.code] = results.students[i];
      }
    }

    // console.log(studentsOb);
    setStudentsOb(studentsOb);
  };

  const handleDownloadExcelFile = async () => {
    const recordTags: any = await getRecordTags(params.classId);
    const tempStackRecords: any = [...stackRecords];
    for (let i = 0; i < stackRecords.length; i++) {
      const tempTags = [];
      for (let k = 0; k < stackRecords[i].tags.length; k++) {
        tempTags.push(recordTags[0]?.tags[stackRecords[i].tags[k]].tagName);
      }
      tempStackRecords[i].tagsName = tempTags;
    }

    for (let i = 0; i < stackRecords.length; i++) {
      let studentsNameToString = "";
      let tagsNameToString = "";
      studentsNameToString = stackRecords[i].studentsName.reduce(
        (previousValue: any, currentValue: any, currentIdx: number) =>
          `${previousValue}${currentIdx === 0 ? "" : ","} ${currentValue}`,
        "",
      );
      tagsNameToString = stackRecords[i].tagsName.reduce(
        (previousValue: any, currentValue: any, currentIdx: number) =>
          `${previousValue}${currentIdx === 0 ? "" : ","} ${currentValue}`,
        "",
      );

      tempStackRecords[i].tagsNameToString = tagsNameToString;
      tempStackRecords[i].studentNameToString = studentsNameToString;
    }

    const arr = [];
    for (let i in stackRecords) {
      arr.push({
        date: time3(stackRecords[i].reportDate),
        tags: stackRecords[i].tagsNameToString,
        students: stackRecords[i].studentNameToString,
        cookies: stackRecords[i].increaseCookie,
        body: stackRecords[i].reportBody,
      });
    }
    const ws = xlsx.utils.json_to_sheet(arr);
    const wb = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, "Sheet1");
    ["날짜", "분류", "대상", "쿠키 수", "내용"].forEach((x, idx) => {
      const cellAdd = xlsx.utils.encode_cell({ c: idx, r: 0 });
      ws[cellAdd].v = x;
    });
    xlsx.writeFile(wb, `누가기록(${context.class}).xlsx`);
  };

  return (
    <>
      <div className="col-md-12 category_container">
        <div className="display-f justify-between">
          <div>
            <div className="display-f align-center justify-between position-r">
              <div className="display-f">
                <p className="font-23 font-700 mr-10">{t(`My Class Report`)}</p>
              </div>
            </div>
            <p className="card-text" style={{ color: "#979797" }}>
              {/* * 모든 누가기록/과제/체크리스트 내용은 이곳에서 클릭을 하여 내용을 수정할 수 있습니다. */}
              {t(`All anecdotal notes assignments and checklists...`)}
            </p>
          </div>

          {params.type === "tab1" && (
            <button
              onClick={handleDownloadExcelFile}
              style={{ border: "1px solid #232323", color: "#232323" }}
              className="student_code_upload_btn display-f align-center"
            >
              <img className="mr-5 excel_img" src={excel} />
              {/* 엑셀로 내려받기 */}
              {t(`Download to Excel`)}
            </button>
          )}
          {/* <button
            onClick={handleDownloadExcelFile}
            style={{ border: "1px solid #232323", color: "#232323" }}
            className="student_code_upload_btn display-f align-center"
          >
            <img className="mr-5 excel_img" src={excel} />
            {t(`Download to Excel`)}
          </button> */}
        </div>

        <section className="mt-30 display-f position-r" style={{ alignItems: "end" }}>
          <Link to={`/page/report/${params.classId}/tab1`}>
            <button
              onClick={() => setTab(t(`Anecdotal Record`))} // 누가기록
              style={{ backgroundColor: tab === t(`Anecdotal Record`) ? "#f48273" : "" }} // 누가기록
              className={`report_tab mr-5 ${tab === t(`Anecdotal Record`) && "report_select"}`} // 누가기록
            >
              {/* 누가 기록 */}
              <p>{t(`Anecdotal Record`)}</p>
            </button>
          </Link>

          <Link to={`/page/report/${params.classId}/tab2`}>
            <button
              style={{ backgroundColor: tab === t(`Assignment`) ? "#F7CC6C" : "" }} // 과제
              onClick={() => setTab(t(`Assignment`))} // 과제
              className={`report_tab mr-5 ${tab === t(`Assignment`) && "report_select"}`} // 과제 &&
            >
              <p>
                {/* 과제 */}
                {t(`Assignment`)}
              </p>
            </button>
          </Link>

          <Link to={`/page/report/${params.classId}/tab3`}>
            <button
              style={{ backgroundColor: tab === t(`Checklist`) ? "#52905d" : "" }}
              onClick={() => setTab(t(`Checklist`))} // 체크리스트
              className={`report_tab mr-5 ${tab === t(`Checklist`) && "report_select"}`} // 체크리스트
            >
              <p>
                {/* 체크리스트 */}
                {t(`Checklist`)}
              </p>
            </button>
          </Link>

          <div className="position-a" style={{ display: "flex", right: -5 }}>
            <Link to={`/page/report/${params.classId}/tab4`}>
              <button
                style={{ backgroundColor: tab === t(`AI Comment`) ? "#5B9BD5" : "" }} // AI 쫑알이
                onClick={() => setTab(t(`AI Comment`))} // AI 쫑알이
                className={`report_tab_2 mr-5 position-r ${
                  tab === t(`AI Comment`) && "report_select_2"
                }`} // AI 쫑알이
              >
                {/* AI 쫑알이 */}
                <p>{t(`AI Comment`)}</p>
              </button>
            </Link>
          </div>
        </section>

        <div className="report_body position-r">
          {/* 누가기록 */}
          {tab === t(`Anecdotal Record`) && (
            <div>
              <StackReport
                init={init}
                stackRecordsCopy={stackRecordsCopy}
                stackRecords={stackRecords}
                setStackRecords={setStackRecords}
                classId={params.classId}
                dateRange={dateRange}
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                setDateRange={setDateRange}
              />
            </div>
          )}
          {/* 과제 */}
          {tab === t(`Assignment`) && (
            <Project
              tableWidth={projectTableWidth}
              tableHeight={projectTableHeight}
              initProjects={initProjects}
              classId={params.classId}
              originalProjectReports={originalProjectReports}
              setOriginalProjectReports={setOriginalProjectReports}
              projectReports={projectReports}
              setProjectReports={setProjectReports}
              students={students}
            />
          )}
          {/* 체크리스트 */}
          {tab === t(`Checklist`) && (
            <Checklist
              tableWidth={checklistTableWidth}
              setTableWidth={setChecklistTableWidth}
              studentsData={studentRowData}
              checklistData={checklistData}
              originalData={originalChecklistData}
              setStudentsData={setStudentRowData}
              setChecklistData={setChecklistData}
              classId={params.classId}
            />
          )}
          {/* AI 쫑알이 */}
          {tab === t(`AI Comment`) && (
            <AIFeedback
              hasAiFeedback={hasAiFeedback}
              students={studentsOb}
              setStudents={setStudentsOb}
              classId={params.classId}
            />
          )}
        </div>
      </div>
    </>
  );
};

const StackReport = (props: any) => {
  const context = useContext<IMainContext>(MainContext);
  const [deleteMode, setDeleteMode] = useState<boolean>(false);
  const [allCheck, setAllCheck] = useState<boolean>(false);
  const [modal, setModal] = useState<boolean>(false);
  const [tagModal, setTagModal] = useState<boolean>(false);
  const [cookieSelected, setCookieSelected] = useState<number>(0);
  const [stackPopup, setStackPopup] = useState<any>({
    classCookies: false,
    allStudentCheck: false,
  });
  const [students, setStudents] = useState<any>([]);
  const [dateSelected, setDateSelected] = useState<any>(new Date());
  const [reportBody, setReportBody] = useState<string>("");
  const [editId, setEditId] = useState<string>("");
  const [create, setCreate] = useState<boolean>(false);
  const [tags, setTags] = useState<any>([{}, {}, {}]);
  const [tag, setTag] = useState<string>("");
  const [index, setIndex] = useState<number>(-1);
  const [tagEdit, setTagEdit] = useState<boolean>(false);
  const [searchInput, setSearchInput] = useState<string>("");
  const [tagsFilterData, setTagsFilterData] = useState<any>([]);
  const [timeRangeData, setTimeRangeData] = useState<any>([]);
  const [filterOn, setFilterOn] = useState<any>({
    dateFilter: false,
    // tagFilter: false,
    searchFilter: false,
  });
  const [moved, setMoved] = useState<boolean>(false);
  const [movedNext, setMovedNext] = useState<boolean>(false);
  const [stackForCookie, setStackForCookie] = useState<boolean>(false);
  const [stackForCookieUse, setStackForCookieUse] = useState<boolean>(false);
  const [useCookie, setUseCookie] = useState<boolean>(false);
  const [addMode, setAddMode] = useState<boolean>(false);
  const [stackInfo, setStackInfo] = useState<any>({});

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    filterHandler();
    return () => {
      filterHandler();
    };
  }, [props.startDate, props.endDate, searchInput, tags, stackForCookie, stackForCookieUse]);

  const init = async () => {
    const students: any = await getStudents(props.classId);
    const recordTags: any = await getRecordTags(props.classId);
    if (recordTags.length !== 0) {
      setTags(recordTags[0]?.tags);
    }
    setTagsFilterData(props.stackRecords);
    setStudents(students);
  };

  const handleFilterTag = (i: number) => {
    let tempTags = [...tags];
    tempTags[i].check = !tags[i].check;

    setTags(tempTags);
  };

  const filterHandler = () => {
    let originArr: any = [...props.stackRecordsCopy];

    const aMilliseconds = moment(props.startDate).hour(0).minute(0).seconds(0).milliseconds(0);
    const bMilliseconds = moment(props.endDate).hour(24).minute(0).seconds(0).milliseconds(0);

    let tempArr: any = [];
    for (let i = 0; i < originArr?.length; i++) {
      if (
        Number(moment(originArr[i].reportDate).valueOf()) >= moment(aMilliseconds).valueOf() &&
        Number(originArr[i].reportDate.valueOf()) <= moment(bMilliseconds).valueOf()
      ) {
        tempArr.push(originArr[i]);
      }
    }
    originArr = [...tempArr];

    let tagFilter: any[] = tags.filter((el: any) => el.check);

    let filterArr: any = new Set();

    // 쿠키지급 && 태그 안걸린 경우
    if (tagFilter.length === 0 && stackForCookie) {
      const flatTags: any = [];

      if (!stackForCookieUse) {
        for (let i = 0; i < originArr.length; i++) {
          if (originArr[i].increaseCookie > 0 && originArr[i].tags.length === 0) {
            filterArr.add(originArr[i]);
          }
        }
      }

      if (stackForCookieUse) {
        for (let i = 0; i < originArr.length; i++) {
          if (originArr[i].increaseCookie !== 0 && originArr[i].tags.length === 0) {
            filterArr.add(originArr[i]);
          }
        }
      }

      originArr = [...filterArr];
    }

    // 쿠키지급X && 쿠키쓰기O && 태그 안걸린 경우
    if (tagFilter.length === 0 && !stackForCookie && stackForCookieUse) {
      const flatTags: any = [];

      for (let i = 0; i < originArr.length; i++) {
        if (originArr[i].increaseCookie < 0 && originArr[i].tags.length === 0) {
          filterArr.add(originArr[i]);
        }
      }

      originArr = [...filterArr];
    }

    // 쿠키지급 && 태그 걸린 경우
    if (tagFilter.length !== 0 && stackForCookie) {
      const flatTags: any = [];

      if (stackForCookieUse) {
        for (let i = 0; i < originArr.length; i++) {
          if (originArr[i].increaseCookie !== 0 && originArr[i].tags.length === 0) {
            filterArr.add(originArr[i]);
          }
        }

        for (let i = 0; i < originArr?.length; i++) {
          for (let k = 0; k < originArr[i]?.tags?.length; k++) {
            flatTags.push({
              i: i,
              tagName: tags[originArr[i].tags[k]]?.tagName,
            });
          }
        }

        for (let i = 0; i < flatTags?.length; i++) {
          for (let k = 0; k < tagFilter?.length; k++) {
            if (flatTags[i]?.tagName === tagFilter[k]?.tagName) {
              filterArr.add(originArr[flatTags[i]?.i]);
            }
          }
        }
      }

      if (!stackForCookieUse) {
        for (let i = 0; i < originArr.length; i++) {
          if (originArr[i].increaseCookie > 0 && originArr[i].tags.length === 0) {
            filterArr.add(originArr[i]);
          }
        }

        for (let i = 0; i < originArr?.length; i++) {
          for (let k = 0; k < originArr[i]?.tags?.length; k++) {
            flatTags.push({
              i: i,
              tagName: tags[originArr[i].tags[k]]?.tagName,
            });
          }
        }

        for (let i = 0; i < flatTags?.length; i++) {
          for (let k = 0; k < tagFilter?.length; k++) {
            if (flatTags[i]?.tagName === tagFilter[k]?.tagName) {
              filterArr.add(originArr[flatTags[i]?.i]);
            }
          }
        }
      }

      originArr = [...filterArr];
    }

    // 쿠키 지급 X && 쿠키 쓰기 X && 태그 걸린 경우
    if (tagFilter.length !== 0 && !stackForCookie && !stackForCookieUse) {
      const flatTags: any = [];

      for (let i = 0; i < originArr?.length; i++) {
        for (let k = 0; k < originArr[i]?.tags?.length; k++) {
          flatTags.push({
            i: i,
            tagName: tags[originArr[i].tags[k]]?.tagName,
          });
        }
      }

      for (let i = 0; i < flatTags?.length; i++) {
        for (let k = 0; k < tagFilter?.length; k++) {
          if (flatTags[i]?.tagName === tagFilter[k]?.tagName) {
            filterArr.add(originArr[flatTags[i]?.i]);
          }
        }
      }

      originArr = [...filterArr];
    }

    // 쿠키 지급 X && 쿠키 쓰기 O && 태그 걸린 경우
    if (tagFilter.length !== 0 && !stackForCookie && stackForCookieUse) {
      const flatTags: any = [];

      for (let i = 0; i < originArr.length; i++) {
        if (originArr[i].increaseCookie < 0 && originArr[i].tags.length === 0) {
          filterArr.add(originArr[i]);
        }
      }

      for (let i = 0; i < originArr?.length; i++) {
        for (let k = 0; k < originArr[i]?.tags?.length; k++) {
          flatTags.push({
            i: i,
            tagName: tags[originArr[i].tags[k]]?.tagName,
          });
        }
      }

      for (let i = 0; i < flatTags?.length; i++) {
        for (let k = 0; k < tagFilter?.length; k++) {
          if (flatTags[i]?.tagName === tagFilter[k]?.tagName) {
            filterArr.add(originArr[flatTags[i]?.i]);
          }
        }
      }

      // for (let i = 0; i < originArr.length; i++) {
      //   if (originArr[i].increaseCookie < 0 && originArr[i].tags.length === 0) {
      //     filterArr.add(originArr[i]);
      //   }
      // }

      // for (let i = 0; i < originArr?.length; i++) {
      //   for (let k = 0; k < originArr[i]?.tags?.length; k++) {
      //     flatTags.push({
      //       i: i,
      //       tagName: tags[originArr[i].tags[k]]?.tagName,
      //     });
      //   }
      // }

      // for (let i = 0; i < flatTags?.length; i++) {
      //   for (let k = 0; k < tagFilter?.length; k++) {
      //     if (flatTags[i]?.tagName === tagFilter[k]?.tagName) {
      //       filterArr.add(originArr[flatTags[i]?.i]);
      //     }
      //   }
      // }

      originArr = [...filterArr];
    }

    // 태그 걸려있는 경우
    // if (tagFilter.length !== 0) {
    //   const flatTags: any = [];

    //   for (let i = 0; i < originArr?.length; i++) {
    //     for (let k = 0; k < originArr[i]?.tags?.length; k++) {
    //       flatTags.push({
    //         i: i,
    //         tagName: tags[originArr[i].tags[k]]?.tagName,
    //       });
    //     }
    //   }

    //   for (let i = 0; i < flatTags?.length; i++) {
    //     for (let k = 0; k < tagFilter?.length; k++) {
    //       if (flatTags[i]?.tagName === tagFilter[k]?.tagName) {
    //         filterArr.add(originArr[flatTags[i]?.i]);
    //       }
    //     }
    //   }

    //   originArr = [...filterArr];
    // }

    if (searchInput !== "") {
      let tempArr: any = [];
      for (let i = 0; i < originArr?.length; i++) {
        for (let k = 0; k < originArr[i]?.studentsName?.length; k++) {
          if (originArr[i]?.studentsName[k]?.includes(searchInput)) {
            tempArr.push(originArr[i]);
          }
        }
      }
      props.setStackRecords(tempArr);
    } else {
      props.setStackRecords(originArr);
    }
  };

  // const filterHandler = () => {
  //   let originArr: any = [...props.stackRecordsCopy];

  //   const aMilliseconds = moment(props.startDate).hour(0).minute(0).seconds(0).milliseconds(0);
  //   const bMilliseconds = moment(props.endDate).hour(24).minute(0).seconds(0).milliseconds(0);

  //   let tempArr: any = [];
  //   for (let i = 0; i < originArr?.length; i++) {
  //     if (
  //       Number(moment(originArr[i].reportDate).valueOf()) >= moment(aMilliseconds).valueOf() &&
  //       Number(originArr[i].reportDate.valueOf()) <= moment(bMilliseconds).valueOf()
  //     ) {
  //       tempArr.push(originArr[i]);
  //     }
  //   }
  //   originArr = [...tempArr];

  //   let tagFilter: any[] = tags.filter((el: any) => el.check);

  //   let filterArr: any = new Set();

  //   if (tagFilter.length === 0 && stackForCookie) {
  //     const flatTags: any = [];

  //     for (let i = 0; i < originArr.length; i++) {
  //       // if (
  //       //   stackForCookieUse &&
  //       //   originArr[i].increaseCookie !== 0 &&
  //       //   originArr[i].tags.length === 0
  //       // ) {
  //       //   filterArr.add(originArr[i]);
  //       // }
  //       if (
  //         !stackForCookieUse &&
  //         originArr[i].increaseCookie > 0 &&
  //         originArr[i].tags.length === 0
  //       ) {
  //         filterArr.add(originArr[i]);
  //       }
  //     }

  //     originArr = [...filterArr];
  //   }
  //   if (tagFilter.length === 0 && !stackForCookie) {
  //     const flatTags: any = [];

  //     for (let i = 0; i < originArr.length; i++) {
  //       // if (
  //       //   stackForCookieUse &&
  //       //   originArr[i].increaseCookie < 0 &&
  //       //   originArr[i].tags.length === 0
  //       // ) {
  //       //   filterArr.add(originArr[i]);
  //       // }
  //       if (!stackForCookieUse && originArr[i].tags.length === 0) {
  //         filterArr.add(originArr[i]);
  //       }
  //     }

  //     originArr = [...filterArr];
  //   }

  //   if (tagFilter.length !== 0 && stackForCookie) {
  //     const flatTags: any = [];

  //     // for (let i = 0; i < originArr.length; i++) {
  //     //   // if (
  //     //   //   stackForCookieUse &&
  //     //   //   originArr[i].increaseCookie !== 0 &&
  //     //   //   originArr[i].tags.length === 0
  //     //   // ) {
  //     //   //   filterArr.add(originArr[i]);
  //     //   // }
  //     //   // if (
  //     //   //   !stackForCookieUse &&
  //     //   //   originArr[i].increaseCookie > 0 &&
  //     //   //   originArr[i].tags.length === 0
  //     //   // ) {
  //     //   //   filterArr.add(originArr[i]);
  //     //   // }
  //     // }

  //     for (let i = 0; i < originArr?.length; i++) {
  //       for (let k = 0; k < originArr[i]?.tags?.length; k++) {
  //         flatTags.push({
  //           i: i,
  //           tagName: tags[originArr[i].tags[k]]?.tagName,
  //         });
  //       }
  //     }

  //     for (let i = 0; i < flatTags?.length; i++) {
  //       for (let k = 0; k < tagFilter?.length; k++) {
  //         if (flatTags[i]?.tagName === tagFilter[k]?.tagName) {
  //           filterArr.add(originArr[flatTags[i]?.i]);
  //         }
  //       }
  //     }

  //     originArr = [...filterArr];
  //   }

  //   if (tagFilter.length !== 0 && !stackForCookie) {
  //     const flatTags: any = [];

  //     for (let i = 0; i < originArr.length; i++) {
  //       // if (
  //       //   stackForCookieUse &&
  //       //   originArr[i].increaseCookie < 0 &&
  //       //   originArr[i].tags.length === 0
  //       // ) {
  //       //   filterArr.add(originArr[i]);
  //       // }
  //       if (
  //         !stackForCookieUse &&
  //         originArr[i].increaseCookie !== 0 &&
  //         originArr[i].tags.length === 0
  //       ) {
  //         filterArr.add(originArr[i]);
  //       }
  //     }

  //     for (let i = 0; i < originArr?.length; i++) {
  //       for (let k = 0; k < originArr[i]?.tags?.length; k++) {
  //         flatTags.push({
  //           i: i,
  //           tagName: tags[originArr[i].tags[k]]?.tagName,
  //         });
  //       }
  //     }

  //     for (let i = 0; i < flatTags?.length; i++) {
  //       for (let k = 0; k < tagFilter?.length; k++) {
  //         if (flatTags[i]?.tagName === tagFilter[k]?.tagName) {
  //           filterArr.add(originArr[flatTags[i]?.i]);
  //         }
  //       }
  //     }

  //     originArr = [...filterArr];
  //   }

  //   if (searchInput !== "") {
  //     let tempArr: any = [];
  //     for (let i = 0; i < originArr?.length; i++) {
  //       for (let k = 0; k < originArr[i]?.studentsName?.length; k++) {
  //         if (originArr[i]?.studentsName[k]?.includes(searchInput)) {
  //           tempArr.push(originArr[i]);
  //         }
  //       }
  //     }
  //     props.setStackRecords(tempArr);
  //   } else {
  //     props.setStackRecords(originArr);
  //   }
  // };

  const handleSelectClassCookies = () => {
    let tempStudent = [...students];
    for (let i in students) {
      students[i].stackCheck = false;
    }

    setStudents(tempStudent);
    setStackPopup((prev: any) => {
      return {
        allStudentCheck: false,
        classCookies: !prev.classCookies,
      };
    });
  };

  const onAddReportBtn = () => {
    let tempStudents = [...students];
    let tempTags = [...tags];
    for (let i in tempStudents) {
      tempStudents[i].stackCheck = false;
    }
    for (let i in tempTags) {
      tempTags[i].tagSelected = false;
    }

    setReportBody("");
    setStackPopup((prev: any) => {
      return {
        classCookies: false,
        allStudentCheck: false,
      };
    });
    setAddMode(true);
    setTags(tempTags);
    setDateSelected(new Date());
    setStudents(tempStudents);
    setCreate(true);
    setModal(true);
  };

  const handleCheckRecords = (stack: any, i: number) => {
    // 삭제시
    if (deleteMode) {
      let tempArr = [...props.stackRecords];
      tempArr[i].checked = !props.stackRecords[i].checked;
      const tempArrCheckedLength = tempArr.filter((item: any) => item.checked).length;
      if (tempArrCheckedLength === tempArr.length) setAllCheck(true);
      else setAllCheck(false);
      props.setStackRecords(tempArr);
    } else {
      // 수정시
      let tempStudents = [...students];
      for (let i in tempStudents) {
        tempStudents[i].stackCheck = false;
        for (let k in stack.studentsCode) {
          if (students[i].code === stack.studentsCode[k]) {
            tempStudents[i].stackCheck = true;
          }
        }
      }
      let tempTags = [...tags];
      for (let i in tempTags) {
        tempTags[i].tagSelected = false;
      }
      for (let i = 0; i < stack?.tags?.length; i++) {
        for (let k = 0; k < tempTags?.length; k++) {
          if (stack.tags[i] === k) {
            tempTags[stack.tags[i]].tagSelected = true;
          }
        }
      }
      if (stack.studentsCode.length === 0) {
        setStackPopup((prev: any) => {
          return {
            ...prev,
            classCookies: true,
          };
        });
      } else {
        setStackPopup((prev: any) => {
          return {
            ...prev,
            classCookies: false,
          };
        });
      }
      if (stack.studentsCode.length !== students.length) {
        setStackPopup((prev: any) => {
          return {
            ...prev,
            allStudentCheck: false,
          };
        });
      }
      if (stack.studentsCode.length === students.length) {
        setStackPopup((prev: any) => {
          return {
            ...prev,
            allStudentCheck: true,
          };
        });
      }

      // ==================새로 추가====================
      // stack.type==="use cookie"일 경우 쓴 쿠키 팝업 열기
      // =============================================

      if (stack.type === "useCookies") {
        setUseCookie(true);
      } else {
        setModal(true);
      }

      setTags(tempTags);
      setEditId(stack._id);
      setCreate(false);
      setDateSelected(time3(stack.reportDate));
      setCookieSelected(stack.increaseCookie);
      setStudents(tempStudents);
      setReportBody(stack.reportBody);
      setStackInfo(stack);
    }
  };

  const handleAllCheckReport = () => {
    let tempStudents = [...students];
    if (!stackPopup.allStudentCheck) {
      for (let i in tempStudents) {
        tempStudents[i].stackCheck = true;
      }
    } else {
      for (let i in tempStudents) {
        tempStudents[i].stackCheck = false;
      }
    }

    setStackPopup((prev: any) => {
      return {
        // classCookies: prev.allStudentCheck ? true : false,
        classCookies: false,
        allStudentCheck: !prev.allStudentCheck,
      };
    });
    setStudents(tempStudents);
  };

  const handleStudentCheck = (student: any, i: number) => {
    if (useCookie) return;
    const tempStudents = [...students];
    tempStudents[i].stackCheck = !students[i].stackCheck;
    const checkedList = tempStudents.filter((el) => el.stackCheck);

    if (checkedList.length !== 0) {
      setStackPopup((prev: any) => {
        return {
          ...prev,
          classCookies: false,
        };
      });
    }

    if (checkedList.length === tempStudents.length) {
      setStackPopup((prev: any) => {
        return {
          ...prev,
          allStudentCheck: true,
        };
      });
    } else {
      setStackPopup((prev: any) => {
        return {
          ...prev,
          allStudentCheck: false,
        };
      });
    }

    setStudents(tempStudents);
  };

  const onChangeCookie = (e: any) => {
    const { value }: any = e.target;

    const numParse = parseInt(e.nativeEvent.data);
    if (e.nativeEvent.data === null) {
      setCookieSelected(value);
    }

    if (!isNaN(numParse)) {
      setCookieSelected(value);
    }
  };

  const handleCheckAll = () => {
    let tempArr = [...props.stackRecords];
    for (let i in tempArr) {
      tempArr[i].checked = allCheck ? false : true;
    }
    props.setStackRecords(tempArr);
    setAllCheck((prev: boolean) => !prev);
  };

  const handleSubmitReport = async () => {
    const tempStudents = [];
    for (let i in students) {
      if (students[i].stackCheck) {
        tempStudents.push(students[i].code);
      }
    }
    if (!stackPopup.classCookies && tempStudents.length === 0) {
      return alert(t(`Please select a student or...`));
    }

    let tempTags = [];
    for (let i = 0; i < tags?.length; i++) {
      if (tags[i]?.tagName && tags[i].tagSelected) {
        tempTags.push(i);
      }
    }

    const postResult: any = await postStackRecord({
      studentsCode: tempStudents,
      body: {
        reportBody,
        tags: tempTags,
        classId: props.classId,
        reportDate: dateSelected === "" ? moment().valueOf() : moment(dateSelected).valueOf(),
        increaseCookie: cookieSelected ? Number(cookieSelected) : 0,
        studentsCode: tempStudents,
      },
      increaseCookie: cookieSelected ? Number(cookieSelected) : 0,
    });

    const dataResult = postResult;
    const studentsArr = Object.keys(dataResult?.students);

    // dataresult badge 정보 받아서 푸시알림 보내기
    const tempPushArr: any = [];

    for (let i = 0; i < studentsArr?.length; i++) {
      for (let k = 0; k < dataResult.students[studentsArr[i]].badgeList.length; k++) {
        if (dataResult.students[studentsArr[i]]?.deviceToken) {
          tempPushArr.push({
            token: dataResult.students[studentsArr[i]]?.deviceToken2,
            notification: {
              title: `🎖${
                dataResult.badge.badges[dataResult.students[studentsArr[i]].badgeList[k]].title
              } ${t(`Badge`)}🎖`, // 뱃지
              body: t(`Congratulations Check it out`), // "축하합니다! 확인해보세요!",
            },
            data: {
              type: "project",
              code: dataResult.students[studentsArr[i]]?.code,
            },
          });
        }
      }
    }
    postNotificationAll(tempPushArr);

    setModal(false);
    context.handleStateChange("class", "");
    props.init();
  };

  const handleEditReport = async (): Promise<void> => {
    const tempStudents = [];
    for (let i in students) {
      if (students[i].stackCheck) {
        tempStudents.push(students[i].code);
      }
    }

    if (!stackPopup.classCookies && tempStudents.length === 0) {
      // return alert("대상을 선택해 주세요!");
      return alert(t(`Please select a student or...`));
    }

    let tempTags = [];
    for (let i = 0; i < tags?.length; i++) {
      if (tags[i]?.tagName && tags[i].tagSelected) {
        tempTags.push(i);
      }
    }

    const postResult: any = await modifyStackRecord({
      studentsCode: tempStudents,
      _id: editId,
      body: {
        reportBody,
        tags: tempTags,
        classId: props.classId,
        reportDate: new Date(dateSelected).getTime(),
        increaseCookie: cookieSelected ? Number(cookieSelected) : 0,
        studentsCode: tempStudents,
      },
      increaseCookie: cookieSelected ? Number(cookieSelected) : 0,
    });

    const dataResult = postResult;
    const studentsArr = Object.keys(dataResult?.students);
    const tempPushArr: any = [];

    for (let i = 0; i < studentsArr?.length; i++) {
      for (let k = 0; k < dataResult?.students[studentsArr[i]]?.badgeList?.length; k++) {
        tempPushArr.push({
          token: dataResult.students[studentsArr[i]].deviceToken2,
          notification: {
            title: `🎖${
              dataResult.badge.badges[dataResult.students[studentsArr[i]].badgeList[k]].title
            } ${t(`Badge`)}`, // 뱃지
            body: t(`Congratulations Check it out`), // `축하합니다! 확인해보세요!`,
          },
          data: {
            type: "project",
            code: dataResult.students[studentsArr[i]].code,
          },
        });
      }
    }
    postNotificationAll(tempPushArr);

    context.handleStateChange("class", "");
    props.init();
    setUseCookie(false);
    setModal(false);
  };

  const handleDeleteStackRecord = async () => {
    const confirm = window.confirm(
      // "⛔️ 누가기록이 영구 삭제됩니다. 데이터 복구는 절대 불가합니다.\n삭제하시겠습니까? ⛔️",
      `⛔️ ${t(`Your anecdotal notes will be permanently deleted...`)} ⛔️`,
    );
    if (confirm) {
      const idList = [];
      const idList2 = [];
      const deleteArr = props.stackRecords.filter(
        (item: any) => item.checked && item.increaseCookie >= 0,
      );
      const deleteCookieArr = props.stackRecords.filter(
        (item: any) => item.checked && item.increaseCookie < 0,
      );

      for (let i in deleteArr) {
        idList.push(deleteArr[i]._id);
      }
      for (let i in deleteCookieArr) {
        idList2.push(deleteCookieArr[i]._id);
      }
      await deleteStackRecords({
        _ids: idList,
        classId: props.classId,
      });
      await deleteStackRecords2({
        _ids: idList2,
        classId: props.classId,
      });

      if (deleteArr.length === props.stackRecords.length) {
        props.setStackRecords([]);
      }

      setDeleteMode(false);
      context.handleStateChange("class", "");
      props.init();
    } else {
      setDeleteMode(false);
      context.handleStateChange("class", "");
    }
  };

  const handleClickTag = (i: any) => {
    setIndex(i);
    setTagModal(true);
    setTag("");
  };

  const handleAddTag = async (): Promise<void> => {
    let tempTags = [];
    for (let i in tags) {
      tempTags.push({ tagName: tags[i].tagName });
    }
    tempTags[index].tagName = tag;

    const insertTags = await postRecordTags({
      classId: props.classId,
      tags: tempTags,
    });

    if (tagEdit && !tags[index]?.tagName) {
      setTagEdit(false);
    }
    setTags(tempTags);
    setTagModal(false);
    setTag("");
  };

  const handleTagSelect = (i: number) => {
    let tempTags = [...tags];
    tempTags[i].tagSelected = !tags[i].tagSelected;
    setTags(tempTags);
  };

  const handleEditTag = (i: number) => {
    setIndex(i);
    setTag(tags[i].tagName);
    setTagModal(true);
  };

  const handleDeleteTag = async () => {
    const confirm: any = window.confirm(
      // "해당 태그가 달린 모든 기록에서 이 태그가 완전히 삭제됩니다. 진행하시겠습니까?",
      t(`This action will completely remove the tag from all...`),
    );
    if (confirm) {
      const deleteResult: any = await deleteRecordTag({
        classId: props.classId,
        deleteTagIndex: index,
      });
    } else {
      return;
    }

    setTagEdit(false);
    setTagModal(false);
    init();
    props.init();
  };

  const downListener = () => {
    setMoved(false);
  };

  const moveListener = () => {
    setMoved(true);
  };

  return (
    <>
      {modal && (
        <Modal
          show={modal}
          onClose={() => {
            setCookieSelected(0);
            setModal(false);
            setAddMode(false);
          }}
        >
          <h1>{modal}</h1>
          <div
            className="stack_report_container"
            style={{ width: "38vw", height: "80vh", overflow: "auto", backgroundColor: "#fff" }}
          >
            <h2 className="text-center">{t(`Anecdotal Record`)}</h2>
            <section className="display-f">
              <div style={{ width: 90 }}>
                <p style={{ fontSize: 18 }} className="font-500">
                  {/* 대상 */}
                  {t(`To`)}
                </p>
              </div>
              <div style={{ width: "100%" }}>
                <div className="pl-20 display-f align-center">
                  <div className="select_stuent_c" onClick={handleSelectClassCookies}>
                    {stackPopup.classCookies && <div className="select_stuent_c_fill" />}
                  </div>
                  <p className="cursor" onClick={handleSelectClassCookies}>
                    {/* 우리반 쿠키함 */}
                    {t(`Class cookie jar`)}
                  </p>
                </div>
                <div className="mt-20">
                  <div className="display-f flex-wrap students_wrapper">
                    {students.map((student: any, i: number) => (
                      <div key={i} className="select_student_list">
                        <div
                          onClick={() => handleStudentCheck(student, i)}
                          className="select_stuent_c"
                        >
                          {student.stackCheck && <div className="select_stuent_c_fill" />}
                        </div>
                        <p onClick={() => handleStudentCheck(student, i)} className="cursor">
                          {student.name}
                        </p>
                      </div>
                    ))}
                  </div>

                  <div
                    className="display-f align-center"
                    style={{
                      paddingLeft: 20,
                      paddingRight: 20,
                      flexWrap: "wrap",
                    }}
                  >
                    <div className="display-f align-center mr-15  mt-10" style={{ flex: 1 }}>
                      <p className="mr-10 font-500" style={{ width: 30 }}>
                        {/* 날짜 */}
                        {t(`Date`)}
                      </p>
                      <input
                        type="date"
                        value={`${time3(dateSelected)}`}
                        onChange={(e: any) => setDateSelected(e.target.value)}
                      />
                    </div>

                    <div className="display-f align-center mt-10" style={{ flex: 2 }}>
                      <p className="mr-10 font-500" style={{ width: 30 }}>
                        {/* 태그 */}
                        {t(`Tag List`)}
                      </p>
                      <div
                        className="display-f align-center"
                        style={{ width: "calc(100% - 30px)", height: 28 }}
                      >
                        {!addMode && cookieSelected < 0 && (
                          <button
                            onClick={() => setUseCookie(true)}
                            className={`stack_tag_btn ${useCookie && "brown"}`}
                          >
                            {/* 쿠키쓰기 */}
                            <p className="font-10">🍪 {t("Spend cookies")}</p>
                          </button>
                        )}
                        {tags?.map((tag: any, i: number) => (
                          <>
                            {tag.tagName && (
                              <button
                                onClick={() => handleTagSelect(i)}
                                key={i}
                                className={`stack_tag_btn ${tag.tagSelected && "blue white"}`}
                              >
                                {tag.tagName}
                              </button>
                            )}
                          </>
                        ))}

                        {tags.filter((el: any) => el.tagName).length === 0 && (
                          <p style={{ fontSize: 13, color: "#979797" }}>
                            {/* 태그가 없습니다. */}
                            {t(`No tags`)}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="display-f mt-30">
              <div style={{ width: 90 }}>
                <p style={{ fontSize: 18 }} className="font-500">
                  {/* 기록 */}
                  {t(`Description`)}
                </p>
              </div>

              <div style={{ width: "100%" }}>
                <div>
                  <div
                    className="display-f flex-wrap students_wrapper"
                    style={{ margin: "0 20px" }}
                  >
                    <textarea
                      value={reportBody}
                      onChange={(e: any) => setReportBody(e.target.value)}
                      className="report_textarea"
                    />
                  </div>
                </div>
              </div>
            </section>

            <section className="display-f mt-30">
              <div style={{ width: 90 }}>
                <p style={{ fontSize: 18 }} className="font-500">
                  {/* 쿠키 */}
                  {t(`Cookie`)}
                </p>
              </div>

              <div style={{ width: "100%" }}>
                <div>
                  <div className="display-f flex-wrap" style={{ margin: "0 20px" }}>
                    <div>
                      <div style={{ width: "100%" }}>
                        <div className="display-f align-center">
                          <button
                            onClick={() => setCookieSelected(1)}
                            className={`${
                              cookieSelected !== 1 && "cookie_border_none"
                            } cookie_circle cursor display-f-center mr-17`}
                          >
                            {cookieSelected === 1 ? (
                              <img className="cookie1" src={cookie1S} alt="cookie1S" />
                            ) : (
                              <img className="cookie1" src={cookie1G} alt="cookie1G" />
                            )}
                          </button>
                          <button
                            onClick={() => setCookieSelected(2)}
                            className={`${
                              cookieSelected !== 2 && "cookie_border_none"
                            } cookie_circle cursor display-f-center mr-17`}
                          >
                            {cookieSelected === 2 ? (
                              <img className="cookie2" src={cookie2S} alt="cookie2S" />
                            ) : (
                              <img className="cookie2" src={cookie2G} alt="cookie2G" />
                            )}
                          </button>
                          <button
                            onClick={() => setCookieSelected(3)}
                            className={`${
                              cookieSelected !== 3 && "cookie_border_none"
                            } cookie_circle cursor display-f-center mr-17`}
                          >
                            {cookieSelected === 3 ? (
                              <img className="cookie3" src={cookie3S} alt="cookie3S" />
                            ) : (
                              <img className="cookie3" src={cookie3G} alt="cookie3G" />
                            )}
                          </button>

                          <div>
                            <input
                              value={cookieSelected}
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                onChangeCookie(e)
                              }
                              className="add_cookie_input"
                            />
                            <span>
                              {/* ~개 */}
                              {t(`pcs`)}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <div className="mt-30 mb-17 display-f justify-center align-center">
              {create && (
                <button
                  onClick={handleSubmitReport}
                  className="mr-10 stack_report_btn report_main_bg"
                >
                  {/* 저장 */}
                  {t(`Save`)}
                </button>
              )}
              {!create && (
                <button
                  onClick={handleEditReport}
                  className="mr-10 stack_report_btn report_main_bg"
                >
                  {/* 수정 */}
                  {t(`Edit`)}
                </button>
              )}
              <button
                onClick={() => {
                  setCookieSelected(0);
                  setModal(false);
                }}
                className="report_cancel_bg stack_report_btn"
              >
                {/* 취소 */}
                {t(`Cancel`)}
              </button>
            </div>
          </div>
        </Modal>
      )}
      {/* 쿠키쓰기 모달 새로 추가 */}
      {useCookie && (
        <Modal
          show={modal}
          onClose={() => {
            setCookieSelected(0);
            setUseCookie(false);
          }}
        >
          <h1>{modal}</h1>
          <div
            className="stack_report_container"
            style={{ width: "38vw", height: "80vh", overflow: "auto", backgroundColor: "#fff" }}
          >
            <h2 className="text-center">{t(`Spend cookies`)}</h2>

            <section>
              <div className="display-f">
                <div style={{ width: 90 }}>
                  <p style={{ fontSize: 18 }} className="font-500">
                    {/* 대상 */}
                    {t(`To`)}
                  </p>
                </div>

                <div style={{ width: "100%" }}>
                  <div className="mt-20">
                    {/* <div className="pl-20 display-f align-center">
                    <div className="select_stuent_c" onClick={handleAllCheckReport}>
                      {stackPopup.allStudentCheck && <div className="select_stuent_c_fill" />}
                    </div>
                    <p className="cursor" onClick={handleAllCheckReport}>

                      {stackPopup.allStudentCheck ? t(`Deselect All`) : t(`Select All`)}
                    </p>
                  </div> */}
                    <div className="pl-20 display-f align-center">
                      <div className="select_stuent_c">
                        {stackPopup.classCookies && <div className="select_stuent_c_fill" />}
                      </div>
                      <p className="cursor">
                        {/* 우리반 쿠키함 */}
                        {t(`Class cookie jar`)}
                      </p>
                    </div>
                    <div className="display-f flex-wrap students_wrapper">
                      {students.map((student: any, i: number) => (
                        <div key={i} className="select_student_list">
                          <div
                            onClick={() => handleStudentCheck(student, i)}
                            className="select_stuent_c"
                          >
                            {student.stackCheck && <div className="select_stuent_c_fill" />}
                          </div>
                          <p onClick={() => handleStudentCheck(student, i)} className="cursor">
                            {student.name}
                          </p>
                        </div>
                      ))}
                    </div>

                    <div
                      className="display-f align-center"
                      style={{
                        paddingLeft: 20,
                        paddingRight: 20,
                        flexWrap: "wrap",
                      }}
                    >
                      <div className="display-f align-center mr-15  mt-10" style={{ flex: 1 }}>
                        <p className="mr-10 font-500" style={{ width: 30 }}>
                          {/* 날짜 */}
                          {t(`Date`)}
                        </p>
                        <input
                          type="date"
                          value={`${time3(dateSelected)}`}
                          onChange={(e: any) => setDateSelected(e.target.value)}
                        />
                      </div>

                      <div className="display-f align-center mt-10" style={{ flex: 2 }}>
                        <p className="mr-10 font-500" style={{ width: 30 }}>
                          {/* 태그 */}
                          {t(`Tag List`)}
                        </p>
                        <div
                          className="display-f align-center"
                          style={{ width: "calc(100% - 30px)", height: 28 }}
                        >
                          <button
                            onClick={() => setUseCookie(true)}
                            className={`stack_tag_btn ${useCookie && "brown"}`}
                          >
                            <p className="font-10">🍪 {t("Spend cookies")}</p>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="display-f mt-30">
              <div style={{ width: 90 }}>
                <p style={{ fontSize: 18 }} className="font-500">
                  {/* 기록 */}
                  {t(`Description`)}
                </p>
              </div>

              <div style={{ width: "100%" }}>
                <div>
                  <div
                    className="display-f flex-wrap students_wrapper"
                    style={{ margin: "0 20px" }}
                  >
                    <textarea
                      value={reportBody}
                      onChange={(e: any) => setReportBody(e.target.value)}
                      className="report_textarea"
                    />
                  </div>
                </div>
              </div>
            </section>

            <section className="display-f mt-30">
              <div style={{ width: 90 }}>
                <p style={{ fontSize: 18 }} className="font-500">
                  {/* 쿠키 */}
                  {t(`Used cookies`)}
                </p>
              </div>

              <div style={{ width: "100%" }}>
                <div>
                  <div className="display-f flex-wrap" style={{ margin: "0 20px" }}>
                    <div>
                      <div style={{ width: "100%" }}>
                        <div className="display-f align-center">
                          <div>
                            <input
                              readOnly={true}
                              value={stackInfo.useCookie ? -cookieSelected : cookieSelected}
                              // onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                              //   onChangeCookie(e)
                              // }
                              className="add_cookie_input"
                            />
                            <span>
                              {/* ~개 */}
                              {t(`pcs`)}
                            </span>

                            <p className="mt-4 text-red font-12">
                              {/* '쿠키 쓰기' 기록을 삭제하고 싶다면 '기록 삭제' 해주세요. */}
                              {t(`If you want to delete the spent cookies history...`)}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <div className="mt-30 mb-17 display-f justify-center align-center">
              {create && (
                <button
                  onClick={handleSubmitReport}
                  className="mr-10 stack_report_btn report_main_bg"
                >
                  {/* 저장 */}
                  {t(`Save`)}
                </button>
              )}
              {!create && (
                <button
                  onClick={handleEditReport}
                  className="mr-10 stack_report_btn report_main_bg"
                >
                  {/* 수정  */}
                  {t(`Edit`)}
                </button>
              )}
              <button
                onClick={() => {
                  setCookieSelected(0);
                  setUseCookie(false);
                }}
                className="report_cancel_bg stack_report_btn"
              >
                {/* 취소 */}
                {t(`Cancel`)}
              </button>
            </div>
          </div>
        </Modal>
      )}

      <PopupModal openModal={tagModal} setOpenModal={setTagModal} styles={{ marginTop: 200 }}>
        <div>
          <div className="text-center" style={{ marginTop: -10 }}>
            <h2 style={{ marginBottom: 0 }}>{t(`Tag`)}</h2>
            <p style={{ marginBottom: 0, fontSize: 15 }} className="font-500 mt-5">
              {/* 분류기준 수정/삭제 : 추가 */}
              {tags[index]?.tagName ? t(`Edit Tag`) : t(`Add`)}
            </p>
          </div>

          <div className="mt-20 display-f justify-center align-center">
            <p className="mr-10 bold" style={{ fontSize: 20, fontWeight: 600 }}>
              {/* 태그명 */}
              {t(`Tag name`)} :{" "}
            </p>
            <input
              value={tag}
              onChange={(e: React.ChangeEvent<any>) => {
                if (e.target.value.length > 4) {
                  return;
                } else {
                  setTag(e.target.value);
                }
              }}
              className="tag_input"
            />
          </div>

          <p className="card-text-red text-center mt-9" style={{ fontSize: 12 }}>
            {/* 최대 4글자 */}
            {t(`Up to 4 characters`)}
          </p>

          <div className="mt-20" style={{ marginBottom: 10 }}>
            <div className="display-f justify-center align-center">
              <button className="mr-10 badge-btn blue" onClick={handleAddTag}>
                {/* 수정 : 추가 */}
                {tags[index]?.tagName ? t(`Edit`) : t(`Add`)}
              </button>
              <button onClick={() => setTagModal(false)} className="badge-btn">
                {/* 취소 */}
                {t(`Cancel`)}
              </button>
            </div>

            {tags[index]?.tagName && (
              <div className="text-center mt-10">
                <button onClick={handleDeleteTag} className="badge-btn-delete">
                  {/* 삭제 */}
                  {t(`Delete`)}
                </button>
              </div>
            )}
            {/* )} */}
          </div>
        </div>
      </PopupModal>

      <div
        style={{
          padding: "0 20px",
          maxHeight: "78vh",
        }}
      >
        <div style={{ position: "sticky", top: 0, zIndex: 2 }}>
          <div className="display-f align-center stack_report_top_wrapper">
            <div style={{ flex: 1 }}>
              <p className="mr-10 font-500" style={{ fontSize: 15 }}>
                {/* 조회 기간 */}
                {t(`Date range`)}
              </p>

              <APDateRangePicker
                dateRange={props.dateRange}
                startDate={props.startDate}
                endDate={props.endDate}
                setStartDate={props.setStartDate}
                setEndDate={props.setEndDate}
              />
            </div>

            <div style={{ flex: 2 }}>
              <p className="mr-10 font-500" style={{ fontSize: 15 }}>
                {/* 태그 목록 */}
                {t(`Tag List`)}
              </p>
              <div className="display-f text-center mt-5">
                {/* 쿠키 */}
                <button
                  onClick={() => setStackForCookie((prev: boolean) => !prev)}
                  className={`stack_record_cookie stack_tag_btn mr-5 cursor record_tag_btn_width2
                           display-f align-center ${stackForCookie && "brown white"}
                      `}
                >
                  <img src={cookie1} style={{ width: 18, height: "auto" }} />
                  <p className="font-12">
                    {/* 쿠키 지급 */}
                    {t(`Reward Cookies`)}
                  </p>
                </button>
                <button
                  onClick={() => setStackForCookieUse((prev: boolean) => !prev)}
                  className={`stack_record_cookie_use stack_tag_btn mr-5 cursor record_tag_btn_width2
                  display-f text-red align-center ${stackForCookieUse && "bg-red white"}
             `}
                >
                  <img src={cookie1} style={{ width: 18, height: "auto" }} />
                  <p className="font-12">
                    {/* 쿠키 쓰기 */}
                    {t("Spend cookies")}
                  </p>
                </button>

                {tags?.map((tag: any, i: number) => {
                  if (tag.tagName) {
                    return (
                      <button
                        key={i}
                        onClick={() => {
                          if (tagEdit) {
                            handleEditTag(i);
                          } else {
                            handleFilterTag(i);
                          }
                        }}
                        className={`stack_record_unchecked stack_tag_btn white mr-5 cursor record_tag_btn_width ${
                          tag.check && "blue border-none"
                        }`}
                      >
                        <p>{tag.tagName}</p>
                      </button>
                    );
                  } else {
                    return (
                      <>
                        <div
                          onClick={() => handleClickTag(i)}
                          key={i}
                          className="tag_btn2 mr-5 cursor"
                          style={{ height: 30 }}
                        >
                          <p style={{ color: "#979797", fontSize: 14 }}>+</p>
                        </div>
                        {/* {tagEdit && (
                          <div
                            onClick={() => handleClickTag(i)}
                            key={i}
                            className="tag_btn mr-5 cursor"
                            style={{ height: 30, width: 80 }}
                          >
                            <p style={{ color: "#979797", fontSize: 14 }}>+</p>
                          </div>
                        )} */}
                        {/* <button
                          className={`stack_record_unchecked stack_tag_btn white mr-5 cursor record_tag_btn_width`}
                        >
                          <p>쿠키지급</p>
                        </button>
                        <div
                          onClick={() => handleClickTag(i)}
                          key={i}
                          className="tag_btn mr-5 cursor"
                          style={{ height: 30, width: 80 }}
                        >
                          <p style={{ color: "#979797", fontSize: 14 }}>+</p>
                        </div> */}
                      </>
                    );
                  }
                })}

                {!tagEdit && (
                  <button onClick={() => setTagEdit(true)} className="stack_record_tag_btn">
                    {/* 수정 */}
                    {t(`Edit`)}
                  </button>
                )}
                {tagEdit && (
                  <button onClick={() => setTagEdit(false)} className="stack_record_tag_btn">
                    {/* 확인 */}
                    {t(`Confirm`)}
                  </button>
                )}
              </div>

              {tagEdit && (
                <p className="mt-5" style={{ fontSize: 12, color: "#979797" }}>
                  {/* 태그를 클릭하여 수정해 주세요. */}
                  {t(`Click on tag to edit it`)}
                </p>
              )}
            </div>

            <div style={{ flex: 1 }}>
              <p
                className="mr-10 font-500"
                style={{ textAlign: "right", fontSize: 15, height: 20 }}
              />
              <div className="display-f text-center mt-5" style={{ justifyContent: "flex-end" }}>
                <form className="mr-10 position-r">
                  <img className="search_icon2 position-a cursor" src={search} alt="search" />

                  <input
                    value={searchInput}
                    placeholder={t(`Search for a student`)}
                    onChange={(e: any) => setSearchInput(e.target.value)}
                    type="text"
                    className="category_searchInput2 pl-40 pr-10"
                  />
                </form>
                {!deleteMode && (
                  <>
                    <button className="report_btn_blue mr-5" onClick={onAddReportBtn}>
                      <p className="font-12">{t(`Add Record`)}</p>
                    </button>
                    <button onClick={() => setDeleteMode(true)} className="report_btn_red">
                      <p className="font-12">{t(`Delete Record`)}</p>
                    </button>
                  </>
                )}
                {deleteMode && (
                  <>
                    <button onClick={handleDeleteStackRecord} className="report_btn_red mr-5">
                      <p className="font-12">
                        {/* 영구 삭제 */}
                        {t(`Delete`)}
                      </p>
                    </button>
                    <button onClick={() => setDeleteMode(false)} className="report_btn_gray">
                      <p className="font-12">
                        {/* 취소 */}
                        {t(`Cancel`)}
                      </p>
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* table head */}
        <div className="display-f stack_report_table" style={{ position: "sticky", top: 0 }}>
          <div className="display-f" style={{ width: "100%", backgroundColor: "#ececec" }}>
            {deleteMode && (
              <div
                className="text-center"
                onClick={handleCheckAll}
                style={{
                  width: "3%",
                  border: "1px solid #979797",
                  borderRight: "none",
                }}
              >
                <p>{t(`All`)}</p>
                <div className="mt-5 display-f align-center justify-center">
                  <div className="select_stuent_c" style={{ backgroundColor: "#fff", margin: 0 }}>
                    {allCheck && <div className="select_stuent_c_fill" />}
                  </div>
                </div>
              </div>
            )}
            <div className="header_folder border_left" style={{ width: "9%" }}>
              {/* 날짜 */}
              {t(`Date`)}
            </div>
            <div className="header_folder" style={{ width: "12.5%" }}>
              {/* 태그 목록 */}
              {t(`Tag List`)}
            </div>
            <div className="header_folder" style={{ width: "25%" }}>
              {/* 대상 */}
              {t(`To`)}
            </div>
            <div className="header_folder" style={{ width: "5%" }}>
              {/* 쿠키 수 */}
              {t(`Cookies`)}
            </div>
            <div className="header_folder" style={{ width: "53.5%" }}>
              <div className="text-center">
                <p>{t(`Details`)}</p>
                <p style={{ fontSize: 12, fontWeight: 300, color: "gray" }}>
                  {/* 클릭하여 수정하실 수 있습니다. */}
                  {t(`You can edit the record by clicking on any field`)}
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* 날짜, 분류, 대상, 쿠키 수, 내용 */}
        <div className="stack_report_table page_report_body_stack">
          {props.stackRecords.map((stack: any, i: number) => (
            <div
              key={i}
              onClick={() => handleCheckRecords(stack, i)}
              style={{ width: "100%" }}
              className="display-f cursor"
            >
              {deleteMode && (
                <div className="display-f align-center justify-center stack_record_check">
                  <div
                    className="display-f align-center justify-center position-r"
                    style={{ zIndex: 1 }}
                  >
                    <div className="select_stuent_c" style={{ margin: 0 }}>
                      {stack.checked && <div className="select_stuent_c_fill" />}
                    </div>
                  </div>
                </div>
              )}
              <div className="student_table_cell border_left" style={{ width: "9%" }}>
                {time1(stack.reportDate)}
              </div>
              <div className="student_table_cell" style={{ width: "12.5%" }}>
                {stack?.tags.length !== 0 &&
                  stack?.tags?.map((name: any, index: number) => (
                    <span key={index}>
                      {tags[name].tagName}
                      {stack.tags.length - 1 !== index && ", "}
                    </span>
                  ))}
                {stack?.tags.length === 0 && stack.increaseCookie > 0 && (
                  <p style={{ color: "#9b7651" }}>
                    {/* 쿠키 지급 */}
                    🍪 {t(`Reward Cookies`)}
                  </p>
                )}
                {stack?.tags.length === 0 && stack.increaseCookie < 0 && (
                  <p style={{ color: "#f25e5e" }}>
                    {/* 쿠키 쓰기 */}
                    {t(`Spend cookies`)}
                  </p>
                )}
              </div>
              <div className="student_table_cell2">
                {stack?.studentsName?.length !== 0 &&
                  stack?.studentsName.map((name: string, index: number) => (
                    <span key={index} style={{ marginRight: 4 }}>
                      {name}
                      {index !== stack.studentsName.length - 1 && ","}
                    </span>
                  ))}
                {stack?.studentsName.length === 0 && <p>우리반 쿠키함</p>}
              </div>
              <div className="student_table_cell" style={{ width: "5%" }}>
                <span className="text-red">
                  {!stack.classCookie &&
                    stack.increaseCookie < 0 &&
                    stack.useCookie &&
                    -stack.increaseCookie}
                </span>
                <span className="text-red">
                  {!stack.classCookie &&
                    stack.increaseCookie < 0 &&
                    !stack.useCookie &&
                    stack.increaseCookie}
                </span>
                {!stack.classCookie && stack.increaseCookie > 0 && stack.increaseCookie}

                {/* 우리반 쿠키함일 경우 */}
                <span className="text-red">
                  {stack.classCookie &&
                    stack.increaseCookie < 0 &&
                    stack.useCookie &&
                    -stack.increaseCookie}
                </span>
                <span className="text-red">
                  {stack.classCookie &&
                    stack.increaseCookie < 0 &&
                    !stack.useCookie &&
                    stack.increaseCookie}
                </span>
                {stack.classCookie && stack.increaseCookie > 0 && stack.increaseCookie}
              </div>
              <div
                onMouseMove={moveListener}
                onMouseDown={downListener}
                onClick={(event: any) => {
                  if (movedNext) {
                    setMovedNext(false);
                    event.stopPropagation();
                    return;
                  }
                  if (moved) {
                    setMovedNext(true);
                    event.stopPropagation();
                  }
                }}
                className="student_table_cell"
                style={{ width: "53.5%" }}
              >
                <div style={{ padding: "0 10px", cursor: "text" }}>{stack.reportBody}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

const Project = (props: any) => {
  const history = useHistory();
  const context = useContext<IMainContext>(MainContext);
  const [tags, setTags] = useState<any>([{}, {}, {}, {}, {}, {}, {}, {}, {}, {}]);
  const [searchInput, setSearchInput] = useState<string>("");
  const [modal, setModal] = useState<boolean>(false);
  const [projectOfStudent, setProjectOfStudent] = useState<any>({});
  const [studentBodyFalse, setStudentBodyFalse] = useState<boolean>(false);
  const [confirmType, setConfirmType] = useState<string>("");
  const [cookieSelected, setCookieSelected] = useState<number>(0);
  const [score, setScore] = useState<string>("");
  const [rejectReason, setRejectReason] = useState<string>("");
  const [feedback, setFeedback] = useState<string>("");
  const [album, setAlbum] = useState<boolean>(false);
  const [albumData, setAlbumData] = useState<any>({});
  const [albumUrl, setAlbumUrl] = useState<string>("");
  const [videoUrl, setVideoUrl] = useState<string>("");
  const [projectInfo, setProjectInfo] = useState<any>({
    title: "",
    hide: false,
  });
  const [filterPopup, setFilterPopup] = useState<boolean>(false);
  const [filter, setFilter] = useState<string[]>([
    t(`Not Marked`), // 미확인 순
    t(`Oldest to Newest`), // 생성 오래된 순
    t(`Alphabetical A Z`), // 가나다 순
  ]);
  const [filterSelected, setFilterSelected] = useState<string>(t(`Oldest to Newest`)); // 생성 오래된 순

  const popupRef = useRef();
  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    filterHandler();
  }, [tags, searchInput, filterSelected]);

  const filterHandler = () => {
    let originArr = _.cloneDeep([...props.originalProjectReports]);
    // let originArr: any = [...props.originalProjectReports];

    let tempTags: any = [...tags];
    const selectedTags = tempTags.filter((item: any) => item.selected);

    if (selectedTags.length !== 0) {
      const tempProjects = [];
      for (let i in originArr) {
        let exist = false;
        for (let k = 0; k < originArr[i]?.tags?.length; k++) {
          for (let h = 0; h < selectedTags?.length; h++) {
            if (!exist && selectedTags[h] === tags[originArr[i].tags[k]]) {
              tempProjects.push(originArr[i]);
              exist = true;
            }
          }
        }
      }
      originArr = tempProjects;
    }

    if (filterSelected === t(`Not Marked`)) {
      // 미확인 순
      const sortedData = originArr.sort((a: any, b: any) => {
        return a.status === "red" && b.status === "yellow"
          ? -1
          : a.status === "yellow" && b.status === "red"
          ? 1
          : a.status === "red" && b.status === "gray"
          ? -1
          : a.status === "yellow" && b.status === "gray"
          ? -1
          : a.status === "red" && b.status === "red"
          ? b.created - a.created
          : 0;
      });
      originArr = sortedData;
    }
    if (filterSelected === t(`Oldest to Newest`)) {
      // 생성 오래된 순
      const sortedData = originArr.sort((a: any, b: any): number => {
        return a.created - b.created;
      });
      originArr = sortedData;
    }
    if (filterSelected === t(`Alphabetical A Z`)) {
      // 가나다 순
      const sortedData = originArr.sort((a: any, b: any): number => {
        return a.title < b.title ? -1 : a.title > b.title ? 1 : 0;
      });
      originArr = sortedData;
    }

    if (searchInput !== "") {
      let tempArr: any = [];
      for (let i = 0; i < originArr.length; i++) {
        if (originArr[i].title.includes(searchInput)) {
          tempArr.push(originArr[i]);
        }
      }
      props.setProjectReports(tempArr);
    } else {
      props.setProjectReports(originArr);
    }
  };

  const init = async () => {
    const tagsResult: any = await getCategorys(props.classId);
    if (tagsResult.length !== 0) {
      setTags(tagsResult[0].tags);
    }
  };

  const setColors = (posState: string): string => {
    let color = "";
    switch (posState) {
      case "complete":
        color = "gray";
        break;
      case "check":
        color = "red";
        break;
      case "":
        color = "yellow";
        break;
    }
    return color;
  };

  const handleFilterTag = (tag: any) => {
    const tempTags = [...tags];
    const idx = tempTags.findIndex((el: any) => el === tag);
    tempTags[idx].selected = !tags[idx].selected;

    setTags(tempTags);
  };

  const handleClickConfirmPopup = async (student: any, project: any) => {
    let fileLimit = 0;
    if (context.userInfo.fileSizeLimit === 0 || !context.userInfo.fileSizeLimit) {
      fileLimit = 100000000;
    } else {
      fileLimit = context.userInfo?.fileSizeLimit;
    }
    if (fileLimit < context.fileSize.originalFileSize) {
      return alert(
        // "제한 용량을 초과하셨습니다. '과제 삭제' 또는 '학급 삭제'를 통해 저장 용량을 조절하시거나, '결제 관리' 페이지에서 저장 용량 업그레이드 후 이용해 주세요.",
        t(`You have exceeded the storage limit...`),
      );
    }
    const posResult: any = await getEachProjectInfo(student.pos._id);
    setProjectInfo((prev: any) => {
      return {
        title: project.title,
        hide: project.hide,
      };
    });

    let tempFileUrls = [...posResult.fileUrls];
    for (let i = 0; i < tempFileUrls?.length; i++) {
      if (!tempFileUrls[i].fileType && tempFileUrls[i].isVideo) {
        tempFileUrls[i].fileType = "video";
      }
      if (!tempFileUrls[i].fileType && !tempFileUrls[i].isVideo) {
        tempFileUrls[i].fileType = "image";
      }
    }
    tempFileUrls = sortedImageFileArr(tempFileUrls);
    setProjectOfStudent({ ...posResult, fileUrls: tempFileUrls });

    if (student.pos.repeat) {
      // return alert("반복과제 페이지에서 확인해 주세요.");
      return alert(t(`Check it out on the repeated assignments page`));
    }
    if (posResult.feedback !== "") {
      setFeedback(posResult.feedback);
    }
    if (Object.keys(student.pos).length === 0 || student.pos.hide) {
      return;
    }
    if (student.pos.state === "complete" && student.pos.cookie !== 0) {
      setConfirmType("cookie");
      setCookieSelected(student.pos.cookie);
    }
    if (student.pos.state === "complete" && student.pos.cookie === 0) {
      setConfirmType("score");
      setScore(student.pos.score);
    }

    if (student.pos.state === "check") {
      setCookieSelected(1);
      setConfirmType("cookie");
    }

    if (student.pos.state === "" && (!student.pos.reject || student.pos.reject === "")) {
      setCookieSelected(1);
      setConfirmType("cookie");
    }
    if (student.pos.state === "" && student.pos.reject !== "") {
      setConfirmType("reject");
      setRejectReason(student.pos.reject);
    }

    setModal(true);
    if (posResult.studentBody) {
      setStudentBodyFalse(true);
    } else {
      setStudentBodyFalse(false);
    }
  };

  const replace = (content: any) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const convertContent = content?.replace(urlRegex, function (url: any) {
      return '<a style="color: blue" href="' + url + '" target="_blank">' + url + "</a>";
    });

    const htmlArr: any = [];
    convertContent?.split("\n")?.forEach(function (text: any) {
      const textHtml = "<p>" + text + "</p>";
      htmlArr.push(textHtml);
    });

    return { __html: htmlArr.join("") };
  };

  const handleConfirmTypeChange = (type: number): void => {
    if (projectInfo.hide) return;
    if (type === 1) {
      if (projectOfStudent.state === "complete" && projectOfStudent.score === "") {
        setConfirmType("cookie");
        setCookieSelected(projectOfStudent.cookie ? projectOfStudent.cookie : 0);
      } else {
        setConfirmType("cookie");
        setCookieSelected(1);
      }
    } else if (type === 2) {
      if (projectOfStudent.state === "complete" && projectOfStudent.score !== "") {
        setConfirmType("score");
        setScore(projectOfStudent.score ? projectOfStudent.score : "");
      } else {
        setConfirmType("score");
        setScore("");
      }
    } else if (type === 3) {
      if (projectOfStudent.state === "" && projectOfStudent.reject !== "") {
        setConfirmType("reject");
        // setRejectReason(projectOfStudent.reject === "_" ? "" : projectOfStudent.reject);
      } else {
        setConfirmType("reject");
        // setRejectReason("");
      }
    }
  };

  const handleConfirmProject = async (): Promise<void> => {
    if (projectInfo.hide) {
      // return alert("숨김상태에서는 과제의 열람만 가능합니다.");
      return alert(t(`While archived assignments can only be viewed`));
    }
    context.handleStateChange("class", "");
    let cookie = 0;
    let tempScore = "";
    if (confirmType === "cookie" && cookieSelected !== 0) cookie = cookieSelected;
    if (confirmType === "score" && score !== "") tempScore = score;
    if (confirmType === "reject") {
      const confirm = window.confirm(
        // `반려하면 학생의 자료가 삭제되며, 복구가 되지 않습니다. 진행하시겠습니까?`,
        t(`If you reject...`),
      );
      if (!confirm) return;

      const result: any = await putRejectProject({
        id: projectOfStudent?._id,
        repeat: false,
        rejectReason: rejectReason === "" ? "_" : rejectReason,
      });

      let body = {
        to: projectOfStudent.studentInfo[0]?.deviceToken2,
        // title: "과제가 반려되었습니다. 📝",
        title: `${t(`Your assignment has been rejected`)} 📝`,
        body: t(`Please resubmit it`), // "다시 보내주세요.",
        data: {
          code: projectOfStudent.studentInfo[0]?.code,
          type: "project",
        },
      };

      postNotification(body);
      // alert("과제가 반려되었습니다.");
      alert(t(`Your assignment has been rejected`));
    } else {
      const result: any = await putConfirmProject({
        id: projectOfStudent?._id,
        repeat: false,
        confirmType,
        confirmScore: tempScore,
        confirmCookies: cookie,
        feedback: feedback,
      });

      if (result.constructor == Array) {
        let pushArr: any = [];
        result.map((pushItem: any, i: number) => {
          pushArr.push({
            token: projectOfStudent.studentInfo[0]?.deviceToken2,
            notification: {
              title: `🎖${pushItem.title} ${t(`Badge`)}🎖`, // 뱃지
              body: t(`Congratulations Check it out`), // `축하합니다! 확인해보세요!`,
            },
            data: {
              type: "project",
              code: projectOfStudent.studentInfo[0]?.code,
            },
          });
        });
        postNotificationAll(pushArr);
      }

      let body = {
        to: projectOfStudent.studentInfo[0]?.deviceToken2,
        // title: confirmType === "cookie" ? "쿠키 도착 🍪" : "과제 확인 📝",
        title:
          confirmType === "cookie"
            ? `${t(`You've earned cookies`)} 🍪`
            : `${t(`Check your assignment`)} 📝`,
        body:
          confirmType === "cookie"
            ? t(`You've earned cookies Check it out`) // "쿠키가 도착했어요! 확인해보세요!"
            : t(`You have a new assignment`), // "과제를 확인하셨습니다! 확인해보세요!",
        data: {
          code: projectOfStudent.studentInfo[0]?.code,
          type: "project",
        },
      };

      postNotification(body);
    }

    const initResult: any = await initProjectForC(props.classId, props.projectReports);
    props.setProjectReports(initResult?.projectResult);
    props.setOriginalProjectReports(initResult.originalData);

    setModal(false);
    setConfirmType("");
    setFeedback("");
  };

  const handleAlbum = (data: any): void => {
    setAlbumData(data);
    setAlbum((prev) => !prev);
    if (!data.isVideo) {
      setAlbumUrl(data.uri);
      setVideoUrl("");
    } else {
      setVideoUrl(videoUrl === "" ? data.uri : "");
      setAlbumUrl("");
    }
  };

  const handleFilterSelect = async (index: number): Promise<void> => {
    let temp = "";
    temp = filter[index];
    setFilterSelected(temp);
    setFilterPopup(false);
  };

  return (
    <>
      {album && (
        <PopupAlbum
          enable={album}
          albumData={albumData}
          setAlbumData={setAlbumData}
          fileData={projectOfStudent?.fileUrls}
          handleAlbum={handleAlbum}
          setAlbumUrl={setAlbumUrl}
          setVideoUrl={setVideoUrl}
          albumUrl={albumUrl}
          videoUrl={videoUrl}
        />
      )}
      {modal && (
        <Modal
          show={modal}
          onClose={() => {
            setModal(false);
            setProjectOfStudent({});
          }}
        >
          <div
            className="projectOfStudentPopup scroll color-light-black"
            style={{ width: "35vw", height: "75vh", overflow: "auto" }}
          >
            <div className="display-f align-center" style={{ alignItems: "flex-end" }}>
              <h2 className="mr-10">{projectInfo.title}</h2>
              <div className="display-f align-center" style={{ marginBottom: -8 }}>
                <h3 className="mr-10">({projectOfStudent?.studentInfo[0]?.name})</h3>
                {projectInfo.hide && (
                  <p style={{ fontSize: 13 }}>
                    {/* (숨김상태에서는 과제의 열람만 가능합니다.) */}(
                    {t(`While archived assignments can only be viewed`)})
                  </p>
                )}
              </div>
            </div>
            <div>
              {projectOfStudent?.fileUrls?.map((aFile: any, index: number) => (
                <>
                  {(aFile.fileType === "image" ||
                    aFile.fileType === "video" ||
                    !aFile.fileType) && (
                    <div
                      key={index}
                      style={{
                        width: "49%",
                        verticalAlign: "top",
                        display: "inline-block",
                        marginRight: index % 2 === 0 ? 10 : 0,
                        marginBottom: 10,
                      }}
                    >
                      {!aFile.isVideo && (
                        <img
                          className="cursor"
                          onClick={() => handleAlbum(aFile)}
                          src={aFile.uri}
                          style={{ width: "100%", height: "auto", verticalAlign: "top" }}
                        />
                      )}
                      {aFile.isVideo && (
                        <video
                          controls
                          style={{ verticalAlign: "top" }}
                          onClick={() => handleAlbum(aFile)}
                          src={aFile.uri}
                          className="background-img student_submitted_image mb-10 cursor"
                          autoPlay={true}
                        ></video>
                      )}
                      {/* <p className="txt_line">{aFile.fileName}</p> */}
                      <p
                        onClick={() => window.open(aFile.uri)}
                        className="download_text cursor"
                        style={{ textAlign: "center" }}
                      >
                        {/* 다운받기 */}
                        {t(`Download`)}
                      </p>
                    </div>
                  )}
                </>
              ))}
              {projectOfStudent?.fileUrls?.filter(
                (el: any, i: number) =>
                  el.fileType !== "image" && el.fileType !== "video" && el.fileType,
              ).length !== 0 && (
                <div style={{ marginBottom: 10 }}>
                  <p style={{ fontWeight: 500, marginBottom: 10 }}>
                    {/* 첨부파일 */}
                    {t(`Attachments`)}
                    <span style={{ fontSize: 12 }}>
                      {/* (아래 파일명을 클릭하면 다운로드됩니다.) */}
                      {t(`Click the filename below to download`)}
                    </span>
                  </p>
                </div>
              )}

              {projectOfStudent?.fileUrls?.map((aFile: any, index: number) => (
                <>
                  {aFile.fileType !== "image" && aFile.fileType !== "video" && aFile.fileType && (
                    <div
                      onClick={() => window.open(aFile.uri)}
                      key={index}
                      className="file_ex_container cursor"
                    >
                      <img src={file_icon} style={{ width: 25, height: "auto", marginRight: 5 }} />
                      <p>{aFile.fileName}</p>
                    </div>
                  )}
                </>
              ))}
              {/* {projectOfStudent?.fileUrls?.map((data: any, index: number) => (
                <div
                  key={index}
                  className="display-f download_item mb-10 align-center"
                  style={{
                    width: "49%",
                    marginRight: index % 2 === 1 ? 0 : 5,
                    marginLeft: index % 2 === 0 ? 0 : 5,
                    flexDirection: "column",
                  }}
                >
                  <div>
                    {data.isVideo ? (
                      <>
                        <video
                          controls
                          onClick={() => handleAlbum(data)}
                          src={data.uri}
                          className="background-img student_submitted_image mb-10 cursor"
                          autoPlay={true}
                        ></video>
                      </>
                    ) : (
                      <div onClick={() => handleAlbum(data)} className="mb-10 cursor">
                        <img src={data.uri} alt="studentImg" className="img-response" />
                      </div>
                    )}
                  </div>

                  <p onClick={() => window.open(data.uri)} className="download_text cursor">
                    다운받기
                  </p>
                </div>
              ))} */}
            </div>

            {studentBodyFalse && (
              <div className="project_list mb-7" style={{ borderRadius: 10 }}>
                <p>
                  <div
                    className="cursor"
                    dangerouslySetInnerHTML={replace(projectOfStudent?.studentBody)}
                  ></div>
                </p>
              </div>
            )}

            {projectOfStudent?.submissionTime && (
              <p className="mb-7 font-bold">
                {timeFormat11(projectOfStudent.submissionTime)}
                {/* 제출 */}
                {t(`Submit`)}
              </p>
            )}

            {confirmType !== "reject" && (
              <div className="display-f  align-center mb-20">
                <p className="font-15 font-500 mr-33">
                  {/* 피드백 */}
                  {t(`Feedback`)}
                </p>

                <textarea
                  value={feedback}
                  onChange={(e: any) => setFeedback(e.target.value)}
                  className="feedback__text"
                  name="feedback"
                  readOnly={(confirmType === "reject" && true) || (projectInfo.hide && true)}
                  cols={20}
                ></textarea>
              </div>
            )}

            <div className="display-f justify-between align-center mb-28">
              <button
                onClick={() => handleConfirmTypeChange(1)}
                className={`focus project__sign__btn light-black ${
                  confirmType === "cookie" && "confirm_selected"
                }`}
              >
                {/* 쿠키 */}
                {t(`Cookie`)}
              </button>
              <button
                onClick={() => handleConfirmTypeChange(2)}
                className={`focus project__sign__btn light-black ${
                  confirmType === "score" && "confirm_selected"
                }`}
              >
                {/* 성적 */}
                {t(`Grade`)}
              </button>
              <button
                onClick={() => handleConfirmTypeChange(3)}
                className={`focus project__sign__btn light-black project_reject_btn ${
                  confirmType === "reject" && "confirm_reject_selected"
                }`}
              >
                {/* 반려 */}
                {t(`Reject`)}
              </button>
            </div>

            <div className="mb-40">
              {confirmType === "cookie" && (
                <div className="display-f align-center">
                  <button
                    onClick={() => {
                      if (projectInfo.hide) {
                        return;
                      } else {
                        setCookieSelected(1);
                      }
                    }}
                    className={`${
                      cookieSelected !== 1 && "cookie_border_none"
                    } cookie_circle cursor display-f-center mr-17`}
                  >
                    {cookieSelected === 1 ? (
                      <img className="cookie1" src={cookie1S} alt="cookie1S" />
                    ) : (
                      <img className="cookie1" src={cookie1G} alt="cookie1G" />
                    )}
                  </button>
                  <button
                    onClick={() => {
                      if (projectInfo.hide) {
                        return;
                      } else {
                        setCookieSelected(2);
                      }
                    }}
                    className={`${
                      cookieSelected !== 2 && "cookie_border_none"
                    } cookie_circle cursor display-f-center mr-17`}
                  >
                    {cookieSelected === 2 ? (
                      <img className="cookie2" src={cookie2S} alt="cookie2S" />
                    ) : (
                      <img className="cookie2" src={cookie2G} alt="cookie2G" />
                    )}
                  </button>
                  <button
                    onClick={() => {
                      if (projectInfo.hide) {
                        return;
                      } else {
                        setCookieSelected(3);
                      }
                    }}
                    className={`${
                      cookieSelected !== 3 && "cookie_border_none"
                    } cookie_circle cursor display-f-center`}
                  >
                    {cookieSelected === 3 ? (
                      <img className="cookie3" src={cookie3S} alt="cookie3S" />
                    ) : (
                      <img className="cookie3" src={cookie3G} alt="cookie3G" />
                    )}
                  </button>
                </div>
              )}
              {confirmType === "score" && (
                <div className="display-f align-center">
                  <p className="mr-23 font-500 font-15">
                    {/* 성적 입력 */}
                    {t(`Enter grade`)}
                  </p>
                  <input
                    readOnly={projectInfo.hide && true}
                    value={score}
                    maxLength={4}
                    onChange={(e: any) => setScore(e.target.value)}
                    className="score_input"
                    type="text"
                  />
                </div>
              )}
              {confirmType === "reject" && (
                <>
                  <div className="display-f align-center justify-between">
                    <p className="font-500 font-15">
                      {/* 반려 사유 */}
                      {t(`Rejection Reason`)}
                    </p>
                    <textarea
                      readOnly={projectInfo.hide && true}
                      value={rejectReason}
                      onChange={(e: any) => setRejectReason(e.target.value)}
                      className="reject__reason__text"
                      name="rejectReason"
                    />
                  </div>
                </>
              )}
            </div>

            {!projectInfo.hide && (
              <div className="display-f-center">
                <button onClick={handleConfirmProject} className="popup__bottom__btn mr-14 ">
                  {/* 확인 */}
                  {t(`Confirm`)}
                </button>
                <button
                  onClick={() => {
                    setModal(false);
                    setConfirmType("");
                    setScore("");
                    setCookieSelected(0);
                    setRejectReason("");
                    setFeedback("");
                  }}
                  className="popup__bottom__btn"
                >
                  {/* 취소 */}
                  {t(`Cancel`)}
                </button>
              </div>
            )}
          </div>
        </Modal>
      )}

      <div
        style={{
          padding: "0 20px",
          maxHeight: "100%",
        }}
      >
        {/* header */}
        <div className="display-f project_record_header justify-between align-fe">
          {/* 태그 */}
          <div className="display-f flex-wrap" style={{ width: "30%" }}>
            {tags.map((tag: any, i: number) => {
              if (!tag.tagName) {
                return (
                  <div
                    key={i}
                    className="tag_btn_block gray text-center mr-5 mb-5"
                    style={{ height: 25 }}
                  >
                    <p style={{ color: "#ececec" }}> </p>
                  </div>
                );
              }
              if (tag.tagName) {
                return (
                  <button
                    onClick={() => handleFilterTag(tag)}
                    key={i}
                    className={`${
                      tag.selected && "tag_btn_selected"
                    } tag_btn_fill  text-center mr-5 mb-5 cursor`}
                  >
                    <p style={{ color: "#fff" }}>{tag.tagName}</p>
                  </button>
                );
              }
            })}
          </div>

          <div className="display-f">
            {/* search input */}
            <form className="mr-10 position-r" style={{ marginBottom: 5 }}>
              <img className="search_icon2 position-a cursor" src={search} alt="search" />

              <input
                value={searchInput}
                placeholder={t(`Search for an assignment title`)}
                onChange={(e: any) => setSearchInput(e.target.value)}
                type="text"
                className="category_searchInput2 pl-40 pr-10"
              />
            </form>

            <OutsideClickHandler
              onOutsideClick={() => {
                setFilterPopup(false);
              }}
            >
              <button
                onClick={() => setFilterPopup((prev) => !prev)}
                style={{ marginBottom: 5, height: 32, width: 200 }}
                className="filter_btn2 text-left pl-17 pr-12 display-f justify-between align-center"
              >
                {filterSelected}
                <img
                  className="filter_icon"
                  src={down}
                  alt="down"
                  style={{ width: 18, height: "auto" }}
                />
              </button>
              {filterPopup && (
                <div className="position-a filter_contents2">
                  {filter.map((data: string, index: number) => (
                    <div
                      onClick={() => handleFilterSelect(index)}
                      className="filter_inner cursor"
                      key={index}
                    >
                      <p className="pl-17 pt-11 pb-11 font-14">{data}</p>
                    </div>
                  ))}
                </div>
              )}
            </OutsideClickHandler>
          </div>
        </div>
        {/* header end */}
        {/* table */}
        <div className="page_report_body_project mt-10" style={{ overflow: "auto" }}>
          <div style={{ width: props.tableWidth, height: props.tableHeight, zIndex: 10 }}>
            {/* table head */}
            <div style={{ position: "sticky", top: 0, zIndex: 1 }}>
              <section className="bg-white">
                <div className="t_head_wrapper" style={{ ...props.headerStyle }}>
                  <div
                    className="t_head_cell2 gray font-500 bg-white"
                    style={{
                      position: "sticky",
                      left: 0,
                      height: 40,
                      borderRight: "1px solid #979797",
                    }}
                  >
                    {/* 과제 이름 */}
                    {t(`Assignment title`)}
                  </div>
                  {props.students?.map((student: any, i: number) => (
                    <div
                      className="t_head_cell_project font-500 bg-white"
                      style={{
                        borderLeft: i === 0 ? "none" : "1px solid #979797",
                        borderRight: i === props.students.length - 1 ? "1px solid #979797" : "",
                        height: 40,
                      }}
                    >
                      <p style={{ fontSize: 14 }}>{student.name}</p>
                    </div>
                  ))}
                </div>
              </section>
            </div>

            {/* table body */}
            <div>
              <section className="table_body_container">
                {props.projectReports.map((aProject: any, i: number) => (
                  <div key={i} className="text-center t_head_wrapper_body">
                    <div
                      className={`w-100 t_head_cell2 project_table_body white-space overflow ellipsis text-center background-${aProject.status}`}
                      style={{
                        borderBottom:
                          props.projectReports.length - 1 === i ? "1px solid #979797" : "",
                      }}
                    >
                      <div className="display-f justify-center align-center">
                        <div>
                          {aProject.repeat && aProject.repeatType === "date" && (
                            <img src={calendar} style={{ width: 15, height: 15, marginRight: 6 }} />
                          )}
                          {aProject.repeat && aProject.repeatType === "count" && (
                            <img src={numbers} style={{ width: 16, height: 16, marginRight: 6 }} />
                          )}
                          {aProject.hide && (
                            <img src={hide} style={{ width: 17, height: 17, marginRight: 6 }} />
                          )}
                        </div>
                        <p className="area2 white-space overflow ellipsis text-center">
                          {aProject.title}
                          {/* {JSON.stringify(aProject.status)} */}
                        </p>
                      </div>
                    </div>

                    {aProject.students.map((aStudent: any, index: number) => (
                      <div key={index}>
                        {!aStudent.pos.hide && (
                          <div
                            onClick={() => handleClickConfirmPopup(aStudent, aProject)}
                            className={`w-200 t_head_cell_project cursor background-${setColors(
                              aStudent.pos.state,
                            )}`}
                            style={{
                              borderLeft: index === 0 ? "none" : "",
                              borderBottom:
                                props.projectReports.length - 1 === i ? "1px solid #979797" : "",
                              borderRight:
                                aProject.students.length - 1 === index
                                  ? "1px solid #979797"
                                  : "none",
                            }}
                          >
                            {/* 쿠키 */}
                            {aStudent.pos.state === "complete" &&
                              aStudent.pos.score === "" &&
                              aStudent.pos.cookie !== 0 &&
                              !aProject.repeat && (
                                <>
                                  {aStudent.pos.cookie === 1 && (
                                    <img src={cookie1} style={{ width: 22 }} />
                                  )}
                                  {aStudent.pos.cookie === 2 && (
                                    <img src={cookie2} style={{ width: 22 }} />
                                  )}
                                  {aStudent.pos.cookie === 3 && (
                                    <img src={cookie3} style={{ width: 22 }} />
                                  )}
                                </>
                              )}

                            {aStudent.pos.state === "complete" && aStudent.pos.cookie === 0 && (
                              <>
                                <p className="color-red font-500" style={{ fontSize: 14 }}>
                                  {aStudent.pos.score}
                                </p>
                              </>
                            )}
                            {aStudent.pos.score === "" &&
                              aStudent.pos.cookie !== 0 &&
                              aProject.repeat && (
                                <div className="display-f justify-center align-center">
                                  {<img src={cookie1} style={{ width: 22 }} />}
                                  <p style={{ fontSize: 12, fontWeight: 400, color: "#232323" }}>
                                    {" "}
                                    X {aStudent.pos.cookie}
                                  </p>
                                </div>
                              )}
                          </div>
                        )}
                        {aStudent.pos.hide && (
                          <div
                            className={`w-200 t_head_cell_project cursor background-white}`}
                            style={{
                              borderLeft: index === 0 ? "none" : "",
                              borderBottom:
                                props.projectReports.length - 1 === i ? "1px solid #979797" : "",
                              borderRight:
                                aProject.students.length - 1 === index
                                  ? "1px solid #979797"
                                  : "none",
                            }}
                          ></div>
                        )}
                      </div>
                    ))}
                  </div>
                ))}
              </section>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const Checklist = (props: any) => {
  const [options, setOptions] = useState<boolean>(false);
  const tableRef = useRef<any>(null);
  const [popup, setPopup] = useState<boolean>(false);
  const [checklistTitle, setChecklistTtile] = useState<string>("");
  const [openSelection, setOpenSelection] = useState<boolean>(false);
  const [popupFolderSelect, setPopupFolderSelect] = useState<any>({
    title: t(`Unspecified folder`),
  }); // 폴더 미지정
  const [foldersData, setFoldersData] = useState<any>([]);
  const [folderSelected, setFolderSelected] = useState<any>({
    title: t(`All Checklist`), // 모든 체크리스트
  });
  const [text, setText] = useState<string>("");
  const [fileUrls, setFileUrls] = useState<string[]>([]);
  const fileRef = useRef<HTMLInputElement>(null);
  const [files, setFiles] = useState<any[]>([]);
  const [studentsList, setStudentsList] = useState<boolean>(false);
  const [students, setStudents] = useState<IStudent[]>([]);
  const [searchInput, setSearchInput] = useState<string>("");

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    filterHandler();
  }, [folderSelected, searchInput]);

  const init = async () => {
    const foldersResult: any = await getFoldersOfChecklist(props.classId);
    setFoldersData(foldersResult);
  };

  const handleCloseModal = (event: any): void => {
    event.preventDefault();
    setPopup(false);
  };
  const filterHandler = async () => {
    let originArr = _.cloneDeep(props.originalData);
    let originStudentsArr = { ...props.studentsData };
    if (
      folderSelected.title !== t(`All Checklist`) &&
      folderSelected.title !== t(`Uncategorized`)
    ) {
      // 모든 체크리스트 && 미분류 항목
      const filterResult: any = await initialChecklist(props.classId, folderSelected._id);
      originArr = [...filterResult?.checklistResult.checklist];
      originStudentsArr = { ...filterResult?.tempChecklistRowData };
      props.setChecklistData(originArr);
      props.setStudentsData(originStudentsArr);
      props.setTableWidth(() => 150 + 100 * filterResult.checklistResult.checklist.length);
    }

    if (folderSelected.title === t(`Uncategorized`)) {
      // 미분류 항목
      const filterResult: any = await initialChecklist(props.classId, "", true);
      originArr = [...filterResult?.checklistResult.checklist];
      originStudentsArr = { ...filterResult?.tempChecklistRowData };
      props.setChecklistData(originArr);
      props.setStudentsData(originStudentsArr);
      props.setTableWidth(() => 150 + 100 * filterResult.checklistResult.checklist.length);
    }

    if (folderSelected.title === t(`All Checklist`)) {
      // 모든 체크리스트
      const filterResult: any = await initialChecklist(props.classId, "");
      originArr = [...filterResult?.checklistResult.checklist];
      originStudentsArr = { ...filterResult?.tempChecklistRowData };
      props.setChecklistData(originArr);
      props.setStudentsData(originStudentsArr);
      props.setTableWidth(() => 150 + 100 * filterResult.checklistResult.checklist.length);
    }

    if (searchInput !== "") {
      let tempSearchArr = [];
      for (let i = 0; i < originArr?.length; i++) {
        if (originArr[i].title.includes(searchInput)) {
          tempSearchArr.push(originArr[i]);
        }
      }

      const reducedData = await initialChecklistReduced(tempSearchArr);
      props.setChecklistData(reducedData.checklistResult);
      props.setStudentsData(reducedData.tempChecklistRowData);
      props.setTableWidth(() => 150 + 100 * reducedData.checklistResult.length);
    }
  };

  const handleFile = (e: any) => {
    const filesArr = Object.keys(e.target.files);
    if (filesArr.length !== 0) {
      let fileTemp = [...files];
      for (let i in filesArr) {
        if (e.target.files[i].type.split("/")[0] === "image") {
          fileTemp.push({
            file: e.target.files[i],
            fileUrl: URL.createObjectURL(e.target.files[i]),
          });
        } else {
          // alert("이미지만 첨부해주세요!");
          alert(t(`Please attach images only`));
        }
      }

      setFiles(fileTemp);
    } else {
      return;
    }
  };

  const handleDeleteFile = (targetFile: object) => {
    let temp = [...files];
    const findIdx = temp.findIndex((el: any) => el === targetFile);
    temp.splice(findIdx, 1);
    setFiles(temp);
  };

  const handleStudentChecked = (i: number): void => {
    const temp = [...students];
    temp[i].checked = !temp[i].checked;
    setStudents(temp);
  };

  const handleStudentsAllCheck = (state: boolean) => {
    const temp = [...students];
    for (let i in temp) {
      temp[i].checked = state;
    }
    setStudents(temp);
  };

  const handleSubmit = async (): Promise<void> => {
    let tempUrls: string[] = [];
    if (files.length !== 0) {
      for (let i in files) {
        const formData = new FormData();
        formData.append("file", files[i].file);
        const getUrls: any = await postUploadImage(formData, 500);
        tempUrls.push(getUrls);
      }
    }

    let tempCode: string[] = [];
    for (let i in students) {
      if (students[i].checked) {
        tempCode.push(students[i].code);
      }
    }
    const data = {
      onFolder: popupFolderSelect.title !== t(`Unspecified folder`) ? true : false, // 폴더 미지정 ?
      folderId: popupFolderSelect._id ? popupFolderSelect._id : "",
      classId: props.classId,
      title: checklistTitle,
      description: text,
      file: tempUrls,
      studentCodeList: tempCode,
    };
    const postChecklistResult: any = await postChecklist(data);
    const result: any = await initialChecklist(props.classId);
    props.setTableWidth(() => 150 + 100 * result.checklistResult.checklist.length);
    props.setStudentsData(result?.tempChecklistRowData);
    props.setChecklistData(result.checklistResult.checklist);
    setPopup(false);
    setFolderSelected({
      title: t(`All Checklist`), // 모든 체크리스트
    });
  };

  const handleAddChecklist = async () => {
    setPopup(true);
    let studentsResult: any = await getStudents(props.classId);
    for (let i in studentsResult) {
      studentsResult[i].checked = true;
    }
    setStudents(studentsResult);
  };

  return (
    <>
      <div
        style={{
          padding: "0 20px",
          maxHeight: "100%",
        }}
      >
        {/* header */}
        <div className="display-f project_record_header">
          <div className="position-r">
            <div
              onClick={() => setOptions((prev: any) => !prev)}
              className="folder_selectbar cursor"
            >
              <div></div>
              <div>{folderSelected.title}</div>
              <img src={down} style={{ width: 14, height: "auto" }} />
            </div>

            {options && (
              <div
                onClick={() => {
                  setOptions(false);
                }}
                style={{ zIndex: 11, maxHeight: 200, overflow: "auto" }}
                className="folder_selectbar_option text-center cursor"
              >
                <div
                  onClick={() =>
                    setFolderSelected({
                      title: t(`All Checklist`),
                    })
                  }
                  className="selectbar_option_item text-center"
                >
                  {/* 모든 체크리스트 */}
                  {t(`All Checklist`)}
                </div>
                <div
                  style={{ paddingTop: 6 }}
                  onClick={() =>
                    setFolderSelected({
                      title: t(`Uncategorized`), // 미분류 항목
                    })
                  }
                  className="selectbar_option_item text-center"
                >
                  {/* 미분류 항목 */}
                  {t(`Uncategorized`)}
                </div>

                {foldersData.map((el: any, i: number) => (
                  <div
                    style={{ paddingTop: 6 }}
                    onClick={() => setFolderSelected(el)}
                    key={i}
                    className="selectbar_option_item text-center"
                  >
                    {el.title}
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="display-f align-center" style={{ flex: 1 }}>
            <p className="font-18" style={{ paddingLeft: 20, fontWeight: 600 }}>
              {/* 체크리스트 */}
              {t(`Checklist`)}
            </p>
          </div>
          <div style={{ flex: 1 }}>
            {/* 검색 바 */}
            <div className="display-f text-center" style={{ justifyContent: "flex-end" }}>
              <form className="mr-10 position-r">
                <img className="search_icon2 position-a cursor" src={search} alt="search" />
                <input
                  value={searchInput}
                  onChange={(e: any) => setSearchInput(e.target.value)}
                  placeholder={t(`Search for a checklist title`)}
                  type="text"
                  className="category_searchInput2 pl-40 pr-10"
                />
              </form>

              <div
                onClick={handleAddChecklist}
                className="report_btn_blue cursor"
                style={{ border: "1px solid #52905d", color: "#52905d" }}
              >
                {/* 체크리스트 추가 */}
                {t(`Add Checklist`)}
              </div>
            </div>
          </div>
        </div>
        {/* header end */}

        {/* table */}
        <div className="page_report_body_checklist mt-10" style={{ overflow: "auto" }}>
          <div style={{ width: props.tableWidth, zIndex: 10 }}>
            <div style={{ position: "sticky", top: 0, backgroundColor: "#fafafa", zIndex: 1 }}>
              <TableHead headData={props.checklistData} />
            </div>

            <TableBody
              setStudentsData={props.setStudentsData}
              setHeadData={props.setChecklistData}
              studentsData={props.studentsData}
              headData={props.checklistData}
              classId={props.classId}
            />
          </div>
        </div>
      </div>

      {popup && (
        <div className="popup" style={{ zIndex: 100 }}>
          <div className="modal-overlay" onClick={handleCloseModal}></div>
          <div className="project_add_popup scroll">
            <p className="font-23 mb-26 text-center">{t(`Create Checklist`)}</p>
            <p className="color-light-black mb-8 font-15">{t(`Title`)}</p>
            <div className="display-f align-center mb-22">
              <input
                value={checklistTitle}
                onChange={(e: any) => setChecklistTtile(e.target.value)}
                className="popup_input mr-18 pl-12 pr-12"
                type="text"
                maxLength={31}
              />
            </div>

            <div className="mb-22">
              <p className="color-light-black mb-8 font-15">
                {/* 폴더 */}
                {t(`Folder`)}
              </p>
              {/* folder selection */}
              <div
                onClick={() => setOpenSelection(true)}
                style={{ alignItems: "center", marginTop: 10 }}
                className="display-f"
              >
                <div className="repeat-selectbar cursor">
                  <p className={`${popupFolderSelect?.title === "폴더 미지정" ? "font-gray" : ""}`}>
                    {popupFolderSelect?.title}
                  </p>

                  <img src={down_b} style={{ width: 18, height: "auto" }} />
                </div>
              </div>

              {openSelection && (
                <OutsideClickHandler
                  onOutsideClick={() => {
                    setOpenSelection(false);
                  }}
                >
                  <div className="repeat-selectbar-open" style={{ marginLeft: 0 }}>
                    <div
                      onClick={() => {
                        setPopupFolderSelect({ title: t(`Unspecified folder`) }); // 폴더 미지정
                        setOpenSelection(false);
                      }}
                      className="repeat-select-item-n cursor font-gray"
                    >
                      {/* 폴더 미지정 */}
                      {t(`Unspecified folder`)}
                    </div>
                    {foldersData
                      .filter((el: any) => !el.hide)
                      .map((folder: any, i: number) => (
                        <div
                          key={i}
                          onClick={() => {
                            setPopupFolderSelect(folder);
                            setOpenSelection(false);
                          }}
                          className="repeat-select-item-n cursor"
                        >
                          {folder.title}
                        </div>
                      ))}
                  </div>
                </OutsideClickHandler>
              )}
            </div>

            <p className="color-light-black font-15 mb-8">
              {/* 내용 */}
              {t(`Detail`)}
            </p>
            <ReactQuill
              value={text}
              formats={formatStyle}
              onChange={(e) => setText(e)}
              className="project-quill"
              modules={quillStyle}
            />
            <div className="display-f" style={{ width: "100%", flexWrap: "wrap" }}>
              {files.map((file: any, index: number) => (
                <div className="position-r" style={{ width: "48%", marginRight: 10 }} key={index}>
                  <img
                    onClick={() => handleDeleteFile(file)}
                    src={close}
                    style={{ width: 15, height: "auto", top: 10, left: 10 }}
                    className="position-a cursor"
                  />
                  <img src={file.fileUrl} className="img-add mb-10" alt="file" />
                </div>
              ))}
            </div>
            <div className="popup__additional__project mb-30">
              <input
                multiple
                ref={fileRef}
                type="file"
                className="display-n"
                name="file"
                onChange={(e: any) => handleFile(e)}
              />
              <button
                onClick={() => {
                  fileRef.current !== null && fileRef.current.click();
                }}
                className="focus add__file__btn font-13 font-500 mr-11 mt-10"
              >
                {/* 파일 첨부 */}
                {t(`Attach`)}
              </button>

              {files && (
                <button
                  onClick={() => {
                    setFiles([]);
                    setFileUrls([]);
                  }}
                  className="focus add__file__btn font-13 font-500 mr-11 mt-10"
                >
                  {/* 파일 삭제 */}
                  {t(`Delete files`)}
                </button>
              )}

              <button
                onClick={() => setStudentsList(true)}
                className="focus target__student__btn font-13 font-500"
              >
                {/* 대상 학생 */}
                {`${t(`Select Recipient`)} (${
                  students.filter((item: IStudent) => item.checked).length
                })`}
              </button>

              {studentsList === true && (
                <div className="studentsList__popup">
                  <div className="studentsList__contents">
                    <div className="overflow-h scroll mb-30">
                      <p className="font-23 text-center mb-20 pt-20">
                        {/* 대상학생 목록 */}
                        {t(`List of recipients`)}
                      </p>
                      {students
                        .sort((a: any, b: any) => a.num - b.num)
                        .map((data: IStudent, index: number) => (
                          <div key={index} className="managing__options__contents position-r">
                            <p className="mt-15 mb-15 font-15">{data.name}</p>
                            <input
                              onChange={() => handleStudentChecked(index)}
                              // checked={data.target}
                              type="checkbox"
                              id={JSON.stringify(index)}
                              className="display-n"
                            />
                            <div>
                              <label
                                className="target_label cursor"
                                htmlFor={JSON.stringify(index)}
                              >
                                {data.checked && <span className="studentList_check"></span>}
                              </label>
                            </div>
                          </div>
                        ))}
                      <div>
                        <div className="text-center mt-20 mb-20 pb-20">
                          <button
                            onClick={() => handleStudentsAllCheck(true)}
                            className="focus popup__bottom__btn mr-14"
                          >
                            {/* 모두 선택 */}
                            {t(`Select All`)}
                          </button>
                          <button
                            onClick={() => handleStudentsAllCheck(false)}
                            className="focus popup__bottom__btn mr-14"
                          >
                            {/* 모두 해제 */}
                            {t(`Deselect all`)}
                          </button>
                          <button
                            onClick={() => setStudentsList(false)}
                            className="focus popup__bottom__btn mr-14"
                          >
                            {/* 확인 */}
                            {t(`Confirm`)}
                          </button>
                          <button
                            onClick={() => setStudentsList(false)}
                            className="focus popup__bottom__btn"
                          >
                            {/* 취소 */}
                            {t(`Cancel`)}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="text-center mb-20">
              <button onClick={handleSubmit} className="focus popup__bottom__btn mr-14">
                {/* 확인 */}
                {t(`Confirm`)}
              </button>
              <button
                onClick={() => {
                  setPopup(false);
                }}
                className="focus popup__bottom__btn"
              >
                {/* 취소 */}
                {t(`Cancel`)}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const AIFeedback = (props: any) => {
  const history = useHistory();
  const context = useContext<IMainContext>(MainContext);
  const [tags, setTags] = useState<any>([{}, {}, {}, {}, {}, {}, {}, {}, {}, {}]);
  const [filter, setFilter] = useState<string[]>([
    t(`Not Marked`), // 미확인 순
    t(`Oldest to Newest`), // 생성 오래된 순
    t(`Alphabetical A Z`), // 가나다 순
  ]);

  const [stackForCookie, setStackForCookie] = useState<boolean>(false);
  // 누가기록
  const [tag, setTag] = useState<string>("");
  const [index, setIndex] = useState<number>(-1);
  const [recordTags, setRecordTags] = useState<any>([]);
  // 체크리스트
  const [foldersData, setFoldersData] = useState<any>([]);
  const [aiContents, setAiContents] = useState<any>({
    stackRecords: [],
  });
  const [prompt, setPrompt] = useState<string>(
    // `아래는 학생에 대한 평가입니다.\n학생에 대한 평가를 생활기록부에 작성하도록 200자 내외로 요약해주세요.\n간결한 문장으로 나누어주되, 모든 문장이 '~함'으로 마쳐야 합니다.`,
    t(`Below is your assessment of the student...`),
  );
  const [checkAll, setCheckAll] = useState<any>({
    recordAll: false,
    projectAll: false,
    checklistAll: false,
  });
  const [aiLoading, setAiLoading] = useState<boolean>(false);
  const [studentLoadingState, setStudentLoadingState] = useState<any>({});
  const [studentTextValueState, setStudentTextValueState] = useState<any>({});

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    const recordTags: any = await getRecordTags(props.classId);
    if (recordTags.length !== 0) {
      setRecordTags(recordTags[0]?.tags);
    }
    const tagsResult: any = await getCategorys(props.classId);
    if (tagsResult.length !== 0) {
      setTags(tagsResult[0].tags);
    }

    const foldersResult: any = await getFoldersOfChecklist(props.classId);
    setFoldersData(foldersResult);
  };

  const handleFilterTag = (tag: any) => {
    const tempTags = [...tags];
    const idx = tempTags.findIndex((el: any) => el === tag);
    tempTags[idx].selected = !tags[idx].selected;

    setTags(tempTags);
  };

  const handleClickStackTag = (tag: any) => {
    const temp = [...recordTags];
    const findIdx = recordTags.findIndex((el: any) => tag === el);
    temp[findIdx].selected = !temp[findIdx].selected;
    setRecordTags(temp);
  };

  const handleClickChecklistFolder = (checklistItem: any) => {
    const temp = [...foldersData];
    const findIdx = foldersData.findIndex((el: any) => checklistItem === el);
    temp[findIdx].selected = !temp[findIdx].selected;
    setFoldersData(temp);
  };

  const handleGenerateFeedback = async (type: string, code: string = "") => {
    // 쫑알이는 비기너는 쓰지못하고, 유료회원만 가능
    if (
      !context.userInfo?.fileSizeLimit ||
      context.userInfo?.fileSizeLimit === 0 ||
      context.userInfo.fileSizeLimit < context.fileSize.originalFileSize
    ) {
      return alert(
        // `쫑알이 서비스는 유료회원 전용 기능입니다. '결제 관리' 페이지에서 유료 플랜 구독 후 이용해주세요.`,
        t(`The AI Comment service is available only to paid members...`),
      );
    }

    if (!context.agreementAi) {
      // const confirm = window.confirm("open ai사의 엔진을 거칩니다.");
      const confirm = window.confirm(t(`It is powered by an engine from OpenAI`));
      if (!confirm) return;
      if (confirm) {
        const updateUserInfo: any = await putModifyUserAgreement({
          agreementAi: true,
        });
        context.handleStateChange("agreementAi", true);
      }
    }

    const { generating }: any = await getIsValidGenerateAI();
    if (generating) {
      // return alert("생성 중입니다. 기다려 주세요.");
      return alert(t(`It’s currently being created...`));
    }
    if (aiLoading) {
      // return alert("처리중입니다.");
      return alert(t(`Processing`));
    }

    const generateOb: any = {
      recordtags: [],
      projecttags: [],
      checklistfolder: [],
      noRecord: true,
      noProject: true,
      noChecklist: true,
    };

    for (let i in recordTags) {
      if (recordTags[i].selected) {
        generateOb.recordtags.push(parseInt(i));
      }
    }
    for (let i in tags) {
      if (tags[i].selected) {
        generateOb.projecttags.push(parseInt(i));
      }
    }
    for (let i in foldersData) {
      if (foldersData[i].selected) {
        generateOb.checklistfolder.push(foldersData[i]._id);
      }
    }

    if (checkAll.recordAll || recordTags.filter((el: any) => el.selected).length > 0) {
      generateOb.noRecord = false;
    }
    if (checkAll.projectAll || tags.filter((el: any) => el.selected).length > 0) {
      generateOb.noProject = false;
    }

    if (checkAll.checklistAll || foldersData.filter((el: any) => el.selected).length > 0) {
      generateOb.noChecklist = false;
    }

    if (generateOb.noRecord && generateOb.noProject && generateOb.noChecklist) {
      // return alert("불러올 내용을 선택해주세요.");
      return alert(t(`Select what you want to import`));
    }

    setAiLoading(true);

    // 1. 모든 피드백을 수집하는 API
    const getFeedbackData: any = await postAIFeedback({
      ...generateOb,
      ...checkAll,
      classId: props.classId,
    });

    let tempFeedbacks: any = {};
    const feedbackArr = Object.keys(getFeedbackData.result);
    for (let i = 0; i < feedbackArr.length; i++) {
      if (getFeedbackData.result[feedbackArr[i]].length !== 0) {
        tempFeedbacks[feedbackArr[i]] = getFeedbackData.result[feedbackArr[i]];
      }
    }

    let tempStudents = { ...props.students };
    if (type === "multi") {
      const studentArr = Object.keys(tempFeedbacks);
      let promptArr: any = [];
      for (let i = 0; i < studentArr.length; i++) {
        promptArr.push({
          prompt: `${prompt}\n${tempFeedbacks[studentArr[i]].join("")}`,
          code: studentArr[i],
        });
      }

      //   // 2. ai 요청
      const { result }: any = await postGenerateAIMulti({ prompts: promptArr });
      for (let i = 0; i < result?.length; i++) {
        tempStudents[result[i].code].aiFeedback = result[i].content;
      }

      props.setStudents(tempStudents);
    }

    if (type === "single") {
      setStudentLoadingState((prev: any) => {
        return {
          ...prev,
          [code]: true,
        };
      });
      const generateAI: any = await postGenerateAI({
        prompt: `${prompt}\n${tempFeedbacks[code].join("")}`,
      });

      tempStudents[code].aiFeedback = generateAI.result?.content;
    }

    if (props.hasAiFeedback) {
      const updateSaveResult: any = await putMoodifySavedAiFeedback({
        classId: props.classId,
        students: tempStudents,
      });
    } else {
      const saveResult: any = await postSaveAiFeedback({
        classId: props.classId,
        students: tempStudents,
      });
    }

    props.setStudents(tempStudents);
    setAiLoading(false);
    setStudentLoadingState((prev: any) => {
      return {
        ...prev,
        [code]: false,
      };
    });
  };

  const handleSetStudentValue = (code: string, e: any) => {
    setStudentTextValueState((prev: any) => {
      return {
        ...prev,
        [code]: true,
      };
    });
    props.setStudents((prev: any) => {
      return {
        ...prev,
        [code]: { ...prev[code], aiFeedback: e.target.value },
      };
    });
  };

  const downloadExcel = () => {
    const arr = [];
    const studentsArr = Object.keys(props.students);
    for (let i in studentsArr) {
      arr.push({
        name: props.students[studentsArr[i]].name,
        feedback: props.students[studentsArr[i]].aiFeedback,
      });
    }
    const ws = xlsx.utils.json_to_sheet(arr);
    const wb = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, "Sheet1");
    ["이름", "AI평가"].forEach((x, idx) => {
      const cellAdd = xlsx.utils.encode_cell({ c: idx, r: 0 });
      ws[cellAdd].v = x;
    });
    xlsx.writeFile(wb, `다했니 쫑알이(${context.class}).xlsx`);
  };

  const handleSaveStudentEachFeedback = async (code: string) => {
    if (props.hasAiFeedback) {
      const updateSaveResult: any = await putMoodifySavedAiFeedback({
        classId: props.classId,
        students: props.students,
      });
    } else {
      const saveResult: any = await postSaveAiFeedback({
        classId: props.classId,
        students: props.students,
      });
    }

    setStudentTextValueState((prev: any) => {
      return {
        ...prev,
        [code]: false,
      };
    });
  };

  return (
    <>
      <div
        style={{
          padding: "0 20px",
          maxHeight: "100%",
          overflowY: "scroll",
        }}
      >
        <div>
          <div className="position-r">
            <div
              className="position-a display-f justify-c "
              style={{ flexDirection: "column", right: 0, top: 0 }}
            >
              <button
                style={{ display: "block" }}
                onClick={() => handleGenerateFeedback("multi")}
                className={`${
                  aiLoading ? "ai-feedback-generate-btn-disabled" : "ai-feedback-generate-btn"
                } `}
              >
                {/* 생성중... : 전체학생 생성 */}
                {aiLoading ? t(`Creating`) : t(`Create All Student`)}
              </button>
              <button
                onClick={downloadExcel}
                style={{
                  background: "none",
                  padding: "6px 24px",
                  border: "1px solid #232323",
                  borderRadius: 4,
                  marginTop: 4,
                }}
              >
                {/* 엑셀로 내려받기 */}
                {t(`Download to Excel`)}
              </button>
            </div>
            {/* <button
              onClick={() => handleGenerateFeedback("multi")}
              className={`position-a ${
                aiLoading ? "ai-feedback-generate-btn-disabled" : "ai-feedback-generate-btn"
              } `}
              style={{ right: 0, top: 0 }}
            >
              {aiLoading ? "생성중..." : "전체 학생 생성"}
            </button> */}

            <div
              className="display-f"
              style={{ paddingBottom: 10, borderBottom: "1px solid lightgray" }}
            >
              <div style={{ width: 150 }} className="display-f align-center justify-center">
                <p style={{ color: "#979797", textAlign: "center" }}>
                  {/* 불러올
                  <br /> 내용 선택 */}
                  {t(`Select loading section`)}
                </p>
              </div>

              {/* 범위 선택 */}
              <div style={{ width: "100%" }}>
                <div className="mt-10 display-f align-center">
                  <button
                    onClick={() => {
                      setCheckAll((prev: any) => {
                        return {
                          ...prev,
                          recordAll: !prev.recordAll,
                        };
                      });
                    }}
                    className={`${
                      checkAll.recordAll && "stack-record-tags-selected bg-blue"
                    } btn-ai-select mr-10`}
                  >
                    {/* 모든 누가기록 */}
                    {t(`All anecdotal notes`)}
                    <br />
                    {/* 미태그 포함 */}
                    <span className="font-12">
                      {/* 미태그 포함 */}
                      {t(`Including untagged records`)}
                    </span>
                  </button>

                  <div className="display-f">
                    {/* <button
                      onClick={() => setStackForCookie((prev: boolean) => !prev)}
                      className={`stack_record_cookie stack_tag_btn mr-5 cursor record_tag_btn_width2
                           display-f align-center ${stackForCookie && "brown white"}
                      `}
                    >
                      <img src={cookie1} style={{ width: 18, height: "auto" }} />
                      <p>쿠키지급</p>
                    </button> */}

                    {recordTags?.map((tag: any, i: number) => {
                      if (tag.tagName) {
                        return (
                          <button
                            key={i}
                            onClick={() => handleClickStackTag(tag)}
                            className={`stack_record_unchecked stack_tag_btn white mr-5 cursor record_tag_btn_width ${
                              tag.selected && "blue border-none"
                            }`}
                          >
                            <p>{tag.tagName}</p>
                          </button>
                        );
                      }
                    })}
                  </div>
                </div>
                <div className="mt-10 display-f align-center">
                  <button
                    onClick={() => {
                      setCheckAll((prev: any) => {
                        return {
                          ...prev,
                          projectAll: !prev.projectAll,
                        };
                      });
                    }}
                    className={`${
                      checkAll.projectAll && "stack-record-tags-selected bg-yellow"
                    } btn-ai-select mr-10`}
                    style={{ marginTop: -5 }}
                  >
                    {/* 모든 과제 */}
                    {t(`All Assignments`)}
                    <br />
                    <span className="font-12">
                      {/* 미태그 포함 */}
                      {t(`Including untagged records`)}
                    </span>
                  </button>
                  <div className="display-f flex-wrap" style={{ width: "30%" }}>
                    {tags.map((tag: any, i: number) => {
                      if (!tag.tagName) {
                        return (
                          <div
                            key={i}
                            className="tag_btn_block gray text-center mr-5 mb-5"
                            style={{ height: 25 }}
                          >
                            <p style={{ color: "#ececec" }}> </p>
                          </div>
                        );
                      }
                      if (tag.tagName) {
                        return (
                          <button
                            onClick={() => handleFilterTag(tag)}
                            key={i}
                            className={`${
                              tag.selected && "tag_btn_selected-project"
                            } tag_btn_fill_project  text-center mr-5 mb-5 cursor`}
                          >
                            <p>{tag.tagName}</p>
                          </button>
                        );
                      }
                    })}
                  </div>
                </div>
                <div className="mt-10 display-f">
                  <button
                    onClick={() => {
                      setCheckAll((prev: any) => {
                        return {
                          ...prev,
                          checklistAll: !prev.checklistAll,
                        };
                      });
                    }}
                    className={`${
                      checkAll.checklistAll && "stack-record-tags-selected bg-green"
                    } btn-ai-select mr-10`}
                  >
                    {/* 모든 체크리스트 */}
                    {t(`All Checklist`)}
                    <br />
                    {/* added 240619 */}
                    {/* <span>체크리스트</span> */}
                  </button>
                  <div className="display-f flex-wrap" style={{ width: "80%" }}>
                    {foldersData?.map((checklistItem: any, i: number) => (
                      <button
                        key={i}
                        onClick={() => handleClickChecklistFolder(checklistItem)}
                        className={`${
                          checklistItem.selected && "ai-feedback-checklist-btn-check"
                        } ai-feedback-checklist-btn-uncheck`}
                      >
                        {checklistItem.title}
                      </button>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            <div
              className="display-f"
              style={{ paddingTop: 10, paddingBottom: 10, borderBottom: "1px solid lightgray" }}
            >
              <div style={{ width: 150 }} className="display-f align-center justify-center">
                <p style={{ color: "#979797", textAlign: "center" }}>
                  {/* 요구사항 */}
                  {t(`Request`)}
                  <br />
                  {/* 수정가능 */}({t(`Able to be revised`)})
                </p>
              </div>

              {/* 범위 선택 */}
              <div style={{ width: "100%" }}>
                <div>
                  <TextareaAutosize
                    value={prompt}
                    onChange={(e: any) => setPrompt(e.target.value)}
                    minRows={3}
                    className="ai-prompt-input"
                  />
                </div>
              </div>
            </div>
          </div>

          <div style={{ padding: "20px 0" }}>
            <p className="font-blue">
              {/* 학생 이름 카드를 누르면 해당 학생의 결과만 재생성 됩니다. */}
              {t(`Click on a student’s name to view their results`)}
            </p>
          </div>

          <div className="page_ai_feedback_body">
            {Object.keys(props.students)?.map((studentCode: any, i: number) => (
              <div key={i} className="display-f mb-10">
                <div
                  style={{
                    width: 150,
                    display: "flex",
                    flexDirection: "column",
                    // justifyContent: "flex-end",
                    alignItems: "flex-end",
                    marginRight: 10,
                  }}
                >
                  <button
                    onClick={() => handleGenerateFeedback("single", studentCode)}
                    className={`ai-student-btn`}
                    style={{
                      background: !studentLoadingState[studentCode] ? "#337ab7" : "none",
                      color: !studentLoadingState[studentCode] ? "#fff" : "#337ab7",
                      border: !studentLoadingState[studentCode] ? "" : "1px solid #337ab7",
                    }}
                  >
                    <p>
                      {props.students[studentCode].num}
                      {props.students[studentCode].num &&
                        props.students[studentCode].num !== "" &&
                        "."}{" "}
                      {props.students[studentCode].name}
                      <span style={{ fontSize: 10 }}>
                        {" "}
                        {/* {studentLoadingState[studentCode] && "(생성중...)"} */}
                        {studentLoadingState[studentCode] && `(${t(`Creating`)})`}
                      </span>
                    </p>
                  </button>
                </div>

                <div
                  style={{
                    width: "100%",
                    border: studentTextValueState[studentCode]
                      ? "1px solid #337ab7"
                      : "1px solid lightgray",
                    padding: 6,
                    position: "relative",
                  }}
                >
                  <TextareaAutosize
                    value={props.students[studentCode].aiFeedback}
                    onChange={(e: any) => handleSetStudentValue(studentCode, e)}
                    minRows={2}
                    style={{
                      background: "none",
                      border: "none",
                      width: "100%",
                      outline: "none",
                      resize: "none",
                      display: "block",
                    }}
                  />

                  <div
                    className="display-f"
                    style={{ justifyContent: "flex-end", alignItems: "f1ex-end" }}
                  >
                    <div
                      className="mr-10"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "#337ab7",
                      }}
                    >
                      {props.students[studentCode].aiFeedback?.length !== 0 &&
                        props.students[studentCode].aiFeedback?.length}
                      {props.students[studentCode].aiFeedback &&
                      props.students[studentCode].aiFeedback.length !== 0
                        ? "자"
                        : ""}
                    </div>

                    {props.students[studentCode].aiFeedback?.length > 0 && (
                      <button
                        style={{
                          background: "none",
                          border: "1px solid #337ab7",
                          color: "#fff",
                          padding: "4px 8px",
                          marginRight: 4,
                          borderRadius: 4,
                        }}
                      >
                        <CopyToClipboard
                          text={props.students[studentCode].aiFeedback}
                          onCopy={() => {
                            if (
                              props.students[studentCode].aiFeedback === "" ||
                              !props.students[studentCode].aiFeedback
                            ) {
                              return;
                            }
                            // alert("복사가 완료되었습니다.");
                            alert(t(`The copy is complete`));
                          }}
                        >
                          <img
                            src={copy}
                            className={"ai-copy-img"}
                            style={{ width: 14, height: "auto", marginTop: -4 }}
                          />
                        </CopyToClipboard>
                      </button>
                    )}
                    {props.students[studentCode].aiFeedback !== "" &&
                      studentTextValueState[studentCode] && (
                        <button
                          onClick={() => handleSaveStudentEachFeedback(studentCode)}
                          style={{
                            backgroundColor:
                              props.students[studentCode].aiFeedback?.length > 0
                                ? "#337ab7"
                                : "lightgray",
                            border: "none",
                            color: "#fff",
                            padding: "4px 8px",
                          }}
                        >
                          <p>
                            {/* 저장 */}
                            {t(`Save`)}
                          </p>
                        </button>
                      )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Classreport;

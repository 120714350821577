import React, { useState, useContext, useEffect, useRef } from "react";
import { Link, useHistory, Redirect } from "react-router-dom";
import { MainContext } from "../common/Context";
import { IMainContext } from "../interface/Interface";
import { getSignin } from "../common/Action";
import logoTop from "../images/logo-top.png";
import qr from "../images/qr.png";
import logoBottom from "../images/logo-bottom.png";
import kakao from "../images/kakao.png";
import talk from "../images/talk.png";
import { t } from "i18next";
import GoogleLoginButton from "../component/GoogleLoginButton";
import lang from "../images/language.png";
import Modal from "../component/Modal";
import ModalComponent from "../component/ModalComponent";
import i18n from "../Locales/i18n";
import { useTranslation } from "react-i18next";

const Login: React.FC = () => {
  const context = useContext<IMainContext>(MainContext);
  const storage = localStorage.getItem("language");
  const history = useHistory();
  const [idInput, setIdInput] = useState<string>("");
  const [passwordInput, setPasswordInput] = useState<string>("");
  const askRef = useRef<HTMLDivElement>(null);
  const [askPopup, setAskPopup] = useState<boolean>(false);
  const [langModal, setLangModal] = useState<boolean>(false);

  const { t } = useTranslation();

  const loginVerify = () => {
    if (idInput.length !== 0 && passwordInput.length !== 0) return true;
    else return false;
  };

  const handleLogin = async (e: any): Promise<void> => {
    e.preventDefault();
    const result: any = await getSignin({
      username: idInput,
      password: passwordInput.trim(),
    });

    // result === "비밀번호가 틀렸습니다." || result === "존재하지 않는 아이디입니다."
    if (result === t(`Invalid password`) || result === t(`This ID doesn't exist`)) {
      // alert("아이디 혹은 비밀번호를 확인해 주세요.");
      alert(t(`Please check your ID or password`));
    } else {
      localStorage.setItem("token", result);
      context.handleStateChange("isUser", true);
      context.handleGetUserInfo();
      // history.push("/");
      window.location.reload();
    }
  };

  // added 240619 언어 변경
  const handleChangeLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
    const currentLanguage: any = localStorage.setItem("language", lang);
    setLangModal(false);
  };

  const handleClickOutside = (e: React.ChangeEvent<HTMLDivElement>): void => {
    if (askRef.current !== null) {
      if (!askRef.current.contains(e.target)) setAskPopup(false);
    }
  };

  if (context.isUser && context.userName !== "") return <Redirect to="/" />;

  return (
    <>
      {langModal && (
        <ModalComponent
          modalStyles={{
            width: "24%",
            minHeight: "20vh",
            maxHeight: "80vh",
            overflowY: "scroll",
            borderRadius: 16,
            backgroundColor: "#fff",
          }}
          disableOutsideClose={false}
          onClose={() => setLangModal(false)}
          title={""}
          open={langModal}
        >
          <div style={{ padding: 10 }}>
            {/* 언어 선택 */}
            <h2 className="text-center">{t(`Choose Language`)}</h2>

            <div style={{ position: "relative" }}>
              <div className="rest_section" />
            </div>

            {/* <div className="popup__btns text-center mt-10 mb-10">
              <p style={{ fontSize: 14, color: "#979797" }}>
                - 현재 글로벌 번역 서비스 준비중입니다.-{" "}
                {t(`We are currently developing a global translation service`)}
              </p>
            </div> */}

            <button
              onClick={() => handleChangeLanguage("ko")}
              style={{ width: "100%", border: "none", padding: 8 }}
              className={` ${
                storage === "ko" ? "bg-yellow" : "border-yellow-1px bg-white text-yellow"
              }  font-600`}
            >
              {/* 한국어 */}
              {t(`Language ko`)}
            </button>
            <button
              onClick={() => handleChangeLanguage("en")}
              style={{ width: "100%", border: "none", padding: 8 }}
              className={` ${
                storage === "en" ? "bg-yellow" : "border-yellow-1px bg-white text-yellow"
              }  font-600`}
            >
              {/* 영어 */}
              {t(`Language en`)}
            </button>
            <button
              onClick={() => handleChangeLanguage("rw")}
              style={{ width: "100%", border: "none", padding: 8 }}
              className={` ${
                storage === "rw" ? "bg-yellow" : "border-yellow-1px bg-white text-yellow"
              }  font-600 border-top-none`}
            >
              {/* 르완다어 */}
              {t(`Language kinyarwanda`)}
            </button>
          </div>
        </ModalComponent>
      )}
      {/* {langModal && (
        <Modal
          show={langModal}
          onClose={() => {
            setLangModal(false);
          }}
        >
          <div
            className="stack_report_container sidebar_cookie_popup"
            style={{ width: "20vw", height: "20vh" }}
          >
            <h2 className="text-center">언어 선택</h2>

            <div style={{ position: "relative" }}>
              <div className="rest_section" />
            </div>

            <div className="popup__btns text-center mt-10">
              <p style={{ fontSize: 14, color: "#979797" }}>
                - 현재 글로번 번역 서비스 준비중입니다.
              </p>
            </div>
          </div>
        </Modal>
      )} */}
      <div className="login_background">
        <div className="container">
          <div className="display-f-center direction-column mt-60 login_p">
            <div>
              <div
                className="align-center"
                style={{ width: "100%", display: "flex", justifyContent: "space-between" }}
              >
                <a
                  style={{ marginBottom: 60 }}
                  target="_blank"
                  href={
                    "https://youtube.com/playlist?list=PL_RwrWFHnSbSFWykyCW1NtcI5gwbWVnb1&si=SckrQsYPDkBs-glM"
                  }
                  className="display-f align-center text-deco-none"
                >
                  <div style={{ marginTop: -4 }}>
                    <p className="font-700 color-dark-blue font-20">ℹ</p>
                  </div>
                  &nbsp;
                  <p className="cursor text-deco-none">
                    {/* 튜토리얼 */}
                    {t("Tutorial")}
                  </p>
                </a>

                <div
                  onClick={() => setLangModal(true)}
                  className="display-f align-center cursor"
                  style={{ marginBottom: 60 }}
                >
                  <img src={lang} className="mr-6" style={{ width: 16, height: "auto" }} />
                  <p>
                    {/* 언어  */}
                    {t(`language`)}
                  </p>
                </div>
              </div>

              <div className="display-f-center direction-column mb-63">
                <img src={logoTop} className="login_logo_top mb-24" alt="logo-top" />
                <img src={logoBottom} className="login_logo_bottom ml-30" alt="logo-top" />
              </div>

              <form onSubmit={handleLogin}>
                <div className="display-f direction-column mb-12">
                  <input
                    value={idInput}
                    onChange={(e) => setIdInput(e.target.value)}
                    placeholder={t(`Please enter your ID`)}
                    type="text"
                    className="login_input mb-12 font-14"
                  />
                  <input
                    value={passwordInput}
                    onChange={(e) => setPasswordInput(e.target.value)}
                    placeholder={t("Please enter a password")}
                    type="password"
                    className="login_input font-14"
                  />
                </div>
                <button
                  type="submit"
                  className="white login_btn font-14 mb-26 font-700"
                  onClick={handleLogin}
                  style={{ backgroundColor: loginVerify() ? "#cc9966" : "#bebebe" }}
                  // style={{ backgroundColor: "#cc9966" }}
                >
                  {/* 로그인 */}
                  {t(`Login`)}
                </button>
              </form>
              <a
                href={`https://kauth.kakao.com/oauth/authorize?response_type=code&client_id=6cadd7e874b389249dbed4eb27fbf71f&redirect_uri=https://dahandin.com/auth/kakao`}
              >
                <button
                  style={{ height: 38 }}
                  // onClick={() => alert("카카오 로그인은 정식 버전에서 이용해 주세요!")}
                  className="kakao login_btn font-14 mb-10 font-700 display-f-center"
                >
                  <img className="mr-10 kakao_img" src={kakao} alt="kakao" />
                  {/* 카카오톡 로그인 */}
                  {t(`KakaoTalk Login`)}
                </button>
              </a>
              <div className="mb-10">
                <GoogleLoginButton />
              </div>
            </div>

            <div className="color-dark-gray">
              <Link to="/signup">
                <span
                  // onClick={() =>
                  //   alert(
                  //     "개인정보 관리가 용이한 카카오톡 간편 로그인(회원가입)로 이용해주세요. \n아이디 공유가 필요한 특수한 경우(학원 강사 등) 따로 '문의하기'로 요청해주세요.",
                  //   )
                  // }
                  className="font-15 cursor font-500 sign-up cursor"
                >
                  {/* 회원가입 */}
                  {t(`Sign up`)}
                </span>
              </Link>
              <Link to="/find-id-pw">
                <span className="font-15 cursor font-500 cursor">
                  {/* 아이디/비밀번호 찾기 */}
                  {t(`Find ID Password`)}
                </span>
              </Link>
            </div>
          </div>

          <div className="login_m" style={{ marginTop: 10 }}>
            <div>
              <div
                className="align-center"
                style={{ width: "100%", display: "flex", justifyContent: "space-between" }}
              >
                <div>
                  <a
                    style={{ marginBottom: 60 }}
                    target="_blank"
                    href={
                      "https://youtube.com/playlist?list=PL_RwrWFHnSbSFWykyCW1NtcI5gwbWVnb1&si=SckrQsYPDkBs-glM"
                    }
                    className="display-f align-center text-deco-none"
                  >
                    <div style={{ marginTop: -4 }}>
                      <p className="font-700 color-dark-blue font-20">ℹ</p>
                    </div>
                    &nbsp;
                    <p className="cursor text-deco-none">
                      {/* 튜토리얼 */}
                      {t("Tutorial")}
                    </p>
                  </a>
                </div>

                <div
                  onClick={() => setLangModal(true)}
                  className="display-f align-center cursor"
                  style={{ marginBottom: 60 }}
                >
                  <img src={lang} className="mr-6" style={{ width: 16, height: "auto" }} />
                  <p>
                    {/* 언어 한국어 or english */}
                    {t(`language`)}
                  </p>
                </div>
              </div>

              <div className="display-f-center direction-column mb-63">
                <img src={logoTop} className="login_logo_top mb-24" alt="logo-top" />
                <img src={logoBottom} className="login_logo_bottom ml-30" alt="logo-top" />
              </div>

              <form onSubmit={handleLogin}>
                <div className="display-f direction-column mb-12">
                  <input
                    value={idInput}
                    onChange={(e) => setIdInput(e.target.value)}
                    // placeholder="아이디를 입력하세요."
                    placeholder={t(`login.Please enter your ID`)}
                    type="text"
                    className="login_input mb-12 font-18"
                  />
                  <input
                    value={passwordInput}
                    onChange={(e) => setPasswordInput(e.target.value)}
                    placeholder={t(`Please enter a password`)}
                    type="password"
                    className="login_input font-18"
                  />
                </div>
                <button
                  type="submit"
                  className="white login_btn font-16 mb-10 font-700"
                  onClick={handleLogin}
                  style={{ backgroundColor: loginVerify() ? "#cc9966" : "#bebebe" }}
                >
                  {/* 로그인 */}
                  {t(`Login`)}
                </button>
              </form>

              <a
                href={`https://kauth.kakao.com/oauth/authorize?response_type=code&client_id=6cadd7e874b389249dbed4eb27fbf71f&redirect_uri=https://dahandin.com/auth/kakao`}
              >
                <button className="kakao login_btn font-16 mb-10 font-700 display-f-center">
                  <img className="mr-10 kakao_img" src={kakao} alt="kakao" />
                  {/* 카카오톡 로그인 */}
                  {t(`KakaoTalk Login`)}
                </button>
              </a>

              <div className="mb-10">
                <GoogleLoginButton />
              </div>
            </div>

            <div className="color-dark-gray text-center">
              <Link to="/signup">
                {/* <span
              // onClick={() =>
              //   alert("일반회원가입은 점검중입니다. 카카오톡 간편 회원가입은 지금도 가능합니다.")
              // }
              onClick={() =>
                alert(
                  "개인정보 관리가 용이한 카카오톡 간편 로그인(회원가입)로 이용해주세요. \n아이디 공유가 필요한 특수한 경우(학원 강사 등) 따로 '문의하기'로 요청해주세요.",
                )
              }
              className="font-15 cursor font-500 sign-up cursor"
            > */}
                {/* 회원가입 */}
                {t(`Sign up`)}
                {/* </span> */}
              </Link>
              <Link to="/find-id-pw">
                <span className="font-15 cursor font-500 cursor">
                  {/* 아이디/비밀번호 찾기 */}
                  {t(`Find ID Password`)}
                </span>
              </Link>
            </div>
          </div>
        </div>

        <div style={{ position: "absolute", bottom: 20, right: 40 }}>
          <div className="color-dark-gray mt-15 display-f align-center justify-center direction-column">
            <img
              src={talk}
              className="cursor"
              style={{ width: 60, height: "auto" }}
              onClick={() => {
                setAskPopup((prevState) => !prevState);
              }}
            />
            <div className="mt-15 display-f align-center justify-center">
              <p className="font-700 color-dark-blue">?</p>&nbsp;
              <span
                onClick={() => setAskPopup((prevState) => !prevState)}
                className="cursor font-500 cursor color-dark-blue"
              >
                {/* 문의하기 */}
                {t(`Contact us`)}
              </span>
            </div>
          </div>
        </div>

        <div className="color-dark-gray mt-15 display-f align-center">
          {askPopup && (
            <div onClick={(e: any) => handleClickOutside(e)} className="popup-main">
              <div ref={askRef} className="main_popup scroll">
                <p className="text-center font-23 mb-26">
                  {/* 문의하기 */}
                  {t(`contact us`)}
                </p>
                {/* <p className="text-center font-20 mt-120 mb-40">dahandinkr@gmail.com</p> */}
                <div className="qr_box margin-auto mb-10">
                  <img src={qr} alt="qr-code" className="qr" />
                </div>
                <a href="https://open.kakao.com/me/dahandin" target="blank">
                  <p className="text-center font-16 mb-26 cursor">
                    {/* 바로가기 */}
                    {t(`Shortcuts`)}
                  </p>
                </a>
                <div style={{ padding: "0 20px" }}>
                  <p className="notice-text">
                    {/* &nbsp;저 역시 현직 교사이므로 오전 수업시간에는 답변이 어려우니 이 점
                    양해부탁드립니다. */}
                    {t(`I’m also a current teacher...`)}
                    <br />
                    {/* &nbsp;자주 연락주시는 부분과 그에 대한 답변을 아래에 정리하여 두었습니다.  */}
                    {t(`I’ve organized the most common questions...`)}
                    <br />
                    {/* &nbsp;'문의하기'로 카톡을 보내주시기 전에, 아래 글을 먼저 확인 부탁드립니다. */}
                    {t(`Before sending an inquiry please check the Q&A section below`)}
                    <br /> <br />
                  </p>
                  <p className="text-center font-500 font-16 mb-8">
                    {/* 자주 하시는 QnA */}
                    {t(`Frequently QNA`)}
                  </p>
                  <a target="_blank" href="https://blog.naver.com/dahandin/222660614302">
                    <p className="text-center font-16 mb-26 cursor">
                      {/* QnA 바로가기 */}
                      {t(`Link to Q&A`)}
                    </p>
                  </a>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Login;

import React, {
  useState,
  useEffect,
  useRef,
  ChangeEvent,
  useContext,
  useCallback,
  useLayoutEffect,
} from "react";
import { IMainContext, IProject, IStudent } from "../interface/Interface";
import { Link, NavLink, Route, useParams } from "react-router-dom";
import OutsideClickHandler from "react-outside-click-handler";
import ReactQuill from "react-quill";
import search from "../images/search.png";
import down_b from "../images/down_b.png";
import down from "../images/down.png";
import calendar from "../images/calendar.png";
import numbers from "../images/numbers.png";
import close from "../images/close.png";
import bin from "../images/bin.png";
import {
  deleteTag,
  getByToken,
  getCategorys,
  getFileSize,
  getProjects,
  getStudents,
  postAddProject,
  postCategory,
  postNotificationAll,
  postUpdate,
  postUploadFile,
  postUploadImage,
  putHideProject,
} from "../common/Action";
import { formatStyle, quillStyle } from "../common/QuillStyle";
import {
  fileTypeValidate,
  fileViewImage,
  getExtension,
  getFileNameAndExtension,
  getUnitSizeUtil,
  sortedImageFileArr,
} from "../common/Method";
import "react-datepicker/dist/react-datepicker.css";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { MainContext } from "../common/Context";
import CalendarC from "../component/CalendarC";
import PopupModal from "../component/PopupModal";
import file_icon from "../images/logo-top.png";
import axios from "axios";
import { t } from "i18next";

interface IfileInfo {
  fileUrl: string;
  fileType: string;
  fileName: string;
  file: File | null;
}

const Projects: React.FC = () => {
  const params: any = useParams();
  const context = useContext<IMainContext>(MainContext);
  const fileRef = useRef<HTMLInputElement>(null);
  const popupRef = useRef<HTMLDivElement>(null);
  const [cardData, setCardData] = useState<IProject[]>([]);
  const [students, setStudents] = useState<IStudent[]>([]);
  const [searchInput, setSearchInput] = useState<string>("");
  const [filter, setFilter] = useState<string[]>([
    t(`Not Marked`), // 미확인 순
    t(`Order of non submission`), // 미제출 순
    t(`Oldest to Newest`), // 생성 날짜 순
    t(`Alphabetical A Z`), // 가나다 순
  ]);
  const [filterSelected, setFilterSelected] = useState<string>(t(`Oldest to Newest`)); // 생성 날짜 순
  const [text, setText] = useState<string>("");
  const [projectNameInput, setProjectNameInput] = useState<string>("");
  const [files, setFiles] = useState<any[]>([]);
  const [fileUrls, setFileUrls] = useState<string[]>([]);
  const [filterPopup, setFilterPopup] = useState<boolean>(false);
  const [projectEdit, setProjectEdit] = useState<boolean>(false);
  const [allSelected, setAllselected] = useState<boolean>(false);
  const [studentsList, setStudentsList] = useState<boolean>(false);
  const [popup, setPopup] = useState<boolean>(false);
  const [blockSubmit, setBlockSubmit] = useState<boolean>(false);
  const [studentCheckWait, setStudentCheckWait] = useState<boolean>(false);
  const [repeatCheck, setRepeatCheck] = useState<boolean>(false);
  const [repeatProjectType, setRepeatProjectType] = useState<string>("");
  const [repeatSelectOpenC, setRepeatSelectOpenC] = useState<boolean>(false);
  const [selectRepeatCount, setSelectRepeatCount] = useState<number>(2);
  const [dates, setDates] = useState<any[]>([]);
  const [tags, setTags] = useState<any[]>([{}, {}, {}, {}, {}, {}, {}, {}, {}, {}]);
  const [addTagModal, setAddTagModal] = useState<boolean>(false);
  const [targetTag, setTargetTag] = useState<number>(-1);
  const [tagInput, setTagInput] = useState<string>("");
  const [allProjects, setAllProjects] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useLayoutEffect(() => {
    init();
    initTags();
    // return () => {};
  }, []);

  useEffect(() => {
    if (popup === false) {
      setText("");
      setProjectNameInput("");
      setFiles([]);
      setFileUrls([]);
    }
  }, [popup]);

  useEffect(() => {
    if (!repeatCheck) {
      setRepeatProjectType("");
    }
    if (repeatCheck && repeatProjectType === "") {
      setRepeatProjectType(t(`By Number of times`)); // 횟수형
    }
  }, [repeatCheck]);

  const init = async (): Promise<void> => {
    const projectResult: any = await getProjects(params.classId, false);
    const studentResult: any = await getStudents(params.classId);

    context.handleStateChange("students", studentResult);
    for (let i in studentResult) {
      studentResult[i].checked = true;
    }

    setAllProjects(projectResult);
    setCardData(projectResult);
    setStudents(studentResult);
  };

  const initTags = async (): Promise<void> => {
    const tagsResult: any = await getCategorys(params.classId);
    if (tagsResult.length !== 0) {
      setTags(tagsResult[0]?.tags);
    }
  };

  const handleSelectAll = (): void => {
    const temp = [...cardData];
    for (let i = 0; i < temp.length; i++) {
      if (!allSelected) {
        temp[i].checked = true;
      } else {
        temp[i].checked = false;
      }
    }
    setAllselected((prev) => !prev);
    setCardData(temp);
  };

  const handleFilterTags = (filter: string, index: number): void => {
    const selectedTags = tags.filter((item: any) => item.selected);
    if (selectedTags.length === 0) {
      setCardData(allProjects);
      return;
    }

    const tempProjects = [];
    for (let i in allProjects) {
      let exist = false;

      for (let k = 0; k < allProjects[i]?.tags?.length; k++) {
        for (let h = 0; h < selectedTags?.length; h++) {
          if (!exist && tags[allProjects[i].tags[k]] === selectedTags[h]) {
            tempProjects.push(allProjects[i]);
            exist = true;
          }
        }
      }
    }

    setCardData(tempProjects);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.target.value === "") {
      const temp = [...cardData];
      for (let i in temp) {
        temp[i].searchHide = false;
      }
      setCardData(temp);
    } else {
      let temp: any = [...cardData];
      for (let i in temp) {
        if (
          temp[i].title.includes(e.target.value) ||
          temp[i].description.includes(e.target.value)
        ) {
          temp[i].searchHide = false;
        } else {
          temp[i].searchHide = true;
        }
      }

      setCardData(temp);
    }
    setSearchInput(e.target.value);
  };

  const handleFilterSelect = async (index: number): Promise<void> => {
    let temp = "";
    temp = filter[index];
    setFilterSelected(temp);
    let filterData: any = [];

    if (filter[index] === t(`Not Marked`)) {
      // 미확인 순
      const projectResult: any = await getProjects(params.classId, false, "confirmOrder", 1);
      filterData = projectResult;
    } else if (filter[index] === t(`Order of non submission`)) {
      // 미제출 순
      const projectResult: any = await getProjects(params.classId, false, "status", -1);
      filterData = projectResult;
    } else if (filter[index] === t(`Oldest to Newest`)) {
      // 생성 날짜 순
      const projectResult: any = await getProjects(params.classId, false, "created", 1);
      filterData = projectResult;
    } else if (filter[index] === t(`Alphabetical A Z`)) {
      // 가나다 순
      const projectResult: any = await getProjects(params.classId, false, "title", 1);
      filterData = projectResult;
    }

    setCardData(filterData);
    setFilterPopup(false);
  };

  const handleCheckCard = (index: number): void => {
    const temp = [...cardData];
    for (let i = 0; i < temp.length; i++) {
      if (index === i) {
        temp[index].checked = !temp[index].checked;
      }
    }
    setCardData(temp);
  };

  const handleFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    // console.log(e.target.files && e.target.files[0].type);
    if (e.target.files) {
      let fileTemp = [...files];
      // let fileUrlTemp = [...fileUrls];
      for (let i in Object.keys(e.target.files)) {
        if (fileTypeValidate(e.target.files[i])) {
          fileTemp.push({
            fileUrl: fileViewImage(e.target.files[i]),
            file: e.target.files[i],
            fileType: getExtension(e.target.files[i]),
            fileName: getFileNameAndExtension(e.target.files[i]).fileName,
          });

          // fileUrlTemp.push(fileViewImage(e.target.files[i]));
        } else {
          console.log(e.target.files);
          // alert("첨부 가능한 파일만 첨부해주세요.");
          alert(t(`Please attach only the file that...`));
        }
      }

      const sorted = sortedImageFileArr(fileTemp);
      setFiles(sorted);
      // setFileUrls(fileUrlTemp);
    } else {
      return;
    }
  };

  const handleSubmit = async () => {
    if (loading) {
      // return alert("과제 생성중입니다...");
      return alert(t(`Creating Assignment`));
    }
    setLoading(true);

    if (blockSubmit) return;
    if (studentCheckWait) return;
    setStudentCheckWait(true);
    setBlockSubmit(true);
    if (projectNameInput === "") {
      setBlockSubmit(false);
      setStudentCheckWait(false);
      // return alert("과제 이름을 입력해 주세요.");
      return alert(t(`Please provide an assignment title`));
    }
    let tempUrls: string[] = [];
    let tempFileTypes: string[] = [];
    let tempFileNames: string[] = [];

    if (files.length !== 0) {
      for (let i in files) {
        let fileUrl: string | boolean = "";

        const formData = new FormData();
        formData.append("file", files[i].file);
        if (files[i].file.type.split("/")[0] === "image") {
          fileUrl = (await postUploadImage(formData)) as string;
        } else {
          fileUrl = (await postUploadFile(formData)) as string;
        }

        tempFileTypes.push(files[i].fileType);
        tempFileNames.push(files[i].fileName);
        tempUrls.push(fileUrl);
      }
    }

    let checkedStudents: string[] = [];
    let pushTokenList: any[] = [];
    for (let i in students) {
      if (students[i].checked) {
        checkedStudents.push(students[i].code);
        pushTokenList.push(students[i]?.deviceToken);
      }
    }
    let tempTags = [];
    for (let i = 0; i < tags?.length; i++) {
      if (tags[i]?.tagName && tags[i].tagSelected) {
        tempTags.push(i);
      }
    }
    let data: any = {
      title: projectNameInput,
      tags: tempTags,
      description: text,
      file: tempUrls,
      fileTypes: tempFileTypes,
      fileNames: tempFileNames,
      classId: params.classId,
      hide: false,
      newProjectAlert: true,
      repeat: repeatCheck,
      repeatType: repeatCheck
        ? repeatProjectType === t(`By Number of times`)
          ? "count"
          : "date"
        : "", // 횟수형 ?
      repeatCount:
        repeatProjectType === t(`By Number of times`) // 횟수형?
          ? selectRepeatCount
          : repeatProjectType === t(`By Date Selection`) // 날짜형 ?
          ? dates.length
          : 0,
      studentCodeList: checkedStudents,
    };
    if (repeatProjectType === t(`By Date Selection`)) {
      // 날짜형 ?
      data = { ...data, repeatDate: dates };
    }

    const projectInsertResult: any = await postAddProject(data);
    if (projectInsertResult.acknowledged) {
      let pushArr: any = [];
      let filerStudents = students.filter((el: any, i: number) => el.checked && el.deviceToken2);
      filerStudents.map((el: any, i: number) => {
        if (el.deviceToken2) {
          pushArr.push({
            token: el.deviceToken2,
            notification: {
              title: `${projectNameInput}💌`,
              body: t(`You have a new assignment`), // "새로운 과제가 도착했어요!",
            },
            data: {
              code: el.code,
              type: "project",
            },
          });
        }
      });
      postNotificationAll(pushArr);
    }
    setLoading(false);
    setStudentCheckWait(false);
    setPopup(false);
    setBlockSubmit(false);
    setRepeatCheck(false);
    setRepeatProjectType("");
    setDates([]);
    init();
  };

  const handleStudentChecked = (i: number): void => {
    setStudentCheckWait(true);
    const temp = [...students];
    temp[i].checked = !temp[i].checked;
    setStudents(temp);
    setStudentCheckWait(false);
  };

  const handleStudentsAllCheck = (state: boolean) => {
    setStudentCheckWait(true);
    const temp = [...students];
    for (let i in temp) {
      temp[i].checked = state;
    }
    setStudents(temp);
    setStudentCheckWait(false);
  };

  const handleClickOutside = (e: any) => {
    if (popupRef.current !== null) {
      if (!popupRef.current.contains(e.target)) setPopup(false);
    }

    setDates([]);
  };

  const handleHideProject = async (): Promise<void> => {
    let tempHideData = [];
    for (let i in cardData) {
      if (cardData[i].checked) {
        tempHideData.push(cardData[i]._id);
      }
    }

    await putHideProject({
      idList: tempHideData,
      hide: true,
    });

    alert(
      // "숨긴 과제 보관함으로 이동되었습니다! 숨긴 과제는 학생의 휴대폰에서 보이지 않게 됩니다.
      // 단, 삭제 된 것이 아니라 언제든지 보관함으로 가셔셔 복구가 가능합니다!
      // 과제가 너무 많아지면 오래된 과제를 보관함으로 이동시켜 정리하세요!",
      t(`It has been moved to the assignment archive...`),
    );
    setProjectEdit(false);
    init();
  };

  const handleChageText = (e: any) => {
    setText(e);
  };

  const getCount = () => {
    let temp = [];
    for (let i = 2; i <= 21; i++) {
      temp.push(i);
    }
    return temp;
  };

  const handleSelectDate = (day: any) => {
    const tempDates: any = [];
    let exist: boolean = false;
    for (let i in dates) {
      if (dates[i] === day.valueOf()) {
        exist = true;
      } else {
        tempDates.push(dates[i]);
      }
    }
    if (!exist) tempDates.push(day.valueOf());
    if (tempDates.length === 22) {
      // return alert("과제는 21개 이상 등록하실 수 없습니다.");
      return alert(t(`Registration of more than 21 assignments is not allowed`));
    }

    setDates(tempDates);
  };

  const handleDeleteImg = (data: any, index: number) => {
    let tempFiles = [...files];
    tempFiles.splice(index, 1);

    setFiles(tempFiles);
  };

  const handleClickTag = (tag: any, i: number) => {
    setTargetTag(i);
    if (tag.tagName) setTagInput(tag.tagName);
    else setTagInput("");
    setAddTagModal(true);
  };

  const handleAddTag = async () => {
    let tempTags = [];
    for (let i in tags) {
      tempTags.push({ tagName: tags[i].tagName });
    }
    if (tagInput === "") {
      // return alert("태그명을 입력해 주세요.");
      return alert(t(`Enter a tag name`));
    }
    tempTags[targetTag].tagName = tagInput;

    const insertCategory = await postCategory({
      classId: params.classId,
      tags: tempTags,
    });

    setTags(tempTags);
    setTagInput("");
    setAddTagModal(false);
  };

  const handleSelectTag = (type: string, tag: any, index: number, tagName: string) => {
    let tempArr = [...tags];
    if (type === "view") {
      tempArr[index].selected = !tags[index].selected;
      handleFilterTags(tagName, index);
    }

    if (type === "add") {
      const findIndexOf = tempArr.findIndex((el: any, i: number) => tag === el);
      tempArr[findIndexOf].tagSelected = !tags[findIndexOf].tagSelected;
      // console.log(tempArr);
    }
    setTags(tempArr);
  };

  const handleDeleteTag = async (): Promise<void> => {
    const confirm: any = window.confirm(
      // "해당 태그가 달린 모든 과제에서 이 태그가 완전히 삭제됩니다. 진행하시겠습니까?",
      t(`This will completely remove the tag from all...`),
    );
    if (confirm) {
      const deleteResult = await deleteTag({
        classId: params.classId,
        deleteTagIndex: targetTag,
      });
    } else {
      return;
    }

    setAddTagModal(false);
    initTags();
  };

  const handleAddProject = async () => {
    let fileLimit = 0;
    if (context.userInfo.fileSizeLimit === 0 || !context.userInfo.fileSizeLimit) {
      fileLimit = 100000000;
    } else {
      fileLimit = context.userInfo?.fileSizeLimit;
    }
    if (fileLimit < context.fileSize.originalFileSize) {
      return alert(
        // "제한 용량을 초과하셨습니다. '과제 삭제' 또는 '학급 삭제'를 통해 저장 용량을 조절하시거나, '결제 관리' 페이지에서 저장 용량 업그레이드 후 이용해 주세요.",
        t(`You have exceeded the storage limit...`),
      );
    }
    setPopup(true);
  };

  return (
    <>
      <div className="col-md-12 category_container">
        {/* header */}

        {projectEdit ? (
          <div className="display-f justify-between align-center mb-30 pl-10 pr-10">
            <div className="display-f align-center">
              <button onClick={handleSelectAll} className="select_all_btn mr-10 position-r">
                {allSelected && (
                  <span className={`check__inside select_all position-a bg-checked`}></span>
                )}
              </button>
              <p className="font-16 mt-9 mb-10">{t(`Select All`)}</p>
            </div>

            <div style={{ width: "30%" }}>
              {tags?.map((tag: any, i: number) => (
                <>
                  {!tag.tagName && (
                    <button
                      onClick={() => {
                        handleClickTag(tag, i);
                      }}
                      className={`tag_btn ${i % 5 === 4 ? "" : "mr-10"} ${i > 4 ? "mt-10" : ""}`}
                    >
                      +
                    </button>
                  )}
                  {tag.tagName && (
                    <button
                      onClick={() => {
                        handleClickTag(tag, i);
                      }}
                      className={`tag_btn_fill tag_btn_selected ${i % 5 === 4 ? "" : "mr-10"} ${
                        i > 4 ? "mt-10" : ""
                      }`}
                    >
                      {tag.tagName}
                    </button>
                  )}
                </>
              ))}
            </div>

            <div className="display-f">
              <div>
                <button onClick={handleHideProject} className="select_option_btn mr-20">
                  {/* 숨기기 */}
                  {t(`Hide`)}
                </button>
              </div>
              <div>
                <button onClick={() => setProjectEdit(false)} className="select_submit_btn">
                  {/* 확인 */}
                  {t(`Confirm`)}
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className="display-f justify-between align-center mb-30 pl-10 pr-10">
            <div className="display-f position-r">
              <form
                className="mr-28 position-r"
                // onSubmit={(e: any) => handleSearch(e)}
                action={`/projects/${searchInput}`}
              >
                <img
                  // onClick={(e: any) => handleSearch(e)}
                  className="search_icon position-a cursor"
                  src={search}
                  alt="search"
                />
                <input
                  value={searchInput}
                  onChange={handleSearch}
                  type="text"
                  className="category_searchInput pl-50 pr-10"
                />
              </form>
              <OutsideClickHandler
                onOutsideClick={() => {
                  setFilterPopup(false);
                }}
              >
                <button
                  onClick={() => setFilterPopup((prev) => !prev)}
                  className="filter_btn text-left pl-17 pr-12 display-f justify-between align-center"
                >
                  {filterSelected} <img className="filter_icon" src={down} alt="down" />
                </button>
                {filterPopup && (
                  <div className="position-a filter_contents">
                    {filter.map((data: string, index: number) => (
                      <div
                        onClick={() => handleFilterSelect(index)}
                        className="filter_inner cursor"
                        key={index}
                      >
                        <p className="pl-17 pt-11 pb-11 font-14">{data}</p>
                      </div>
                    ))}
                  </div>
                )}
              </OutsideClickHandler>
            </div>

            <div style={{ width: "30%" }}>
              {tags?.map((tag: any, i: number) => (
                <>
                  {!tag.tagName && (
                    <button
                      onClick={() => handleClickTag(tag, i)}
                      className={`tag_btn ${i % 5 === 4 ? "" : "mr-10"} ${i > 4 ? "mt-10" : ""}`}
                    >
                      +
                    </button>
                  )}
                  {tag?.tagName && (
                    <button
                      onClick={() => {
                        handleSelectTag("view", tag, i, tag.tagName);
                      }}
                      className={`tag_btn_fill ${i % 5 === 4 ? "" : "mr-10"} ${
                        i > 4 ? "mt-10" : ""
                      } ${tag.selected && "tag_btn_selected"}`}
                    >
                      {tag.tagName}
                    </button>
                  )}
                </>
              ))}
            </div>

            <div className="display-f">
              <Link
                className={`${window.location.pathname.includes("hidden") && "active"}`}
                to={`/page/hiddens/project/${params.classId}`}
              >
                <button
                  style={{
                    border: "1px solid #979797",
                    backgroundColor: "#fff",
                    fontWeight: 500,
                  }}
                  className="text-left mr-10 cursor display-f align-center select_btn"
                >
                  <img style={{ width: 18, height: "auto", marginRight: 5 }} src={bin} alt="bin" />
                  <p className="font-15 sidebar_contents">
                    {/* 숨긴 과제함 */}
                    {t(`Archived Assignments`)}
                  </p>
                </button>
              </Link>

              <button onClick={() => setProjectEdit(true)} className="select_btn mr-28">
                {/* 수정 */}
                {t(`Edit`)}
              </button>
            </div>
          </div>
        )}

        {/* card */}
        <div className="display-f flex-wrap">
          {/* {cardData.length === 0 && <Nodata />} */}
          {cardData.length !== 0 &&
            cardData?.map((data: any, index: number) => {
              if (projectEdit && !data.repeat) {
                return (
                  <div key={index} className="display-ib pl-10 pr-10 position-r">
                    <button
                      style={{ border: "none" }}
                      onClick={() => handleCheckCard(index)}
                      className={`card project_inner_card padding-0 justify-center cursor pl-15 pr-15 ${data.status}`}
                    >
                      <div className="display-b font-15 color-dark font-700 word-break text-center">
                        {data.title}
                      </div>
                      <span
                        // onClick={() => handleCheckCard(index)}
                        className="position-a check cursor"
                      >
                        {data.checked && (
                          <span className={`check__inside position-a bg-checked`}></span>
                        )}
                      </span>
                    </button>
                  </div>
                );
              }

              if (!projectEdit && !data.searchHide && !data.repeat) {
                return (
                  <Link key={index} to={`/page/project/${params.classId}/${data._id}`}>
                    <div key={index} className="display-ib pl-10 pr-10">
                      <div
                        className={`card project_inner_card justify-center padding-0 pl-15 pr-15 word-break background-${data.status}`}
                      >
                        <div className="display-b font-15 color-dark word-break font-700 text-center">
                          {data.title}
                        </div>
                      </div>
                    </div>
                  </Link>
                );
              }

              if (!projectEdit && !data.searchHide && data.repeat) {
                return (
                  <Link key={index} to={`/page/project/${params.classId}/${data._id}`}>
                    <div
                      key={index}
                      className={`display-ib pl-10 pr-10 `}
                      style={{ position: "relative" }}
                    >
                      {data.repeatType === "date" && (
                        <img
                          src={calendar}
                          className="repeat-type-icon"
                          style={{ width: 19, height: 19 }}
                        />
                      )}
                      {data.repeatType === "count" && (
                        <img src={numbers} className="repeat-type-icon" style={{ width: 21 }} />
                      )}
                      <div
                        className={`card project_inner_card2 justify-center pl-15 pr-15 ${data.status} border-${data.borderStatus}`}
                      >
                        <div className="font-15 color-dark font-700 text-center word-break">
                          {data.title}
                        </div>
                      </div>
                    </div>
                  </Link>
                );
              }

              if (projectEdit && !data.searchHide && data.repeat) {
                return (
                  <div key={index} className="display-ib pl-10 pr-10 position-r">
                    <button
                      onClick={() => handleCheckCard(index)}
                      style={{ border: "8px solid rgb(206, 206, 206)" }}
                      className={`card project_inner_card2 padding-0 justify-center cursor pl-15 pr-15 ${data.status} border-${data.borderStatus}`}
                    >
                      <div className="display-b font-15 color-dark font-700 word-break text-center">
                        {data.title}
                      </div>
                      <span className="position-a check cursor">
                        {data.checked && (
                          <span className={`check__inside position-a bg-checked`}></span>
                        )}
                      </span>
                    </button>
                  </div>
                );
              }
            })}

          {!projectEdit && tags.filter((el: any) => el.selected).length === 0 && (
            <div className="display-ib pl-10 pr-10">
              <div
                onClick={handleAddProject}
                className="card project_inner_card padding-0 justify-center project_card"
              >
                <p className="font-34 font-900">+</p>
              </div>
            </div>
          )}
        </div>

        {/* popup */}
        {popup && (
          <div className="popup">
            <div className="modal-overlay" onClick={(e: any) => handleClickOutside(e)}></div>
            <div ref={popupRef} className="project_add_popup scroll">
              {/* 과제 생성 */}
              <p className="font-23 mb-26 text-center">{t(`Create Assignment`)}</p>
              {/* 과제 이름 */}
              <p className="color-light-black mb-8 font-15">{t(`Assignment title`)}(*)</p>
              <div className="display-f align-center mb-22">
                <input
                  value={projectNameInput}
                  onChange={(e: any) => setProjectNameInput(e.target.value)}
                  className="popup_input mr-18 pl-12 pr-12"
                  type="text"
                  maxLength={31}
                />
              </div>

              <p className="color-light-black mb-8 font-15">{t(`Tag`)}</p>
              {tags?.filter((item: any, i: number) => item.tagName).length === 0 && (
                <p style={{ fontSize: 11, color: "#979797" }}>
                  {/* 아직 등록된 분류 기준이 없습니다. 분류 기준을 추가해 주세요! */}
                  {t(`There are no tags yet please add one`)}
                </p>
              )}

              <div style={{ width: "65%", marginBottom: 20 }}>
                {tags
                  .filter((item: any, i: number) => item.tagName)
                  .map((tag: any, index: number) => (
                    <div
                      key={index}
                      className={`modal_in_tag ${index % 5 !== 4 && "mr-10"} ${
                        index > 4 && "mt-5"
                      }`}
                      style={{ display: "inline-block" }}
                    >
                      <button
                        onClick={() => handleSelectTag("add", tag, index, "")}
                        style={{ width: "100%" }}
                        className={`modal_tag_btn ${tag.tagSelected && "tag_btn_selected"}`}
                      >
                        {tag.tagName}
                      </button>
                    </div>
                  ))}
              </div>

              {/* 반복과제 */}
              <div style={{ marginBottom: 20 }} className="position-r">
                <div className="display-f">
                  <input
                    onChange={() => setRepeatCheck((prev: boolean) => !prev)}
                    // checked={repeatCheck}
                    type="checkbox"
                    id={JSON.stringify("repeat")}
                    className="display-n"
                  />
                  <div>
                    <label
                      className="target_label2 cursor"
                      htmlFor={JSON.stringify("repeat")}
                      style={{ marginRight: 5 }}
                    >
                      {repeatCheck && <span className="studentList_check"></span>}
                    </label>
                  </div>
                  <p
                    onClick={() => setRepeatCheck((prev: boolean) => !prev)}
                    className="color-light-black font-15 mb-8 cursor"
                  >
                    {/* 반복과제 설정 */}
                    {t(`Repeat`)}
                  </p>
                </div>

                <button
                  onClick={() => {
                    setRepeatCheck(true);
                    setRepeatProjectType(t(`By Number of times`)); // 횟수형
                  }}
                  className="repeat-type-btn"
                  style={{
                    marginLeft: 25,
                    marginRight: 10,
                    // backgroundColor: repeatProjectType === "횟수형" ? "#FFD37E" : "",
                    // color: repeatProjectType === "횟수형" ? "#000" : "#979797",
                    backgroundColor: repeatProjectType === t(`By Number of times`) ? "#FFD37E" : "",
                    color: repeatProjectType === t(`By Number of times`) ? "#000" : "#979797",
                  }}
                >
                  {/* 횟수형 */}
                  {t(`By Number of times`)}
                </button>
                <button
                  onClick={() => {
                    setRepeatCheck(true);
                    setRepeatProjectType(t(`By Date Selection`)); // 날짜형
                  }}
                  className="repeat-type-btn"
                  style={{
                    // backgroundColor: repeatProjectType === "날짜형" ? "#FFD37E" : "",
                    // color: repeatProjectType === "날짜형" ? "#000" : "#979797",
                    backgroundColor: repeatProjectType === t(`By Date Selection`) ? "#FFD37E" : "",
                    color: repeatProjectType === t(`By Date Selection`) ? "#000" : "#979797",
                  }}
                >
                  {/* 날짜형 */}
                  {t(`By Date Selection`)}
                </button>

                {/* 횟수형 */}
                {repeatProjectType === t(`By Number of times`) && (
                  <>
                    <div
                      style={{ marginLeft: 25, alignItems: "center", marginTop: 10 }}
                      className="display-f"
                    >
                      <div
                        onClick={() => setRepeatSelectOpenC((prev: boolean) => !prev)}
                        className="repeat-selectbar cursor"
                      >
                        <p>{selectRepeatCount}</p>
                        <img src={down_b} style={{ width: 18, height: "auto" }} />
                      </div>
                      <p>
                        {/* 회 */}
                        {t(`Times`)}
                      </p>
                    </div>

                    {repeatSelectOpenC && (
                      <div className="repeat-selectbar-open">
                        {getCount().map((numberItem: any, i: number) => (
                          <div
                            onClick={() => {
                              setSelectRepeatCount(numberItem);
                              setRepeatSelectOpenC(false);
                            }}
                            className="repeat-select-item-n cursor"
                            style={{
                              fontWeight: numberItem === selectRepeatCount ? "bold" : "normal",
                            }}
                          >
                            {numberItem}
                          </div>
                        ))}
                      </div>
                    )}
                  </>
                )}

                {/* 날짜형 */}
                {repeatProjectType === t(`By Date Selection`) && (
                  <>
                    <div style={{ marginTop: 10, marginLeft: 25 }}>
                      <CalendarC
                        selectDates={dates}
                        setSelectDates={setDates}
                        handleSelectDate={handleSelectDate}
                      />
                    </div>
                  </>
                )}
              </div>
              {/* 반복과제 */}

              <p className="color-light-black font-15 mb-8">{t(`Instructions`)}(*)</p>
              <ReactQuill
                value={text}
                onChange={(e) => handleChageText(e)}
                className="project-quill"
                modules={quillStyle}
                formats={formatStyle}
              />

              <div>
                {files.map((data: IfileInfo, index: number) => (
                  <>
                    {data.fileType === "image" && (
                      <div
                        className="img-add-wrapper"
                        key={index}
                        style={{ marginRight: index % 3 === 2 ? 0 : 10, display: "inline-block" }}
                      >
                        <img
                          onClick={() => handleDeleteImg(data, index)}
                          src={close}
                          style={{ width: 17, height: 17 }}
                          className="img-delete"
                        />

                        <img src={data.fileUrl} className="img-add mb-10" alt="file" />
                        {/* <p className="txt_line" style={{ fontSize: 13 }}>
                          {data.fileName}
                        </p> */}
                      </div>
                    )}

                    {data.fileType !== "image" && (
                      <div key={index} className="mt-10 display-f align-center">
                        <img
                          src={close}
                          onClick={() => handleDeleteImg(data, index)}
                          style={{ width: 10, height: 10 }}
                          className="cursor mr-5"
                        />
                        <img
                          src={file_icon}
                          style={{ width: 30, height: "auto", marginRight: 3 }}
                          alt="file"
                        />
                        <p style={{ fontSize: 13 }}>{data.fileName}</p>
                      </div>
                    )}
                  </>
                ))}
              </div>
              <div className="popup__additional__project mb-30">
                <input
                  ref={fileRef}
                  type="file"
                  className="display-n"
                  name="file"
                  onChange={(e: any) => handleFile(e)}
                  multiple
                />
                <button
                  onClick={() => {
                    fileRef.current !== null && fileRef.current.click();
                  }}
                  className="focus add__file__btn font-13 font-500 mr-11 mt-10"
                >
                  {/* 파일 첨부 */}
                  {t(`Attach`)}
                </button>

                {fileUrls && (
                  <button
                    onClick={() => {
                      setFiles([]);
                      setFileUrls([]);
                    }}
                    className="focus add__file__btn font-13 font-500 mr-11 mt-10"
                  >
                    {/* 파일 전체 삭제 */}
                    {t(`Delete All files`)}
                  </button>
                )}

                <button
                  onClick={() => setStudentsList(true)}
                  className="focus target__student__btn font-13 font-500"
                >
                  {/* 대상학생 */}
                  {`${t(`Select Recipient`)} (${
                    students.filter((item: IStudent) => item.checked).length
                  })`}
                </button>

                {studentsList === true && (
                  <div className="studentsList__popup">
                    <div className="studentsList__contents">
                      <div className="overflow-h scroll mb-30">
                        <p className="font-23 text-center mb-20 pt-20">
                          {/* 대상 학생 목록 */}
                          {t(`List of recipients`)}
                        </p>
                        {students
                          .sort((a: any, b: any) => a.num - b.num)
                          .map((data: IStudent, index: number) => (
                            <div key={index} className="managing__options__contents position-r">
                              <p className="mt-15 mb-15 font-15">{data.name}</p>
                              <input
                                onChange={() => handleStudentChecked(index)}
                                // checked={data.target}
                                type="checkbox"
                                id={JSON.stringify(index)}
                                className="display-n"
                              />
                              <div>
                                <label
                                  className="target_label cursor"
                                  htmlFor={JSON.stringify(index)}
                                >
                                  {data.checked && <span className="studentList_check"></span>}
                                </label>
                              </div>
                            </div>
                          ))}
                        <div>
                          <div className="text-center mt-20 mb-20 pb-20">
                            <button
                              onClick={() => handleStudentsAllCheck(true)}
                              className="focus popup__bottom__btn mr-14"
                            >
                              {/* 모두 선택 */}
                              {t(`Select All`)}
                            </button>
                            <button
                              onClick={() => handleStudentsAllCheck(false)}
                              className="focus popup__bottom__btn mr-14"
                            >
                              {/* 모두 해제 */}
                              {t(`Deselect all`)}
                            </button>
                            <button
                              onClick={() => setStudentsList(false)}
                              className="focus popup__bottom__btn mr-14"
                            >
                              {/* 확인 */}
                              {t(`Confirm`)}
                            </button>
                            <button
                              onClick={() => setStudentsList(false)}
                              className="focus popup__bottom__btn"
                            >
                              {/* 취소 */}
                              {t(`Cancel`)}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="text-center mb-20">
                <button onClick={handleSubmit} className="focus popup__bottom__btn2 mr-14">
                  {loading
                    ? // "과제 생성중..."
                      t(`Creating Assignment`)
                    : // 생성
                      t(`Create`)}
                </button>
                <button
                  onClick={() => {
                    setPopup(false);
                    setRepeatProjectType("");
                    setRepeatCheck(false);
                    setDates([]);
                  }}
                  className="focus popup__bottom__btn"
                >
                  {/* 취소 */}
                  {t(`Cancel`)}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>

      <PopupModal openModal={addTagModal} setOpenModal={setAddTagModal} styles={{ marginTop: 200 }}>
        <div>
          <div className="text-center" style={{ marginTop: -10 }}>
            <h2 style={{ marginBottom: 0 }}>
              {/* 분류기준 */}
              {t(`Categorization`)}
            </h2>
            <p style={{ marginBottom: 0, fontSize: 15 }} className="font-500 mt-5">
              {
                !tags[targetTag]?.tagName
                  ? // "추가"
                    t(`Add`)
                  : t(`Edit Delete`)
                // "수정/삭제"
              }
            </p>
          </div>

          <div className="mt-20 display-f justify-center align-center">
            <p className="mr-10 bold" style={{ fontSize: 20, fontWeight: 600 }}>
              {/* 태그명 :{" "} */}
              {t(`Tag name`)} :{" "}
            </p>
            <input
              value={tagInput}
              onChange={(e: ChangeEvent<any>) => {
                if (e.target.value.length > 4) {
                  return;
                } else {
                  setTagInput(e.target.value);
                }
              }}
              className="tag_input"
            />
          </div>

          <p className="card-text-red text-center mt-9" style={{ fontSize: 12 }}>
            {/* 최대 4글자 */}
            {t(`Up to 4 characters`)}
          </p>

          <div className="mt-20" style={{ marginBottom: 10 }}>
            <div className="display-f justify-center align-center">
              {projectEdit && tags[targetTag]?.tagName && (
                <>
                  <button className="mr-10 badge-btn dark_green" onClick={handleAddTag}>
                    {/* 수정 */}
                    {t(`Edit`)}
                  </button>
                </>
              )}

              {!tags[targetTag]?.tagName && (
                <>
                  <button className="mr-10 badge-btn dark_green" onClick={handleAddTag}>
                    {/* 추가 */}
                    {t(`Add`)}
                  </button>
                </>
              )}
              <button onClick={() => setAddTagModal(false)} className="badge-btn">
                {/* 취소 */}
                {t(`Cancel`)}
              </button>
            </div>
            {projectEdit && tags[targetTag]?.tagName && (
              <div className="text-center mt-10">
                <button onClick={handleDeleteTag} className="badge-btn-delete">
                  {/* 삭제 */}
                  {t(`Delete`)}
                </button>
              </div>
            )}
          </div>
        </div>
      </PopupModal>
    </>
  );
};

export default Projects;

import axios from "axios";
import React, { useState, useEffect, useContext, useRef } from "react";
import { useHistory } from "react-router-dom";
import { IMainContext } from "../interface/Interface";
import { MainContext } from "../common/Context";
import MainHeader from "./MainHeader";
import { currency, time1, time2, time3 } from "../common/Method";
import qr from "../images/qr.png";
import moment from "moment";
import warning from "../images/warning.png";

import { getIsRegisteredSerialCode, postCancelSerialCode } from "../common/Action";
import { t } from "i18next";

const PAY_PLAN_ARR = [
  {
    plan: t(`Beginners`), // plan: "비기너",
    color: "bg-beginner",
    desc: "~100MB",
    durations: [
      {
        duration: 0,
        label: t(`For free`), // label: "무료",
        price: 0,
        disable: true,
      },
    ],
  },
  {
    plan: t(`Basic`), //  plan: "베이직",
    color: "bg-basic",
    desc: "~15GB",
    durations: [
      {
        duration: 1,
        label: t(`Month`), // label: "월",
        price: 9900,
        disable: true,
      },
      {
        duration: 6,
        label: t(`6 months`), // label: "6개월",
        price: 49500,
      },
      {
        duration: 12,
        label: t(`1 year`), // label: "1년",
        price: 99000,
      },
    ],
  },
  {
    plan: t(`Standard`), // plan: "스탠다드",
    color: "bg-standard",
    desc: "~50GB",
    durations: [
      {
        duration: 1,
        label: t(`Month`), // label: "월",
        price: 14300,
        disable: true,
      },
      {
        duration: 6,
        label: t(`6 months`), // label: "6개월",
        price: 71500,
      },
      {
        duration: 12,
        label: t(`1 year`), // label: "1년",
        price: 143000,
      },
    ],
  },
  {
    plan: t(`Standard Plus`), // plan: "스탠다드플러스",
    color: "bg-standardplus",
    desc: "~200GB",
    durations: [
      {
        duration: 1,
        label: t(`Month`), // label: "월",
        price: 18700,
        disable: true,
      },
      {
        duration: 6,
        label: t(`6 months`), // label: "6개월",
        price: 93500,
      },
      {
        duration: 12,
        label: t(`1 year`), // label: "1년",
        price: 187000,
      },
    ],
  },
  {
    plan: t(`Premium`), // plan: "프리미엄",
    color: "bg-enterprise",
    desc: "2TB~",
    durations: [
      {
        duration: 1,
        label: t(`Month`), // label: "월",
        price: 18700,
        disable: true,
      },
      {
        duration: 6,
        label: t(`6 months`), // label: "6개월",
        price: 93500,
      },
      {
        duration: 12,
        label: t(`1 year`), // label: "1년",
        price: 187000,
      },
    ],
  },
];

const PayPlanMain: React.FC = () => {
  const history = useHistory();
  const context = useContext<IMainContext>(MainContext);
  const [askPopup, setAskPopup] = useState<boolean>(false);
  const askRef = useRef<HTMLDivElement>(null);
  const [registered, setRegistered] = useState<boolean>(true);
  const [registeredCode, setRegisteredCode] = useState<string>("");
  const [paymentInfo, setPaymentInfo] = useState<any>({
    mainType: "개별 결제",
    subType: "학교(법인) 카드로 결제",
    plan: "",
    duration: -1,
    price: 0,
  });
  const [productName, setProductName] = useState<string>("");

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    const isRegistered: any = await getIsRegisteredSerialCode();

    if (!isRegistered.registeredUser) {
      setRegistered(false);
    } else {
      setRegisteredCode(isRegistered.serialCode);
      setProductName(isRegistered.productName);
    }
  };

  useEffect(() => {
    const clickOutside = (e: any) => {
      // 모달이 열려 있고 모달의 바깥쪽을 눌렀을 때 창 닫기

      if (askPopup && askRef.current && !askRef.current.contains(e.target)) {
        setAskPopup(false);
      }
    };

    document.addEventListener("click", clickOutside);

    return () => {
      document.removeEventListener("click", clickOutside);
    };
  }, [askPopup]);

  const handleGoPrivatePay = () => {
    // return alert("1인 이용 서비스 준비중입니다.");
    if (registered)
      return alert(
        // "단체 이용권이 등록되어 있습니다. 등록된 단체 이용권(시리얼 코드)를 해제하고 결제를 진행해주세요.",
        t(`You are currently registered under a group plan...`),
      );

    if (context.userInfo?.paidInfo?.isPersonal && context.userInfo?.fileSizeLimit !== 0)
      // return alert("이미 1인 결제를 하신 경우 중복 결제가 불가합니다.");
      return alert(t(`If you have already paid for a single plan...`));

    history.push("/plan/private");
  };

  const handleGoBulkPay = () => {
    if (context.userInfo?.paidInfo?.isPersonal && context.userInfo?.fileSizeLimit !== 0)
      // return alert("이미 1인 이용권을 결제하셨습니다. 중복 등록이 불가합니다.");
      return alert(t(`If you have already paid for a single plan...`));
    history.push("plan/addserialnum");
  };

  return (
    <>
      {askPopup && (
        <div className="popup-main" style={{ zIndex: 10 }}>
          <div ref={askRef} className="main_popup scroll">
            <p className="text-center font-23 mb-26">
              {/* 요금제 관련 문의 */}
              {t(`Inquiries about plans`)}
            </p>
            {/* <p className="text-center font-20 mt-120 mb-40">dahandinkr@gmail.com</p> */}
            <div className="qr_box margin-auto mb-10">
              <img src={qr} alt="qr-code" className="qr" />
            </div>
            <a href="https://open.kakao.com/me/dahandin" target="blank">
              <p className="text-center font-16 mb-26 cursor">
                {/* 바로가기 */}
                {t(`Shortcuts`)}
              </p>
            </a>
            <div className="text-center">
              <p>
                {/* 또는 */}
                {t(`Or`)}
              </p>

              <p className="color-blue">edujiwon@naver.com</p>
            </div>
          </div>
        </div>
      )}
      <div style={{ backgroundColor: "#fff" }} className="min_height_1vh">
        {/* <MainHeader /> */}

        <div className="container text-center">
          <div className="col-md-12">
            <div className="display-f justify-between mt-20">
              <button className="font-600 back-btn-arrow" onClick={() => history.goBack()}>
                ←
              </button>

              <button
                className="font-600 btnOrange btnPush pay-card-type-select"
                style={{ border: "none", padding: "0px 16px" }}
                onClick={handleGoBulkPay}
              >
                {/* 단체 이용권 등록하기 */}
                {t(`Register a group plan`)}
              </button>
              {/* {!registered && (
                <button
                  className="font-600 btnOrange btnPush pay-card-type-select"
                  style={{ border: "none", padding: "0px 16px" }}
                  onClick={() => history.push("plan/addserialnum")}
                >
                  단체 이용권 등록하기
                </button>
              )} */}
              {/* {registered && (
                <button
                  onClick={handleCancelSerialCode}
                  className="font-600  pay-card-type-select"
                  style={{ border: "none", padding: "0px 16px" }}
                >
                  이용권 해지하기
                </button>
              )} */}
            </div>
            <div className="mt-20 mb-10">
              <p style={{ fontSize: 26, fontWeight: 600 }}>
                {/* 저장 용량 확장하기 */}
                {t(`Expand storage capacity`)}
              </p>

              <p className="mt-10 display-f justify-center align-center">
                <p className="mr-10">
                  {/* 현재 */}
                  {t(`You are now`)}
                </p>
                {(context.userInfo?.fileSizeLimit === 0 || !context.userInfo?.fileSizeLimit) && (
                  <span style={{ fontWeight: 600, fontSize: 20 }} className="font-blue">
                    {/* 비기너 */}
                    {t(`Beginners`)}
                  </span>
                )}
                {context.userInfo?.fileSizeLimit !== 0 && context.userInfo?.fileSizeLimit && (
                  <div>
                    <span style={{ fontWeight: 600, fontSize: 20 }} className="color-red mr-10">
                      {/* 2024년 무제한 */}
                      {/* {t(`Unlimited 2024`)} */}
                      {productName}
                    </span>
                  </div>
                )}
                {/* {(!registered || context.userInfo?.paidInfo?.expired) && (
                  <span style={{ fontWeight: 600, fontSize: 20 }} className="font-blue">
                    비기너
                  </span>
                )}
                {context.userInfo?.paidInfo?.isSerialCode &&
                  registered &&
                  !context.userInfo?.paidInfo?.expired && (
                    <div>
                      <span style={{ fontWeight: 600, fontSize: 20 }} className="color-red mr-10">
                        2024년 무제한
                      </span>
                    </div>
                  )}
                {context.userInfo.fileSizeLimit !== 0 &&
                  context.userInfo?.paidInfo?.isPersonal &&
                  !context.userInfo?.paidInfo?.expired && (
                    <div>
                      <span style={{ fontWeight: 600, fontSize: 20 }} className="color-red mr-10">
                        2024년 무제한
                      </span>
                    </div>
                  )} */}
                {/* 요금제를 사용중이십니다. */}
                {"  "}
                {t(`Using the plan`)}
              </p>
            </div>

            {/* 단체결제시 */}
            {context.userInfo?.paidInfo?.isSerialCode &&
              registered &&
              !context.userInfo?.paidInfo?.expired &&
              context.userInfo?.paidInfo?.dueDate && (
                <div className="mt-8">
                  <span className=" bg-red color-white font-500" style={{ padding: "4px 10px" }}>
                    {/* 적용 기간: ~{" "} */}
                    {t(`Plan duration`)}: ~{" "}
                    {moment(context.userInfo.paidInfo.dueDate).format("YYYY년 MM월 DD일 HH:mm:ss")}
                  </span>
                </div>
              )}

            {context.userInfo?.paidInfo?.isPersonal &&
              context.userInfo?.fileSizeLimit !== 0 &&
              !context.userInfo?.expired && (
                <div className="mt-8">
                  <span className=" bg-red color-white font-500" style={{ padding: "4px 10px" }}>
                    {/* 적용 기간: ~{" "} */}
                    {t(`Plan duration`)}: ~{" "}
                    {moment(context.userInfo.paidInfo.dueDate).format("YYYY년 MM월 DD일 HH:mm:ss")}
                  </span>
                </div>
              )}

            <div className="col-md-6 mt-10">
              <div style={{ width: 72, height: 26 }}></div>
              <button
                style={{ padding: "14px 22px", width: "100%" }}
                onClick={handleGoPrivatePay}
                className="btnOrange btnPush pay-card-type-select font-16"
              >
                {/* 1인 이용 결제 */}
                {t(`Pay for a single person membership`)}
              </button>

              <div className="mt-10">
                <p className="text-left font-12">
                  {/* - 본 계정 하나에 대한 이용권 결제 */}
                  {t(`Pay for a subscription for a single account`)}
                </p>
                <p className="text-left font-12">
                  {/* - 개인 체크/신용 카드나 간편페이 및{" "} */}
                  {t(`Personal debit credit cards...`)}{" "}
                  <span className="color-red">
                    {/* 학교/법인 카드 */}
                    {t(`Credit card or school corporate cards`)}{" "}
                  </span>
                  {/* TODO:: 고유번역 */}
                  {localStorage.getItem("language") === "ko" ? "이용가능" : ""}
                </p>
              </div>
            </div>
            <div className="col-md-6 text-left mt-10">
              <div className="display-f justify-center align-center discount-label">
                {/* 33% 할인 */}
                {t(`33% off`)}
              </div>
              <div className="mt-4">
                <button
                  style={{ padding: "14px 22px", width: "100%" }}
                  onClick={() => history.push("/plan/organization")}
                  className="btnOrange btnPush pay-card-type-select font-16 position-r"
                >
                  {/* 교사 단체 이용권 결제 */}
                  {t(`Pay for teacher group plans`)}
                  <div
                    className="position-a font-500 font-12 color-red"
                    style={{ left: 8, bottom: -11 }}
                  >
                    {/* * 3인 이상 신청 가능 */}
                    {t(`Available for 3 or more people`)}
                  </div>
                </button>

                <div className="mt-10">
                  <p className="text-left font-12">
                    {/* - 33% 저렴한 가격 */}
                    {t(`33% lower price`)}
                    <br />
                    {/* - 개인 체크/신용 카드나 간편페이 및{" "} */}
                    {t(`Personal debit credit cards...`)}{" "}
                    <span className="color-red">
                      {/* 학교/법인 카드 */}
                      {t(`Credit card or school corporate cards`)}{" "}
                    </span>{" "}
                    {/* TODO:: 고유번역 */}
                    {localStorage.getItem("language") === "ko" ? "이용가능" : ""}
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* <div
                className="grid-wrapper position-a"
                style={{ backgroundColor: "rgba(0,0,0,0.3)" }}
              >
                <div style={{ border: "1px solid red", width: "100%" }} className={`plan-box`}>
                  <div>
                    <h2 style={{ padding: "4px 0" }} className={` margin-0`}>
                      d
                    </h2>
                  </div>

                  <div className="mt-10 mb-10 plan-desc white">
                    <p className="white">ddd</p>
                    dddd
                  </div>

                  <div className="plan-btn-wrapper">
                    <button style={{ background: "none" }} className={`plan-btn white `}>
                      무료
                    </button>
                  </div>
                </div>
              </div> */}

          <div className="col-md-12 mt-30">
            <div className="flex align-center mt-20">
              <img
                className="mr-6"
                src={warning}
                style={{ width: 22, height: "auto", marginTop: -6 }}
              />
              <span className="color-red cursor text-hover-underline font-18 font-600">
                <a href="https://blog.naver.com/dahandin/223362598044" target="_blank">
                  {/* 저장용량이 초과되면 어떻게 되나요? */}
                  {t(`What happens if I exceed my storage limit`)}
                </a>
              </span>
            </div>

            <h1 className="mt-60" style={{ fontWeight: 500 }}>
              {/* 다양한 구독 플랜 살펴보기 */}
              {t(`Explore our different subscription plans`)}
            </h1>
            <div className="grid-wrapper" style={{ display: "grid", position: "relative" }}>
              <div className="position-a grid-wrapper2" style={{ top: 0, width: "100%" }}>
                <div style={{ height: 340 }}></div>
                <div style={{ height: 340 }}></div>
                <div className="benefit-overlay">
                  <p className="font-600 font-18" style={{ color: "red" }}>
                    {/* 특별 혜택 */}
                    {t(`Special offers`)}
                  </p>
                  <p className="font-600 font-16 mt-20" style={{ color: "#fff" }}>
                    {/* 2025월 2월 28일까지는 <br />
                    저장용량에 관계 없이, */}
                    {t(`Until February 28 2025...`)}
                    <br />
                    <span className="font-basic font-18">
                      {/* 베이직 가격 */}
                      {t(`Basic plan users will...`)}{" "}
                    </span>
                    {/* 에 이용하실 수 있습니다! */}
                    {t(`Standard and Standard Plus...`)}
                  </p>

                  {/* <div
                    className="mt-10"
                    style={{
                      backgroundColor: "rgb(255,255,255,0.8)",
                      margin: "0 15px",
                      borderRadius: 4,
                      padding: 6,
                    }}
                  >
                    <p>
                      단, 2025년 3월 1일 부터는 저장 용량에 따라 추가 요금이 발생하며,
                      <br />
                      30일간 한도를 넘은 상태로 유지 시 모든 학급, 학생, 자료가
                      <br />
                      삭제될 수 있습니다.
                    </p>
                  </div> */}
                </div>

                <div className="benefit-overlay-res" style={{ height: 340 }}>
                  <p className="font-600 font-18" style={{ color: "red" }}>
                    {/* 특별 혜택 */}
                    {t(`Special Offer`)}
                  </p>
                  <p className="font-600 font-16 mt-20" style={{ color: "#fff" }}>
                    {/* 2025월 2월 28일까지는 <br />
                      저장용량에 관계 없이, */}
                    {t(`Until February 28 2025...`)}
                    <br />
                    {/* 모두 베이직 가격에 이용하실 수 있습니다! */}
                    {t(`Basic plan users will...`)} {t(`Standard and Standard Plus...`)}
                  </p>
                </div>
                {/* <div style={{ height: 340, border: "2px solid black" }}>555</div> */}
              </div>
              {PAY_PLAN_ARR.map((item: any, i: number) => (
                <React.Fragment key={i}>
                  {i === 0 && (
                    <div>
                      <div style={{ backgroundColor: "#6babe0" }} className={`plan-box`}>
                        <div>
                          <h2
                            style={{ padding: "4px 0" }}
                            className={`${item.plan === "비기너" && "white"} margin-0`}
                          >
                            {item.plan}
                          </h2>
                        </div>

                        <div className="mt-10 mb-10 plan-desc white">
                          <p className="white">{item.desc}</p>
                          {i === 0 && (
                            <p className="white">
                              {/* AI 쫑알이 <br />
                              사용불가 */}
                              {t(`AI Comment is not available`)}
                            </p>
                          )}
                          SD
                        </div>

                        <div className="plan-btn-wrapper">
                          <button style={{ background: "none" }} className={`plan-btn white `}>
                            {/* 무료 */}
                            {t(`For free`)}
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                  {i === 4 && (
                    <div className={`plan-box ${item.color}`}>
                      <div style={{ padding: "4px 0" }}>
                        <h2 className={`${item.plan === "비기너" && "white"} margin-0`}>
                          {item.plan}
                        </h2>
                        <span className="font-12">
                          {/* (엔터프라이즈) */}({t(`Enterprise`)})
                        </span>
                      </div>

                      <div className="mt-10  white" style={{ padding: "20px 0" }}>
                        <p className="white">{item.desc}</p>
                      </div>

                      <div className="plan-btn-wrapper">
                        <a
                          href="https://open.kakao.com/o/sKi64z4f"
                          target="_blank"
                          style={{ zIndex: 1 }}
                          className="cursor"
                        >
                          <img
                            src={
                              "https://dahandin.com/static/media/qr_pay.80559297c858970b9e6a.jpeg"
                            }
                            style={{ width: "80%", height: "auto" }}
                          />
                        </a>
                        {/* <button
                          style={{ zIndex: 1 }}
                          onClick={() => setAskPopup(true)}
                          className={`plan-btn cursor ${item.color} font-600`}
                        >
                          문의하기
                        </button> */}
                      </div>
                    </div>
                  )}

                  {i !== 0 && i !== 4 && (
                    <div className="">
                      <div className={`${item.color} plan-box`}>
                        <div className={`${item.color}`}>
                          <h2
                            style={{ padding: "4px 0" }}
                            className={`${item.plan === "비기너" && "white"} margin-0`}
                          >
                            {item.plan}
                          </h2>
                        </div>

                        <div className="mt-10 mb-10 plan-desc">
                          <p>{item.desc}</p>
                        </div>

                        <div className="plan-btn-wrapper">
                          {item.durations.map((term: any, index: number) => (
                            <button
                              disabled={
                                paymentInfo.subType === "학교(법인) 카드로 결제" && term.disable
                              }
                              key={index}
                              className={`plan-btn ${item.color}`}
                            >
                              {term.label} {i !== 0 ? `${currency(term.price)}${t(`Won`)}` : ``}{" "}
                            </button>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PayPlanMain;

import axios from "axios";
import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { IMainContext } from "../interface/Interface";
import { MainContext } from "../common/Context";
import qr from "../images/payqr2.jpeg";
import pay_process from "../images/pay_process.png";
import ModalComponent from "../component/ModalComponent";
import { currency, timeformatKR } from "../common/Method";
import { postEmail, postPayment } from "../common/Action";
import TermsModal from "../component/TermsModal";
import TermsModalRefund from "../component/TermsModalRefund";
import { t } from "i18next";

const PayPlanOrganization: React.FC = () => {
  const history = useHistory();
  const context = useContext<IMainContext>(MainContext);
  const [emailModal, setEmailModal] = useState<boolean>(false);
  const [count, setCount] = useState<string>("3");
  const [form, setForm] = useState<any>({});
  const [agreementModal, setAgreementModal] = useState<boolean>(false);
  const [agreementCheck, setAgreementCheck] = useState<boolean>(false);
  const [agreementModal2, setAgreementModal2] = useState<boolean>(false);
  const [agreementCheck2, setAgreementCheck2] = useState<boolean>(false);

  const handleStateChange = (type: string, value: string) => {
    setForm((prev: any) => {
      return {
        ...prev,
        [type]: value,
      };
    });
  };

  const handleChangeCount = (value: string) => {
    // 5개 이상이어야 함
    // 5를 지웠을 때는 ""이어야 함
    // 이외에 스트링이 들어가면 안됨
    if (value === "") {
      setCount("");
    }

    if (value !== "" && !isNaN(Number(value))) {
      setCount(value);
    }
  };

  const isValidForm = () => {
    let isValid = false;
    if (parseInt(count) < 3) {
      // return alert("최소 3개부터 신청 가능합니다.");
      return alert(t(`You can apply with a minimum of three`));
    }
    if (!form.team || form.team === "") {
      // return alert("학교명을 입력해 주세요.");
      return alert(t(`Please enter the name of your school`));
    }
    if (!form.lead || form.lead === "") {
      // return alert("업무 담당자를 입력해 주세요.");
      return alert(t(`Please enter a contact person`));
    }
    if (!form.leaderPhone || form.leaderPhone === "") {
      // return alert("업무 담당자 연락처를 입력해 주세요.");
      return alert(t(`Please provide a contact person`));
    }
    if (!form.payerName || form.payeerName === "") {
      // return alert("결제자 이름을 입력해 주세요.");
      return alert(t(`Please enter a payer name`));
    }
    if (!form.payerEmail || form.payerEmail === "") {
      // return alert("결제자 이메일을 정확히 입력해 주세요.");
      return alert(t(`Please enter the correct payer email`));
    }
    if (!agreementCheck) {
      // return alert("서비스 이용약관에 동의해 주세요.");
      return alert(t(`Please agree to the Terms of Service`));
    }
    if (!agreementCheck2) {
      // return alert("환불 정책에 동의해 주세요.");
      return alert(t(`Agree to the refund policy`));
    }

    isValid = true;
    return isValid;
  };

  const handlePostEmail = async () => {
    const isValid = isValidForm();
    if (isValid) {
      const insertPay: any = await postPayment({
        ...form,
        count: parseInt(count),
        totalAmount: 33000 * parseInt(count),
        state: "pending",
        type: "학교 단체 이용권",
        dueDate: timeformatKR("2025-02-28") + 86400000 - 1,
        user_id: context.userInfo.kakaoId ? context.userInfo.kakaoId : context.userInfo.id,
        linkCode: context.userInfo.linkCode,
      });

      if (insertPay.result && insertPay.insertedId && insertPay.uid) {
        const mailResult: any = await postEmail({
          email: form.payerEmail,
          subject: `[다했니] 결제창 안내 - (${form.lead}) 선생님`,
          html: `<div style="padding: 10px 10px 20px 10px; border: 1px solid #979797; width:600px"><h3 style="color: black;">귀 학교의 (${form.lead}) 선생님께서 발송하신 결제창입니다.</h3><br/><p style="color: black;">아래 링크를 클릭하여 학교 카드로 결제해 주세요.</p><a href="https://dahandin.com/buy/${insertPay.insertedId}/${insertPay.uid}">결제하러 가기💸</a></div>`,
        });

        if (mailResult === "Email send success") {
          setEmailModal(true);
        }
      }
    }
  };

  const handleAgree = (valid: boolean) => {
    if (valid) {
      setAgreementCheck(true);
    }
    if (!valid) {
      setAgreementCheck(false);
    }

    setAgreementModal(false);
  };
  const handleAgree2 = (valid: boolean) => {
    if (valid) {
      setAgreementCheck2(true);
    }
    if (!valid) {
      setAgreementCheck2(false);
    }

    setAgreementModal2(false);
  };

  return (
    <div style={{ backgroundColor: "#fff" }}>
      {emailModal && (
        <ModalComponent
          modalStyles={{
            width: "40%",
            minHeight: "10vh",
            maxHeight: "80vh",
            overflowY: "scroll",
            borderRadius: 16,
            backgroundColor: "#efefef",
          }}
          onClose={() => setEmailModal(false)}
          title={"모달타이틀"}
          open={emailModal}
        >
          <div style={{ padding: 25, textAlign: "center" }}>
            {/* <h1>메일이 전송되었습니다.</h1> */}
            <h1>{t(`An email has been sent`)}</h1>

            <p className="font-20">
              {/* 학교 카드 결제 권한을 가지신 분께 메일이 발송되었습니다! */}
              {t(`An email has been sent to the person authorized to pay with your school card`)}
            </p>
            {/* <p className="font-20 mt-10">
              담당자에게 사내 메신저로 '메일함 확인하여 결제부탁드립니다'라고 <br />
              안내해 주세요.
            </p> */}
            <p className="font-20 mt-10">{t(`Please inform them via internal messenger...`)}</p>
            <p className="font-20 mt-20">
              {/* 결제 완료 즉시 적용됩니다. */}
              {t(`The payment will be processed as soon as it is made`)}
            </p>

            <p className="font-18 mt-40">
              {/* 메일이 확인되지 않았을 경우, */}
              {t(`If you haven't checked your mail`)}
              <br />
              {/* 스팸메일함을 확인해보시거나 아래 문의하기로 연락 주세요. */}
              {t(`please look in your spam folder or contact us using the form below`)}
            </p>

            <img className="mt-18" src={qr} alt="qrcode" style={{ width: 100, height: "auto" }} />
          </div>
        </ModalComponent>
      )}
      <TermsModal
        handleAgree={handleAgree}
        open={agreementModal}
        onClose={() => setAgreementModal(false)}
      />
      <TermsModalRefund
        handleAgree={handleAgree2}
        open={agreementModal2}
        onClose={() => setAgreementModal2(false)}
      />
      {/* <MainHeader /> */}

      <div className="container">
        <div className="col-md-12">
          <button
            className="font-600 back-btn-arrow mt-20"
            style={{ padding: 0 }}
            onClick={() => history.goBack()}
          >
            ←
          </button>
        </div>
      </div>

      <div className="container display-f" style={{ alignItems: "flex-start" }}>
        <div
          className="col-md-3 sticky-box mt-30 mb-30 display-f"
          style={{
            minHeight: "calc(100vh - 140px)",
            flexDirection: "column",
            justifyContent: "space-around",
          }}
        >
          {/* <div className="pay-box-info">
            <p className="color-red font-600 text-center font-16">이벤트</p>

            <div className="mt-10">
              <p className="font-12">
                -<span className="color-red">3월 15일까지</span> 결제하는 학교에 한하여
              </p>
              <p className="font-12">
                -<span className="color-red">'다했니 사용방법 40분 줌 연수'</span>를
              </p>
              <p className="font-12">
                -<span className="color-red">무료</span>로 제공해드립니다.
              </p>
            </div>

            <div className="mt-20">
              <button className="pay-btn-2 bg-beginner">무료 연수 신청하기</button>
            </div>
          </div> */}

          <div className="pay-box-info">
            <p className="font-600 text-center font-16">
              {/* 구매 후 절차 안내 */}
              {t(`Post-purchase instructions`)}
            </p>

            <div className="mt-10">
              <img
                src={pay_process}
                style={{ width: "100%", height: "auto" }}
                alt="pay-process-img"
              />
            </div>

            <div className="mt-20">
              <button
                onClick={() => {
                  window.open("https://blog.naver.com/dahandin/223367031681", "_blank");
                }}
                style={{ width: "100%" }}
                className="pay-btn-2 bg-beginner"
              >
                {/* 클릭하여 자세히 보기 */}
                {t(`Click to view more`)}
              </button>
            </div>
          </div>
        </div>

        <div className="col-md-6 content" style={{ padding: "20px 15px" }}>
          <div className="discount-label display-f justify-center align-center mt-10">
            {/* 33% 할인 */}
            {t(`33% Off`)}
          </div>

          <h2 className="text-center">
            {/* 교사 단체 이용권 결제 */}
            {t(`Pay for teacher group plans`)}
          </h2>
          <p className="color-red text-center">
            {/* * 3인 이상 모여서 일괄결제 */}
            {t(`Make a bulk payment with a group of three or more people`)}
          </p>
          <p className="text-center mt-10">
            {/* - 개인 체크/신용 카드나 간편페이 및  */}
            {t(`Pay with your personal debit...`)}
            <span className="color-red">
              {/* 학교/법인 카드 */}
              {t(`Credit card or school corporate cards`)}
            </span>{" "}
            {/* 이용 가능 */}
            {t(`Available`)}
          </p>
          <p className="color-red mt-20 text-center font-12">
            {/* 분할결제 불가 */}
            {t(`No installments`)}
            <br />
            {/* 개인별 환불 및 중도 추가 불가 */}
            {t(`No individual refunds or add ons`)}
          </p>

          <div className="mt-20">
            <h3 className="mt-10 mb-10">
              {/* 상품명 */}
              {t(`Product Name`)}
            </h3>
            <div className="border-yellow2" style={{ padding: 10 }}>
              <p className="font-600">
                {/* 2024학년도 다했니 단체 이용권 */}
                {t(`Class of 2024 다했니 Group membership`)}
              </p>
              <p className="font-gray mt-10">
                {/* 이용 기간: (결제일) ~ 2025.2.28. */}
                {t(`Plan duration...`)}
              </p>
            </div>
            <span className="font-12 color-gray">
              {/* 2024.8.1.부터는 2학기 용 이용권으로 변경됩니다. */}
              {t(`Available for use for two semesters...`)}
              {/* 2024.8.1부터는 한 학기용 이용권 판매가 시작됩니다. (결제일.~2025.2.28.) 단가 33,000원 */}
            </span>
            <h3 className="mt-20 mb-10">
              {/* 이용권 수량 */}
              {t(`Plan Quantity`)}
              <span className="font-400 font-12 color-red">
                {/* (최소 3개부터 신청 가능합니다.) */}
                {t(`Minimum quantity is 3`)}
              </span>
            </h3>
            <div className="display-f align-center">
              <input
                value={count}
                onChange={(e: any) => handleChangeCount(e.target.value)}
                className="popup_input_default mr-10"
              />
              <p>{localStorage.getItem("language") === "ko" ? "개" : ""}</p>
            </div>
            {(Number(count) < 3 || count === "") && (
              <span className="color-red font-12">
                {/* 최소 수량(3개) 이상 입력해주세요. */}
                {t("Please enter at least the minimum quantity 3")}
              </span>
            )}
          </div>

          <h3 className="mt-20 mb-10">
            {/* 자동 견적 */}
            {t(`Automated quotes`)}
          </h3>
          <div className="mt-10" style={{ border: "1px solid #ececec", padding: 20, fontSize: 18 }}>
            <div className="display-f justify-between">
              {/* 상품명 */}
              {t(`Product Name`)}
              <p className="text-right">
                {/* 2024학년도 다했니 단체 이용권 */}
                {t(`Class of 2024 다했니 Group membership`)}
              </p>
            </div>
            <div className="display-f justify-between mt-10">
              {/* 이용 기간  */}
              {t`Term`}
              <p className="text-right">~ 2025.2.28.</p>
            </div>
            <div className="display-f justify-between mt-10">
              {/* 결제 금액(단가){" "} */}
              {t(`Payment amount unit price`)}
              <p className="text-right">
                {currency(33000)}
                {t(`Won`)}
                <span style={{ fontSize: 12 }}>
                  {/* (부가세포함) */} {t(`inclusive`)}
                </span>
              </p>
            </div>
            <div className="display-f justify-between mt-10">
              {/* 상품 수량  */}
              {t(`Quantity of products`)}
              <p className="text-right">
                {count === "" ? 0 : parseInt(count)}
                {localStorage.getItem("language") === "ko" ? "개" : ""}
              </p>
            </div>
            <div className="display-f justify-between mt-10">
              {/* 총 결제 금액{" "} */}
              {t(`Total payment amount`)}
              <p className="text-right color-red">
                {count === "" ? 0 : currency(parseInt(count) * 33000)}
                {t(`Won`)}
                <span style={{ fontSize: 12 }}>
                  {/* (부가세포함) */} {t(`inclusive`)}
                </span>
              </p>
            </div>

            <div className="display-f justify-between align-center mt-10">
              <div>
                {/* 학교/단체명 */}
                {t(`School Organization Name`)}
                <p className="font-12">
                  {/* (결제자 식별용) */}
                  {t(`for payer identification...`)}
                </p>
              </div>{" "}
              <input
                value={form.team}
                onChange={(e: any) => handleStateChange("team", e.target.value)}
                className="pay-input"
              />
            </div>
            <div className="display-f justify-between align-center mt-10">
              <div>
                {/* 업무 담당자 이름 */}
                {t(`Business Contact Name`)}
                <p className="font-12">
                  {/* (결제자 식별용) */}
                  {t(`for payer identification...`)}
                </p>
              </div>{" "}
              <input
                value={form.lead}
                onChange={(e: any) => handleStateChange("lead", e.target.value)}
                className="pay-input"
              />
            </div>
            <div className="display-f justify-between align-center mt-10">
              <div>
                {/* 업무 담당자 연락처 */}
                {t(`Business Contact`)}
                <p className="font-12">
                  {/* (결제자 식별용) */}
                  {t(`for payer identification...`)}
                </p>
              </div>{" "}
              <input
                value={form.leaderPhone}
                onChange={(e: any) => handleStateChange("leaderPhone", e.target.value)}
                className="pay-input"
              />
            </div>

            <div className="display-f justify-between align-center mt-10 color-blue">
              <div>
                {/* 결제자 이름 */}
                {t(`Payer Name`)}
                <p className="font-12">
                  {/* (행정실/실무사 또는 결제하는 본인) */}
                  {t(`Account manager or person...`)}
                </p>
              </div>{" "}
              <input
                value={form.payerName}
                onChange={(e: any) => handleStateChange("payerName", e.target.value)}
                className="pay-input"
              />
            </div>
            <div className="display-f justify-between align-center mt-10 color-blue">
              <div>
                {/* 결제자 이메일 주소 */}
                {t(`Payer email address`)}
                <p className="font-12 ">
                  {/* (행정실/실무사 또는 결제하는 본인) */}
                  {t(`Account manager or person...`)}
                </p>
              </div>
              <input
                value={form.payerEmail}
                // placeholder="(전자 결제창 및 시리얼 코드를 받을 이메일 주소)"
                placeholder={t(`Email address to receive the...`)}
                onChange={(e: any) => handleStateChange("payerEmail", e.target.value)}
                className="pay-input"
              />
            </div>
          </div>

          <div className="display-f mt-20">
            <div
              onClick={() => setAgreementModal(true)}
              className="check-box mr-10 display-f justify-center align-center"
            >
              {agreementCheck && <p className="font-12">✔️</p>}
            </div>
            <p>
              {/* 서비스 이용약관 동의 */}
              {t(`Agree to terms of use`)}
            </p>
          </div>
          <div className="display-f mt-4">
            <div
              onClick={() => setAgreementModal2(true)}
              className="check-box mr-10 display-f justify-center align-center"
            >
              {agreementCheck2 && <p className="font-12">✔️</p>}
            </div>
            <p>
              {/* 환불 정책 동의 */}
              {t(`Accept the refund policy`)}
            </p>
          </div>

          <button onClick={handlePostEmail} className="pay-btn mt-20 mb-20">
            <p>
              {/* 결제 요청 메일 전송하기 */}
              {t(`Sending a payment request email`)}
              <br />
              <span className="font-12 font-400">
                {/* 행정실/실무사님의 메일로 결제창이 전송됩니다. */}
                {t(`The payment link will be sent to...`)}
              </span>
            </p>
          </button>
        </div>
        <div
          className="col-md-3 sticky-box mt-30 mb-30 display-f"
          style={{
            minHeight: "calc(100vh - 140px)",
            flexDirection: "column",
            justifyContent: "space-around",
          }}
        >
          <div className="pay-box-info-blue">
            <p className="font-600 text-center font-16">
              <span className="color-red">
                {/* 예산맞춤/대량구매/견적서 */}
                {t(`Budgeting Bulk Purchase Quote`)}
              </span>
              <br />
              {/* 필요 시 문의 */}
              {t(`Contact us if you need`)}
            </p>
            <p className="font-12 text-center">
              {/* * 전체 교원 이용 시  */}
              {t(`For all faculty`)}
              <span className="color-red font-600">
                {/* 추가 할인 */}
                {t(`Additional discounts`)}
              </span>{" "}
              {/* 가능 */}
              {t(`Available`)}
            </p>

            <div className="mt-20 text-center">
              <a href="https://open.kakao.com/o/sKi64z4f" target="_blank" className="cursor">
                <img
                  src={"https://dahandin.com/static/media/qr_pay.80559297c858970b9e6a.jpeg"}
                  style={{ width: 120, height: "auto" }}
                />
              </a>
            </div>

            {/* <div className="mt-20 text-center font-14">
              <p>TEL. 010-4663-4063</p>
            </div> */}

            <div className="mt-20 text-center font-14">
              <p>edujiwon@naver.com </p>
            </div>
          </div>

          <div className="pay-box-info-yellow">
            <p className="font-600 text-center font-16">
              {/* 학교 예산 항목 추천 */}
              {t(`Recommended School Budget Line Items`)}
            </p>

            <div className="mt-10 text-center">
              <p className="font-12">
                {/* 운영비-일반운영비- */}
                {t(`Operating Expenses General Operating Expenses`)}
                <br />
                <span className="font-600 font-14">
                  {/* 일반 수용비 */}
                  {t(`General Accommodations`)}
                </span>
              </p>

              <p className="font-12 mt-10">
                {/* 자산 취득비-기타자산 취득비- */}
                {t(`Asset Acquisition Other Asset Acquisition`)}
                <br />
                <span className="font-600 font-14">
                  {/* 소프트웨어 구입비 */}
                  {t(`Software Purchases`)}
                </span>
              </p>

              {/* <p className="font-12 mt-10">
                학급운영비, 꿈실, 전학공 예산 등<br />
                다양한 항목에서 구입하실 수 있습니다.
                <br />* 학교에 따라 상이할 수 있음
              </p> */}
            </div>
          </div>
        </div>
      </div>
    </div>
    // </div>
  );
};

export default PayPlanOrganization;

import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  getIsRegisteredSerialCode,
  postCancelSerialCode,
  postRegisterSerialCode,
} from "../common/Action";
import { IMainContext } from "../interface/Interface";
import { MainContext } from "../common/Context";
import { t } from "i18next";

const AddSerialNum = () => {
  const history = useHistory();
  const context = useContext<IMainContext>(MainContext);
  const [code, setCode] = useState<string>("");
  const [registered, setRegistered] = useState<boolean>(true);
  const [registeredCode, setRegisteredCode] = useState<string>("");
  const [productName, setProductName] = useState<string>("");

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    const isRegistered: any = await getIsRegisteredSerialCode();

    if (!isRegistered.registeredUser) {
      setRegistered(false);
      // setCode("");
    } else {
      setRegistered(true);
      setProductName(isRegistered.productName);
      setCode(isRegistered.serialCode);
      // setRegisteredCode(isRegistered.serialCode);
    }
  };

  const handleRegisterSerialCode = async () => {
    if (registered) return;
    const serialCode = code.trim();

    const addResult: any = await postRegisterSerialCode({
      serialCode: serialCode,
    });

    if (serialCode === "") return;
    else if (!addResult.result && addResult.message === "serialcode expiration") {
      // return alert("유효기간이 지난 시리얼코드입니다.");
      // return alert(t(`The serial code has expired`));
      return alert("시리얼코드의 유효기간을 확인해주세요.");
    } else if (!addResult.result && addResult.message === "already registered user") {
      // return alert("이미 등록된 유저입니다.");
      return alert(t(`You're already a registered user`));
    } else if (!addResult.result && addResult.message === "every sericalcode registered") {
      // return alert("해당 시리얼넘버의 허용 개수가 초과되었습니다.");
      return alert(t(`The allowed number for this serial number has been exceeded`));
    } else if (!addResult.result && addResult.message === "serialcode not found") {
      // return alert("존재하지 않는 시리얼넘버입니다.");
      return alert(t(`A serial number that doesn't exist`));
    } else {
      alert("시리얼 넘버가 등록되었습니다.");
      // alert("Your serial number has been registered.");

      init();
      context.handleGetUserInfo();

      // history.push("/payplan");
    }
  };

  const handleCancelSerialCode = async () => {
    const confirm = window.confirm(
      //  "현재 단체이용권 사용중이십니다. 이용권을 해지하시겠습니까? 이용에 제약이 있을 수 있습니다.",
      t(`You are currently subscribed to an organization plan...`),
    );

    if (confirm) {
      const update: any = await postCancelSerialCode({
        serialCode: code,
      });

      if (update.result && update.message === "complete filesize update decrease") {
        // alert("해지되었습니다.");
        alert(t(`The revocation is complete`));
        // history.push("/payplan");
        init();
        context.handleGetUserInfo();
      }
    } else {
      return;
    }
  };

  return (
    <div className="page-min-height">
      <div className="container mt-20">
        <div className="col-md-12">
          <button className="font-600 back-btn-arrow" onClick={() => history.goBack()}>
            ←
          </button>
        </div>
      </div>
      <div className="container text-center">
        <div className="col-md-3"></div>
        <div className="col-md-6">
          <div className="mt-20 mb-20">
            <p style={{ fontSize: 26, fontWeight: 600 }}>
              {/* 단체 이용권 등록 */}
              {t(`Register a group plan`)}
            </p>

            <p className="mt-10">
              {/* 현재 */}
              {t(`You're currently`)}
              {!registered ||
                (context.userInfo?.paidInfo?.expired && (
                  <span style={{ fontWeight: 600, fontSize: 20 }} className="font-blue">
                    {/* 비기너 */}
                    {t(`Beginner's plan`)}
                  </span>
                ))}
              {registered && !context.userInfo?.paidInfo?.expired && (
                <span style={{ fontWeight: 600, fontSize: 20 }} className="color-red">
                  {/* 2024년 무제한 요금제 */}
                  {/* {t(`Unlimited plan in 2024`)} */} {productName}
                </span>
              )}
              {/*  TODO:: 고유번역 */}
              {localStorage.getItem("language") === "ko" ? "  요금제를 사용중이십니다." : ""}
            </p>

            <h3>
              {/* 시리얼 넘버를 아래에 입력하세요! */}
              {t(`Enter your serial number below`)}
            </h3>
          </div>
          <div className="mt-60 display-f justify-between" style={{ width: "100%", height: "60%" }}>
            <input
              readOnly={registered ? true : false}
              value={code}
              onChange={(e: any) => setCode(e.target.value)}
              style={{
                width: "80%",
                padding: "10px 8px",
                fontSize: 16,
                border: "1px solid #ececec",
                backgroundColor: registered ? "#ececec" : "",
              }}
              // placeholder="시리얼 넘버 입력"
              placeholder={t(`Enter the serial number`)}
            />

            {!registered && (
              <button
                onClick={handleRegisterSerialCode}
                className={`${registered ? "gray" : "bg-yellow"}  font-16 font-600`}
                style={{ width: "20%", border: "none", color: registered ? "#979797" : "" }}
              >
                {/* 등록 */}
                {t(`Register`)}
              </button>
            )}
            {registered && (
              <button
                onClick={handleCancelSerialCode}
                className={`${registered ? "gray" : "bg-yellow"}  font-16 font-600`}
                style={{ width: "20%", border: "none", color: registered ? "#979797" : "" }}
              >
                {/* 해지 */}
                {t(`Cancel the membership`)}
              </button>
            )}
          </div>
        </div>
        <div className="col-md-3"></div>
      </div>
    </div>
  );
};

export default AddSerialNum;

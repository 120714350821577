// import axios from "axios";
// import React, { useState, useEffect, useContext } from "react";
// import { useHistory } from "react-router-dom";
// import { IMainContext } from "../interface/Interface";
// import { MainContext } from "../common/Context";
// import MainHeader from "./MainHeader";
// import { currency, timeformatKR } from "../common/Method";
// import ModalComponent from "../component/ModalComponent";
// import qr from "../images/qr.png";
// import TermsModal from "../component/TermsModal";
// import TermsModalRefund from "../component/TermsModalRefund";
// import {
//   getPaymentItem,
//   getPortonePayment,
//   postEmail,
//   postPaidAfterPrivatePurchase,
//   postPayment,
//   putPayment,
// } from "../common/Action";
// import { t } from "i18next";

// const PAY_PLAN_ARR = [
//   {
//     plan: t(`Beginners`), // plan: "비기너",
//     color: "bg-beginner",
//     desc: "~100MB",
//     durations: [
//       {
//         duration: 0,
//         label: t(`For free`), // label: "무료",
//         price: 0,
//         disable: true,
//       },
//     ],
//   },
//   {
//     plan: t(`Basic`), // plan: "베이직",
//     color: "bg-basic",
//     desc: t(`Unlimited`), // desc: `무제한`,
//     durations: [
//       // {
//       //   duration: 1,
//       //   label: "월",
//       //   price: 9900,
//       //   disable: true,
//       // },
//       {
//         duration: 1,
//         label: "",
//         price: 99000,
//       },
//       // {
//       //   duration: 12,
//       //   label: "1년",
//       //   price: 99000,
//       // },
//     ],
//   },
//   {
//     plan: t(`Standard`), // plan: "스탠다드",
//     color: "bg-standard",
//     desc: "~50GB",
//     durations: [
//       {
//         duration: 1,
//         label: t(`Month`), // label: "월",
//         price: 14300,
//         disable: true,
//       },
//       {
//         duration: 6,
//         label: t(`6 months`), // label: "6개월",
//         price: 71500,
//       },
//       {
//         duration: 12,
//         label: t(`1 year`), // label: "1년",
//         price: 143000,
//       },
//     ],
//   },
//   {
//     plan: t(`Standard Plus`), // plan: "스탠다드플러스",
//     color: "bg-standardplus",
//     desc: "~200GB",
//     durations: [
//       {
//         duration: 1,
//         label: t(`Month`), // label: "월",
//         price: 18700,
//         disable: true,
//       },
//       {
//         duration: 6,
//         label: t(`6 months`), // label: "6개월",
//         price: 93500,
//       },
//       {
//         duration: 12,
//         label: t(`1 year`), // label: "1년",
//         price: 187000,
//       },
//     ],
//   },
// ];

// const PayPlan: React.FC = () => {
//   const history = useHistory();
//   const context = useContext<IMainContext>(MainContext);
//   const [paymentInfo, setPaymentInfo] = useState<any>({
//     mainType: "개별 결제", // t(`individual payment`)
//     subType: "학교(법인) 카드로 결제", //  t(`Payment using a school corporate card`),
//     plan: "",
//     duration: -1,
//     price: 0,
//   });
//   const [emailModal, setEmailModal] = useState<boolean>(false);
//   const [privateForm, setPrivateForm] = useState<any>({});
//   const [agreementModal, setAgreementModal] = useState<boolean>(false);
//   const [agreementCheck, setAgreementCheck] = useState<boolean>(false);
//   const [agreementModal2, setAgreementModal2] = useState<boolean>(false);
//   const [agreementCheck2, setAgreementCheck2] = useState<boolean>(false);
//   const [payLoading, setPayLoading] = useState<boolean>(false);

//   useEffect(() => {
//     // console.log(Date.now());
//     init();
//   }, []);

//   const init = async (): Promise<void> => {
//     setPaymentInfo({
//       duration: 1,
//       label: "",
//       mainType: t(`individual payment`), // "개별 결제",
//       plan: t(`Basic`), // "베이직",
//       price: 99000,
//       subType: t(`Payment using a school corporate card`), // "학교(법인) 카드로 결제",
//     });
//   };

//   const handleSelectPlan = (item: any, term: any) => {
//     setPaymentInfo((prev: any) => {
//       return {
//         ...prev,
//         plan: item.plan,
//         duration: term.duration,
//         price: term.price,
//         label: term.label,
//       };
//     });
//   };

//   const handleChangeValue = (type: string, value: string) => {
//     setPrivateForm((prev: any) => {
//       return {
//         ...prev,
//         [type]: value,
//       };
//     });
//   };

//   const handleAgree = (valid: boolean) => {
//     if (valid) {
//       setAgreementCheck(true);
//     }
//     if (!valid) {
//       setAgreementCheck(false);
//     }

//     setAgreementModal(false);
//   };
//   const handleAgree2 = (valid: boolean) => {
//     if (valid) {
//       setAgreementCheck2(true);
//     }
//     if (!valid) {
//       setAgreementCheck2(false);
//     }

//     setAgreementModal2(false);
//   };

//   const isValidForm = () => {
//     let isValid = false;

//     if (!privateForm.lead || privateForm.lead === "") {
//       // return alert("업무 담당자를 입력해 주세요.");
//       return alert(t(`Please enter the name of the person in charge of the task`));
//     }
//     if (!privateForm.leaderPhone || privateForm.leaderPhone === "") {
//       // return alert("업무 담당자 연락처를 입력해 주세요.");
//       return alert(t(`Please enter the contact information for the person in charge`));
//     }
//     if (!privateForm.payerName || privateForm.payeerName === "") {
//       // return alert("결제자 이름을 입력해 주세요.");
//       return alert(t(`Please enter the payer’s name`));
//     }
//     if (!privateForm.payerEmail || privateForm.payerEmail === "") {
//       // return alert("결제자 이메일을 정확히 입력해 주세요.");
//       return alert(t(`Please ensure you enter the payer’s email correctly`));
//     }
//     if (!agreementCheck) {
//       // return alert("서비스 이용약관에 동의해 주세요.");
//       return alert(t(`Please accept the Terms and Conditions of Service`));
//     }
//     if (!agreementCheck2) {
//       // return alert("환불 정책에 동의해 주세요.");
//       return alert(t(`Please agree to our refund policy`));
//     }

//     isValid = true;
//     return isValid;
//   };

//   const handlePostEmail = async () => {
//     const isValid = isValidForm();
//     if (isValid) {
//       const insertPay: any = await postPayment({
//         ...privateForm,
//         count: 1,
//         totalAmount: 99000 * 1,
//         state: "pending",
//         type: "1인 이용권",
//         dueDate: timeformatKR("2025-02-28") + 86400000 - 1,
//         user_id: context.userInfo.kakaoId ? context.userInfo.kakaoId : context.userInfo.id,
//         linkCode: context.userInfo.linkCode,
//       });

//       if (insertPay.result && insertPay.insertedId && insertPay.uid) {
//         const mailResult: any = await postEmail({
//           email: privateForm.payerEmail,
//           subject: `[다했니] 결제창 안내 - (${privateForm.lead}) 선생님`,
//           // html: `<div style="padding: 10px 10px 20px 10px; border: 1px solid #979797; width:600px">
//           // <h3 style="color: black;">귀 학교의 (${privateForm.lead}) 선생님께서 발송하신 결제창입니다.</h3><br/>
//           // <p style="color: black;">아래 링크를 클릭하여 학교 카드로 결제해 주세요.</p>
//           // <a href="https://dahandin.com/buy/${insertPay.insertedId}/${insertPay.uid}">결제하러 가기💸</a></div>`,

//           // TODO:: 고유번역
//           html: `<div style="padding: 10px 10px 20px 10px; border: 1px solid #979797; width:600px"><h3 style="color: black;">
//           ${
//             localStorage.getItem("language") === "ko"
//               ? `귀 학교의 (${privateForm.lead}) 선생님께서 발송하신 결제창입니다.`
//               : `This payment window was sent to you by your (${privateForm.lead}) teacher at your school`
//           }
//           </h3><br/><p style="color: black;">
//           ${t(`To pay with your school card, click the link below`)}</p>
//           <a href="https://dahandin.com/buy/${insertPay.insertedId}/${insertPay.uid}">
//           ${t(`Proceed to checkout`)}
//           </a>
//           </div>`,
//         });

//         if (mailResult === "Email send success") {
//           setEmailModal(true);
//         }
//       }
//     }
//   };

//   const handlePay = async () => {
//     if (payLoading) return;
//     setPayLoading(true);
//     if (!privateForm.lead || privateForm.lead === "") {
//       // return alert("성함을 입력해 주세요.");
//       return alert(t(`Enter your name`));
//     }
//     if (!privateForm.leaderPhone || privateForm.leaderPhone === "") {
//       // return alert("연락처를 입력해 주세요.");
//       return alert(t(`Please provide your contact information`));
//     }
//     if (!agreementCheck) {
//       // return alert("서비스 이용약관에 동의해 주세요.");
//       return alert(t(`Please agree to the Terms and Conditions of Service`));
//     }
//     if (!agreementCheck2) {
//       // return alert("환불 정책에 동의해 주세요.");
//       return alert(t(`Please agree to the refund policy`));
//     }

//     const insertPayForm = {
//       ...privateForm,
//       payerName: privateForm.lead,
//       payerEmail: "",
//       count: 1,
//       totalAmount: 99000 * 1,
//       state: "pending",
//       type: "1인 이용권",
//       dueDate: timeformatKR("2025-02-28") + 86400000 - 1,
//       user_id: context.userInfo.kakaoId ? context.userInfo.kakaoId : context.userInfo.id,
//       linkCode: context.userInfo.linkCode,
//     };

//     const insertPay: any = await postPayment(insertPayForm);

//     if (insertPay.result && insertPay.insertedId && insertPay.uid) {
//       localStorage.setItem(
//         "pay",
//         JSON.stringify({
//           payId: insertPay.insertedId,
//           uid: insertPay.uid,
//         }),
//       );
//       await onClickPayment({ ...insertPayForm, _id: insertPay.insertedId, uid: insertPay.uid });
//     }
//   };

//   async function onClickPayment(payData: any) {
//     // /* 1. 가맹점 식별하기 */

//     const { IMP }: any = window;
//     IMP.init("imp41427313");
//     /* 2. 결제 데이터 정의하기 */
//     const data = {
//       pg: "nice_v2.IM0017205m", // PG사
//       pay_method: "card", // 결제수단
//       merchant_uid: `mid_${new Date().getTime()}`, // 주문번호
//       // amount: 1000,
//       amount: payData.totalAmount, // 결제금액
//       name: `다했니 무제한 이용권(2024학년도 특별가)`, // 주문명
//       buyer_name: `${payData.lead}`, // 구매자 이름
//       buyer_tel: `${payData.leaderPhone}`, // 구매자 전화번호
//       buyer_email: `${payData.payerEmail}`, // 구매자 이메일
//       // m_redirect_url: "http://localhost:3000/plan/private",
//       // m_redirect_url: `http://localhost:3000/buy/${payData._id}/${payData.uid}`,
//       // buyer_addr: "신사동 661-16", // 구매자 주소
//       // buyer_postcode: "06018", // 구매자 우편번호
//       notice_url: `https://api.dahandin.com/api/buy?trackingid=${payData._id}&uid=${payData.uid}`,
//       popup: true,
//     };

//     /* 4. 결제 창 호출하기 */
//     IMP.request_pay(data, callback);
//   }

//   /* 3. 콜백 함수 정의하기 */
//   const callback = async (response: any) => {
//     const payIds: any = localStorage.getItem("pay");
//     const parsed = JSON.parse(payIds);
//     const payResult: any = await getPortonePayment(response.imp_uid);

//     if (!response.error_code && !response.error_msg) {
//       const result: any = await putPayment({
//         id: parsed.payId,
//         uid: parsed.uid,
//         // 취소시 최초결제를 구분하기 위해 필드 추가
//         paidAt: payResult.response.paid_at,
//         ...payResult.response,
//         state: "completed",
//         startingDate: Date.now(),
//         isSerialCode: false,
//         isPersonal: true,
//         serialCode: null,
//       });

//       // 바로 등록되는 userInfo 변경 API
//       await postPaidAfterPrivatePurchase({ userId: context.userInfo?._id });
//       localStorage.removeItem("pay");
//       context.handleGetUserInfo();

//       // alert(`결제가 완료되었습니다. 결제내역에서 영수증을 확인해주세요!`);
//       alert(t(`Your payment has been completed...`));
//       history.push("/");
//     } else {
//       // alert(`결제실패: ${response.error_code}: ${response.error_msg}`);
//       alert(`${t(`Payment failed`)}: ${response.error_code}: ${response.error_msg}`);
//       localStorage.removeItem("pay");
//     }

//     setPayLoading(false);
//   };

//   return (
//     <div style={{ backgroundColor: "#fff", minHeight: "100vh" }}>
//       {emailModal && (
//         <ModalComponent
//           modalStyles={{
//             width: "40%",
//             minHeight: "10vh",
//             maxHeight: "80vh",
//             overflowY: "scroll",
//             borderRadius: 16,
//             backgroundColor: "#efefef",
//           }}
//           onClose={() => setEmailModal(false)}
//           title={"모달타이틀"}
//           open={emailModal}
//         >
//           <div style={{ padding: 25, textAlign: "center" }}>
//             <h1>
//               {/* 메일이 전송되었습니다. */}
//               {t(`Your email has been sent`)}
//             </h1>

//             <p className="font-20">
//               {/* 학교 카드 결제 권한을 가지신 분께 메일이 발송되었습니다! */}
//               {t(`An email has been sent to the person authorized to pay for the school card`)}
//             </p>
//             <p className="font-20 mt-10">
//               {/* 담장자에게 사내 메신저로 '메일함 확인하여 결제부탁드립니다'라고 <br />
//               안내해 주세요. */}
//               {t(`Please notify the authorized payer via...`)}
//             </p>
//             <p className="font-20 mt-20 color-red font-600">
//               {/* 결제 완료 후, */}
//               {t(`After completing the payment...`)}
//               <br />
//               {/* 홈 화면에서 새로고침 버튼을 한 번 눌러주시면 즉시 적용 됩니다. */}
//               {t(`Refresh the page to apply the changes immediately`)}
//             </p>

//             <p className="font-18 mt-40">
//               {/* 메일이 확인되지 않았을 경우, */}
//               {t(`If you don't receive the mail`)}
//               <br />
//               {/* 스팸메일함을 확인해보시거나 아래 문의하기로 연락 주세요. */}
//               {t(`please check your spam folder...`)}
//             </p>

//             <img className="mt-18" src={qr} alt="qrcode" style={{ width: 100, height: "auto" }} />
//           </div>
//         </ModalComponent>
//       )}
//       <TermsModal
//         handleAgree={handleAgree}
//         open={agreementModal}
//         onClose={() => setAgreementModal(false)}
//       />
//       <TermsModalRefund
//         handleAgree={handleAgree2}
//         open={agreementModal2}
//         onClose={() => setAgreementModal2(false)}
//       />
//       {/* <MainHeader /> */}
//       <div className="container text-center">
//         <div className="col-md-12">
//           <div className="display-f justify-between mt-20">
//             <button className="font-600 back-btn-arrow" onClick={() => history.goBack()}>
//               ←
//             </button>
//           </div>

//           <div className="mt-40 mb-20">
//             <p style={{ fontSize: 26, fontWeight: 600 }}>
//               {/* 1인 이용 결제 */}
//               {t(`Pay for a single person membership`)}
//             </p>

//             {/* <p className="mt-4">
//               현재{" "}
//               <span style={{ fontWeight: 600, fontSize: 20 }} className="font-blue">
//                 비기너
//               </span>{" "}
//               요금제를 사용중이십니다.
//             </p> */}
//           </div>

//           <div className="col-md-6">
//             <div>
//               <button
//                 onClick={() => {
//                   setPaymentInfo((prev: any) => {
//                     return {
//                       subType: "학교(법인) 카드로 결제",
//                       duration: 1,
//                       label: "",
//                       mainType: "개별 결제",
//                       plan: "베이직",
//                       price: 99000,
//                     };
//                   });
//                 }}
//                 className={`${
//                   paymentInfo.subType === "학교(법인) 카드로 결제"
//                     ? "pay-card-type-select-2"
//                     : "pay-card-type"
//                 } `}
//               >
//                 {/* 학교(법인) 카드로 결제 */}
//                 {t(`Payment using a school corporate card`)}
//               </button>
//               {paymentInfo.subType === "학교(법인) 카드로 결제" && (
//                 <>
//                   <p className="mt-10 text-left font-12 font-600">
//                     {/* 교육용 소프트웨어는 아래와 같은 예산 항목으로 구매하실 수 있습니다. */}
//                     {t(
//                       `Educational software can be...`,
//                     )}
//                   </p>
//                   <p className="text-left font-12">
//                     {/* - 자산취득비-기타자산 취득비-소프트웨어 구입비 */}
//                     {t(`Asset acquisition expenses...`)}
//                   </p>
//                   <p className="text-left font-12">
//                     {/* - 운영비-일반운영비-일반 수용비 */}
//                     {t(`Operating expenses...`)}
//                   </p>
//                   {/* <p className="text-left font-12">
//                     - 기타 자율예산 예) 학급/학년 운영비, 꿈실, 전학공 예산 등
//                   </p> */}
//                 </>
//               )}
//             </div>
//           </div>
//           <div className="col-md-6">
//             <div>
//               <button
//                 onClick={() => {
//                   setPaymentInfo((prev: any) => {
//                     return {
//                       subType: "개인 카드로 결제",
//                       duration: 1,
//                       label: "",
//                       mainType: "개별 결제",
//                       plan: "베이직",
//                       price: 99000,
//                     };
//                   });
//                 }}
//                 className={`${
//                   paymentInfo.subType === "개인 카드로 결제"
//                     ? "pay-card-type-select-2"
//                     : "pay-card-type"
//                 } `}
//               >
//                 {/* 개인 카드로 결제 */}
//                 {t(`Pay with a personal card`)}
//               </button>

//               {paymentInfo.subType === "개인 카드로 결제" && (
//                 <>
//                   <p className="mt-10 text-left font-12 font-600">
//                     {/* 학급운영비(및 기타 예산)를 개산급으로 처리하는 경우, */}
//                     {t(`If you are paying for classroom expenses...`)}
//                     <br />
//                     {/* 개인 카드로 결제하여 영수증을 첨부하시면 됩니다. */}
//                     {t(`You can use a personal card and attach the receipt`)}
//                   </p>
//                   <div className="mt-10" style={{ padding: 10, border: "1px solid #ececec" }}>
//                     <p className="text-left font-12 font-600">
//                       {/* 세금계산서 및 영수증 발급 안내 */}
//                       {t(`Guidance on issuing tax invoices and receipts`)}
//                     </p>
//                     <p className="text-left font-12">
//                       {/* [부가가치세법 시행령 제 57조 2항]에 따라 카드결제는 세금계산서 대신 신용카드
//                       매출전표(영수증)가 발행됩니다. */}
//                       {t(`In accordance with...`)}
//                     </p>
//                   </div>
//                 </>
//               )}
//             </div>
//           </div>
//         </div>

//         <div className="col-md-12">
//           <h3>
//             {/* 2024년은 무제한 단일 요금제로 진행합니다. */}
//             {t(`2024 Unlimited Single Rate Plan`)}
//             <br />
//             <span className="color-red font-14 font-400">
//               {/* 2024.8.1. 부터는 49,500원으로 가격이 변경됩니다. (종료일은 25.2.28.로 같음) */}
//               {t(`Starting August 1 2024 the price will change to...`)}
//             </span>
//           </h3>
//         </div>

//         {/* {paymentInfo.subType === "학교(법인) 카드로 결제" && (
//           <div>
//             <h3>
//               법인(학교 카드 결제)는 <span className="color-red">6개월</span> 및{" "}
//               <span className="color-red">1년 이용권</span> 결제만 가능합니다.
//               <br />
//               <span className="color-red">단체 결제 전환을 위한 중도 환불은 불가합니다.</span>
//             </h3>
//           </div>
//         )} */}

//         <div className="col-md-12 position-r mt-40">
//           <div className="col-md-12 benefit-overlay-plan" style={{ position: "absolute", left: 0 }}>
//             <div className="col-md-3"></div>
//             <div className="col-md-3"></div>
//             <div
//               className="col-md-6"
//               style={{ backgroundColor: "rgba(0,0,0,0.7)", height: 340, zIndex: 10 }}
//             >
//               <div
//                 style={{ height: 340 }}
//                 className="display-f direction-column align-center justify-center"
//               >
//                 <p className="font-600 font-18" style={{ color: "red" }}>
//                   {/* 특별 혜택 */}
//                   {t(`Special Offer`)}
//                 </p>
//                 <p className="font-600 font-16 mt-20" style={{ color: "#fff" }}>
//                   {/* 2025월 2월 28일까지는 <br />
//                   저장용량에 관계 없이, */}
//                   {t(`Until February 28 2025...`)}
//                   <br />
//                   <span className="font-basic font-18">
//                     {/* 베이직 가격 */}
//                     {t(`Basic plan users will...`)}
//                   </span>
//                   {/* 에 이용하실 수 있습니다! */}
//                   {t(`Standard and Standard Plus...`)}
//                 </p>

//                 {/* <div
//                   className="mt-10"
//                   style={{
//                     backgroundColor: "rgb(255,255,255,0.8)",
//                     margin: "0 15px",
//                     borderRadius: 4,
//                     padding: 6,
//                   }}
//                 >
//                   <p>
//                     단, 2025년 3월 1일 부터는 저장 용량에 따라 추가 요금이 발생하며,
//                     <br />
//                     30일간 한도를 넘은 상태로 유지 시 모든 학급, 학생, 자료가
//                     <br />
//                     삭제될 수 있습니다.
//                   </p>
//                 </div> */}
//               </div>
//             </div>
//           </div>
//           {PAY_PLAN_ARR.map((item: any, i: number) => (
//             <React.Fragment key={i}>
//               {i !== 2 && i !== 3 && (
//                 <div className="col-md-3" style={{ zIndex: 1 }}>
//                   <div className={`${paymentInfo.plan === item.plan ? item.color : ""} plan-box`}>
//                     <div className={`${item.color}`}>
//                       <h2
//                         style={{ padding: "4px 0" }}
//                         className={`${item.plan === "비기너" && "white"} margin-0`}
//                       >
//                         {item.plan}
//                       </h2>
//                     </div>

//                     <div className="mt-10 mb-10 plan-desc">
//                       {i !== 1 && <p>{item.desc}</p>}
//                       {i === 1 && (
//                         <p className="color-red font-15">
//                           {/* 무제한 */}
//                           {t(`Unlimited`)}
//                           <br />
//                           <span>
//                             {/* 결제일 ~ 2025.2.28까지 */}
//                             {t(`Payment date through...`)}
//                             <br />
//                             {/* 무제한 이용 */}
//                             {t(`Unlimited access`)}
//                           </span>
//                         </p>
//                       )}
//                       {i === 0 && (
//                         <p>
//                           {/* AI 쫑알이 <br />
//                           사용불가 */}
//                           {t(`AI Comment is not available`)}
//                         </p>
//                       )}
//                     </div>

//                     <div className="plan-btn-wrapper">
//                       {item.durations.map((term: any, index: number) => (
//                         <button
//                           disabled={
//                             paymentInfo.subType === "학교(법인) 카드로 결제" && term.disable
//                           }
//                           onClick={() => handleSelectPlan(item, term)}
//                           key={index}
//                           className={`${
//                             (paymentInfo.subType === "학교(법인) 카드로 결제" && term.disable) ||
//                             term.label === "무료"
//                               ? "plan-btn-disable"
//                               : `plan-btn ${item.color}`
//                           }
//                         ${paymentInfo.plan === item.plan && item.color}

//                         ${
//                           paymentInfo.plan === item.plan && term.duration === paymentInfo.duration
//                             ? `border-focus`
//                             : ""
//                         }`}
//                         >
//                           {term.label} {i !== 0 ? `${currency(term.price)}${t(`Won`)}` : ``}{" "}
//                         </button>
//                       ))}
//                     </div>
//                   </div>
//                 </div>
//               )}

//               {(i === 2 || i === 3) && (
//                 <div className="col-md-3 position-r">
//                   <div
//                     className={`${i === 2 && "benefit-overlay2"} ${i === 3 && "benefit-overlay3"}`}
//                   >
//                     <p className="font-600 font-18" style={{ color: "red" }}>
//                       {/* 특별 혜택 */}
//                       {t(`Special Offer`)}
//                     </p>
//                     <p className="font-600 font-16 mt-20" style={{ color: "#fff" }}>
//                       {/* 2025월 2월 28일까지는 <br />
//                       저장용량에 관계 없이, */}
//                       {t(`Until February 28 2025...`)}
//                       <br />
//                       {/* 모두  */}
//                       {t(`All`)}{" "}
//                       <span className="font-basic font-18">
//                         {/* 베이직 가격 */}
//                         {t(`Basic plan users will...`)}{" "}
//                       </span>
//                       {/* 에 이용하실 수 있습니다! */}
//                       {t(`Standard and Standard Plus...`)}
//                     </p>
//                   </div>
//                   <div className={`${paymentInfo.plan === item.plan ? item.color : ""} plan-box`}>
//                     <div className={`${item.color}`}>
//                       <h2
//                         style={{ padding: "4px 0" }}
//                         className={`${item.plan === "비기너" && "white"} margin-0`}
//                       >
//                         {item.plan}
//                       </h2>
//                     </div>

//                     <div className="mt-10 mb-10 plan-desc">
//                       <p>{item.desc}</p>
//                     </div>

//                     <div className="plan-btn-wrapper">
//                       {item.durations.map((term: any, index: number) => (
//                         <button
//                           disabled={
//                             paymentInfo.subType === "학교(법인) 카드로 결제" && term.disable
//                           }
//                           onClick={() => handleSelectPlan(item, term)}
//                           key={index}
//                           className={`${
//                             (paymentInfo.subType === "학교(법인) 카드로 결제" && term.disable) ||
//                             term.label === "무료"
//                               ? "plan-btn-disable"
//                               : `plan-btn ${item.color}`
//                           }
//                         ${paymentInfo.plan === item.plan && item.color}

//                         ${
//                           paymentInfo.plan === item.plan && term.duration === paymentInfo.duration
//                             ? `border-focus`
//                             : ""
//                         }`}
//                         >
//                           {term.label} {`${currency(term.price)}${t(`Won`)}`}
//                         </button>
//                       ))}
//                     </div>
//                   </div>
//                 </div>
//               )}
//             </React.Fragment>
//           ))}
//         </div>

//         <div className="col-md-12 mt-40">
//           <div className="col-md-3"></div>
//           <div className="col-md-6 text-left payform-pc">
//             <div style={{ border: "1px solid #ececec", padding: 20, fontSize: 18 }}>
//               <div className="display-f justify-between">
//                 {/* 상품명{" "} */}
//                 {t(`Product Name`)}
//                 <p className="text-right">
//                   {/* 2024학년도 다했니 이용권 */}
//                   {t(`2024 Academic Year 다했니 membership`)}
//                   {/* 다했니 {paymentInfo.plan} {paymentInfo.label} 이용권 */}
//                 </p>
//               </div>
//               <div className="display-f justify-between mt-10">
//                 {/* 이용 기간{" "} */}
//                 {t(`Term of use`)}
//                 <p className="text-right">
//                   {/* 결제일 ~  */}
//                   {t(`Payment Date`)} ~<span className="color-red">2025.2.28.</span>
//                 </p>
//               </div>
//               <div className="display-f justify-between mt-10">
//                 {/* 결제 금액(단가){" "} */}
//                 {t(`Payment amount unit price`)}
//                 <p className="text-right">
//                   {currency(paymentInfo.price)}
//                   {/* 원 */}
//                   {t(`Won`)}
//                   <span style={{ fontSize: 12 }}>
//                     {/* (부가세포함) */}({t(`VAT included`)})
//                   </span>
//                 </p>
//               </div>
//               <div className="display-f justify-between mt-10">
//                 {/* 상품 수량  */}
//                 {t(`Quantity of products`)}
//                 <p className="text-right">
//                   1{/* 개 TODO:: 고유번역*/}
//                   {localStorage.getItem("language") === "ko" ? "개" : ""}
//                 </p>
//               </div>
//               <div className="display-f justify-between mt-10">
//                 {/* 총 결제 금액{" "} */}
//                 {t(`Total payment amount`)}
//                 <p className="text-right color-red">
//                   {currency(paymentInfo.price)}
//                   {/* 원 */}
//                   {t(`Won`)}
//                   <span style={{ fontSize: 12 }}>
//                     {/* (부가세포함) */}({t(`VAT included`)})
//                   </span>
//                 </p>
//               </div>
//               <div className="display-f justify-between align-center mt-10">
//                 <div>
//                   {/* 선생님 본인 성함 */}
//                   {t(`Teacher's full name`)}
//                   <p className="font-12">
//                     {/* (결제자 식별용) */}({t(`For the purpose of payer identification`)})
//                   </p>
//                 </div>{" "}
//                 <input
//                   value={privateForm.lead}
//                   onChange={(e: any) => handleChangeValue("lead", e.target.value)}
//                   className="pay-input"
//                 />
//               </div>
//               <div className="display-f justify-between align-center mt-10">
//                 <div>
//                   {/* 선생님 본인 연락처 */}
//                   {t(`Teacher's contact information`)}
//                   <p className="font-12">
//                     {/* (결제자 식별용) */}({t(`For the purpose of payer identification`)})
//                   </p>
//                 </div>
//                 <input
//                   value={privateForm.leaderPhone}
//                   onChange={(e: any) => handleChangeValue("leaderPhone", e.target.value)}
//                   className="pay-input"
//                 />
//               </div>
//               {paymentInfo.subType !== "개인 카드로 결제" && (
//                 <div className="display-f justify-between align-center mt-10 color-blue">
//                   <div>
//                     {/* 학교(법인)명 */}
//                     {t(`School legal entity name`)}
//                     {/* <p className="font-12">(행정실/실무사 또는 결제하는 본인)</p> */}
//                   </div>
//                   <input
//                     value={privateForm.payerName}
//                     onChange={(e: any) => handleChangeValue("payerName", e.target.value)}
//                     className="pay-input"
//                   />
//                 </div>
//               )}
//               {paymentInfo.subType !== "개인 카드로 결제" && (
//                 <div className="display-f justify-between align-center mt-10 color-blue">
//                   <div>
//                     {/* 결제자 이메일 주소 */}
//                     {t(`Payer Email Address`)}
//                     <p className="font-12">
//                       {/* (행정실/실무사 또는 결제하는 본인) */}(
//                       {t(`Account manager or Person Responsible for Payment`)})
//                     </p>
//                   </div>
//                   <input
//                     // placeholder="전자 결제창을 받을 이메일 주소"
//                     placeholder={t(`Email address for receiving the electronic payment link`)}
//                     value={privateForm.payerEmail}
//                     onChange={(e: any) => handleChangeValue("payerEmail", e.target.value)}
//                     className="pay-input"
//                   />
//                 </div>
//               )}
//             </div>
//           </div>
//           <div className="col-md-3"></div>
//         </div>

//         <div className="col-md-12 mt-10 mb-40">
//           <div className="col-md-3"></div>
//           <div className="col-md-6 text-left payform-pc">
//             <div className="display-f ">
//               <div
//                 onClick={() => setAgreementModal(true)}
//                 className="check-box mr-10 display-f justify-center align-center"
//               >
//                 {agreementCheck && <p className="font-12">✔️</p>}
//               </div>
//               <p>
//                 {/* 서비스 이용약관 동의 */}
//                 {t(`Agree to terms of use`)}
//               </p>
//             </div>
//             <div className="display-f mt-4">
//               <div
//                 onClick={() => setAgreementModal2(true)}
//                 className="check-box mr-10 display-f justify-center align-center"
//               >
//                 {agreementCheck2 && <p className="font-12">✔️</p>}
//               </div>
//               <p>
//                 {/* 환불 정책 동의 */}
//                 {t(`Accept the refund policy`)}
//               </p>
//             </div>

//             {paymentInfo.subType === "학교(법인) 카드로 결제" && (
//               <button onClick={handlePostEmail} className="mt-10 pay-btn">
//                 <p>
//                   {/* 결제 요청 메일 전송하기 */}
//                   {t(`Sending a payment request email`)}
//                 </p>
//                 <p style={{ fontSize: 14, fontWeight: 400 }}>
//                   {/* 행정실/실무사님의 메일로 결제창이 전송됩니다. */}
//                   {t(`The payment link will be sent to...`)}
//                 </p>
//               </button>
//             )}
//             {paymentInfo.subType === "개인 카드로 결제" && (
//               <button onClick={handlePay} className="mt-10 pay-btn">
//                 <p>
//                   {/* 결제하기 */}
//                   {t(`Proceed with payment`)}
//                 </p>
//               </button>
//             )}
//           </div>
//           <div className="col-md-3"></div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default PayPlan;

import axios from "axios";
import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { IMainContext } from "../interface/Interface";
import { MainContext } from "../common/Context";
import MainHeader from "./MainHeader";
import { currency, timeformatKR } from "../common/Method";
import ModalComponent from "../component/ModalComponent";
import qr from "../images/qr.png";
import TermsModal from "../component/TermsModal";
import TermsModalRefund from "../component/TermsModalRefund";
import {
  getPaymentItem,
  getPortonePayment,
  postEmail,
  postPaidAfterPrivatePurchase,
  postPayment,
  putPayment,
} from "../common/Action";
import { t } from "i18next";

const PAY_PLAN_ARR = [
  {
    plan: t(`Beginners`), // plan: "비기너",
    color: "bg-beginner",
    desc: "~100MB",
    durations: [
      {
        duration: 0,
        label: t(`For free`), // label: "무료",
        price: 0,
        disable: true,
      },
    ],
  },
  {
    plan: t(`Basic`), // plan: "베이직",
    color: "bg-basic",
    desc: t(`Unlimited`), // desc: `무제한`,
    durations: [
      // {
      //   duration: 1,
      //   label: "월",
      //   price: 9900,
      //   disable: true,
      // },
      {
        duration: 1,
        label: "",
        price: 49500,
      },
      // {
      //   duration: 12,
      //   label: "1년",
      //   price: 99000,
      // },
    ],
  },
  {
    plan: t(`Standard`), // plan: "스탠다드",
    color: "bg-standard",
    desc: "~50GB",
    durations: [
      {
        duration: 1,
        label: t(`Month`), // label: "월",
        price: 14300,
        disable: true,
      },
      {
        duration: 6,
        label: t(`6 months`), // label: "6개월",
        price: 71500,
      },
      {
        duration: 12,
        label: t(`1 year`), // label: "1년",
        price: 143000,
      },
    ],
  },
  {
    plan: t(`Standard Plus`), // plan: "스탠다드플러스",
    color: "bg-standardplus",
    desc: "~200GB",
    durations: [
      {
        duration: 1,
        label: t(`Month`), // label: "월",
        price: 18700,
        disable: true,
      },
      {
        duration: 6,
        label: t(`6 months`), // label: "6개월",
        price: 93500,
      },
      {
        duration: 12,
        label: t(`1 year`), // label: "1년",
        price: 187000,
      },
    ],
  },
];

const PayPlan: React.FC = () => {
  const history = useHistory();
  const context = useContext<IMainContext>(MainContext);
  const [paymentInfo, setPaymentInfo] = useState<any>({
    mainType: "개별 결제", // t(`individual payment`)
    subType: "학교(법인) 카드로 결제", //  t(`Payment using a school corporate card`),
    plan: "",
    duration: -1,
    price: 0,
  });
  const [emailModal, setEmailModal] = useState<boolean>(false);
  const [privateForm, setPrivateForm] = useState<any>({});
  const [agreementModal, setAgreementModal] = useState<boolean>(false);
  const [agreementCheck, setAgreementCheck] = useState<boolean>(false);
  const [agreementModal2, setAgreementModal2] = useState<boolean>(false);
  const [agreementCheck2, setAgreementCheck2] = useState<boolean>(false);
  const [payLoading, setPayLoading] = useState<boolean>(false);

  useEffect(() => {
    // console.log(Date.now());
    init();
  }, []);

  const init = async (): Promise<void> => {
    setPaymentInfo({
      duration: 1,
      label: "",
      mainType: t(`individual payment`), // "개별 결제",
      plan: t(`Basic`), // "베이직",
      price: 49500,
      subType: t(`Payment using a school corporate card`), // "학교(법인) 카드로 결제",
    });
  };

  const handleSelectPlan = (item: any, term: any) => {
    setPaymentInfo((prev: any) => {
      return {
        ...prev,
        plan: item.plan,
        duration: term.duration,
        price: term.price,
        label: term.label,
      };
    });
  };

  const handleChangeValue = (type: string, value: string) => {
    setPrivateForm((prev: any) => {
      return {
        ...prev,
        [type]: value,
      };
    });
  };

  const handleAgree = (valid: boolean) => {
    if (valid) {
      setAgreementCheck(true);
    }
    if (!valid) {
      setAgreementCheck(false);
    }

    setAgreementModal(false);
  };
  const handleAgree2 = (valid: boolean) => {
    if (valid) {
      setAgreementCheck2(true);
    }
    if (!valid) {
      setAgreementCheck2(false);
    }

    setAgreementModal2(false);
  };

  const isValidForm = () => {
    let isValid = false;

    if (!privateForm.lead || privateForm.lead === "") {
      // return alert("업무 담당자를 입력해 주세요.");
      return alert(t(`Please enter the name of the person in charge of the task`));
    }
    if (!privateForm.leaderPhone || privateForm.leaderPhone === "") {
      // return alert("업무 담당자 연락처를 입력해 주세요.");
      return alert(t(`Please enter the contact information for the person in charge`));
    }
    if (!privateForm.payerName || privateForm.payeerName === "") {
      // return alert("결제자 이름을 입력해 주세요.");
      return alert(t(`Please enter the payer’s name`));
    }
    if (!privateForm.payerEmail || privateForm.payerEmail === "") {
      // return alert("결제자 이메일을 정확히 입력해 주세요.");
      return alert(t(`Please ensure you enter the payer’s email correctly`));
    }
    if (!agreementCheck) {
      // return alert("서비스 이용약관에 동의해 주세요.");
      return alert(t(`Please accept the Terms and Conditions of Service`));
    }
    if (!agreementCheck2) {
      // return alert("환불 정책에 동의해 주세요.");
      return alert(t(`Please agree to our refund policy`));
    }

    isValid = true;
    return isValid;
  };

  const handlePostEmail = async () => {
    const isValid = isValidForm();
    if (isValid) {
      const insertPay: any = await postPayment({
        ...privateForm,
        count: 1,
        totalAmount: 49500 * 1,
        state: "pending",
        type: "1인 이용권",
        dueDate: timeformatKR("2025-02-28") + 86400000 - 1,
        user_id: context.userInfo.kakaoId ? context.userInfo.kakaoId : context.userInfo.id,
        linkCode: context.userInfo.linkCode,
      });

      if (insertPay.result && insertPay.insertedId && insertPay.uid) {
        const mailResult: any = await postEmail({
          email: privateForm.payerEmail,
          subject: `[다했니] 결제창 안내 - (${privateForm.lead}) 선생님`,
          // html: `<div style="padding: 10px 10px 20px 10px; border: 1px solid #979797; width:600px">
          // <h3 style="color: black;">귀 학교의 (${privateForm.lead}) 선생님께서 발송하신 결제창입니다.</h3><br/>
          // <p style="color: black;">아래 링크를 클릭하여 학교 카드로 결제해 주세요.</p>
          // <a href="https://dahandin.com/buy/${insertPay.insertedId}/${insertPay.uid}">결제하러 가기💸</a></div>`,

          // TODO:: 고유번역
          html: `<div style="padding: 10px 10px 20px 10px; border: 1px solid #979797; width:600px"><h3 style="color: black;">
          ${
            localStorage.getItem("language") === "ko"
              ? `귀 학교의 (${privateForm.lead}) 선생님께서 발송하신 결제창입니다.`
              : `This payment window was sent to you by your (${privateForm.lead}) teacher at your school`
          }
          </h3><br/><p style="color: black;">
          ${t(`To pay with your school card, click the link below`)}</p>
          <a href="https://dahandin.com/buy/${insertPay.insertedId}/${insertPay.uid}">
          ${t(`Proceed to checkout`)}
          </a>
          </div>`,
        });

        if (mailResult === "Email send success") {
          setEmailModal(true);
        }
      }
    }
  };

  const handlePay = async () => {
    if (payLoading) return;
    setPayLoading(true);
    if (!privateForm.lead || privateForm.lead === "") {
      // return alert("성함을 입력해 주세요.");
      return alert(t(`Enter your name`));
    }
    if (!privateForm.leaderPhone || privateForm.leaderPhone === "") {
      // return alert("연락처를 입력해 주세요.");
      return alert(t(`Please provide your contact information`));
    }
    if (!agreementCheck) {
      // return alert("서비스 이용약관에 동의해 주세요.");
      return alert(t(`Please agree to the Terms and Conditions of Service`));
    }
    if (!agreementCheck2) {
      // return alert("환불 정책에 동의해 주세요.");
      return alert(t(`Please agree to the refund policy`));
    }

    const insertPayForm = {
      ...privateForm,
      payerName: privateForm.lead,
      payerEmail: "",
      count: 1,
      totalAmount: 49500 * 1,
      state: "pending",
      type: "1인 이용권",
      dueDate: timeformatKR("2025-02-28") + 86400000 - 1,
      user_id: context.userInfo.kakaoId ? context.userInfo.kakaoId : context.userInfo.id,
      linkCode: context.userInfo.linkCode,
    };

    const insertPay: any = await postPayment(insertPayForm);

    if (insertPay.result && insertPay.insertedId && insertPay.uid) {
      localStorage.setItem(
        "pay",
        JSON.stringify({
          payId: insertPay.insertedId,
          uid: insertPay.uid,
        }),
      );
      await onClickPayment({ ...insertPayForm, _id: insertPay.insertedId, uid: insertPay.uid });
    }
  };

  async function onClickPayment(payData: any) {
    // /* 1. 가맹점 식별하기 */

    const { IMP }: any = window;
    IMP.init("imp41427313");
    /* 2. 결제 데이터 정의하기 */
    const data = {
      pg: "nice_v2.IM0017205m", // PG사
      pay_method: "card", // 결제수단
      merchant_uid: `mid_${new Date().getTime()}`, // 주문번호
      // amount: 1000,
      amount: payData.totalAmount, // 결제금액
      name: `다했니 무제한 이용권(2024학년도 특별가)`, // 주문명
      buyer_name: `${payData.lead}`, // 구매자 이름
      buyer_tel: `${payData.leaderPhone}`, // 구매자 전화번호
      buyer_email: `${payData.payerEmail}`, // 구매자 이메일
      // m_redirect_url: "http://localhost:3000/plan/private",
      // m_redirect_url: `http://localhost:3000/buy/${payData._id}/${payData.uid}`,
      // buyer_addr: "신사동 661-16", // 구매자 주소
      // buyer_postcode: "06018", // 구매자 우편번호
      notice_url: `https://api.dahandin.com/api/buy?trackingid=${payData._id}&uid=${payData.uid}`,
      popup: true,
    };

    /* 4. 결제 창 호출하기 */
    IMP.request_pay(data, callback);
  }

  /* 3. 콜백 함수 정의하기 */
  const callback = async (response: any) => {
    const payIds: any = localStorage.getItem("pay");
    const parsed = JSON.parse(payIds);
    const payResult: any = await getPortonePayment(response.imp_uid);

    if (!response.error_code && !response.error_msg) {
      const result: any = await putPayment({
        id: parsed.payId,
        uid: parsed.uid,
        // 취소시 최초결제를 구분하기 위해 필드 추가
        paidAt: payResult.response.paid_at,
        ...payResult.response,
        state: "completed",
        startingDate: payResult.startingDate,
        isSerialCode: false,
        isPersonal: true,
        serialCode: null,
      });

      // 바로 등록되는 userInfo 변경 API
      await postPaidAfterPrivatePurchase({ userId: context.userInfo?._id });
      localStorage.removeItem("pay");
      context.handleGetUserInfo();

      // alert(`결제가 완료되었습니다. 결제내역에서 영수증을 확인해주세요!`);
      alert(t(`Your payment has been completed...`));
      history.push("/");
    } else {
      // alert(`결제실패: ${response.error_code}: ${response.error_msg}`);
      alert(`${t(`Payment failed`)}: ${response.error_code}: ${response.error_msg}`);
      localStorage.removeItem("pay");
    }

    setPayLoading(false);
  };

  return (
    <div style={{ backgroundColor: "#fff", minHeight: "100vh" }}>
      {emailModal && (
        <ModalComponent
          modalStyles={{
            width: "40%",
            minHeight: "10vh",
            maxHeight: "80vh",
            overflowY: "scroll",
            borderRadius: 16,
            backgroundColor: "#efefef",
          }}
          onClose={() => setEmailModal(false)}
          title={"모달타이틀"}
          open={emailModal}
        >
          <div style={{ padding: 25, textAlign: "center" }}>
            <h1>
              {/* 메일이 전송되었습니다. */}
              {t(`Your email has been sent`)}
            </h1>

            <p className="font-20">
              {/* 학교 카드 결제 권한을 가지신 분께 메일이 발송되었습니다! */}
              {t(`An email has been sent to the person authorized to pay for the school card`)}
            </p>
            <p className="font-20 mt-10">
              {/* 담장자에게 사내 메신저로 '메일함 확인하여 결제부탁드립니다'라고 <br />
              안내해 주세요. */}
              {t(`Please notify the authorized payer via...`)}
            </p>
            <p className="font-20 mt-20 color-red font-600">
              {/* 결제 완료 후, */}
              {t(`After completing the payment...`)}
              <br />
              {/* 홈 화면에서 새로고침 버튼을 한 번 눌러주시면 즉시 적용 됩니다. */}
              {t(`Refresh the page to apply the changes immediately`)}
            </p>

            <p className="font-18 mt-40">
              {/* 메일이 확인되지 않았을 경우, */}
              {t(`If you don't receive the mail`)}
              <br />
              {/* 스팸메일함을 확인해보시거나 아래 문의하기로 연락 주세요. */}
              {t(`please check your spam folder...`)}
            </p>

            <img className="mt-18" src={qr} alt="qrcode" style={{ width: 100, height: "auto" }} />
          </div>
        </ModalComponent>
      )}
      <TermsModal
        handleAgree={handleAgree}
        open={agreementModal}
        onClose={() => setAgreementModal(false)}
      />
      <TermsModalRefund
        handleAgree={handleAgree2}
        open={agreementModal2}
        onClose={() => setAgreementModal2(false)}
      />
      {/* <MainHeader /> */}
      <div className="container text-center">
        <div className="col-md-12">
          <div className="display-f justify-between mt-20">
            <button className="font-600 back-btn-arrow" onClick={() => history.goBack()}>
              ←
            </button>
          </div>

          <div className="mt-40 mb-20">
            <p style={{ fontSize: 26, fontWeight: 600 }}>
              {/* 1인 이용 결제 */}
              {t(`Pay for a single person membership`)}
            </p>

            {/* <p className="mt-4">
              현재{" "}
              <span style={{ fontWeight: 600, fontSize: 20 }} className="font-blue">
                비기너
              </span>{" "}
              요금제를 사용중이십니다.
            </p> */}
          </div>

          <div className="col-md-6">
            <div>
              <button
                onClick={() => {
                  setPaymentInfo((prev: any) => {
                    return {
                      subType: "학교(법인) 카드로 결제",
                      duration: 1,
                      label: "",
                      mainType: "개별 결제",
                      plan: "베이직",
                      price: 49500,
                    };
                  });
                }}
                className={`${
                  paymentInfo.subType === "학교(법인) 카드로 결제"
                    ? "pay-card-type-select-2"
                    : "pay-card-type"
                } `}
              >
                {/* 학교(법인) 카드로 결제 */}
                {t(`Payment using a school corporate card`)}
              </button>
              {paymentInfo.subType === "학교(법인) 카드로 결제" && (
                <>
                  <p className="mt-10 text-left font-12 font-600">
                    {/* 교육용 소프트웨어는 아래와 같은 예산 항목으로 구매하실 수 있습니다. */}
                    {t(`Educational software can be...`)}
                  </p>
                  <p className="text-left font-12">
                    {/* - 자산취득비-기타자산 취득비-소프트웨어 구입비 */}
                    {t(`Asset acquisition expenses...`)}
                  </p>
                  <p className="text-left font-12">
                    {/* - 운영비-일반운영비-일반 수용비 */}
                    {t(`Operating expenses...`)}
                  </p>
                  {/* <p className="text-left font-12">
                    - 기타 자율예산 예) 학급/학년 운영비, 꿈실, 전학공 예산 등
                  </p> */}
                </>
              )}
            </div>
          </div>
          <div className="col-md-6">
            <div>
              <button
                onClick={() => {
                  setPaymentInfo((prev: any) => {
                    return {
                      subType: "개인 카드로 결제",
                      duration: 1,
                      label: "",
                      mainType: "개별 결제",
                      plan: "베이직",
                      price: 49500,
                    };
                  });
                }}
                className={`${
                  paymentInfo.subType === "개인 카드로 결제"
                    ? "pay-card-type-select-2"
                    : "pay-card-type"
                } `}
              >
                {/* 개인 카드로 결제 */}
                {t(`Pay with a personal card`)}
              </button>

              {paymentInfo.subType === "개인 카드로 결제" && (
                <>
                  <p className="mt-10 text-left font-12 font-600">
                    {/* 학급운영비(및 기타 예산)를 개산급으로 처리하는 경우, */}
                    {t(`If you are paying for classroom expenses...`)}
                    <br />
                    {/* 개인 카드로 결제하여 영수증을 첨부하시면 됩니다. */}
                    {t(`You can use a personal card and attach the receipt`)}
                  </p>
                  <div className="mt-10" style={{ padding: 10, border: "1px solid #ececec" }}>
                    <p className="text-left font-12 font-600">
                      {/* 세금계산서 및 영수증 발급 안내 */}
                      {t(`Guidance on issuing tax invoices and receipts`)}
                    </p>
                    <p className="text-left font-12">
                      {/* [부가가치세법 시행령 제 57조 2항]에 따라 카드결제는 세금계산서 대신 신용카드
                      매출전표(영수증)가 발행됩니다. */}
                      {t(`In accordance with...`)}
                    </p>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>

        <div className="col-md-12">
          <h3>
            {/* 2024년은 무제한 단일 요금제로 진행합니다. */}
            {t(`2024 Unlimited Single Rate Plan`)}
            <br />
            <span className="color-red font-14 font-400">
              {/* 2024.8.1. 부터는 49,500원으로 가격이 변경됩니다. (종료일은 25.2.28.로 같음) */}
              {t(`Starting August 1 2024 the price will change to...`)}
            </span>
          </h3>
        </div>

        {/* {paymentInfo.subType === "학교(법인) 카드로 결제" && (
          <div>
            <h3>
              법인(학교 카드 결제)는 <span className="color-red">6개월</span> 및{" "}
              <span className="color-red">1년 이용권</span> 결제만 가능합니다.
              <br />
              <span className="color-red">단체 결제 전환을 위한 중도 환불은 불가합니다.</span>
            </h3>
          </div>
        )} */}

        <div className="col-md-12 position-r mt-40">
          <div className="col-md-12 benefit-overlay-plan" style={{ position: "absolute", left: 0 }}>
            <div className="col-md-3"></div>
            <div className="col-md-3"></div>
            <div
              className="col-md-6"
              style={{ backgroundColor: "rgba(0,0,0,0.7)", height: 340, zIndex: 10 }}
            >
              <div
                style={{ height: 340 }}
                className="display-f direction-column align-center justify-center"
              >
                <p className="font-600 font-18" style={{ color: "red" }}>
                  {/* 특별 혜택 */}
                  {t(`Special Offer`)}
                </p>
                <p className="font-600 font-16 mt-20" style={{ color: "#fff" }}>
                  {/* 2025월 2월 28일까지는 <br />
                  저장용량에 관계 없이, */}
                  {t(`Until February 28 2025...`)}
                  <br />
                  {/* 모두 */}
                  {t(`All`)}
                  <span className="font-basic font-18">
                    {/* 베이직 가격 */}
                    {t(`Basic plan users will...`)}
                  </span>
                  {/* 에 이용하실 수 있습니다! */}
                  {t(`Standard and Standard Plus...`)}
                </p>

                {/* <div
                  className="mt-10"
                  style={{
                    backgroundColor: "rgb(255,255,255,0.8)",
                    margin: "0 15px",
                    borderRadius: 4,
                    padding: 6,
                  }}
                >
                  <p>
                    단, 2025년 3월 1일 부터는 저장 용량에 따라 추가 요금이 발생하며,
                    <br />
                    30일간 한도를 넘은 상태로 유지 시 모든 학급, 학생, 자료가
                    <br />
                    삭제될 수 있습니다.
                  </p>
                </div> */}
              </div>
            </div>
          </div>
          {PAY_PLAN_ARR.map((item: any, i: number) => (
            <React.Fragment key={i}>
              {i !== 2 && i !== 3 && (
                <div className="col-md-3" style={{ zIndex: 1 }}>
                  <div className={`${paymentInfo.plan === item.plan ? item.color : ""} plan-box`}>
                    <div className={`${item.color}`}>
                      <h2
                        style={{ padding: "4px 0" }}
                        className={`${item.plan === "비기너" && "white"} margin-0`}
                      >
                        {item.plan}
                      </h2>
                    </div>

                    <div className="mt-10 mb-10 plan-desc">
                      {i !== 1 && <p>{item.desc}</p>}
                      {i === 1 && (
                        <p className="color-red font-15">
                          {/* 무제한 */}
                          {t(`Unlimited`)}
                          <br />
                          <span>
                            {/* 결제일 ~ 2025.2.28까지 */}
                            {t(`Payment date through...`)}
                            <br />
                            {/* 무제한 이용 */}
                            {t(`Unlimited access`)}
                          </span>
                        </p>
                      )}
                      {i === 0 && (
                        <p>
                          {/* AI 쫑알이 <br />
                          사용불가 */}
                          {t(`AI Comment is not available`)}
                        </p>
                      )}
                    </div>

                    <div className="plan-btn-wrapper">
                      {item.durations.map((term: any, index: number) => (
                        <button
                          disabled={
                            paymentInfo.subType === "학교(법인) 카드로 결제" && term.disable
                          }
                          onClick={() => handleSelectPlan(item, term)}
                          key={index}
                          className={`${
                            (paymentInfo.subType === "학교(법인) 카드로 결제" && term.disable) ||
                            term.label === "무료"
                              ? "plan-btn-disable"
                              : `plan-btn ${item.color}`
                          }
                        ${paymentInfo.plan === item.plan && item.color}

                        ${
                          paymentInfo.plan === item.plan && term.duration === paymentInfo.duration
                            ? `border-focus`
                            : ""
                        }`}
                        >
                          {term.label} {i !== 0 ? `${currency(term.price)}${t(`Won`)}` : ``}{" "}
                        </button>
                      ))}
                    </div>
                  </div>
                </div>
              )}

              {(i === 2 || i === 3) && (
                <div className="col-md-3 position-r">
                  <div
                    className={`${i === 2 && "benefit-overlay2"} ${i === 3 && "benefit-overlay3"}`}
                  >
                    <p className="font-600 font-18" style={{ color: "red" }}>
                      {/* 특별 혜택 */}
                      {t(`Special Offer`)}
                    </p>
                    <p className="font-600 font-16 mt-20" style={{ color: "#fff" }}>
                      {/* 2025월 2월 28일까지는 <br />
                      저장용량에 관계 없이, */}
                      {t(`Until February 28 2025...`)}
                      <br />
                      {/* 모두  */}
                      {t(`All`)}{" "}
                      <span className="font-basic font-18">
                        {/* 베이직 가격 */}
                        {t(`Basic plan users will...`)}{" "}
                      </span>
                      {/* 에 이용하실 수 있습니다! */}
                      {t(`Standard and Standard Plus...`)}
                    </p>
                  </div>
                  <div className={`${paymentInfo.plan === item.plan ? item.color : ""} plan-box`}>
                    <div className={`${item.color}`}>
                      <h2
                        style={{ padding: "4px 0" }}
                        className={`${item.plan === "비기너" && "white"} margin-0`}
                      >
                        {item.plan}
                      </h2>
                    </div>

                    <div className="mt-10 mb-10 plan-desc">
                      <p>{item.desc}</p>
                    </div>

                    <div className="plan-btn-wrapper">
                      {item.durations.map((term: any, index: number) => (
                        <button
                          disabled={
                            paymentInfo.subType === "학교(법인) 카드로 결제" && term.disable
                          }
                          onClick={() => handleSelectPlan(item, term)}
                          key={index}
                          className={`${
                            (paymentInfo.subType === "학교(법인) 카드로 결제" && term.disable) ||
                            term.label === "무료"
                              ? "plan-btn-disable"
                              : `plan-btn ${item.color}`
                          }
                        ${paymentInfo.plan === item.plan && item.color}

                        ${
                          paymentInfo.plan === item.plan && term.duration === paymentInfo.duration
                            ? `border-focus`
                            : ""
                        }`}
                        >
                          {term.label} {`${currency(term.price)}${t(`Won`)}`}
                        </button>
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </React.Fragment>
          ))}
        </div>

        <div className="col-md-12 mt-40">
          <div className="col-md-3"></div>
          <div className="col-md-6 text-left payform-pc">
            <div style={{ border: "1px solid #ececec", padding: 20, fontSize: 18 }}>
              <div className="display-f justify-between">
                {/* 상품명{" "} */}
                {t(`Product Name`)}
                <p className="text-right">
                  {/* 2024학년도 다했니 이용권 */}
                  {t(`2024 Academic Year 다했니 membership`)}
                  {/* 다했니 {paymentInfo.plan} {paymentInfo.label} 이용권 */}
                </p>
              </div>
              <div className="display-f justify-between mt-10">
                {/* 이용 기간{" "} */}
                {t(`Term of use`)}
                <p className="text-right">
                  {/* 결제일 ~  */}
                  {t(`Payment Date`)} ~<span className="color-red">2025.2.28.</span>
                </p>
              </div>
              <div className="display-f justify-between mt-10">
                {/* 결제 금액(단가){" "} */}
                {t(`Payment amount unit price`)}
                <p className="text-right">
                  {currency(paymentInfo.price)}
                  {/* 원 */}
                  {t(`Won`)}
                  <span style={{ fontSize: 12 }}>
                    {/* (부가세포함) */}({t(`VAT included`)})
                  </span>
                </p>
              </div>
              <div className="display-f justify-between mt-10">
                {/* 상품 수량  */}
                {t(`Quantity of products`)}
                <p className="text-right">
                  1{/* 개 TODO:: 고유번역*/}
                  {localStorage.getItem("language") === "ko" ? "개" : ""}
                </p>
              </div>
              <div className="display-f justify-between mt-10">
                {/* 총 결제 금액{" "} */}
                {t(`Total payment amount`)}
                <p className="text-right color-red">
                  {currency(paymentInfo.price)}
                  {/* 원 */}
                  {t(`Won`)}
                  <span style={{ fontSize: 12 }}>
                    {/* (부가세포함) */}({t(`VAT included`)})
                  </span>
                </p>
              </div>
              <div className="display-f justify-between align-center mt-10">
                <div>
                  {/* 선생님 본인 성함 */}
                  {t(`Teacher's full name`)}
                  <p className="font-12">
                    {/* (결제자 식별용) */}({t(`For the purpose of payer identification`)})
                  </p>
                </div>{" "}
                <input
                  value={privateForm.lead}
                  onChange={(e: any) => handleChangeValue("lead", e.target.value)}
                  className="pay-input"
                />
              </div>
              <div className="display-f justify-between align-center mt-10">
                <div>
                  {/* 선생님 본인 연락처 */}
                  {t(`Teacher's contact information`)}
                  <p className="font-12">
                    {/* (결제자 식별용) */}({t(`For the purpose of payer identification`)})
                  </p>
                </div>
                <input
                  value={privateForm.leaderPhone}
                  onChange={(e: any) => handleChangeValue("leaderPhone", e.target.value)}
                  className="pay-input"
                />
              </div>
              {paymentInfo.subType !== "개인 카드로 결제" && (
                <div className="display-f justify-between align-center mt-10 color-blue">
                  <div>
                    {/* 학교(법인)명 */}
                    {t(`School legal entity name`)}
                    {/* <p className="font-12">(행정실/실무사 또는 결제하는 본인)</p> */}
                  </div>
                  <input
                    value={privateForm.payerName}
                    onChange={(e: any) => handleChangeValue("payerName", e.target.value)}
                    className="pay-input"
                  />
                </div>
              )}
              {paymentInfo.subType !== "개인 카드로 결제" && (
                <div className="display-f justify-between align-center mt-10 color-blue">
                  <div>
                    {/* 결제자 이메일 주소 */}
                    {t(`Payer Email Address`)}
                    <p className="font-12">
                      {/* (행정실/실무사 또는 결제하는 본인) */}(
                      {t(`Account manager or Person Responsible for Payment`)})
                    </p>
                  </div>
                  <input
                    // placeholder="전자 결제창을 받을 이메일 주소"
                    placeholder={t(`Email address for receiving the electronic payment link`)}
                    value={privateForm.payerEmail}
                    onChange={(e: any) => handleChangeValue("payerEmail", e.target.value)}
                    className="pay-input"
                  />
                </div>
              )}
            </div>
          </div>
          <div className="col-md-3"></div>
        </div>

        <div className="col-md-12 mt-10 mb-40">
          <div className="col-md-3"></div>
          <div className="col-md-6 text-left payform-pc">
            <div className="display-f ">
              <div
                onClick={() => setAgreementModal(true)}
                className="check-box mr-10 display-f justify-center align-center"
              >
                {agreementCheck && <p className="font-12">✔️</p>}
              </div>
              <p>
                {/* 서비스 이용약관 동의 */}
                {t(`Agree to terms of use`)}
              </p>
            </div>
            <div className="display-f mt-4">
              <div
                onClick={() => setAgreementModal2(true)}
                className="check-box mr-10 display-f justify-center align-center"
              >
                {agreementCheck2 && <p className="font-12">✔️</p>}
              </div>
              <p>
                {/* 환불 정책 동의 */}
                {t(`Accept the refund policy`)}
              </p>
            </div>

            {paymentInfo.subType === "학교(법인) 카드로 결제" && (
              <button onClick={handlePostEmail} className="mt-10 pay-btn">
                <p>
                  {/* 결제 요청 메일 전송하기 */}
                  {t(`Sending a payment request email`)}
                </p>
                <p style={{ fontSize: 14, fontWeight: 400 }}>
                  {/* 행정실/실무사님의 메일로 결제창이 전송됩니다. */}
                  {t(`The payment link will be sent to...`)}
                </p>
              </button>
            )}
            {paymentInfo.subType === "개인 카드로 결제" && (
              <button onClick={handlePay} className="mt-10 pay-btn">
                <p>
                  {/* 결제하기 */}
                  {t(`Proceed with payment`)}
                </p>
              </button>
            )}
          </div>
          <div className="col-md-3"></div>
        </div>
      </div>
    </div>
  );
};

export default PayPlan;

import React, {
  useState,
  useEffect,
  useContext,
  useLayoutEffect,
  useRef,
  ChangeEvent,
} from "react";
import { MainContext } from "../common/Context";
import { IProject, IMainContext } from "../interface/Interface";
import { Link, NavLink, Route, useHistory, useParams } from "react-router-dom";
import {
  deleteRecordTag,
  deleteStackRecords,
  deleteStackRecords2,
  getCategorys,
  getEachProjectInfo,
  getEachStudentInfo,
  getRecordTags,
  getReportProjects,
  getStackRecords,
  getStudentChecklistReport,
  getStudentReportProjects,
  getStudents,
  getStudentStackRecords,
  modifyStackRecord,
  postNotification,
  postNotificationAll,
  postRecordTags,
  postStackRecord,
  putCancelCheckCos,
  putCheckCos,
  putConfirmProject,
  putModifyCos,
  putRejectProject,
} from "../common/Action";
import cookie1 from "../images/cookie1.png";
import cookie1S from "../images/cookie1S.png";
import cookie2S from "../images/cookie2S.png";
import cookie3S from "../images/cookie3S.png";
import cookie1G from "../images/cookie1G.png";
import cookie2G from "../images/cookie2G.png";
import cookie3G from "../images/cookie3G.png";
import file_icon from "../images/file_icon.png";
import check from "../images/check2.png";
import hide from "../images/hide.png";
import numbers from "../images/numbers.png";
import calendar from "../images/calendar.png";
import excel from "../images/excel.png";
import search from "../images/search.png";
import down from "../images/down_b.png";
import Modal from "../component/Modal";

import {
  getChecklistInitForS,
  initProjectForS,
  sortedImageFileArr,
  time1,
  time3,
  timeFormat10,
  timeFormat11,
} from "../common/Method";
import CheckList from "./CheckList";
import PopupModal from "../component/PopupModal";
import * as xlsx from "xlsx";
import PopupAlbum from "../component/PopupAlbum";
import { ppid } from "process";
import ka from "date-fns/locale/ka";
import OutsideClickHandler from "react-outside-click-handler";
import { APDateRangePicker } from "../component/DateRangePicker";
import moment from "moment";
import { t } from "i18next";

const StudentReport: React.FC = () => {
  const context = useContext<IMainContext>(MainContext);
  const params: any = useParams();
  const [tab, setTab] = useState<string>("누가기록");
  const [projectReports, setProjectReports] = useState<any>([]);
  const [originalProjectReports, setOriginalProjectReports] = useState<any>([]);
  const [students, setStudents] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [stackRecords, setStackRecords] = useState<any>([]);
  const [stackRecordsCopy, setStackRecordsCopy] = useState<any>([]);
  const [tagEdit, setTagEdit] = useState<boolean>(false);
  const [dateRange, setDateRange] = useState<any>({
    startDate: new Date(),
    dueDate: new Date(),
  });
  const [aStudentInfo, setAStudentInfo] = useState<any>({});
  const [checklistData, setChecklistData] = useState<any>([]);
  const [originChecklistData, setOriginChecklistData] = useState<any>([]);
  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);

  useEffect(() => {
    if (params.type === "tab1") {
      setTab("누가기록");
    }
    if (params.type === "tab2") {
      setTab("과제");
    }
    if (params.type === "tab3") {
      setTab("체크리스트");
    }
  }, []);

  useLayoutEffect(() => {
    if (tab === "누가기록") {
      init();
    }
    if (tab === "과제") {
      initProjects();
    }
    if (tab === "체크리스트") {
      initChecklists();
    }
  }, [tab]);

  const init = async () => {
    const studentInfo: any = await getEachStudentInfo(params.studentId);
    setAStudentInfo(studentInfo[0]);
    const aStudentRecords: any = await getStudentStackRecords(params.classId, params.studentId);

    if (aStudentRecords?.records.length !== 0) {
      const sorted: any = aStudentRecords?.records.sort(
        (a: any, b: any) => b.reportDate - a.reportDate,
      );
      setStackRecords(sorted);
      setStackRecordsCopy(sorted);
      setDateRange(() => {
        return {
          dueDate: sorted[0]?.reportDate,
          startDate: sorted[sorted.length - 1]?.reportDate,
        };
      });
      setStartDate(moment(sorted[sorted.length - 1]?.reportDate));
      setEndDate(moment(sorted[0]?.reportDate));
    }
  };

  const initProjects = async () => {
    const aStudentProjectsResult: any = await getStudentReportProjects(
      params.classId,
      params.studentId,
    );
    setProjectReports(aStudentProjectsResult?.projects);
    setOriginalProjectReports(aStudentProjectsResult?.projects);
  };

  const initChecklists = async () => {
    const aStudentChecklistResult: any = await getStudentChecklistReport(
      params.classId,
      params.studentId,
    );

    setChecklistData(aStudentChecklistResult);
    setOriginChecklistData(aStudentChecklistResult.cos);
  };

  const handleDownloadExcelFile = async () => {
    const recordTags: any = await getRecordTags(params.classId);
    const tempStackRecords: any = [...stackRecords];
    for (let i = 0; i < stackRecords.length; i++) {
      const tempTags = [];
      for (let k = 0; k < stackRecords[i].tags.length; k++) {
        tempTags.push(recordTags[0]?.tags[stackRecords[i].tags[k]].tagName);
      }
      tempStackRecords[i].tagsName = tempTags;
    }

    for (let i = 0; i < stackRecords.length; i++) {
      let studentsNameToString = "";
      let tagsNameToString = "";
      studentsNameToString = stackRecords[i].studentsName.reduce(
        (previousValue: any, currentValue: any, currentIdx: number) =>
          `${previousValue}${currentIdx === 0 ? "" : ","} ${currentValue}`,
        "",
      );
      tagsNameToString = stackRecords[i].tagsName.reduce(
        (previousValue: any, currentValue: any, currentIdx: number) =>
          `${previousValue}${currentIdx === 0 ? "" : ","} ${currentValue}`,
        "",
      );

      tempStackRecords[i].tagsNameToString = tagsNameToString;
      tempStackRecords[i].studentNameToString = studentsNameToString;
    }

    const arr = [];
    for (let i in stackRecords) {
      arr.push({
        date: time3(stackRecords[i].reportDate),
        tags: stackRecords[i].tagsNameToString,
        students: stackRecords[i].studentNameToString,
        cookies: stackRecords[i].increaseCookie,
        body: stackRecords[i].reportBody,
      });
    }
    const ws = xlsx.utils.json_to_sheet(arr);
    const wb = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, "Sheet1");
    ["날짜", "분류", "대상", "쿠키 수", "내용"].forEach((x, idx) => {
      const cellAdd = xlsx.utils.encode_cell({ c: idx, r: 0 });
      ws[cellAdd].v = x;
    });
    xlsx.writeFile(wb, `누가기록(${context.class}).xlsx`);
  };

  return (
    <>
      <div className="col-md-12 category_container">
        <div className="display-f justify-between">
          <div>
            <div className="display-f align-center justify-between position-r">
              <div className="display-f">
                <p className="font-23 font-700 mr-10">
                  {aStudentInfo.name}({aStudentInfo?.num}) {t(`Report`)}
                </p>
              </div>
            </div>
            <p className="card-text" style={{ color: "#979797" }}>
              {/* * 모든 누가기록/과제/체크리스트 내용은 이곳에서 클릭을 하여 내용을 수정할 수 있습니다. */}
              {t(`All anecdotal notes assignments and checklists...`)}
            </p>
          </div>

          {params.type === "tab1" && (
            <button
              onClick={handleDownloadExcelFile}
              style={{ border: "1px solid #232323", color: "#232323" }}
              className="student_code_upload_btn display-f align-center"
            >
              <img className="mr-5 excel_img" src={excel} />
              {/* 엑셀로 내려받기 */}
              {t(`Download to Excel`)}
            </button>
          )}
        </div>

        <section className="mt-30 display-f" style={{ alignItems: "flex-end" }}>
          <Link to={`/page/studentreport/${params.classId}/${params.studentId}/tab1`}>
            <button
              onClick={() => setTab("누가기록")}
              style={{ backgroundColor: tab === "누가기록" ? "#f48273" : "" }}
              className={`report_tab mr-5 ${tab === "누가기록" && "report_select"}`}
            >
              {/* 누가 기록 */}
              <p>{t(`Anecdotal Record`)}</p>
            </button>
          </Link>

          <Link to={`/page/studentreport/${params.classId}/${params.studentId}/tab2`}>
            <button
              style={{ backgroundColor: tab === "과제" ? "#F7CC6C" : "" }}
              onClick={() => setTab("과제")}
              className={`report_tab mr-5 ${tab === "과제" && "report_select"}`}
            >
              <p>
                {/* 과제 */}
                {t(`Assignment`)}
              </p>
            </button>
          </Link>

          <Link to={`/page/studentreport/${params.classId}/${params.studentId}/tab3`}>
            <button
              style={{ backgroundColor: tab === "체크리스트" ? "#52905d" : "" }}
              onClick={() => setTab("체크리스트")}
              className={`report_tab mr-5 ${tab === "체크리스트" && "report_select"}`}
            >
              {/* 체크리스트 */}
              <p>{t(`Checklist`)}</p>
            </button>
          </Link>
        </section>

        <div className="report_body position-r">
          {tab === "누가기록" && (
            <StackReport
              init={init}
              stackRecordsCopy={stackRecordsCopy}
              stackRecords={stackRecords}
              setStackRecords={setStackRecords}
              classId={params.classId}
              dateRange={dateRange}
              setDateRange={setDateRange}
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              aStudentInfo={aStudentInfo}
            />
          )}
          {tab === "과제" && (
            <Project
              initProjects={initProjects}
              classId={params.classId}
              studentId={params.studentId}
              originalProjectReports={originalProjectReports}
              setOriginalProjectReports={setOriginalProjectReports}
              projectReports={projectReports}
              setProjectReports={setProjectReports}
              students={students}
              studentInfo={aStudentInfo}
            />
          )}
          {tab === "체크리스트" && (
            <Checklist
              checklistData={checklistData}
              setChecklistData={setChecklistData}
              originChecklistData={originChecklistData}
              setOriginChecklistData={setOriginChecklistData}
              init={initChecklists}
              classId={params.classId}
              studentId={params.studentId}
            />
          )}
        </div>
      </div>
    </>
  );
};

const StackReport = (props: any) => {
  const context = useContext<IMainContext>(MainContext);
  const [deleteMode, setDeleteMode] = useState<boolean>(false);
  const [allCheck, setAllCheck] = useState<boolean>(false);
  const [modal, setModal] = useState<boolean>(false);
  const [tagModal, setTagModal] = useState<boolean>(false);
  const [cookieSelected, setCookieSelected] = useState<number>(0);
  const [stackPopup, setStackPopup] = useState<any>({
    classCookies: false,
    allStudentCheck: false,
  });
  const [students, setStudents] = useState<any>([]);
  const [dateSelected, setDateSelected] = useState<any>(new Date());
  const [reportBody, setReportBody] = useState<string>("");
  const [editId, setEditId] = useState<string>("");
  const [create, setCreate] = useState<boolean>(false);
  const [tags, setTags] = useState<any>([{}, {}, {}]);
  const [tag, setTag] = useState<string>("");
  const [index, setIndex] = useState<number>(-1);
  const [tagEdit, setTagEdit] = useState<boolean>(false);
  const [searchInput, setSearchInput] = useState<string>("");
  const [tagsFilterData, setTagsFilterData] = useState<any>([]);
  const [timeRangeData, setTimeRangeData] = useState<any>([]);
  const [filterOn, setFilterOn] = useState<any>({
    dateFilter: false,
    // tagFilter: false,
    searchFilter: false,
  });
  const [moved, setMoved] = useState<boolean>(false);
  const [movedNext, setMovedNext] = useState<boolean>(false);

  const [stackForCookie, setStackForCookie] = useState<boolean>(false);
  const [stackForCookieUse, setStackForCookieUse] = useState<boolean>(false);

  const [useCookie, setUseCookie] = useState<boolean>(false);
  const [addMode, setAddMode] = useState<boolean>(false);

  const [stackInfo, setStackInfo] = useState<any>({});

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    const students: any = await getStudents(props.classId);
    const recordTags: any = await getRecordTags(props.classId);
    if (recordTags.length !== 0) {
      setTags(recordTags[0]?.tags);
    }
    setTagsFilterData(props.stackRecords);
    setStudents(students);
  };

  // 조회기간
  const onChangeFilterDate = (e: any, type: string) => {
    setFilterOn((prev: any) => {
      return {
        ...prev,
        dateFilter: true,
      };
    });
    const getTimestamp = new Date(e.target.value).getTime();
    const filterData = tags.filter((el: any) => el.check);
    let startDate;
    let dueDate;
    if (type === "startDate") {
      startDate = getTimestamp;
      dueDate = new Date(props.dateRange?.dueDate).getTime();
    }
    if (type === "dueDate") {
      startDate = new Date(props.dateRange.startDate).getTime();
      dueDate = getTimestamp;
    }

    if (Number(startDate) > Number(dueDate)) {
      // return alert("조회 기간을 정확히 설정해 주세요.");
      return alert(t(`Specify the exact time range for the lookup`));
    }

    props.setDateRange((prev: any) => {
      return {
        ...prev,
        [type]: new Date(e.target.value).getTime(),
      };
    });
  };

  const handleFilterTag = (i: number) => {
    let tempTags = [...tags];
    tempTags[i].check = !tags[i].check;

    setTags(tempTags);
  };

  useEffect(() => {
    filterHandler();
    return () => {
      filterHandler();
    };
  }, [props.startDate, props.endDate, searchInput, tags, stackForCookie, stackForCookieUse]);

  const filterHandler = () => {
    let originArr: any = [...props.stackRecordsCopy];

    const aMilliseconds = moment(props.startDate).hour(0).minute(0).seconds(0).milliseconds(0);
    const bMilliseconds = moment(props.endDate).hour(24).minute(0).seconds(0).milliseconds(0);

    let tempArr: any = [];
    for (let i = 0; i < originArr?.length; i++) {
      if (
        Number(moment(originArr[i].reportDate).valueOf()) >= moment(aMilliseconds).valueOf() &&
        Number(originArr[i].reportDate.valueOf()) <= moment(bMilliseconds).valueOf()
      ) {
        tempArr.push(originArr[i]);
      }
    }
    originArr = [...tempArr];

    let tagFilter: any[] = tags.filter((el: any) => el.check);

    let filterArr: any = new Set();

    if (tagFilter.length === 0 && stackForCookie) {
      const flatTags: any = [];

      for (let i = 0; i < originArr.length; i++) {
        if (
          stackForCookieUse &&
          originArr[i].increaseCookie !== 0 &&
          originArr[i].tags.length === 0
        ) {
          filterArr.add(originArr[i]);
        }
        if (
          !stackForCookieUse &&
          originArr[i].increaseCookie > 0 &&
          originArr[i].tags.length === 0
        ) {
          filterArr.add(originArr[i]);
        }
      }

      originArr = [...filterArr];
    }
    if (tagFilter.length === 0 && !stackForCookie) {
      const flatTags: any = [];

      for (let i = 0; i < originArr.length; i++) {
        if (
          stackForCookieUse &&
          originArr[i].increaseCookie < 0 &&
          originArr[i].tags.length === 0
        ) {
          filterArr.add(originArr[i]);
        }
        if (!stackForCookieUse && originArr[i].tags.length === 0) {
          filterArr.add(originArr[i]);
        }
      }

      originArr = [...filterArr];
    }

    if (tagFilter.length !== 0 && stackForCookie) {
      const flatTags: any = [];

      for (let i = 0; i < originArr.length; i++) {
        if (
          stackForCookieUse &&
          originArr[i].increaseCookie !== 0 &&
          originArr[i].tags.length === 0
        ) {
          filterArr.add(originArr[i]);
        }
        if (
          !stackForCookieUse &&
          originArr[i].increaseCookie > 0 &&
          originArr[i].tags.length === 0
        ) {
          filterArr.add(originArr[i]);
        }
      }

      for (let i = 0; i < originArr?.length; i++) {
        for (let k = 0; k < originArr[i]?.tags?.length; k++) {
          flatTags.push({
            i: i,
            tagName: tags[originArr[i].tags[k]]?.tagName,
          });
        }
      }

      for (let i = 0; i < flatTags?.length; i++) {
        for (let k = 0; k < tagFilter?.length; k++) {
          if (flatTags[i]?.tagName === tagFilter[k]?.tagName) {
            filterArr.add(originArr[flatTags[i]?.i]);
          }
        }
      }

      originArr = [...filterArr];
    }

    if (tagFilter.length !== 0 && !stackForCookie) {
      const flatTags: any = [];

      for (let i = 0; i < originArr.length; i++) {
        if (
          stackForCookieUse &&
          originArr[i].increaseCookie < 0 &&
          originArr[i].tags.length === 0
        ) {
          filterArr.add(originArr[i]);
        }
        if (
          !stackForCookieUse &&
          originArr[i].increaseCookie !== 0 &&
          originArr[i].tags.length === 0
        ) {
          filterArr.add(originArr[i]);
        }
      }

      for (let i = 0; i < originArr?.length; i++) {
        for (let k = 0; k < originArr[i]?.tags?.length; k++) {
          flatTags.push({
            i: i,
            tagName: tags[originArr[i].tags[k]]?.tagName,
          });
        }
      }

      for (let i = 0; i < flatTags?.length; i++) {
        for (let k = 0; k < tagFilter?.length; k++) {
          if (flatTags[i]?.tagName === tagFilter[k]?.tagName) {
            filterArr.add(originArr[flatTags[i]?.i]);
          }
        }
      }

      originArr = [...filterArr];
    }

    if (searchInput !== "") {
      let tempArr: any = [];
      for (let i = 0; i < originArr?.length; i++) {
        for (let k = 0; k < originArr[i]?.studentsName?.length; k++) {
          if (originArr[i]?.studentsName[k]?.includes(searchInput)) {
            tempArr.push(originArr[i]);
          }
        }
      }
      props.setStackRecords(tempArr);
    } else {
      props.setStackRecords(originArr);
    }
  };

  const handleSelectClassCookies = () => {
    let tempStudent = [...students];
    for (let i in students) {
      students[i].stackCheck = false;
    }

    setStudents(tempStudent);
    setStackPopup((prev: any) => {
      return {
        allStudentCheck: false,
        classCookies: !prev.classCookies,
      };
    });
  };

  const onAddReportBtn = () => {
    let tempStudents = [...students];
    let tempTags = [...tags];
    for (let i in tempStudents) {
      tempStudents[i].stackCheck = false;
      if (props.aStudentInfo.code === tempStudents[i].code) {
        tempStudents[i].stackCheck = true;
      }
    }
    for (let i in tempTags) {
      tempTags[i].tagSelected = false;
    }

    setReportBody("");
    setStackPopup((prev: any) => {
      return {
        classCookies: false,
        allStudentCheck: false,
      };
    });
    setTags(tempTags);
    setDateSelected(new Date());
    setStudents(tempStudents);
    setCreate(true);
    setModal(true);
  };

  const handleCheckRecords = (stack: any, i: number) => {
    // 삭제시
    if (deleteMode) {
      let tempArr = [...props.stackRecords];
      tempArr[i].checked = !props.stackRecords[i].checked;
      const tempArrCheckedLength = tempArr.filter((item: any) => item.checked).length;
      if (tempArrCheckedLength === tempArr.length) setAllCheck(true);
      else setAllCheck(false);
      props.setStackRecords(tempArr);
    } else {
      // 수정시
      let tempStudents = [...students];
      for (let i in tempStudents) {
        tempStudents[i].stackCheck = false;
        for (let k in stack.studentsCode) {
          if (students[i].code === stack.studentsCode[k]) {
            tempStudents[i].stackCheck = true;
          }
        }
      }

      let tempTags = [...tags];
      for (let i in tempTags) {
        tempTags[i].tagSelected = false;
      }

      for (let i = 0; i < stack?.tags?.length; i++) {
        for (let k = 0; k < tempTags?.length; k++) {
          if (stack.tags[i] === k) {
            tempTags[stack.tags[i]].tagSelected = true;
          }
        }
      }

      if (stack.studentsCode.length === 0) {
        setStackPopup((prev: any) => {
          return {
            ...prev,
            classCookies: true,
          };
        });
      } else {
        setStackPopup((prev: any) => {
          return {
            ...prev,
            classCookies: false,
          };
        });
      }
      if (stack.studentsCode.length !== students.length) {
        setStackPopup((prev: any) => {
          return {
            ...prev,
            allStudentCheck: false,
          };
        });
      }
      if (stack.studentsCode.length === students.length) {
        setStackPopup((prev: any) => {
          return {
            ...prev,
            allStudentCheck: true,
          };
        });
      }

      // ==================새로 추가====================
      // stack.type==="use cookie"일 경우 쓴 쿠키 팝업 열기
      // =============================================

      if (stack.type === "useCookies") {
        setUseCookie(true);
      } else {
        setModal(true);
      }

      setTags(tempTags);
      setEditId(stack._id);
      setCreate(false);
      setDateSelected(time3(stack.reportDate));
      setCookieSelected(stack.increaseCookie);
      setStudents(tempStudents);
      setReportBody(stack.reportBody);
      setStackInfo(stack);
    }
  };

  const handleAllCheckReport = () => {
    let tempStudents = [...students];
    if (!stackPopup.allStudentCheck) {
      for (let i in tempStudents) {
        tempStudents[i].stackCheck = true;
      }
    } else {
      for (let i in tempStudents) {
        tempStudents[i].stackCheck = false;
      }
    }

    setStackPopup((prev: any) => {
      return {
        classCookies: prev.allStudentCheck ? true : false,
        allStudentCheck: !prev.allStudentCheck,
      };
    });
    setStudents(tempStudents);
  };

  const handleStudentCheck = (student: any, i: number) => {
    if (useCookie) return;
    const tempStudents = [...students];
    tempStudents[i].stackCheck = !students[i].stackCheck;
    const checkedList = tempStudents.filter((el) => el.stackCheck);
    if (checkedList.length === 0) {
      setStackPopup((prev: any) => {
        return {
          ...prev,
          classCookies: true,
        };
      });
    } else {
      setStackPopup((prev: any) => {
        return {
          ...prev,
          classCookies: false,
        };
      });
    }

    if (checkedList.length === tempStudents.length) {
      setStackPopup((prev: any) => {
        return {
          ...prev,
          allStudentCheck: true,
        };
      });
    } else {
      setStackPopup((prev: any) => {
        return {
          ...prev,
          allStudentCheck: false,
        };
      });
    }

    setStudents(tempStudents);
  };

  const onChangeCookie = (e: any) => {
    const { value }: any = e.target;

    const numParse = parseInt(e.nativeEvent.data);
    if (e.nativeEvent.data === null) {
      setCookieSelected(value);
    }

    if (!isNaN(numParse)) {
      setCookieSelected(value);
    }
  };

  const handleCheckAll = () => {
    let tempArr = [...props.stackRecords];
    for (let i in tempArr) {
      tempArr[i].checked = allCheck ? false : true;
    }
    props.setStackRecords(tempArr);
    setAllCheck((prev: boolean) => !prev);
  };

  const handleSubmitReport = async () => {
    const tempStudents = [];
    for (let i in students) {
      if (students[i].stackCheck) {
        tempStudents.push(students[i].code);
      }
    }
    if (!stackPopup.classCookies && tempStudents.length === 0) {
      return alert("대상을 선택해 주세요!");
    }

    let tempTags = [];
    for (let i = 0; i < tags?.length; i++) {
      if (tags[i]?.tagName && tags[i].tagSelected) {
        tempTags.push(i);
      }
    }

    const postResult = await postStackRecord({
      studentsCode: tempStudents,
      body: {
        reportBody,
        tags: tempTags,
        classId: props.classId,
        reportDate: dateSelected === "" ? new Date().getTime() : new Date(dateSelected).getTime(),
        increaseCookie: cookieSelected ? Number(cookieSelected) : 0,
        studentsCode: tempStudents,
      },
      increaseCookie: cookieSelected ? Number(cookieSelected) : 0,
    });

    const dataResult = postResult;
    const studentsArr = Object.keys(dataResult.students);

    // dataresult badge 정보 받아서 푸시알림 보내기
    const tempPushArr: any = [];

    for (let i = 0; i < studentsArr.length; i++) {
      for (let k = 0; k < dataResult.students[studentsArr[i]].badgeList.length; k++) {
        tempPushArr.push({
          token: dataResult.students[studentsArr[i]].deviceToken2,
          notification: {
            title: `🎖${
              dataResult.badge.badges[dataResult.students[studentsArr[i]].badgeList[k]].title
            } ${t(`Badge`)}🎖`, // 뱃지
            body: t(`Congratulations Check it out`), // `축하합니다! 확인해보세요!`,
          },
          data: {
            code: dataResult.students[studentsArr[i]].code,
            type: "project",
          },
        });
      }
    }
    postNotificationAll(tempPushArr);

    setModal(false);
    context.handleStateChange("class", "");
    props.init();
  };

  const handleEditReport = async (): Promise<void> => {
    const tempStudents = [];
    for (let i in students) {
      if (students[i].stackCheck) {
        tempStudents.push(students[i].code);
      }
    }

    let tempTags = [];
    for (let i = 0; i < tags?.length; i++) {
      if (tags[i]?.tagName && tags[i].tagSelected) {
        tempTags.push(i);
      }
    }

    const postResult: any = await modifyStackRecord({
      studentsCode: tempStudents,
      _id: editId,
      body: {
        reportBody,
        tags: tempTags,
        classId: props.classId,
        reportDate: new Date(dateSelected).getTime(),
        increaseCookie: cookieSelected ? cookieSelected : 0,
        studentsCode: tempStudents,
      },
      increaseCookie: cookieSelected,
    });

    const dataResult = postResult;
    const studentsArr = Object.keys(dataResult.students);

    // dataresult badge 정보 받아서 푸시알림 보내기
    const tempPushArr: any = [];

    for (let i = 0; i < studentsArr.length; i++) {
      for (let k = 0; k < dataResult.students[studentsArr[i]].badgeList.length; k++) {
        tempPushArr.push({
          token: dataResult.students[studentsArr[i]].deviceToken2,
          notification: {
            title: `🎖${
              dataResult.badge.badges[dataResult.students[studentsArr[i]].badgeList[k]].title
            } ${t(`Badge`)}🎖`, // 뱃지
            body: t(`Congratulations Check it out`), // `축하합니다! 확인해보세요!`,
          },
          data: {
            code: dataResult.students[studentsArr[i]].code,
            type: "project",
          },
        });
      }
    }
    postNotificationAll(tempPushArr);

    context.handleStateChange("class", "");
    props.init();
    setModal(false);
    setUseCookie(false);
  };

  const handleDeleteStackRecord = async () => {
    const idList = [];
    const idList2 = [];

    const deleteArr = props.stackRecords.filter((item: any) => item.checked);
    const deleteCookieArr = props.stackRecords.filter(
      (item: any) => item.checked && item.increaseCookie < 0,
    );

    for (let i in deleteArr) {
      idList.push(deleteArr[i]._id);
    }
    for (let i in deleteCookieArr) {
      idList2.push(deleteCookieArr[i]._id);
    }

    await deleteStackRecords({
      _ids: idList,
      classId: props.classId,
    });
    await deleteStackRecords2({
      _ids: idList2,
      classId: props.classId,
    });

    if (deleteArr.length === props.stackRecords.length) {
      props.setStackRecords([]);
    }

    setDeleteMode(false);
    context.handleStateChange("class", "");
    props.init();
  };

  const handleClickTag = (i: any) => {
    setIndex(i);
    setTagModal(true);
    setTag("");
  };

  const handleAddTag = async (): Promise<void> => {
    let tempTags = [];
    for (let i in tags) {
      tempTags.push({ tagName: tags[i].tagName });
    }
    tempTags[index].tagName = tag;

    const insertTags = await postRecordTags({
      classId: props.classId,
      tags: tempTags,
    });

    if (tagEdit && !tags[index]?.tagName) {
      setTagEdit(false);
    }
    setTags(tempTags);
    setTagModal(false);
    setTag("");
  };

  const handleTagSelect = (i: number) => {
    let tempTags = [...tags];
    tempTags[i].tagSelected = !tags[i].tagSelected;
    setTags(tempTags);
  };

  const handleEditTag = (i: number) => {
    setIndex(i);
    setTag(tags[i].tagName);
    setTagModal(true);
  };

  const handleDeleteTag = async () => {
    const confirm: any = window.confirm(
      // "해당 태그가 달린 모든 기록에서 이 태그가 완전히 삭제됩니다. 진행하시겠습니까?",
      t(`This action will completely remove the tag from all...`),
    );
    if (confirm) {
      const deleteResult: any = await deleteRecordTag({
        classId: props.classId,
        deleteTagIndex: index,
      });
    } else {
      return;
    }

    setTagEdit(false);
    setTagModal(false);
    init();
    props.init();
  };

  const downListener = () => {
    setMoved(false);
  };

  const moveListener = () => {
    setMoved(true);
  };

  return (
    <>
      {modal && (
        <Modal
          show={modal}
          onClose={() => {
            setCookieSelected(0);
            setModal(false);
            setAddMode(false);
          }}
        >
          <h1>{modal}</h1>
          <div
            className="stack_report_container"
            style={{ width: "38vw", height: "80vh", overflow: "auto", backgroundColor: "#fff" }}
          >
            <h2 className="text-center">{t(`Anecdotal Record`)}</h2>
            <section className="display-f">
              <div style={{ width: 90 }}>
                <p style={{ fontSize: 18 }} className="font-500">
                  {/* 대상 */}
                  {t(`To`)}
                </p>
              </div>
              <div style={{ width: "100%" }}>
                <div className="pl-20 display-f align-center">
                  <div className="select_stuent_c">
                    {stackPopup.classCookies && <div className="select_stuent_c_fill" />}
                  </div>
                  <p className="cursor" onClick={handleSelectClassCookies}>
                    {/* 우리반 쿠키함 */}
                    {t(`Class cookie jar`)}
                  </p>
                </div>
                <div className="mt-20">
                  <div className="pl-20 display-f align-center">
                    <div className="select_stuent_c">
                      {stackPopup.allStudentCheck && <div className="select_stuent_c_fill" />}
                    </div>
                    <p className="cursor" onClick={handleAllCheckReport}>
                      {/* 모두 해제 : 모두 선택 */}
                      {stackPopup.allStudentCheck ? t(`Deselect All`) : t(`Select All`)}
                    </p>
                  </div>

                  <div className="display-f flex-wrap students_wrapper">
                    {students.map((student: any, i: number) => (
                      <div key={i} className="select_student_list">
                        <div
                          onClick={() => handleStudentCheck(student, i)}
                          className="select_stuent_c"
                        >
                          {student.stackCheck && <div className="select_stuent_c_fill" />}
                        </div>
                        <p onClick={() => handleStudentCheck(student, i)} className="cursor">
                          {student.name}
                        </p>
                      </div>
                    ))}
                  </div>

                  <div
                    className="display-f align-center"
                    style={{
                      paddingLeft: 20,
                      paddingRight: 20,
                      flexWrap: "wrap",
                    }}
                  >
                    <div className="display-f align-center mr-15  mt-10" style={{ flex: 1 }}>
                      <p className="mr-10 font-500" style={{ width: 30 }}>
                        {/* 날짜 */}
                        {t(`Date`)}
                      </p>
                      <input
                        type="date"
                        value={`${time3(dateSelected)}`}
                        onChange={(e: any) => setDateSelected(e.target.value)}
                      />
                    </div>

                    <div className="display-f align-center mt-10" style={{ flex: 2 }}>
                      <p className="mr-10 font-500" style={{ width: 30 }}>
                        {/* 태그 */}
                        {t(`Tag`)}
                      </p>
                      <div
                        className="display-f align-center"
                        style={{ width: "calc(100% - 30px)", height: 28 }}
                      >
                        {!addMode && cookieSelected < 0 && (
                          <button
                            onClick={() => setUseCookie(true)}
                            className={`stack_tag_btn ${useCookie && "brown"}`}
                          >
                            <p className="font-10">
                              {/* 쿠키 쓰기 */}
                              🍪 {t("Spend cookies")}
                            </p>
                          </button>
                        )}
                        {tags?.map((tag: any, i: number) => (
                          <>
                            {tag.tagName && (
                              <button
                                onClick={() => handleTagSelect(i)}
                                key={i}
                                className={`stack_tag_btn ${tag.tagSelected && "blue white"}`}
                              >
                                {tag.tagName}
                              </button>
                            )}
                          </>
                        ))}
                        {tags.filter((el: any) => el.tagName).length === 0 && (
                          <p style={{ fontSize: 13, color: "#979797" }}>
                            {/* 태그가 없습니다. */}
                            {t(`No tags`)}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="display-f mt-30">
              <div style={{ width: 90 }}>
                <p style={{ fontSize: 18 }} className="font-500">
                  {/* 기록 */}
                  {t(`Description`)}
                </p>
              </div>

              <div style={{ width: "100%" }}>
                <div>
                  <div
                    className="display-f flex-wrap students_wrapper"
                    style={{ margin: "0 20px" }}
                  >
                    <textarea
                      value={reportBody}
                      onChange={(e: any) => setReportBody(e.target.value)}
                      className="report_textarea"
                    />
                  </div>
                </div>
              </div>
            </section>

            <section className="display-f mt-30">
              <div style={{ width: 90 }}>
                <p style={{ fontSize: 18 }} className="font-500">
                  {/* 쿠키 */}
                  {t(`Cookie`)}
                </p>
              </div>

              <div style={{ width: "100%" }}>
                <div>
                  <div className="display-f flex-wrap" style={{ margin: "0 20px" }}>
                    <div>
                      <div style={{ width: "100%" }}>
                        <div className="display-f align-center">
                          <button
                            onClick={() => setCookieSelected(1)}
                            className={`${
                              cookieSelected !== 1 && "cookie_border_none"
                            } cookie_circle cursor display-f-center mr-17`}
                          >
                            {cookieSelected === 1 ? (
                              <img className="cookie1" src={cookie1S} alt="cookie1S" />
                            ) : (
                              <img className="cookie1" src={cookie1G} alt="cookie1G" />
                            )}
                          </button>
                          <button
                            onClick={() => setCookieSelected(2)}
                            className={`${
                              cookieSelected !== 2 && "cookie_border_none"
                            } cookie_circle cursor display-f-center mr-17`}
                          >
                            {cookieSelected === 2 ? (
                              <img className="cookie2" src={cookie2S} alt="cookie2S" />
                            ) : (
                              <img className="cookie2" src={cookie2G} alt="cookie2G" />
                            )}
                          </button>
                          <button
                            onClick={() => setCookieSelected(3)}
                            className={`${
                              cookieSelected !== 3 && "cookie_border_none"
                            } cookie_circle cursor display-f-center mr-17`}
                          >
                            {cookieSelected === 3 ? (
                              <img className="cookie3" src={cookie3S} alt="cookie3S" />
                            ) : (
                              <img className="cookie3" src={cookie3G} alt="cookie3G" />
                            )}
                          </button>

                          <div>
                            <input
                              value={cookieSelected}
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                onChangeCookie(e)
                              }
                              className="add_cookie_input"
                            />
                            <span>
                              {/* 개 */}
                              {t(`pcs`)}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <div className="mt-30 mb-17 display-f justify-center align-center">
              {create && (
                <button
                  onClick={handleSubmitReport}
                  className="mr-10 stack_report_btn report_main_bg"
                >
                  저장
                </button>
              )}
              {!create && (
                <button
                  onClick={handleEditReport}
                  className="mr-10 stack_report_btn report_main_bg"
                >
                  수정
                </button>
              )}
              <button
                onClick={() => {
                  setCookieSelected(0);
                  setModal(false);
                }}
                className="report_cancel_bg stack_report_btn"
              >
                {t(`Cancel`)}
              </button>
            </div>
          </div>
        </Modal>
      )}
      {/* 쿠키쓰기 모달 새로 추가 */}
      {useCookie && (
        <Modal
          show={modal}
          onClose={() => {
            setCookieSelected(0);
            setUseCookie(false);
          }}
        >
          <h1>{modal}</h1>
          <div
            className="stack_report_container"
            style={{ width: "38vw", height: "80vh", overflow: "auto", backgroundColor: "#fff" }}
          >
            <h2 className="text-center">{t(`Spend cookies`)}</h2>
            <section className="display-f">
              <div style={{ width: 90 }}>
                <p style={{ fontSize: 18 }} className="font-500">
                  {/* 대상 */}
                  {t(`To`)}
                </p>
              </div>
              <div style={{ width: "100%" }}>
                <div className="mt-20">
                  <div className="display-f flex-wrap students_wrapper">
                    {students.map((student: any, i: number) => (
                      <div key={i} className="select_student_list">
                        <div
                          onClick={() => handleStudentCheck(student, i)}
                          className="select_stuent_c"
                        >
                          {student.stackCheck && <div className="select_stuent_c_fill" />}
                        </div>
                        <p onClick={() => handleStudentCheck(student, i)} className="cursor">
                          {student.name}
                        </p>
                      </div>
                    ))}
                  </div>

                  <div
                    className="display-f align-center"
                    style={{
                      paddingLeft: 20,
                      paddingRight: 20,
                      flexWrap: "wrap",
                    }}
                  >
                    <div className="display-f align-center mr-15  mt-10" style={{ flex: 1 }}>
                      <p className="mr-10 font-500" style={{ width: 30 }}>
                        {/* 날짜 */}
                        {t(`Date`)}
                      </p>
                      <input
                        type="date"
                        value={`${time3(dateSelected)}`}
                        onChange={(e: any) => setDateSelected(e.target.value)}
                      />
                    </div>

                    <div className="display-f align-center mt-10" style={{ flex: 2 }}>
                      <p className="mr-10 font-500" style={{ width: 30 }}>
                        {/* 태그 */}
                        {t(`Tag List`)}
                      </p>
                      <div
                        className="display-f align-center"
                        style={{ width: "calc(100% - 30px)", height: 28 }}
                      >
                        <button
                          onClick={() => setUseCookie(true)}
                          className={`stack_tag_btn ${useCookie && "brown"}`}
                        >
                          <p className="font-10">🍪 {t("Spend cookies")}</p>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="display-f mt-30">
              <div style={{ width: 90 }}>
                <p style={{ fontSize: 18 }} className="font-500">
                  {/* 기록 */}
                  {t(`Description`)}
                </p>
              </div>

              <div style={{ width: "100%" }}>
                <div>
                  <div
                    className="display-f flex-wrap students_wrapper"
                    style={{ margin: "0 20px" }}
                  >
                    <textarea
                      value={reportBody}
                      onChange={(e: any) => setReportBody(e.target.value)}
                      className="report_textarea"
                    />
                  </div>
                </div>
              </div>
            </section>

            <section className="display-f mt-30">
              <div style={{ width: 90 }}>
                <p style={{ fontSize: 18 }} className="font-500">
                  {/* 쿠키 */}
                  {t(`Used cookies`)}
                </p>
              </div>

              <div style={{ width: "100%" }}>
                <div>
                  <div className="display-f flex-wrap" style={{ margin: "0 20px" }}>
                    <div>
                      <div style={{ width: "100%" }}>
                        <div className="display-f align-center">
                          <div>
                            <input
                              readOnly={true}
                              value={stackInfo.useCookie ? -cookieSelected : cookieSelected}
                              // onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                              //   onChangeCookie(e)
                              // }
                              className="add_cookie_input"
                            />
                            <span>
                              {/* ~개 */}
                              {t(`pcs`)}
                            </span>
                            <p className="mt-4 text-red font-12">
                              {/* '쿠키 쓰기' 기록을 삭제하고 싶다면 '기록 삭제' 해주세요. */}
                              {t(`If you want to delete the spent cookies history...`)}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <div className="mt-30 mb-17 display-f justify-center align-center">
              {create && (
                <button
                  onClick={handleSubmitReport}
                  className="mr-10 stack_report_btn report_main_bg"
                >
                  {/* 저장 */}
                  {t(`Save`)}
                </button>
              )}
              {!create && (
                <button
                  onClick={handleEditReport}
                  className="mr-10 stack_report_btn report_main_bg"
                >
                  {/* 수정 */}
                  {t(`Edit`)}
                </button>
              )}
              <button
                onClick={() => {
                  setCookieSelected(0);
                  setUseCookie(false);
                }}
                className="report_cancel_bg stack_report_btn"
              >
                {/* 취소  */}
                {t(`Cancel`)}
              </button>
            </div>
          </div>
        </Modal>
      )}

      <PopupModal openModal={tagModal} setOpenModal={setTagModal} styles={{ marginTop: 200 }}>
        <div>
          <div className="text-center" style={{ marginTop: -10 }}>
            <h2 style={{ marginBottom: 0 }}>분류기준</h2>
            <p style={{ marginBottom: 0, fontSize: 15 }} className="font-500 mt-5">
              {/* {tags[index]?.tagName ? "수정/삭제" : "추가"} */}
              {tags[index]?.tagName ? t(`Edit Delete`) : t(`Add`)}
            </p>
          </div>

          <div className="mt-20 display-f justify-center align-center">
            <p className="mr-10 bold" style={{ fontSize: 20, fontWeight: 600 }}>
              {/* 태그명 :{" "} */}
              {t(`Tag name`)}:{" "}
            </p>
            <input
              value={tag}
              onChange={(e: React.ChangeEvent<any>) => {
                if (e.target.value.length > 4) {
                  return;
                } else {
                  setTag(e.target.value);
                }
              }}
              className="tag_input"
            />
          </div>

          <p className="card-text-red text-center mt-9" style={{ fontSize: 12 }}>
            {/* 최대 4글자 */}
            {t(`Up to 4 characters`)}
          </p>

          <div className="mt-20" style={{ marginBottom: 10 }}>
            <div className="display-f justify-center align-center">
              <button className="mr-10 badge-btn blue" onClick={handleAddTag}>
                {/* {tags[index]?.tagName ? "수정" : "추가"} */}
                {tags[index]?.tagName ? t(`Edit`) : t(`Add`)}
              </button>
              <button onClick={() => setTagModal(false)} className="badge-btn">
                {/* 취소 */}
                {t(`Cancel`)}
              </button>
            </div>

            {tags[index]?.tagName && (
              <div className="text-center mt-10">
                <button onClick={handleDeleteTag} className="badge-btn-delete">
                  {/* 삭제 */}
                  {t(`Delete`)}
                </button>
              </div>
            )}
            {/* )} */}
          </div>
        </div>
      </PopupModal>

      <div
        style={{
          padding: "0 20px",
          maxHeight: "78vh",
        }}
      >
        <div style={{ position: "sticky", top: 0, zIndex: 2 }}>
          <div className="display-f align-center stack_report_top_wrapper">
            <div style={{ flex: 1 }}>
              <p className="mr-10 font-500" style={{ fontSize: 15 }}>
                {/* 조회 기간 */}
                {t(`Date range`)}
              </p>

              <APDateRangePicker
                dateRange={props.dateRange}
                startDate={props.startDate}
                endDate={props.endDate}
                setStartDate={props.setStartDate}
                setEndDate={props.setEndDate}
              />

              {/* <div className="display-f mt-5">
              <input
                type="date"
                value={`${time3(props.dateRange.startDate)}`}
                onChange={(e: any) => onChangeFilterDate(e, "startDate")}
              />

              <p> ~ </p>
              <input
                type="date"
                className="ml-5"
                value={`${time3(props.dateRange.dueDate)}`}
                onChange={(e: any) => onChangeFilterDate(e, "dueDate")}
              />
            </div> */}
            </div>

            <div style={{ flexDirection: "column" }} className="display-f justify-center">
              <p className="mr-10 font-500" style={{ fontSize: 15 }}>
                {/* 태그 */}
                {t(`Tag`)}
              </p>
              <div className="display-f justify-center text-center mt-5">
                {/* 쿠키 지급 */}
                <button
                  onClick={() => setStackForCookie((prev: boolean) => !prev)}
                  className={`stack_record_cookie stack_tag_btn mr-5 cursor record_tag_btn_width2
                               display-f align-center ${stackForCookie && "brown white"}
                          `}
                >
                  <img src={cookie1} style={{ width: 18, height: "auto" }} />
                  <p className="font-12">{t(`Reward Cookies`)}</p>
                </button>

                {/* 쿠키 쓰기 */}
                <button
                  onClick={() => setStackForCookieUse((prev: boolean) => !prev)}
                  className={`stack_record_cookie_use text-red stack_tag_btn mr-5 cursor record_tag_btn_width2
                           display-f align-center ${stackForCookieUse && "bg-red white"}
                      `}
                >
                  <img src={cookie1} style={{ width: 18, height: "auto" }} />
                  {/* 쿠키 쓰기 */}
                  <p className="font-12">{t("Spend cookies")}</p>
                </button>

                {tags?.map((tag: any, i: number) => {
                  if (tag.tagName) {
                    return (
                      <button
                        key={i}
                        onClick={() => {
                          if (tagEdit) {
                            handleEditTag(i);
                          } else {
                            handleFilterTag(i);
                          }
                        }}
                        className={`stack_tag_btn white mr-5 cursor record_tag_btn_width ${
                          tag.check && "blue border-none"
                        }`}
                        style={{
                          backgroundColor: "rgba(38,97,217,0.4)",
                        }}
                      >
                        <p>{tag.tagName}</p>
                      </button>
                    );
                  } else {
                    return (
                      <div
                        onClick={() => handleClickTag(i)}
                        key={i}
                        className="tag_btn mr-5 cursor"
                        style={{ height: 30, width: 80 }}
                      >
                        <p style={{ color: "#979797", fontSize: 14 }}>+</p>
                      </div>
                    );
                  }
                })}
                {!tagEdit && (
                  <button
                    onClick={() => setTagEdit(true)}
                    style={{
                      backgroundColor: "#e7e7e7",
                      border: "none",
                      borderRadius: 4,
                      color: "#979797",
                    }}
                  >
                    {/* 수정 */}
                    {t(`Edit`)}
                  </button>
                )}
                {tagEdit && (
                  <button
                    onClick={() => setTagEdit(false)}
                    style={{
                      backgroundColor: "#e7e7e7",
                      border: "none",
                      borderRadius: 4,
                      color: "#979797",
                    }}
                  >
                    {/* 확인 */}
                    {t(`Confirm`)}
                  </button>
                )}
              </div>

              {tagEdit && (
                <p className="mt-5" style={{ fontSize: 12, color: "#979797" }}>
                  {/* 태그를 클릭하여 수정해 주세요. */}
                  {t(`Click on tag to edit it`)}
                </p>
              )}
            </div>

            <div style={{ flex: 1 }}>
              <p
                className="mr-10 font-500"
                style={{ textAlign: "right", fontSize: 15, height: 20 }}
              />
              <div className="display-f text-center mt-5" style={{ justifyContent: "flex-end" }}>
                {/* <form className="mr-10 position-r">
                  <img className="search_icon2 position-a cursor" src={search} alt="search" />

                  <input
                    value={searchInput}
                    placeholder="학생을 검색해 주세요."
                    onChange={(e: any) => setSearchInput(e.target.value)}
                    // onChange={(e) => handleSearchRecord(e)}
                    type="text"
                    className="category_searchInput2 pl-40 pr-10"
                  />
                </form> */}
                {!deleteMode && (
                  <>
                    <button className="report_btn_blue mr-5" onClick={onAddReportBtn}>
                      <p>
                        {/* 기록 추가 */}
                        {t(`Add Record`)}
                      </p>
                    </button>
                    <button onClick={() => setDeleteMode(true)} className="report_btn_red">
                      <p>
                        {/* 기록 삭제 */}
                        {t(`Delete Record`)}
                      </p>
                    </button>
                  </>
                )}
                {deleteMode && (
                  <>
                    <button onClick={handleDeleteStackRecord} className="report_btn_red mr-5">
                      {/* 영구 삭제 */}
                      <p>{t(`Delete`)}</p>
                    </button>
                    <button onClick={() => setDeleteMode(false)} className="report_btn_gray">
                      {/* 취소 */}
                      <p>{t(`Cancel`)}</p>
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* table head */}
        <div className="display-f stack_report_table" style={{ position: "sticky", top: 0 }}>
          <div className="display-f" style={{ width: "100%", backgroundColor: "#ececec" }}>
            {deleteMode && (
              <div
                className="text-center"
                onClick={handleCheckAll}
                style={{
                  width: "3%",
                  border: "1px solid #979797",
                  borderRight: "none",
                }}
              >
                <p>
                  {/* 전체 */}
                  {t(`All`)}
                </p>
                <div className="mt-5 display-f align-center justify-center">
                  <div className="select_stuent_c" style={{ backgroundColor: "#fff", margin: 0 }}>
                    {allCheck && <div className="select_stuent_c_fill" />}
                  </div>
                </div>
              </div>
            )}
            <div className="header_folder border_left" style={{ width: "9%" }}>
              {/* 날짜 */}
              {t(`Date`)}
            </div>
            <div className="header_folder" style={{ width: "12.5%" }}>
              {/* 태그 목록 */}
              {t(`Tag List`)}
            </div>
            <div className="header_folder" style={{ width: "25%" }}>
              {/* 대상 */}
              {t(`To`)}
            </div>
            <div className="header_folder" style={{ width: "5%" }}>
              {/* 쿠키 수 */}
              {t(`Cookies`)}
            </div>
            <div className="header_folder" style={{ width: "53.5%" }}>
              <div className="text-center">
                <p>
                  {/* 내용 */}
                  {t(`Detail`)}
                </p>
                <p style={{ fontSize: 12, fontWeight: 300, color: "gray" }}>
                  {/* 클릭하여 수정하실 수 있습니다 */}
                  (({t(`You can edit the record by clicking on any field`)}))
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* 날짜, 분류, 대상, 쿠키 수, 내용 */}
        <div className="stack_report_table page_report_body_stack">
          {props.stackRecords.map((stack: any, i: number) => (
            <div
              key={i}
              onClick={() => handleCheckRecords(stack, i)}
              style={{ width: "100%" }}
              className="display-f cursor"
            >
              {deleteMode && (
                <div
                  className="display-f align-center justify-center"
                  style={{
                    width: "3%",
                    border: "1px solid #979797",
                    borderRight: "none",
                    borderTop: "none",
                  }}
                >
                  <div
                    className="display-f align-center justify-center position-r"
                    style={{ zIndex: 1 }}
                  >
                    <div className="select_stuent_c" style={{ margin: 0 }}>
                      {stack.checked && <div className="select_stuent_c_fill" />}
                    </div>
                  </div>
                </div>
              )}
              <div className="student_table_cell border_left" style={{ width: "9%" }}>
                {time1(stack.reportDate)}
              </div>
              <div className="student_table_cell" style={{ width: "12.5%" }}>
                {stack?.tags?.map((name: any, index: number) => (
                  <span key={index}>
                    {tags[name].tagName}
                    {stack.tags.length - 1 !== index && ", "}
                  </span>
                ))}
                {stack?.tags?.length === 0 && stack.increaseCookie < 0 && (
                  <p className="text-red">
                    {/* 쿠키 쓰기 */}
                    {t(`Spend cookies`)}
                  </p>
                )}
                {stack?.tags?.length === 0 && stack.increaseCookie > 0 && (
                  <p style={{ color: "#9b7651" }}>
                    {/* 쿠키 지급 */}
                    🍪 {t(`Reward Cookies`)}
                  </p>
                )}
              </div>
              <div className="student_table_cell2">
                {stack?.studentsName?.length !== 0 &&
                  stack?.studentsName.map((name: string, index: number) => (
                    <span key={index} style={{ marginRight: 4 }}>
                      {name}
                      {index !== stack.studentsName.length - 1 && ","}
                    </span>
                  ))}
                {stack?.studentsName.length === 0 && (
                  <p>
                    {/* 우리반 쿠키함 */}
                    {t(`Class cookie jar`)}
                  </p>
                )}
              </div>
              <div className="student_table_cell" style={{ width: "5%" }}>
                <span className="text-red">
                  {!stack.classCookie &&
                    stack.increaseCookie < 0 &&
                    stack.useCookie &&
                    -stack.increaseCookie}
                </span>
                <span className="text-red">
                  {!stack.classCookie &&
                    stack.increaseCookie < 0 &&
                    !stack.useCookie &&
                    stack.increaseCookie}
                </span>
                {!stack.classCookie && stack.increaseCookie > 0 && stack.increaseCookie}
              </div>
              <div
                onMouseMove={moveListener}
                onMouseDown={downListener}
                onClick={(event: any) => {
                  if (movedNext) {
                    setMovedNext(false);
                    event.stopPropagation();
                    return;
                  }
                  if (moved) {
                    setMovedNext(true);
                    event.stopPropagation();
                  }
                }}
                className="student_table_cell"
                style={{ width: "53.5%" }}
              >
                {stack.reportBody}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

const Project = (props: any) => {
  const history = useHistory();
  const context = useContext<IMainContext>(MainContext);
  const [tags, setTags] = useState<any>([{}, {}, {}, {}, {}, {}, {}, {}, {}, {}]);
  const [searchInput, setSearchInput] = useState<string>("");
  const [modal, setModal] = useState<boolean>(false);
  const [projectOfStudent, setProjectOfStudent] = useState<any>({});
  const [studentBodyFalse, setStudentBodyFalse] = useState<boolean>(false);
  const [confirmType, setConfirmType] = useState<string>("");
  const [cookieSelected, setCookieSelected] = useState<number>(0);
  const [score, setScore] = useState<string>("");
  const [rejectReason, setRejectReason] = useState<string>("");
  const [feedback, setFeedback] = useState<string>("");
  const [album, setAlbum] = useState<boolean>(false);
  const [albumData, setAlbumData] = useState<any>({});
  const [albumUrl, setAlbumUrl] = useState<string>("");
  const [videoUrl, setVideoUrl] = useState<string>("");
  const [projectInfo, setProjectInfo] = useState<any>({
    title: "",
    hide: false,
  });
  const [moved, setMoved] = useState<boolean>(false);
  const [movedNext, setMovedNext] = useState<boolean>(false);

  const popupRef = useRef();
  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    const tagsResult: any = await getCategorys(props.classId);
    if (tagsResult.length !== 0) {
      setTags(tagsResult[0].tags);
    }
  };

  const override: any = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
  };

  const setColors = (posState: string): string => {
    let color = "";
    switch (posState) {
      case "complete":
        color = "gray";
        break;
      case "check":
        color = "red";
        break;
      case "":
        color = "yellow";
        break;
    }
    return color;
  };

  useEffect(() => {
    filterHandler();
  }, [tags, searchInput]);

  const filterHandler = () => {
    let tempData: any = [...props.originalProjectReports];
    const tempTags: any = [...tags];
    const selectedTags = tempTags.filter((item: any) => item.selected);
    if (selectedTags.length !== 0) {
      let tempArr = [];
      for (let i = 0; i < props.originalProjectReports.length; i++) {
        let exist = false;
        for (let k = 0; k < props.originalProjectReports[i]?.projectInfo[0]?.tags?.length; k++) {
          for (let h = 0; h < selectedTags.length; h++) {
            if (
              !exist &&
              tags[props.originalProjectReports[i]?.projectInfo[0]?.tags[k]] === selectedTags[h]
            ) {
              tempArr.push(props.originalProjectReports[i]);
              exist = true;
            }
          }
        }
      }

      tempData = [...tempArr];
    }

    if (searchInput !== "") {
      let tempArr: any = [];
      for (let i = 0; i < tempData.length; i++) {
        if (tempData[i].projectInfo[0]?.title.includes(searchInput)) {
          tempArr.push(tempData[i]);
        }
      }
      props.setProjectReports(tempArr);
    } else {
      props.setProjectReports(tempData);
    }
  };

  const handleFilterTag = (tag: any) => {
    const tempTags = [...tags];
    const idx = tempTags.findIndex((el: any) => el === tag);
    tempTags[idx].selected = !tags[idx].selected;

    setTags(tempTags);
  };

  const handleClickConfirmPopup = (aProject: any) => {
    let fileLimit = 0;
    if (context.userInfo.fileSizeLimit === 0 || !context.userInfo.fileSizeLimit) {
      fileLimit = 100000000;
    } else {
      fileLimit = context.userInfo?.fileSizeLimit;
    }
    if (fileLimit < context.fileSize.originalFileSize) {
      return alert(
        // "제한 용량을 초과하셨습니다. '과제 삭제' 또는 '학급 삭제'를 통해 저장 용량을 조절하시거나, '결제 관리' 페이지에서 저장 용량 업그레이드 후 이용해 주세요.",
        t(`You have exceeded the storage limit...`),
      );
    }
    setProjectInfo((prev: any) => {
      return {
        title: aProject.projectInfo[0]?.title,
        hide: aProject.projectInfo[0]?.hide,
      };
    });

    let tempFileUrls = [...aProject.fileUrls];
    for (let i = 0; i < tempFileUrls?.length; i++) {
      if (!tempFileUrls[i].fileType && tempFileUrls[i].isVideo) {
        tempFileUrls[i].fileType = "video";
      }
      if (!tempFileUrls[i].fileType && !tempFileUrls[i].isVideo) {
        tempFileUrls[i].fileType = "image";
      }
    }
    tempFileUrls = sortedImageFileArr(tempFileUrls);
    setProjectOfStudent({ ...aProject, fileUrls: tempFileUrls });

    if (aProject.repeat) {
      // return alert("반복과제 페이지에서 확인해 주세요.");
      return alert(t(`Check it out on the repeated assignments page`));
    }
    if (aProject.feedback !== "") {
      setFeedback(aProject.feedback);
    }
    if (aProject.feedback === "") {
      setFeedback("");
    }

    if (aProject.state === "complete" && aProject.cookie !== 0) {
      setConfirmType("cookie");
      setCookieSelected(aProject.cookie);
    }
    if (aProject.state === "complete" && aProject.cookie === 0) {
      setConfirmType("score");
      setScore(aProject.score);
    }

    if (aProject.state === "check") {
      setCookieSelected(1);
      setConfirmType("cookie");
    }

    if (aProject.state === "" && (!aProject.reject || aProject.reject === "")) {
      setCookieSelected(1);
      setConfirmType("cookie");
    }
    if (aProject.state === "" && aProject.reject !== "") {
      setConfirmType("reject");
      setRejectReason(aProject.reject);
    }
    setModal(true);
    if (aProject.studentBody) {
      setStudentBodyFalse(true);
    } else {
      setStudentBodyFalse(false);
    }
  };

  const replace = (content: any) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const convertContent = content?.replace(urlRegex, function (url: any) {
      return '<a style="color: blue" href="' + url + '" target="_blank">' + url + "</a>";
    });

    const htmlArr: any = [];
    convertContent?.split("\n")?.forEach(function (text: any) {
      const textHtml = "<p>" + text + "</p>";
      htmlArr.push(textHtml);
    });

    return { __html: htmlArr.join("") };
  };

  const handleConfirmTypeChange = (type: number): void => {
    if (projectInfo.hide) return;
    if (type === 1) {
      if (projectOfStudent.state === "complete" && projectOfStudent.score === "") {
        setConfirmType("cookie");
        setCookieSelected(projectOfStudent.cookie ? projectOfStudent.cookie : 0);
      } else {
        setConfirmType("cookie");
        setCookieSelected(1);
      }
    } else if (type === 2) {
      if (projectOfStudent.state === "complete" && projectOfStudent.score !== "") {
        setConfirmType("score");
        setScore(projectOfStudent.score ? projectOfStudent.score : "");
      } else {
        setConfirmType("score");
        setScore("");
      }
    } else if (type === 3) {
      if (projectOfStudent.state === "" && projectOfStudent.reject !== "") {
        setConfirmType("reject");
        // setRejectReason(projectOfStudent.reject === "_" ? "" : projectOfStudent.reject);
      } else {
        setConfirmType("reject");
        // setRejectReason("");
      }
    }
  };

  const handleConfirmProject = async (): Promise<void> => {
    if (projectInfo.hide) {
      // return alert("숨김상태에서는 과제의 열람만 가능합니다.");
      return alert(t(`While archived assignments can only be viewed`));
    }
    context.handleStateChange("class", "");
    let cookie = 0;
    let tempScore = "";
    if (confirmType === "cookie" && cookieSelected !== 0) cookie = cookieSelected;
    if (confirmType === "score" && score !== "") tempScore = score;
    if (confirmType === "reject") {
      const confirm = window.confirm(
        // `반려하면 학생의 자료가 삭제되며, 복구가 되지 않습니다. 진행하시겠습니까?`,
        t(`If you reject...`),
      );
      if (!confirm) return;

      const result: any = await putRejectProject({
        id: projectOfStudent?._id,
        rejectReason: rejectReason === "" ? "_" : rejectReason,
      });

      // console.log(props.studentInfo);
      if (props.studentInfo?.deviceToken) {
        let body = {
          to: props.studentInfo?.deviceToken2,
          // title: `과제가 반려되었습니다. 📝`,
          title: `${t(`Your assignment has been rejected`)} 📝`,
          body: t(`Please resubmit it`), // "다시 보내주세요.",
          data: {
            code: props.studentInfo?.code,
            type: "project",
          },
        };

        postNotification(body);
      }
      // alert("과제가 반려되었습니댜.");
      alert(t(`Your assignment has been rejected`));
    } else {
      const result: any = await putConfirmProject({
        id: projectOfStudent?._id,
        confirmType,
        confirmScore: tempScore,
        confirmCookies: cookie,
        feedback: feedback,
      });

      if (result.constructor == Array) {
        let pushArr: any = [];
        result.map((pushItem: any, i: number) => {
          pushArr.push({
            token: projectOfStudent.studentInfo[0]?.deviceToken2,
            notification: {
              title: `🎖${pushItem.title} ${t(`Badge`)}🎖`, // 뱃지
              body: t(`Congratulations Check it out`), // `축하합니다! 확인해보세요!`,
            },
            data: {
              code: projectOfStudent.studentInfo[0]?.code,
              type: "project",
            },
          });
        });
        postNotificationAll(pushArr);
      }

      let body = {
        to: props.studentInfo?.deviceToken2,
        // title: confirmType === "cookie" ? "쿠키 도착 🍪" : "과제 확인 📝",
        title:
          confirmType === "cookie"
            ? `${t(`You've earned cookies`)} 🍪` // 쿠키 도착
            : `${t(`Check your assignment`)} 📝`, // 과제 확인
        body:
          confirmType === "cookie"
            ? t(`You've earned cookies Check it out`) // "쿠키가 도착했어요! 확인해보세요!"
            : t(`You have a new assignment`), // "과제를 확인하셨습니다! 확인해보세요!",
        data: {
          code: props.studentInfo?.code,
          type: "project",
        },
      };

      postNotification(body);
    }

    const initResult = await initProjectForS(props.classId, props.studentId, props.projectReports);
    props.setProjectReports(initResult?.projects);
    props.setOriginalProjectReports(initResult.origin);
    setModal(false);
    setConfirmType("");
    setFeedback("");
  };

  const handleAlbum = (data: any): void => {
    setAlbumData(data);
    setAlbum((prev) => !prev);
    if (!data.isVideo) {
      setAlbumUrl(data.uri);
      setVideoUrl("");
    } else {
      setVideoUrl(videoUrl === "" ? data.uri : "");
      setAlbumUrl("");
    }
  };

  const downListener = () => {
    setMoved(false);
  };

  const moveListener = () => {
    setMoved(true);
  };

  return (
    <>
      {album && (
        <PopupAlbum
          enable={album}
          albumData={albumData}
          setAlbumData={setAlbumData}
          fileData={projectOfStudent?.fileUrls}
          handleAlbum={handleAlbum}
          setAlbumUrl={setAlbumUrl}
          setVideoUrl={setVideoUrl}
          albumUrl={albumUrl}
          videoUrl={videoUrl}
        />
      )}

      {/* 과제 확인 모달 */}
      {modal && (
        <Modal
          show={modal}
          onClose={() => {
            setModal(false);
            setProjectOfStudent({});
          }}
        >
          <div
            className="projectOfStudentPopup scroll color-light-black"
            style={{ width: "35vw", height: "75vh", overflow: "auto" }}
          >
            <div className="display-f align-center" style={{ alignItems: "flex-end" }}>
              <h2 className="mr-10">{projectInfo.title}</h2>
              {projectInfo.hide && (
                <div style={{ fontSize: 13, marginBottom: -5 }}>
                  {/* (숨김상태에서는 과제의 열람만 가능합니다.) */}
                  {t(`While archived assignments can only be viewed `)}
                </div>
              )}
            </div>
            <div>
              {projectOfStudent?.fileUrls?.map((aFile: any, index: number) => (
                <>
                  {(aFile.fileType === "image" ||
                    aFile.fileType === "video" ||
                    !aFile.fileType) && (
                    <div
                      key={index}
                      style={{
                        width: "49%",
                        verticalAlign: "top",
                        display: "inline-block",
                        marginRight: index % 2 === 0 ? 10 : 0,
                        marginBottom: 10,
                      }}
                    >
                      {!aFile.isVideo && (
                        <img
                          className="cursor"
                          onClick={() => handleAlbum(aFile)}
                          src={aFile.uri}
                          style={{ width: "100%", height: "auto", verticalAlign: "top" }}
                        />
                      )}
                      {aFile.isVideo && (
                        <video
                          controls
                          style={{ verticalAlign: "top" }}
                          onClick={() => handleAlbum(aFile)}
                          src={aFile.uri}
                          className="background-img student_submitted_image mb-10 cursor"
                          autoPlay={true}
                        ></video>
                      )}
                      {/* <p className="txt_line">{aFile.fileName}</p> */}
                      <p
                        onClick={() => window.open(aFile.uri)}
                        className="download_text cursor"
                        style={{ textAlign: "center" }}
                      >
                        {/* 다운받기 */}
                        {t(`Download`)}
                      </p>
                    </div>
                  )}
                </>
              ))}
              {projectOfStudent?.fileUrls?.filter(
                (el: any, i: number) =>
                  el.fileType !== "image" && el.fileType !== "video" && el.fileType,
              ).length !== 0 && (
                <div style={{ marginBottom: 10 }}>
                  <p style={{ fontWeight: 500, marginBottom: 10 }}>
                    {/* 첨부파일 */}
                    {t(`Attachments`)}
                    <span style={{ fontSize: 12 }}>
                      {/* (아래 파일명을 클릭하면 다운로드됩니다.) */}
                      {t(`Click the filename below to download`)}
                    </span>
                  </p>
                </div>
              )}

              {projectOfStudent?.fileUrls?.map((aFile: any, index: number) => (
                <>
                  {aFile.fileType !== "image" && aFile.fileType !== "video" && aFile.fileType && (
                    <div
                      onClick={() => window.open(aFile.uri)}
                      key={index}
                      className="file_ex_container cursor"
                    >
                      <img src={file_icon} style={{ width: 25, height: "auto", marginRight: 5 }} />
                      <p>{aFile.fileName}</p>
                    </div>
                  )}
                </>
              ))}
            </div>
            {/* <div className="display-f align-center flex-wrap">
              {projectOfStudent?.fileUrls?.map((data: any, index: number) => (
                <div
                  key={index}
                  className="display-f download_item mb-10 align-center"
                  style={{
                    width: "49%",
                    marginRight: index % 2 === 1 ? 0 : 5,
                    marginLeft: index % 2 === 0 ? 0 : 5,
                    flexDirection: "column",
                  }}
                >
                  <div>
                    {data.isVideo ? (
                      <>
                        <video
                          controls
                          onClick={() => handleAlbum(data)}
                          src={data.uri}
                          className="background-img student_submitted_image mb-10 cursor"
                          autoPlay={true}
                        ></video>
                      </>
                    ) : (
                      <div onClick={() => handleAlbum(data)} className="mb-10 cursor">
                        <img src={data.uri} alt="studentImg" className="img-response" />
                      </div>
                    )}
                  </div>

                  <p onClick={() => window.open(data.uri)} className="download_text cursor">
                    다운받기
                  </p>
                </div>
              ))}
            </div> */}

            {studentBodyFalse && (
              <div className="project_list mb-7" style={{ borderRadius: 10 }}>
                <p>
                  <div
                    className="cursor"
                    dangerouslySetInnerHTML={replace(projectOfStudent?.studentBody)}
                  ></div>
                </p>
              </div>
            )}

            {projectOfStudent?.submissionTime && (
              <p className="mb-7 font-bold">
                {timeFormat11(projectOfStudent.submissionTime)}
                {/* 제출 */}
                {t(`Submit`)}
              </p>
            )}

            {confirmType !== "reject" && (
              <div className="display-f  align-center mb-20">
                <p className="font-15 font-500 mr-33">피드백</p>

                <textarea
                  value={feedback}
                  onChange={(e: any) => setFeedback(e.target.value)}
                  className="feedback__text"
                  name="feedback"
                  readOnly={(confirmType === "reject" && true) || (projectInfo.hide && true)}
                  cols={20}
                ></textarea>
              </div>
            )}

            <div className="display-f justify-between align-center mb-28">
              <button
                onClick={() => handleConfirmTypeChange(1)}
                className={`focus project__sign__btn light-black ${
                  confirmType === "cookie" && "confirm_selected"
                }`}
              >
                {/* 쿠키 */}
                {t(`Cookie`)}
              </button>
              <button
                onClick={() => handleConfirmTypeChange(2)}
                className={`focus project__sign__btn light-black ${
                  confirmType === "score" && "confirm_selected"
                }`}
              >
                {/* 성적 */}
                {t(`Grade`)}
              </button>
              <button
                onClick={() => handleConfirmTypeChange(3)}
                className={`focus project__sign__btn light-black project_reject_btn ${
                  confirmType === "reject" && "confirm_reject_selected"
                }`}
              >
                {/* 반려 */}
                {t(`Reject`)}
              </button>
            </div>

            <div className="mb-40">
              {confirmType === "cookie" && (
                <div className="display-f align-center">
                  <button
                    onClick={() => {
                      if (projectInfo.hide) return;
                      else setCookieSelected(1);
                    }}
                    className={`${
                      cookieSelected !== 1 && "cookie_border_none"
                    } cookie_circle cursor display-f-center mr-17`}
                  >
                    {cookieSelected === 1 ? (
                      <img className="cookie1" src={cookie1S} alt="cookie1S" />
                    ) : (
                      <img className="cookie1" src={cookie1G} alt="cookie1G" />
                    )}
                  </button>
                  <button
                    onClick={() => {
                      if (projectInfo.hide) return;
                      else setCookieSelected(2);
                    }}
                    className={`${
                      cookieSelected !== 2 && "cookie_border_none"
                    } cookie_circle cursor display-f-center mr-17`}
                  >
                    {cookieSelected === 2 ? (
                      <img className="cookie2" src={cookie2S} alt="cookie2S" />
                    ) : (
                      <img className="cookie2" src={cookie2G} alt="cookie2G" />
                    )}
                  </button>
                  <button
                    onClick={() => {
                      if (projectInfo.hide) return;
                      else setCookieSelected(3);
                    }}
                    className={`${
                      cookieSelected !== 3 && "cookie_border_none"
                    } cookie_circle cursor display-f-center`}
                  >
                    {cookieSelected === 3 ? (
                      <img className="cookie3" src={cookie3S} alt="cookie3S" />
                    ) : (
                      <img className="cookie3" src={cookie3G} alt="cookie3G" />
                    )}
                  </button>
                </div>
              )}
              {confirmType === "score" && (
                <div className="display-f align-center">
                  <p className="mr-23 font-500 font-15">
                    {/* 성적 입력 */}
                    {t(`Enter grade`)}
                  </p>
                  <input
                    readOnly={projectInfo.hide ? true : false}
                    value={score}
                    maxLength={4}
                    onChange={(e: any) => setScore(e.target.value)}
                    className="score_input"
                    type="text"
                  />
                </div>
              )}
              {confirmType === "reject" && (
                <>
                  <div className="display-f align-center justify-between">
                    <p className="font-500 font-15">
                      {/* 반려 사유 */}
                      {t(`Rejection Reason`)}
                    </p>
                    <textarea
                      readOnly={projectInfo.hide ? true : false}
                      value={rejectReason}
                      onChange={(e: any) => setRejectReason(e.target.value)}
                      className="reject__reason__text"
                      name="rejectReason"
                    />
                  </div>
                </>
              )}
            </div>

            {!projectInfo.hide && (
              <div className="display-f-center">
                <button onClick={handleConfirmProject} className="popup__bottom__btn mr-14 ">
                  {/* 확인 */}
                  {t(`Confirm`)}
                </button>
                <button
                  onClick={() => {
                    setModal(false);
                    setConfirmType("");
                    setScore("");
                    setCookieSelected(0);
                    setRejectReason("");
                    setFeedback("");
                  }}
                  className="popup__bottom__btn"
                >
                  {/* 취소 */}
                  {t(`Cancel`)}
                </button>
              </div>
            )}
          </div>
        </Modal>
      )}

      <div
        style={{
          padding: "0 20px",
          maxHeight: "78vh",
        }}
      >
        <div style={{ position: "sticky", top: 0, zIndex: 2 }}>
          <div className="display-f align-center stack_report_top_wrapper">
            <div style={{ flex: 1 }}>
              <div style={{ maxWidth: "100%", width: "100%" }}>
                <div className="display-f flex-wrap">
                  {tags.map((tag: any, i: number) => {
                    if (!tag.tagName) {
                      return (
                        <div
                          key={i}
                          className="tag_btn_block gray text-center mr-5 mb-5"
                          style={{ height: 25 }}
                        >
                          <p style={{ color: "#ececec" }}> </p>
                        </div>
                      );
                    }
                    if (tag.tagName) {
                      return (
                        <button
                          onClick={() => handleFilterTag(tag)}
                          key={i}
                          className={`${
                            tag.selected && "tag_btn_selected"
                          } tag_btn_fill  text-center mr-5 mb-5 cursor`}
                        >
                          <p style={{ color: "#fff" }}>{tag.tagName}</p>
                        </button>
                      );
                    }
                  })}
                </div>
              </div>
            </div>
            <div style={{ flex: 1 }}></div>

            <div style={{ flex: 1 }}>
              <p
                className="mr-10 font-500"
                style={{ textAlign: "right", fontSize: 15, height: 20 }}
              />
              <div className="display-f text-center mt-5" style={{ justifyContent: "flex-end" }}>
                <form className="mr-10 position-r">
                  <img className="search_icon2 position-a cursor" src={search} alt="search" />
                  <input
                    value={searchInput}
                    placeholder={t(`Search for an assignment title`)}
                    onChange={(e: any) => setSearchInput(e.target.value)}
                    type="text"
                    className="category_searchInput2 pl-40 pr-10"
                  />
                </form>
              </div>
            </div>
          </div>
        </div>

        {/* table head */}
        <div className="display-f stack_report_table" style={{ position: "sticky", top: 0 }}>
          <div className="display-f " style={{ width: "100%" }}>
            <div className="header_folder border_left background-white" style={{ width: "10%" }}>
              <p>
                {/* 과제 이름 */}
                {t(`Assignment title`)}
              </p>
            </div>
            <div className="header_folder background-white" style={{ width: "8%" }}>
              {/* 태그 */}
              {t(`Tag`)}
            </div>
            <div className="header_folder background-white" style={{ width: "6%" }}>
              <p>
                {/* 쿠키 / 성적 */}
                {t(`Cookie Grades`)}
              </p>
            </div>
            {/* <div className="header_folder background-white" style={{ width: "8%" }}>
              성적
            </div> */}
            <div className="header_folder background-white" style={{ width: "76%" }}>
              <div className="text-center">
                <p>
                  {/* 피드백 기록내용 */}
                  {t(`Feedback history`)}
                </p>
                <p style={{ fontSize: 12, fontWeight: 300, color: "gray" }}>
                  {/* 클릭하여 수정하실 수 있습니다. */}
                  (({t(`You can edit the record by clicking on any field`)}))
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* table body */}
        <div className="stack_report_table page_report_body_stack_project">
          {props.projectReports?.map((aProject: any, i: number) => (
            <div
              onClick={() => handleClickConfirmPopup(aProject)}
              key={i}
              style={{ width: "100%" }}
              className={`${setColors(aProject.state)} display-f cursor`}
            >
              <div className="student_table_cell border_left" style={{ width: "10%" }}>
                <div className="display-f">
                  {aProject?.projectInfo[0]?.repeat &&
                    aProject?.projectInfo[0]?.repeatType === "count" && (
                      <img className="mr-5" src={numbers} style={{ width: 14, height: "auto" }} />
                    )}
                  {aProject?.projectInfo[0]?.repeat &&
                    aProject?.projectInfo[0]?.repeatType === "date" && (
                      <img className="mr-5" src={calendar} style={{ width: 14, height: "auto" }} />
                    )}
                  {aProject?.projectInfo[0]?.hide && (
                    <img className="mr-5" src={hide} style={{ width: 14, height: "auto" }} />
                  )}
                </div>
                {aProject.projectInfo[0]?.title}
              </div>
              <div className="student_table_cell2" style={{ width: "8%" }}>
                {aProject.projectInfo[0]?.tags?.map((el: any, index: number) => (
                  <span key={index} className="mr-3">
                    {tags[el]?.tagName}
                    {aProject.projectInfo[0]?.tags.length - 1 !== index && ", "}
                  </span>
                ))}
              </div>
              <div className="student_table_cell2" style={{ width: "6%" }}>
                {/* {aProject.cookie === 0 ? "-" : aProject.cookie}
                {aProject.score === "" ? "-" : aProject.score} */}
                {!aProject.projectInfo[0]?.repeat &&
                  aProject.state === "complete" &&
                  aProject.cookie === 1 && (
                    <img src={cookie1S} style={{ width: 17, height: "auto" }} />
                  )}
                {!aProject.projectInfo[0]?.repeat &&
                  aProject.state === "complete" &&
                  aProject.cookie === 2 && (
                    <img src={cookie2S} style={{ width: 17, height: "auto" }} />
                  )}
                {!aProject.projectInfo[0]?.repeat &&
                  aProject.state === "complete" &&
                  aProject.cookie === 3 && (
                    <img src={cookie3S} style={{ width: 17, height: "auto" }} />
                  )}

                {!aProject.projectInfo[0]?.repeat &&
                  aProject.state === "complete" &&
                  aProject.cookie === 0 && (
                    <p className="card-text-red font-500">{aProject.score}</p>
                  )}

                {aProject.projectInfo[0]?.repeat && (
                  <div className="display-f align-center" style={{ fontSize: 11 }}>
                    <img src={cookie1S} style={{ width: 15, height: "auto", marginRight: 3 }} /> X{" "}
                    {aProject.cookie}
                  </div>
                )}
              </div>

              <div
                onMouseMove={moveListener}
                onMouseDown={downListener}
                onClick={(event: any) => {
                  if (movedNext) {
                    setMovedNext(false);
                    event.stopPropagation();
                    return;
                  }
                  if (moved) {
                    setMovedNext(true);
                    event.stopPropagation();
                  }
                }}
                className="student_table_cell"
                style={{ width: "76%" }}
              >
                {aProject.feedback}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

const Checklist = (props: any) => {
  const [options, setOptions] = useState<boolean>(false);
  const [sorting, setSorting] = useState<string>(t(`Newest to Oldest`)); // 최신 순
  const [folderOpen, setFolderOpen] = useState<boolean>(false);
  const folderRef = useRef<any>(null);
  const [popup, setPopup] = useState<boolean>(false);
  const [cosData, setCosData] = useState<any>({});
  const [cosInput, setCosInput] = useState<any>({
    state: "",
    description: "",
  });
  const [saved, setSaved] = useState<boolean>(false);
  const [selectedFolder, setSelectedFolder] = useState<string>(t(`All Folder`)); // 모든 폴더
  const [filterOn, setFilterOn] = useState<any>({
    sorting: false,
    folders: false,
  });
  const [searchInput, setSearchInput] = useState<string>("");
  const [moved, setMoved] = useState<boolean>(false);
  const [movedNext, setMovedNext] = useState<boolean>(false);
  const [hiddenChecklist, setHiddenChecklist] = useState<boolean>(true);

  const handleOpenCos = async (cos: any): Promise<void> => {
    if (cos.checklistHide) {
      return alert(
        // "숨긴 체크리스트입니다.\n기록하시려면 숨김을 해제해 주세요."
        t(`This is an archived checklist...`),
      );
    } else {
      setCosData(cos);
      if (cos.state === "") {
        let state = "complete";
        await putCheckCos(cos._id, state);
        const getData = await getChecklistInitForS(
          props.classId,
          props.studentId,
          props.checklistData,
          sorting,
        );
        props.setOriginChecklistData(getData.origin);
        props.setChecklistData(getData.cos);
      } else {
        setPopup(true);
        setCosInput((prev: any) => {
          return {
            state: cos.state,
            description: cos.description,
          };
        });
      }
    }
  };

  const handleSave = async (): Promise<void> => {
    const update: any = await putModifyCos({
      id: cosData._id,
      description: cosInput.description,
    });

    if (update) setSaved(true);

    setTimeout(() => {
      setSaved(false);
    }, 500);

    const getData = await getChecklistInitForS(
      props.classId,
      props.studentId,
      props.checklistData,
      sorting,
    );
    props.setOriginChecklistData(getData.origin);
    props.setChecklistData(getData.cos);
  };

  const handleSubmitChecklist = async (): Promise<void> => {
    let state = cosInput.state === "" ? "complete" : cosInput.state;
    await putCheckCos(cosData._id, state);
    await handleSave();
    setPopup(false);

    const getData = await getChecklistInitForS(
      props.classId,
      props.studentId,
      props.checklistData,
      sorting,
    );
    props.setOriginChecklistData(getData.origin);
    props.setChecklistData(getData.cos);
  };

  const handleUnSubmit = async (): Promise<void> => {
    await putCancelCheckCos(cosData._id);
    setPopup(false);
    const getData = await getChecklistInitForS(
      props.classId,
      props.studentId,
      props.checklistData,
      sorting,
    );
    props.setOriginChecklistData(getData.origin);
    props.setChecklistData(getData.cos);
  };

  useEffect(() => {
    filterHandler();
  }, [sorting, selectedFolder, searchInput, hiddenChecklist]);

  const filterHandler = () => {
    let originArr: any = [...props.originChecklistData];

    if (filterOn.sorting) {
      // 항목 생성 날짜 순 or 체크 상태 순
      let filter = [];
      if (sorting === t(`Newest to Oldest`)) {
        // 최신 순
        filter = originArr.sort((aCos: any, bCos: any) => aCos.created - bCos.created);
      }
      if (sorting === t(`Marking status`)) {
        // 체크상태 순
        filter = originArr.sort((aCos: any, bCos: any) => {
          if (aCos.state === "") return -1;
          else return 0;
        });
      }

      originArr = [...filter];
    }
    if (filterOn.folders) {
      let tempArr = [];
      if (selectedFolder === "미분류") {
        // console.log(originArr);
        let tempFilter = originArr.filter((el: any, i: number) => !el.checklistInfo.onFolder);
        tempArr = tempFilter;
      } else {
        for (let i = 0; i < originArr.length; i++) {
          if (originArr[i].folderTitle === selectedFolder) {
            tempArr.push(originArr[i]);
          }
        }
      }

      originArr = [...tempArr];
    }

    if (!hiddenChecklist) {
      let tempArr: any = [];

      for (let i = 0; i < originArr.length; i++) {
        if (!originArr[i].checklistHide) {
          tempArr.push(originArr[i]);
        }
      }

      originArr = [...tempArr];
    }

    if (searchInput !== "") {
      const filter = originArr.filter((el: any) => el.checklistInfo.title.includes(searchInput));
      props.setChecklistData((prev: any) => {
        return {
          ...prev,
          cos: filter,
        };
      });
    } else {
      props.setChecklistData((prev: any) => {
        return {
          ...prev,
          cos: originArr,
        };
      });
    }
  };

  const downListener = () => {
    setMoved(false);
  };

  const moveListener = () => {
    setMoved(true);
  };

  return (
    <>
      {popup && (
        <div className="popup" style={{ zIndex: 100 }}>
          <div className="modal-overlay" onClick={() => setPopup(false)}></div>
          <div
            className="project_add_popup scroll popup_column_sb"
            style={{ backgroundColor: "rgba(246, 248, 250)" }}
          >
            <div>
              <h2 className="text-center">
                {/* {JSON.stringify(cosData)} */}
                {cosData.checklistInfo.title}
              </h2>
              <div
                style={{
                  paddingTop: 20,
                  padding: "20px 0 20px 10px",
                  backgroundColor: "#fff",
                }}
              >
                <div dangerouslySetInnerHTML={{ __html: cosData.checklistInfo.description }} />
                <div className="display-f" style={{ flexWrap: "wrap" }}>
                  {cosData.checklistInfo?.file?.map((url: string, i: number) => (
                    <img key={i} src={url} style={{ width: "48%" }} />
                  ))}
                </div>
              </div>
              <div>
                <div className="display-f align-center">
                  <h3>
                    {/* 상세 기록 */}
                    {t(`Details`)}
                  </h3>
                  <button onClick={handleSave} className="checklist-save-btn ml-15">
                    {/* 기록 저장 */}
                    {t(`Save records`)}
                  </button>

                  {saved && (
                    <p className="ml-7" style={{ color: "red", fontSize: 10 }}>
                      {/* 기록이 저장되었습니다. */}
                      {t(`Your records have been saved`)}
                    </p>
                  )}

                  {!saved && (
                    <p className="ml-7" style={{ color: "#979797", fontSize: 10 }}>
                      {/* 기록 저장을 누르지 않으면, 상세 기록이 저장되지 않습니다. */}
                      {t(`If you don’t tap Save...`)}
                    </p>
                  )}
                </div>
                <textarea
                  onChange={(e: any) => {
                    setCosInput((prev: any) => {
                      return {
                        ...prev,
                        description: e.target.value,
                      };
                    });
                  }}
                  value={cosInput.description}
                  rows={16}
                  className="popup-checklist-textarea"
                />
              </div>
            </div>

            <div>
              <div className="pt-20 pb-20 display-f align-center">
                <p className="mr-20">
                  {/* 간단 메모 */}
                  {t(`Memo`)}
                </p>
                <input
                  value={cosInput.state === "complete" ? "" : cosInput.state}
                  className="popup-scrore-input"
                  onChange={(e) => {
                    if (e.target.value.length > 10) return;
                    setCosInput((prev: any) => {
                      return {
                        ...prev,
                        state: e.target.value,
                      };
                    });
                  }}
                />
              </div>

              <div className="display-f justify-center mt-20 mb-10">
                <button
                  onClick={handleSubmitChecklist}
                  className="project__sign__btn background-red-btn mr-10 color-white"
                >
                  {/* 체크 */}
                  {t(`Check`)}
                </button>
                <button
                  onClick={handleUnSubmit}
                  className="project__sign__btn background-green-btn color-white"
                >
                  {/* 체크 취소 */}
                  {t(`Cancel`)}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <div
        style={{
          // overflow: "auto",
          padding: "0 20px",
          maxHeight: "78vh",
        }}
      >
        {/* header */}
        <div
          className="display-f"
          style={{
            width: "100%",
            position: "sticky",
            top: 0,
            zIndex: 2,
            backgroundColor: "#fafafa",
          }}
        >
          <div className="display-f align-center" style={{ flex: 1 }}>
            <p className="font-13">
              {/* 체크리스트 */}
              {/* 폴더/항목 생성 및 수정은 체크리스트 페이지에서 진행해 주세요. */}
              {t(`Please go to the checklist tab to..`)}
            </p>
          </div>
          <div className="display-f align-center">
            <div className="display-f mr-10">
              <input
                onChange={() => setHiddenChecklist((prev) => !prev)}
                type="checkbox"
                id={JSON.stringify("showHiddenChecklists")}
                className="display-n"
              />
              <div>
                <label
                  className="target_label_g mr-5 cursor"
                  htmlFor={JSON.stringify("showHiddenChecklists")}
                >
                  {hiddenChecklist && <span className="studentList_check"></span>}
                </label>
              </div>
              <p className="cursor" onClick={() => setHiddenChecklist((prev) => !prev)}>
                {/* 숨긴 체크리스트 보기 */}
                {t(`View archived checklists`)}
              </p>
            </div>
            {/* 검색 바 */}
            <div className="display-f text-center" style={{ justifyContent: "flex-end" }}>
              <form className="mr-10 position-r">
                <img className="search_icon2 position-a cursor" src={search} alt="search" />
                <input
                  value={searchInput}
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    setSearchInput(event.target.value)
                  }
                  placeholder={t(`Search for a checklist title`)}
                  type="text"
                  className="category_searchInput2 pl-40 pr-10"
                />
              </form>
            </div>
            {/* 정렬 */}
            <OutsideClickHandler
              onOutsideClick={() => {
                if (options) {
                  setOptions(false);
                }
              }}
            >
              <div className="position-r">
                <div
                  onClick={() => setOptions((prev: boolean) => !prev)}
                  className="folder_selectbar cursor"
                >
                  <div>{sorting}</div>
                  <img src={down} style={{ width: 14, height: "auto" }} />
                </div>
                {options && (
                  <div
                    onClick={() => {
                      if (sorting === t(`Newest to Oldest`)) {
                        // 최신 순
                        setSorting(t(`Marking status`)); // 체크상태 순
                      } else {
                        setSorting(t(`Newest to Oldest`)); // 최신 순
                      }
                      setFilterOn((prev: any) => {
                        return {
                          ...prev,
                          sorting: true,
                        };
                      });

                      setOptions(false);
                    }}
                    className="folder_selectbar_option text-center cursor"
                  >
                    {/* {sorting === "체크 상태 순" ? "항목 생성 날짜 순" : "체크 상태 순"} */}
                    {sorting === t(`Marking status`) ? t(`Newest to Oldest`) : t(`Marking status`)}
                  </div>
                )}
              </div>
            </OutsideClickHandler>
          </div>
        </div>
        {/* header end */}
        {/* table head */}
        <div className="mt-10" style={{ position: "sticky", top: 45 }}>
          <div className="display-f" style={{ width: "100%", height: 45 }}>
            <div className="header_folder border_left position-r" style={{ padding: "0 10px" }}>
              <OutsideClickHandler
                onOutsideClick={() => {
                  if (folderOpen) {
                    setFolderOpen(false);
                  }
                }}
              >
                <div
                  onClick={() => {
                    setFolderOpen((prev: boolean) => !prev);
                  }}
                  className="display-f cursor"
                  style={{ width: "100%" }}
                >
                  <p>{selectedFolder}</p>
                  <img src={down} style={{ width: 18, height: "auto", marginLeft: 24 }} />
                </div>

                {folderOpen && (
                  <div ref={folderRef} className="position-a folder_cell_options">
                    <div
                      onClick={() => {
                        setSelectedFolder(t(`All Folder`));
                        setFolderOpen(false);
                        setFilterOn((prev: any) => {
                          return {
                            ...prev,
                            folders: false,
                          };
                        });
                      }}
                      className="folder_item cursor"
                      style={{
                        borderBottom: "1px dashed #979797",
                      }}
                    >
                      {/* 모든 폴더 */}
                      {t(`All Folder`)}
                    </div>
                    <div
                      onClick={() => {
                        setSelectedFolder("미분류");
                        setFolderOpen(false);
                        setFilterOn((prev: any) => {
                          return {
                            ...prev,
                            folders: true,
                          };
                        });
                      }}
                      className="folder_item cursor"
                      style={{
                        borderBottom: "1px dashed #979797",
                      }}
                    >
                      {/* 미분류 */}
                      {t(`Uncategorized`)}
                    </div>
                    {props.checklistData?.folders?.map((aFolder: any, i: number) => (
                      <div
                        onClick={() => {
                          setSelectedFolder(aFolder.title);
                          setFolderOpen(false);
                          setFilterOn((prev: any) => {
                            return {
                              ...prev,
                              folders: true,
                            };
                          });
                        }}
                        className="folder_item cursor"
                        style={{
                          borderBottom:
                            props.checklistData.folders?.length - 1 !== i
                              ? "1px dashed #979797"
                              : "",
                        }}
                        key={i}
                      >
                        {aFolder?.title}
                      </div>
                    ))}
                  </div>
                )}
              </OutsideClickHandler>
            </div>
            <div className="header_folder" style={{ width: "15%" }}>
              {/* 항목명 */}
              {t(`Checklist name`)}
            </div>
            <div className="header_folder" style={{ width: "10%" }}>
              {/* 체크 상태 */}
              {t(`Check State`)}
            </div>
            <div
              className="header_folder"
              style={{ width: "63%", borderRight: "1px solid #979797" }}
            >
              <div className="text-center">
                <p>
                  {/* 상세 기록 내용 */}
                  {t(`Details`)}
                </p>
                <p style={{ fontSize: 12, fontWeight: 300, color: "gray" }}>
                  {/* 클릭하여 수정하실 수 있습니다. */}(
                  {t(`You can edit the record by clicking on any field`)})
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* table */}
        <div className="page_report_body" style={{ overflow: "auto" }}>
          <div style={{ width: "100%" }}>
            {props.checklistData?.cos?.map((aList: any, i: number) => (
              <div className="display-f" style={{ width: "100%" }}>
                <div className="student_table_cell border_left">{aList.folderTitle}</div>
                <div className="student_table_cell overflow" style={{ width: "15%" }}>
                  <div className="text-center">
                    {aList.checklistHide && (
                      <img src={hide} style={{ width: 12, height: "auto" }} />
                    )}
                    <p
                      className=" overflow white-space overflow ellipsis text-center"
                      style={{ width: "100%" }}
                    >
                      {aList.checklistInfo.title}
                    </p>
                  </div>
                </div>
                <div
                  onClick={() => handleOpenCos(aList)}
                  className={`student_table_cell cursor ${aList.state === "" ? "green" : "gray"}`}
                  style={{ width: "10%" }}
                >
                  {aList.state === "complete" ? (
                    <img src={check} style={{ width: 18, height: "auto" }} />
                  ) : (
                    <div className="card-text-red font-500">{aList.state}</div>
                  )}
                </div>
                <div
                  onMouseMove={moveListener}
                  onMouseDown={downListener}
                  onClick={(event: any) => {
                    if (movedNext) {
                      setMovedNext(false);
                      event.stopPropagation();
                      return;
                    }
                    if (moved) {
                      setMovedNext(true);
                      event.stopPropagation();
                    } else {
                      handleOpenCos(aList);
                    }
                  }}
                  className="student_table_cell cursor"
                  style={{ width: "63%", borderRight: "1px solid #979797" }}
                >
                  {aList.description}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default StudentReport;

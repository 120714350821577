import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import PopupModal from "../component/PopupModal";
import { useDropzone } from "react-dropzone";
import {
  getBadges,
  getBs,
  postNotificationAll,
  postUploadImage,
  putBadges,
  putBs,
  putDeleteBadge,
} from "../common/Action";
import { t } from "i18next";
// import { ItemTypes } from "../common/ItemTypes";
// import { IBadges } from "../interface/Interface";

// import { FileDrop } from "react-file-drop";

const DATA = [
  { imgUrl: "", title: "", type: "", target: "", desc: "" },
  { imgUrl: "", title: "", type: "", target: "", desc: "" },
  { imgUrl: "", title: "", type: "", target: "", desc: "" },
  { imgUrl: "", title: "", type: "", target: "", desc: "" },
  { imgUrl: "", title: "", type: "", target: "", desc: "" },
  { imgUrl: "", title: "", type: "", target: "", desc: "" },
];

// student badge, teachers badge collection 추가
// studentBadge -> post -> [0,1,4]

interface IPushList {
  deviceToken2?: string;
  code: string;
  badgeIndex?: number;
  badgeInfo?: { title?: "" };
}

export const Badges = () => {
  const params: any = useParams();
  // const [badgeData, setBadgeData] = useState<IBadges[]>([]);
  const [badgesArr, setBadgesArr] = useState<any[]>(DATA);
  const [studentsData, setStudentsData] = useState<any>([]);
  const [openModal, setOpenModal] = useState<any>(false);
  const [targetIdx, setTargetIdx] = useState<number>(0);
  const [badge, setBadge] = useState<any>({
    imgUrl: "",
    title: "",
    type: "",
    autoTarget: "",
    manualTarget: "",
    desc: "",
  });
  const [check, setCheck] = useState<string>("");
  const [file, setFile] = useState<any>("");
  const [activateBadgeBtn, setActivateBadgeBtn] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [pushNotificationsArr, setPushNotificationArr] = useState<IPushList[]>([]);
  const [badgeEdit, setBadgeEdit] = useState<boolean>(false);

  //   const classId = location.pathname.split("/")[3];

  const init = async (): Promise<void> => {
    const result: any = await getBadges(params.classId);
    if (result === null) {
      // 뱃지, 뱃지 학생 추가
    } else {
      // setBadgeData(result);
      setBadgesArr(result.badges);
      let studentsResult: any = await getBs(params.classId);
      studentsResult.sort((a: any, b: any) => a.studentInfo[0]?.num - b.studentInfo[0]?.num);
      setStudentsData(studentsResult);
    }
  };

  useEffect(() => {
    init();
  }, []);

  // [3,0,0,0,0,0]

  const onDrop = useCallback((acceptedFiles: any) => {
    let reg = /(.*?)\.(jpg|jpeg|png)$/;
    if (!acceptedFiles[0].path.match(reg)) {
      // return alert("해당 파일은 이미지 파일이 아닙니다.");
      return alert(t(`The file is not an image file`));
    }

    setFile(acceptedFiles);
    setBadge((prev: any) => {
      return {
        ...prev,
        imgUrl: URL.createObjectURL(acceptedFiles[0]),
      };
    });
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleClickAddBadge = (item: any, index: number) => {
    if (item.type !== "") setBadgeEdit(true);
    setOpenModal(true);
    setTargetIdx(index);
    setCheck(item.type);
    setBadge((prev: any) => {
      return {
        ...prev,
        imgUrl: item.imgUrl,
        title: item.title,
        type: item.type,
        autoTarget: item.autoTarget === 0 ? "" : item.autoTarget,
        manualTarget: item.manualTarget,
        desc: item.desc,
      };
    });
  };

  const handleAddBadge = async (): Promise<void> => {
    if (badge.imgUrl === "") {
      // return alert("뱃지 이미지를 등록해 주세요!");
      return alert(t(`Please register your badge image`));
    }
    if (badge.title.length > 10) {
      // return alert("뱃지 이름은 10자 이내로 등록해 주세요!");
    }
    if (badge.desc.length > 30) {
      // return alert("뱃지 설명은 30자 이내로 등록해 주세요!");
      return alert(t(`Please register a badge description up to 30 characters`));
    }
    if (badge.autoTarget === "" && badge.manualTarget === "") {
      // return alert("뱃지 유형을 선택해주세요!");
      return alert(t(`Please select a badge type`));
    }
    if (badge.desc === "") {
      // return alert("뱃지 설명을 추가해 주세요!");
      return alert(t(`Please add a badge description`));
    }

    if (file) {
      const formData = new FormData();
      formData.append("file", file[0]);
      const getUrl: any = await postUploadImage(formData, 400);

      let tempArr = [...badgesArr];
      for (let i = 0; i < tempArr.length; i++) {
        if (i === targetIdx) {
          tempArr[i] = { ...badge, imgUrl: getUrl };
        }
      }

      // 교사가 뱃지기능을 새로 등록하는 상태
      await putBadges({
        classId: params.classId,
        targetIndex: targetIdx,
        imgUrl: getUrl,
        title: badge.title,
        type: badge.type,
        autoTarget: parseInt(badge.autoTarget),
        manualTarget: badge.manualTarget,
        desc: badge.desc,
      });

      setBadgesArr(tempArr);
    } else {
      // 파일 수정 안하는 상태에서 뱃지 업데이트
      let tempArr = [...badgesArr];
      for (let i = 0; i < tempArr.length; i++) {
        if (i === targetIdx) {
          tempArr[i] = badge;
        }
      }
      await putBadges({
        classId: params.classId,
        targetIndex: targetIdx,
        imgUrl: badge.imgUrl,
        title: badge.title,
        type: badge.type,
        autoTarget: parseInt(badge.autoTarget),
        manualTarget: badge.manualTarget,
        desc: badge.desc,
      });
      setBadgesArr(tempArr);
    }

    setFile("");
    setOpenModal(false);
    setBadge({
      imgUrl: "",
      title: "",
      type: "",
      target: "",
      desc: "",
    });

    setBadgeEdit(false);
    init();
  };

  const textWarning = (name: string) => {
    if (name === "name" && badge.title.length > 10) {
      // return "뱃지명은 10자 이내로 입력해주세요!";
      return t(`Please register the badge name up to 10 characters`);
    } else if (name === "desc" && badge.desc.trim().length > 30) {
      // return "뱃지 설명은 30자 이내로 입력해주세요!";
      return t(`Please register a badge description up to 30 characters`);
    } else return "";
  };

  const handleChangeValue = (name: string, value: string) => {
    setBadge((prev: any) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const handleDeleteImg = () => {
    setBadge((prev: any) => {
      return {
        ...prev,
        imgUrl: "",
      };
    });
    setFile("");
  };

  const onDeleteBadge = async (): Promise<void> => {
    setBadge({
      imgUrl: "",
      title: "",
      type: "",
      target: "",
      desc: "",
    });

    let tempBadges = [...badgesArr];
    for (let i = 0; i < tempBadges.length; i++) {
      if (i === targetIdx) {
        tempBadges[i] = {
          imgUrl: "",
          title: "",
          type: "",
          target: "",
          desc: "",
        };
      }
    }

    const tempData: any = [...studentsData];
    for (let i = 0; i < tempData.length; i++) {
      tempData[i].badges[targetIdx] = "0";
    }

    const deleteResult: any = putDeleteBadge({
      classId: params.classId,
      targetIndex: targetIdx,
    });

    setBadgeEdit(false);
    setStudentsData(tempData);
    setBadgesArr(tempBadges);
    setOpenModal(false);
  };

  const handleBadges = (student: any, studentIndex: number, badgeIndex: number) => {
    let tempArr = [...studentsData];
    tempArr[studentIndex].badges[badgeIndex] = !tempArr[studentIndex].badges[badgeIndex]
      ? true
      : false;

    if (student.badges[badgeIndex]) {
      setPushNotificationArr((prev: any) => {
        return [
          ...prev,
          {
            deviceToken2: student.studentInfo[0].deviceToken2,
            code: student.studentInfo[0].code,
            badgeIndex: badgeIndex,
            badgeInfo: badgesArr[badgeIndex],
          },
        ];
      });
    }

    setStudentsData(tempArr);
  };

  const handleSubmitAllBadges = async (): Promise<void> => {
    if (loading) return;
    setLoading(true);
    let studentBadgeData: any = {};
    for (let i in studentsData) {
      studentBadgeData[studentsData[i].code] = studentsData[i].badges;
    }
    const postData = {
      classId: params.classId,
      studentBadgeInfoList: {
        ...studentBadgeData,
      },
    };
    await putBs(postData);
    let tempPushArr: any = [];
    for (let i in pushNotificationsArr) {
      tempPushArr.push({
        token: pushNotificationsArr[i].deviceToken2,
        notification: {
          title: `🎖${pushNotificationsArr[i]?.badgeInfo?.title} ${t(`Badge`)}🎖`, // 뱃지
          body: t(`Congratulations Check it out`), // `축하합니다! 확인해보세요!`,
        },
        data: {
          code: pushNotificationsArr[i].code,
          type: "project",
        },
      });
    }
    // 푸시알림보내기
    postNotificationAll(tempPushArr);
    setPushNotificationArr([]);
    setLoading(false);
    setActivateBadgeBtn(false);
  };

  const handleSetCookies = (e: any): void => {
    if (e.target.value < 0) return;

    // const targetCookies = parseInt(e.target.value);
    const numParse = parseInt(e.nativeEvent.data);
    if (e.nativeEvent.data === null) {
      // if (!isNaN(targetCookies)) {
      handleChangeValue("autoTarget", e.target.value);
      // }
    }
    if (!isNaN(numParse)) {
      // if (!isNaN(targetCookies)) {
      handleChangeValue("autoTarget", e.target.value);
      // }
    }
  };

  return (
    <>
      <div className="col-md-12 category_container">
        <div className="col-md-8 display-f align-center" style={{ flexWrap: "wrap" }}>
          <p className="font-23 font-700 mr-40">
            {/* 뱃지 설정 */}
            {t(`Badge Settings`)}
          </p>
          <div style={{ marginBottom: -10 }}>
            <p>
              <span className="card-text-red bold">
                {/* 뱃지 */}
                {t(`Badge`)}
              </span>
              {/* 는 "쿠키" 수에 포함되지 않습니다. */}
              {t(`Is not included in the number of cookies`)}
            </p>
            <p>
              <span className="card-text-red bold">
                {/* 뱃지 기능 비활성화 */}
                {t(`Disabling the badge`)}
              </span>
              {/* : 아무 뱃지도 등록하지 않으면, 다했어요! 앱에서 뱃지란이 숨겨집니다. */}:{" "}
              {t(`If you don't register any badges...`)}
            </p>
          </div>
        </div>

        <div className="mt-100 badge_container">
          <div className="col-md-12 padding-0">
            {badgesArr.map((item: any, index: number) => (
              <div key={index} className="col-md-2" style={{ height: 150 }}>
                {item.imgUrl === "" && (
                  <div
                    onClick={() => handleClickAddBadge(item, index)}
                    className="display-f justify-center align-center font-20 font-500 cursor"
                    style={{
                      width: "100%",
                      height: "100%",
                      border: "1px dashed #000",
                      borderRadius: 12,
                    }}
                  >
                    {/* 등록 */}
                    {t(`Register`)}
                  </div>
                )}
                {item.imgUrl !== "" && (
                  <div
                    onClick={() => handleClickAddBadge(item, index)}
                    className="popup-flex-center"
                    style={{
                      width: "100%",
                      height: "100%",
                      border: "1px dashed #979797",
                      borderRadius: 12,
                    }}
                  >
                    <img
                      src={item.imgUrl}
                      alt="badge_img"
                      className="file-img"
                      style={{ objectFit: "contain" }}
                    />
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="col-md-12 category_container">
        <div className="col-md-12" style={{ marginTop: 60 }}>
          <div
            className="display-f align-center justify-between"
            style={{ flexWrap: "wrap", marginBottom: 60 }}
          >
            <div className="display-f align-center" style={{ flexWrap: "wrap" }}>
              <p className="font-23 font-700 mr-40">
                {/* 뱃지 통계 */}
                {t(`Badge Statistics`)}
              </p>
              <p>
                {/* 이곳에서 뱃지를 클릭하여 여러 학생들에게 간편하게 뱃지를 주거나 수거할 수 있습니다. */}
                {t(`From here you can easily award or remove...`)}
              </p>
            </div>
            {activateBadgeBtn && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p style={{ color: "red", fontSize: 12 }}>
                  {/* 클릭하여 수동으로 지급한 뱃지를 주거나 수거합니다. */}
                  {t(`Click to manually give or take the badge`)}
                </p>

                <button
                  onClick={handleSubmitAllBadges}
                  style={{ margin: "0 10px" }}
                  className="give-badges-btn"
                >
                  {!loading &&
                    // "저장"
                    t(`Save`)}
                  {loading && <div className="spinner" />}
                </button>

                <button
                  onClick={() => {
                    setActivateBadgeBtn(false);
                    init();
                  }}
                  className="give-badges-btn"
                >
                  {/* 취소 */}
                  {t(`Cancel`)}
                </button>
              </div>
            )}
            {!activateBadgeBtn && (
              <div className="display-f align-center badge_average">
                <p className="mr-14 font-12" style={{ color: "red" }}>
                  {/* * 자동지급 뱃지는 클릭으로 줄 수 없습니다. */}*{" "}
                  {t(`Automatic payment badges cannot be given by clicking`)}
                </p>
                {!activateBadgeBtn && (
                  <button
                    onClick={() => setActivateBadgeBtn(true)}
                    className="give-badges-btn"
                    style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                  >
                    {/* 수동 뱃지 간편 지급 */}
                    {t(`Award manual badges`)}
                  </button>
                )}
              </div>
            )}
          </div>

          <div className="studentContainer">
            {studentsData?.map((student: any, i: number) => (
              <div className="studentBox" key={i}>
                <div className="text-center" style={{ width: 90 }}>
                  <p>{student.studentInfo[0]?.name}</p>
                </div>
                <div style={{ display: "flex" }}>
                  {student.badges &&
                    student.badges?.map((badgeItem: any, index: number) => (
                      <>
                        {!badgesArr[index].imgUrl && (
                          <div key={index} className="student-badge-box" />
                        )}
                        {!activateBadgeBtn &&
                          badgesArr[index].imgUrl !== "" &&
                          student.badges[index] && (
                            <div
                              style={{ position: "relative" }}
                              className="student-badge-box cursor"
                            >
                              <img
                                alt="badge_img"
                                src={badgesArr[index].imgUrl}
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  borderRadius: 5,
                                  objectFit: "contain",
                                }}
                              />
                            </div>
                          )}
                        {!activateBadgeBtn &&
                          badgesArr[index].imgUrl !== "" &&
                          !student.badges[index] && (
                            <div
                              style={{ backgroundColor: "rgba(0,0,0,0.5)", position: "relative" }}
                              className="student-badge-box cursor"
                            >
                              <img
                                alt="badge_img"
                                src={badgesArr[index].imgUrl}
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  filter: "brightness(45%)",
                                  objectFit: "contain",
                                }}
                              />
                            </div>
                          )}
                        {activateBadgeBtn &&
                          badgesArr[index].imgUrl !== "" &&
                          student.badges[index] && (
                            <button
                              disabled={badgesArr[index].type === "auto" ? true : false}
                              onClick={() => handleBadges(student, i, index)}
                              className="student-badge-box-btn cursor"
                            >
                              <img
                                alt="badge_img"
                                src={badgesArr[index].imgUrl}
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  backgroundColor: "#fff",
                                  borderRadius: 5,
                                  objectFit: "contain",
                                }}
                              />
                            </button>
                          )}
                        {activateBadgeBtn &&
                          badgesArr[index].imgUrl !== "" &&
                          !student.badges[index] && (
                            <button
                              disabled={badgesArr[index].type === "auto" ? true : false}
                              onClick={() => handleBadges(student, i, index)}
                              style={{ backgroundColor: "rgba(0,0,0,0.5)", position: "relative" }}
                              className="student-badge-box-btn cursor"
                            >
                              <img
                                alt="badge_img"
                                src={badgesArr[index].imgUrl}
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  filter: "brightness(45%)",
                                  objectFit: "contain",
                                }}
                              />
                            </button>
                          )}
                      </>
                    ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {openModal && (
        <PopupModal openModal={openModal} setOpenModal={setOpenModal}>
          {badge.imgUrl === "" && (
            <div className="badge-img-container">
              <div className="popup-flex-center">
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  {isDragActive ? (
                    <div className="file-drop-dragging-over-target">
                      <div
                        style={{
                          width: 140,
                          height: 140,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: 12,
                        }}
                      >
                        {/* 뱃지 이미지를 */}
                        {t(`Drag the`)}
                        <br />
                        {/* 드래그하여 */}
                        {t(`Badge image`)}
                        <br />
                        {/* 이곳에 */}
                        {t(`And drop it`)}
                        <br />
                        {/* 드롭하세요! */}
                        {t(`Here`)}
                      </div>
                    </div>
                  ) : (
                    <div className="file-drop">
                      <div
                        style={{
                          width: 140,
                          height: 140,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: 12,
                        }}
                      >
                        {/* 뱃지 이미지를 */}
                        {t(`Drag the`)}
                        <br />
                        {/* 드래그하여 */}
                        {t(`Badge image`)}
                        <br />
                        {/* 이곳에 */}
                        {t(`And drop it`)}
                        <br />
                        {/* 드롭하세요! */}
                        {t(`Here`)}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          {badge.imgUrl !== "" && (
            <div className="badge-img-container">
              <div className="popup-flex-center" style={{ position: "relative" }}>
                <img
                  alt="badge_img"
                  src={badge.imgUrl}
                  className="file-img"
                  style={{
                    width: 180,
                    height: 180,
                    border: "1px dashed #979797",
                    objectFit: "contain",
                  }}
                />
                <button
                  onClick={handleDeleteImg}
                  className="img-delete-btn"
                  style={{ position: "absolute", right: 8, bottom: 8 }}
                >
                  {/* 삭제 */}
                  {t(`Delete`)}
                </button>
              </div>
            </div>
          )}

          <div style={{ padding: "10px 50px 0 50px" }}>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              <h3>
                {/* 뱃지명 */}
                {t(`Badge name`)}
              </h3>
              <input
                onChange={(e: any) => handleChangeValue("title", e.target.value)}
                value={badge.title}
                className="badge-title-input"
              />
            </div>
            <div style={{ marginTop: -6 }}>
              <p style={{ fontSize: 12, color: "rgba(176, 35, 24)" }}>{textWarning("name")}</p>
            </div>
          </div>

          <div style={{ padding: "0 50px" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <h3>
                {/* 뱃지 유형 */}
                {t(`Badge type`)}
              </h3>
              <p className="ml-15 mb-m5" style={{ fontSize: 10, color: "rgb(176, 35, 24)" }}>
                {/* 저장 후에는 자동 {"<->"} 수동 변경이 불가합니다. */}
                {t(`You can’t change the badge type`)}
                {"<->"}
                {t(`after registration`)}
              </p>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div
                onClick={() => {
                  if (badgeEdit) return;
                  else {
                    setCheck("auto");
                    setBadge((prev: any) => {
                      return {
                        ...prev,
                        type: "auto",
                      };
                    });
                  }
                }}
                className="cursor"
                style={{
                  width: 18,
                  height: 18,
                  border: "1px solid rgba(176, 35, 24)",
                  marginRight: 5,
                  position: "relative",
                }}
              >
                {check === "auto" && (
                  <div
                    style={{
                      width: 10,
                      height: 10,
                      left: 3,
                      top: 3,
                      backgroundColor: "rgba(176, 35, 24)",
                      marginRight: 5,
                      position: "absolute",
                    }}
                  />
                )}
              </div>
              <p
                onClick={() => {
                  if (badgeEdit) return;
                  else {
                    setCheck("auto");
                    setBadge((prev: any) => {
                      return {
                        ...prev,
                        type: "auto",
                      };
                    });
                  }
                }}
                className="cursor"
              >
                {/* 받은 쿠키 수에 따라 자동 지급하기 */}
                {t(`Award automatic badges`)}
              </p>
            </div>
            {check === "auto" && (
              <li style={{ marginLeft: 20, marginTop: 5 }}>
                {/* 받은 쿠키 수가{" "} */}
                {t(`When the number of cookies received is`)}
                <span>
                  <input
                    // type="number"
                    value={badge.autoTarget}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleSetCookies(e)}
                    style={{
                      width: 55,
                      backgroundColor: "#f7f7f7",
                      border: "none",
                      borderBottom: "1px solid black",
                    }}
                  />
                  {/*  TODO:: 고유번역 */}
                  {localStorage.getItem("language") === "ko" ? "개일 때" : ""}
                </span>
              </li>
            )}

            <div style={{ display: "flex", alignItems: "center", marginTop: 10 }}>
              <div
                onClick={() => {
                  if (badgeEdit) return;
                  else {
                    setCheck("manual");
                    setBadge((prev: any) => {
                      return {
                        ...prev,
                        type: "manual",
                      };
                    });
                  }
                }}
                className="cursor"
                style={{
                  width: 18,
                  height: 18,
                  border: "1px solid rgba(176, 35, 24)",
                  marginRight: 5,
                  position: "relative",
                }}
              >
                {check === "manual" && (
                  <div
                    style={{
                      width: 10,
                      height: 10,
                      left: 3,
                      top: 3,
                      backgroundColor: "rgba(176, 35, 24)",
                      marginRight: 5,
                      position: "absolute",
                    }}
                  />
                )}
              </div>
              <p
                onClick={() => {
                  if (badgeEdit) return;
                  else {
                    setCheck("manual");
                    setBadge((prev: any) => {
                      return {
                        ...prev,
                        type: "manual",
                      };
                    });
                  }
                }}
                className="cursor"
              >
                {/* 선생님이 수동으로 지급하기 */}
                {t(`Award manual badges`)}
              </p>
            </div>
            {check === "manual" && (
              <li style={{ marginLeft: 20, marginTop: 5 }}>
                {/* 지급 조건 :{" "} */}
                {t(`Badge award requirements`)}
                <span style={{ fontSize: 12 }}>
                  <input
                    value={badge.manualTarget}
                    onChange={(e: any) => handleChangeValue("manualTarget", e.target.value)}
                    style={{
                      width: 180,
                      backgroundColor: "#f7f7f7",
                      border: "none",
                      borderBottom: "1px solid black",
                    }}
                  />
                </span>
              </li>
            )}
          </div>

          <div style={{ padding: "10px 50px 10px 50px" }}>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              <h3>
                {/* 뱃지 설명 */}
                {t(`Badge Description`)}
              </h3>
            </div>

            <input
              value={badge.desc}
              onChange={(e: any) => handleChangeValue("desc", e.target.value)}
              className="badge-desc-input"
            />
            <p style={{ paddingTop: 7, fontSize: 12, color: "rgba(176, 35, 24)" }}>
              {textWarning("desc")}
            </p>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "0 100px",
              marginTop: 20,
            }}
          >
            <div>
              <button onClick={handleAddBadge} className="badge-btn">
                {/* 등록/수정 */}
                {t(`Register Modify`)}
              </button>
            </div>
            <div>
              <button
                onClick={() => {
                  setBadgeEdit(false);
                  setOpenModal(false);
                }}
                className="badge-btn"
              >
                {/* 취소 */}
                {t(`Cancel`)}
              </button>
            </div>
          </div>

          <div className="badge-delete-container" style={{ marginTop: 10 }}>
            <div>
              <button onClick={onDeleteBadge} className="badge-btn-delete">
                {/* 뱃지 삭제 */}
                {t(`Delete Badge`)}
              </button>
            </div>
          </div>
        </PopupModal>
      )}
    </>
  );
};

export default Badges;

import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import tranEn from "./Files/en.json";
import tranKo from "./Files/ko.json";
import tranRw from "./Files/rw.json";

// 추가될 언어를 여기에서 import
export const languages = ["en", "ko", "rw"] as const;
export type Languages = (typeof languages)[number]; // 'en' | 'ko' | 'rw

// 언어 json 파일 여기에 지정
const resources = {
  en: { translation: tranEn },
  ko: { translation: tranKo },
  rw: { translation: tranRw },
};

const userLanguage = window.navigator.language || window.navigator.language;

i18n.use(initReactI18next).init({
  resources,
  lng: localStorage.getItem("language") || userLanguage || "en",
  fallbackLng: "en",
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
});

// i18n.use(initReactI18next).init({
//   resources,
//   lng: "ko", // 기본 설정 언어, 'cimode'로 설정할 경우 키 값으로 출력된다.
//   fallbackLng: "en", // 번역 파일에서 찾을 수 없는 경우 기본 언어
//   interpolation: {
//     escapeValue: false,
//   },
// });

export default i18n;

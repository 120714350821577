import React, { useState } from "react";
import moment from "moment";
import right from "../images/right-arrow.png";
import left from "../images/left-arrow.png";
import { t } from "i18next";

export default function CalendarC({ selectDates, setSelectDates, handleSelectDate }: any) {
  const [getMoment, setMoment] = useState(moment());
  const today = getMoment;
  // const [selectDates, setSelectDates] = useState<any>([]);

  const getCalendarDays = () => {
    const firstWeek = today.clone().startOf("month").week();
    const lastWeek =
      today.clone().endOf("month").week() === 1 ? 53 : today.clone().endOf("month").week();

    let weeks: any = [];
    for (let week = firstWeek; week <= lastWeek; week++) {
      weeks = [...weeks, week];
    }
    // [6, 7, 8, 9, 10]

    return weeks.reduce((acc: any, week: any) => {
      let newarr = new Array(7);
      //newarr = [31, 1, 2, 3, 4, 5, 6]
      for (let i = 0; i < newarr.length; i++) {
        newarr[i] = today.clone().startOf("year").week(week).startOf("week").add(i, "day");
      }
      return [...acc, newarr];
    }, []);

    // [
    //6 [31,1,2,3,4,5,6],  //newarr
    //7 [0,0,0,0,0,0,0],  //newarr
    //8 [0,0,0,0,0,0,0],
    //9 [0,0,0,0,0,0,0],
    //10 [0,0,0,0,0,0,0],
    // ]
  };

  return (
    <>
      {/* <p style={{ color: "red" }}>달력 테스트입니다. 클릭 되는지 확인해주세요!</p> */}
      <div
        className="display-f"
        style={{ alignItems: "flex-end", flexWrap: "wrap", backgroundColor: "#fff" }}
      >
        {/* calendar */}
        <div style={{ marginRight: 15, minWidth: 220, width: "55%" }}>
          <section
            className="display-f justify-between align-center"
            style={{ padding: "0 0 10px 0", flexWrap: "wrap" }}
          >
            <div
              onClick={() => {
                setMoment(getMoment.clone().subtract(1, "month"));
              }}
              className="arrow_wrapper display-f justify-center align-center cursor"
            >
              <img className="calendar_arrow" src={left} />
            </div>
            <p style={{ fontSize: 17, fontWeight: "bold" }}>
              {/* {getMoment.format("YYYY")}년 {getMoment.format("MM")}월 */}
              {getMoment.format("YYYY")}
              {t(`Year`)} {getMoment.format("MM")}
              {t(`Month`)}
            </p>
            <div
              onClick={() => {
                setMoment(getMoment.clone().add(1, "month"));
              }}
              className="arrow_wrapper display-f justify-center align-center cursor"
            >
              <img className="calendar_arrow" src={right} />
            </div>
          </section>

          <table className="calendar_table" style={{ width: "100%" }}>
            <th>{t(`Sun`)}</th>
            <th>{t(`Mon`)}</th>
            <th>{t(`Tue`)}</th>
            <th>{t(`Wed`)}</th>
            <th>{t(`Thu`)}</th>
            <th>{t(`Fri`)}</th>
            <th>{t(`Sat`)}</th>

            {getCalendarDays().map((week: any, i: number) => (
              <tr className="cursor text-center">
                {week.map((day: any, i: number) => {
                  if (getMoment.format("MM") === day.format("MM")) {
                    return (
                      <td
                        className={`day_include 
                    ${selectDates.includes(day.valueOf()) ? "selectDate" : ""} `}
                        onClick={() => handleSelectDate(day)}
                      >
                        <div className={`display-f justify-center align-center `}>
                          <div
                            className={`${
                              moment().startOf("day").valueOf() === day.valueOf() ? "today" : ""
                            }`}
                            style={{ width: 30, color: "#050505" }}
                          >
                            {day.format("D")}
                          </div>
                        </div>
                      </td>
                    );
                  } else {
                    return (
                      <td
                        className={`day_exclude  ${
                          selectDates.includes(day.valueOf()) ? "selectDate" : ""
                        } ${getMoment.startOf("day").valueOf() === day.valueOf() ? "today" : ""}`}
                        onClick={() => handleSelectDate(day)}
                      >
                        <div className={`display-f justify-center align-center `}>
                          <div
                            className={`${
                              getMoment.startOf("day").valueOf() === day.valueOf() ? "today" : ""
                            }`}
                            style={{ width: 30 }}
                          >
                            {day.format("D")}
                          </div>
                        </div>
                      </td>
                    );
                  }
                })}
              </tr>
            ))}
          </table>
        </div>

        <div className="calendar_r">
          <div className="display-f align-center">
            <div className="calendar-mark" style={{ backgroundColor: "#c2e09d" }} />
            <p style={{ fontSize: 12 }}>
              {/* 오늘 */}
              {t(`Today`)}
            </p>
          </div>

          <div className="display-f align-center" style={{ marginTop: 8 }}>
            <div className="calendar-mark" style={{ backgroundColor: "rgb(255, 211, 126)" }} />
            <p style={{ fontSize: 12 }}>
              {/* 선택한 날짜 */}
              {t(`Selected Date`)}
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./styles/bootstrap.min.css";
import "./styles/quill.snow.css";
import "./styles/index.css";
import "./styles/Login.css";
import "./styles/Main.css";
import "./styles/Sidebar.css";
import "./styles/Category.css";
import "./styles/SidebarInner.css";
import "./styles/SignUp.css";
import "./styles/FindIdPw.css";
import "./styles/Popup.css";
import "./styles/responsive.css";
import "./styles/badge.css";
import "./styles/notice.css";
import "./styles/calendar.css";
import "./styles/calendarC.css";
import "./styles/toggle.css";
import "./styles/classreport.css";
import "./styles/modal.css";
import "./styles/table.css";
import "./styles/v2_.css";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import "./Locales/i18n";

ReactDOM.render(
  <React.StrictMode>
    <DndProvider backend={HTML5Backend}>
      <App />
    </DndProvider>
  </React.StrictMode>,
  document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React, { useState, useEffect, useRef } from "react";
import { IProject, IStudent } from "../interface/Interface";
import { Link, NavLink, useParams } from "react-router-dom";
import OutsideClickHandler from "react-outside-click-handler";
import ReactQuill from "react-quill";
import search from "../images/search.png";
import down from "../images/down.png";
import bin from "../images/bin.png";
import down_b from "../images/down_b.png";
import close from "../images/close.png";
import {
  deletelist,
  getChecklistFolders,
  getCheckLists,
  getStudents,
  postChecklist,
  postChecklistFolder,
  // postDelete,
  // postDeleteMany,
  // postInsertVerify,
  // postNotification,
  // postUpdate,
  // postUploadFile,
  postUploadImage,
  putHideChecklist,
} from "../common/Action";
import { formatStyle, quillStyle } from "../common/QuillStyle";
import Modal from "../component/Modal";
import { t } from "i18next";

const CheckList: React.FC = () => {
  const params: any = useParams();
  const fileRef = useRef<HTMLInputElement>(null);
  const popupRef = useRef<HTMLDivElement>(null);
  const [cardData, setCardData] = useState<IProject[]>([]);
  const [students, setStudents] = useState<IStudent[]>([]);
  const [searchInput, setSearchInput] = useState<string>("");
  const [filter, setFilter] = useState<string[]>([
    t(`Not Marked`), // 미확인 순
    t(`Oldest to Newest`), // 생성 오래된 순
    t(`Alphabetical A Z`), // 가나가 순
  ]);
  const [filterSelected, setFilterSelected] = useState<string>(t(`Not Marked`)); // 미확인 순
  const [text, setText] = useState<string>("");
  const [checklistTitle, setChecklistTtile] = useState<string>("");
  const [files, setFiles] = useState<any[]>([]);
  const [fileUrls, setFileUrls] = useState<string[]>([]);
  const [filterPopup, setFilterPopup] = useState<boolean>(false);
  const [checklistEdit, setChecklistEdit] = useState<boolean>(false);
  const [allSelected, setAllselected] = useState<boolean>(false);
  const [studentsList, setStudentsList] = useState<boolean>(false);
  const [popup, setPopup] = useState<boolean>(false);
  const [blockSubmit, setBlockSubmit] = useState<boolean>(false);
  const [studentCheckWait, setStudentCheckWait] = useState<boolean>(false);
  const [folderModal, setFolderModal] = useState<boolean>(false);
  const [folder, setFolder] = useState<boolean>(true);
  const [openSelection, setOpenSelection] = useState<boolean>(false);
  const [folderName, setFolderName] = useState<string>("");
  const [foldersData, setFoldersData] = useState<any>([]);
  const [popupFolderSelect, setPopupFolderSelect] = useState<any>({
    title: t(`Unspecified folder`),
  }); // 폴더 미지정
  const [originData, setOriginData] = useState<any>([]);

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (popup === false) {
      setText("");
      setChecklistTtile("");
      setFiles([]);
      setFileUrls([]);
    }
  }, [popup]);

  const init = async (): Promise<void> => {
    let studentResult: any = await getStudents(params.classId);
    const checklists: any = await getCheckLists(params.classId);
    let tempFolders = [];
    for (let i in checklists) {
      if (checklists[i].isFolder) {
        tempFolders.push(checklists[i]);
      }
    }
    if (tempFolders.length !== 0) {
      setFoldersData(tempFolders);
    }
    for (let i in studentResult) {
      studentResult[i].checked = true;
    }

    setOriginData(checklists);
    setCardData(checklists);
    setStudents(studentResult);
  };

  const handleSelectAll = (): void => {
    const temp = [...cardData];
    for (let i = 0; i < temp.length; i++) {
      if (!allSelected) {
        temp[i].checked = true;
      } else {
        temp[i].checked = false;
      }
    }
    setAllselected((prev) => !prev);
    setCardData(temp);
  };

  const handleSearch = (e: any): void => {
    if (e.target.value === "") {
      const temp = [...cardData];
      for (let i in temp) {
        temp[i].searchHide = false;
      }
      setCardData(temp);
    } else {
      let temp: any = [...cardData];
      for (let i in temp) {
        if (temp[i].title.includes(e.target.value)) {
          temp[i].searchHide = false;
        } else {
          temp[i].searchHide = true;
        }
      }
      setCardData(temp);
    }
    setSearchInput(e.target.value);
  };

  const handleFilterSelect = async (index: number): Promise<void> => {
    let temp = "";
    temp = filter[index];
    setFilterSelected(temp);
    let filterData: any = [];

    if (filter[index] === t(`Not Marked`)) {
      // 미확인 순
      filterData = await getCheckLists(params.classId, "status", -1);
    }
    if (filter[index] === t(`Oldest to Newest`)) {
      // 생성 오래된 순
      filterData = await getCheckLists(params.classId, "created", 1);
    }
    if (filter[index] === t(`Alphabetical A Z`)) {
      // 가나다 순
      filterData = await getCheckLists(params.classId, "title", 1);
    }

    setCardData(filterData);
    setFilterPopup(false);
  };

  const handleCheckCard = (index: number): void => {
    const temp = [...cardData];
    for (let i = 0; i < temp.length; i++) {
      if (index === i) {
        temp[index].checked = !temp[index].checked;
      }
    }
    setCardData(temp);
  };

  const handleFile = (e: any) => {
    const filesArr = Object.keys(e.target.files);
    if (filesArr.length !== 0) {
      let fileTemp = [...files];
      for (let i in filesArr) {
        if (e.target.files[i].type.split("/")[0] === "image") {
          fileTemp.push({
            file: e.target.files[i],
            fileUrl: URL.createObjectURL(e.target.files[i]),
          });
        } else {
          // alert("이미지만 첨부해주세요!");
          alert(t(`Please attach images only`));
        }
      }
      setFiles(fileTemp);
    } else {
      return;
    }
  };

  const handleSubmit = async (): Promise<void> => {
    let tempUrls: string[] = [];
    if (files.length !== 0) {
      for (let i in files) {
        const formData = new FormData();
        formData.append("file", files[i].file);
        const getUrls: any = await postUploadImage(formData, 500);
        tempUrls.push(getUrls);
      }
    }
    let tempCode: string[] = [];
    for (let i in students) {
      if (students[i].checked) {
        tempCode.push(students[i].code);
      }
    }
    const data = {
      onFolder: popupFolderSelect.title !== t(`Unspecified folder`) ? true : false, // !== 폴더 미지정 ?
      folderId: popupFolderSelect._id ? popupFolderSelect._id : "",
      classId: params.classId,
      title: checklistTitle,
      description: text,
      file: tempUrls,
      studentCodeList: tempCode,
    };
    const postChecklistResult: any = await postChecklist(data);
    setPopup(false);
    setFolder(true);
    init();
  };

  const handleDeleteFile = (targetFile: object) => {
    let temp = [...files];
    const findIdx = temp.findIndex((el: any) => el === targetFile);
    temp.splice(findIdx, 1);
    setFiles(temp);
  };

  const handleStudentChecked = (i: number): void => {
    setStudentCheckWait(true);
    const temp = [...students];
    temp[i].checked = !temp[i].checked;
    setStudents(temp);
    setStudentCheckWait(false);
  };

  const handleStudentsAllCheck = (state: boolean) => {
    setStudentCheckWait(true);
    const temp = [...students];
    for (let i in temp) {
      temp[i].checked = state;
    }
    setStudents(temp);
    setStudentCheckWait(false);
  };

  const handleClickOutside = (e: any) => {
    if (popupRef.current !== null) {
      if (!popupRef.current.contains(e.target)) setPopup(false);
    }
  };

  const handleDeleteChecklist = async (): Promise<void> => {
    // 체크리스트 / 폴더 숨김처리 로직 필요.
    let checklistIds = [];
    let folderIds = [];

    for (let i in cardData) {
      if (cardData[i].checked && cardData[i].isFolder) {
        folderIds.push(cardData[i]._id);
      }
      if (cardData[i].checked && !cardData[i].isFolder) {
        checklistIds.push(cardData[i]._id);
      }
    }

    await putHideChecklist({
      checklistIds,
      folderIds,
      hide: true,
      classId: params.classId,
    });

    setChecklistEdit(false);
    init();
  };

  const handleCloseModal = (event: any): void => {
    event.preventDefault();
    setFolder(true);
    setPopup(false);
  };

  const handleAddFolder = async (): Promise<void> => {
    if (!folder) {
      setPopup(true);
    } else {
      // 폴더 생성시 들어갈 로직
      if (folderName === "") {
        // return alert("폴더명을 지정해 주세요.");
        return alert(t(`Please provide a folder name`));
      }
      const postFolderResult = await postChecklistFolder({
        title: folderName,
        classId: params.classId,
      });
      init();
    }

    setFolder(true);
    setFolderModal(false);
  };

  return (
    <div className="col-md-12 category_container">
      {/* header */}

      {checklistEdit ? (
        <div className="display-f justify-between align-center mb-33 pl-10 pr-10">
          <div className="display-f align-center">
            <button onClick={handleSelectAll} className="select_all_btn mr-10 position-r">
              {allSelected && (
                <span className={`check__inside select_all position-a bg-checked`}></span>
              )}
            </button>
            <p className="font-16 mt-9 mb-10">{t(`Select All`)}</p>
          </div>
          <div></div>

          <div>
            <button onClick={handleDeleteChecklist} className="select_submit_btn mr-13">
              {/* 숨기기 */}
              {t(`Archive`)}
            </button>
            <button onClick={() => setChecklistEdit(false)} className="select_submit_btn">
              {/* 취소 */}
              {t(`Cancel`)}
            </button>
          </div>
        </div>
      ) : (
        <div className="display-f justify-between align-center mb-33 pl-10 pr-10">
          <div className="display-f align-center" style={{ width: 528 }}>
            <h2 className="mr-40">
              {/* 체크리스트 */}
              {t(`Checklist`)}
            </h2>
            {/* 학생과 연동되지 않습니다 */}
            {t(`Not visible to students`)}
          </div>

          <NavLink
            className={`${window.location.pathname.includes("hidden")}`}
            to={`/page/hiddens/checklist/${params.classId}`}
          >
            <div className="cursor hidden_btn mr-10 select_btn display-f align-center">
              <img style={{ width: 18, height: "auto", marginRight: 5 }} src={bin} alt="bin" />
              <p>
                {/* 숨긴 체크리스트 */}
                {t(`Archived Checklists`)}
              </p>
            </div>
          </NavLink>

          <div
            className="display-f align-center"
            style={{ width: 528 }}
            // style={{ width: "50%", justifyContent: "space-between" }}
          >
            <div className="display-f position-r">
              <form
                className="mr-10 position-r"
                // onSubmit={(e: any) => handleSearch(e)}
                action={`/projects/${searchInput}`}
              >
                <img
                  // onClick={(e: any) => handleSearch(e)}
                  className="search_icon position-a cursor"
                  src={search}
                  alt="search"
                />
                <input
                  value={searchInput}
                  onChange={(e) => handleSearch(e)}
                  type="text"
                  className="category_searchInput pl-50 pr-10"
                />
              </form>
              <OutsideClickHandler
                onOutsideClick={() => {
                  setFilterPopup(false);
                }}
              >
                <button
                  onClick={() => setFilterPopup((prev) => !prev)}
                  className="filter_btn mr-10 text-left pl-17 pr-12 display-f justify-between align-center"
                >
                  {filterSelected} <img className="filter_icon" src={down} alt="down" />
                </button>
                {filterPopup && (
                  <div className="position-a filter_contents">
                    {filter.map((data: string, index: number) => (
                      <div
                        onClick={() => handleFilterSelect(index)}
                        className="filter_inner cursor"
                        key={index}
                      >
                        <p className="pl-17 pt-11 pb-11 font-14">{data}</p>
                      </div>
                    ))}
                  </div>
                )}
              </OutsideClickHandler>
            </div>
            <div>
              <button onClick={() => setChecklistEdit(true)} className="select_btn mr-28">
                {/* 수정 */}
                {t(`Edit`)}
              </button>
            </div>
          </div>
        </div>
      )}

      {/* card */}

      <div className="display-f flex-wrap">
        {cardData &&
          cardData.map((data: any, index: number) => {
            if (checklistEdit && !data.isFolder && !data.searchHide) {
              return (
                <div key={index} className="display-ib pl-10 pr-10 position-r cursor">
                  <button
                    onClick={() => handleCheckCard(index)}
                    style={{ border: "none", cursor: "pointer" }}
                    className={`card_checklist_normal project_inner_card border-14 padding-0 justify-center pl-15 pr-15 ${data?.status}`}
                  >
                    <div className="display-b mb-9 font-15 color-dark font-700 text-center word-break">
                      {data.title}
                    </div>
                    <span className="position-a check cursor">
                      {data.checked && (
                        <span className={`check__inside position-a bg-checked`}></span>
                      )}
                    </span>
                  </button>
                </div>
              );
            }

            if (checklistEdit && data.isFolder && !data.searchHide) {
              return (
                <div key={index} className="display-ib pl-10 pr-10 position-r cursor">
                  <button
                    onClick={() => handleCheckCard(index)}
                    style={{ border: "none", cursor: "pointer" }}
                    className={`card_checklist project_inner_card border-14 padding-0 justify-center pl-15 pr-15 ${data.status}`}
                  >
                    <div className="display-b mb-9 font-15 color-dark font-700 text-center word-break">
                      {data.title}
                    </div>
                    <span className="position-a check cursor">
                      {data.checked && (
                        <span className={`check__inside position-a bg-checked`}></span>
                      )}
                    </span>
                  </button>
                </div>
              );
            }

            if (!checklistEdit && !data.searchHide && !data.isFolder) {
              return (
                <Link key={index} to={`/page/checklistdetail/${params.classId}/${data._id}`}>
                  <div key={index} className="display-ib pl-10 pr-10">
                    <div
                      className={`card_checklist_normal project_inner_card justify-center padding-0 pl-15 pr-15 ${data.status}`}
                    >
                      <div
                        className={`word-break display-b mb-9 font-15 font-700 text-center color-${
                          data.color === "green" ? "white" : "dark"
                        }`}
                      >
                        {data.title}
                      </div>
                    </div>
                  </div>
                </Link>
              );
            }

            if (!checklistEdit && data.isFolder && !data.searchHide) {
              return (
                <Link key={index} to={`/page/folder/checklist/${params.classId}/${data._id}`}>
                  <div key={index} className="display-ib pl-10 pr-10">
                    <div
                      className={`border-14 card_checklist project_inner_card justify-center padding-0 pl-15 pr-15 ${data.status}`}
                    >
                      <div
                        className={`word-break display-b mb-9 font-15 font-700 text-center color-${
                          data.color === "green" ? "white" : "dark"
                        }`}
                      >
                        {data.title}
                      </div>
                    </div>
                  </div>
                </Link>
              );
            }
          })}

        {!checklistEdit && (
          <>
            <div className="display-ib pl-10 pr-10">
              <div
                onClick={() => setFolderModal(true)}
                className="card_checklist_normal project_inner_card padding-0 justify-center project_card"
              >
                <p className="font-34 font-900">+</p>
              </div>
            </div>
          </>
        )}
      </div>

      {/* folder popup */}
      {folderModal && (
        <Modal
          show={folderModal}
          onClose={() => {
            setFolder(true);
            setFolderModal(false);
          }}
        >
          <div style={{ background: "#fff", padding: 30, borderRadius: 14 }}>
            <div className="text-center">
              <h2 style={{ margin: "0 0 30px 0" }}>
                {/* 생성 선택 */}
                {t(`Add New Folder Checklist`)}
              </h2>
            </div>

            <div className="display-f">
              <button
                // onClick={() => setFolder((prev: boolean) => !prev)}
                className={`mr-10 checklist_folder_popup_btn ${folder && "green"}`}
                style={{ border: "2px solid #336633" }}
              >
                {/* 폴더 생성 */}
                {t(`Create Folder`)}
              </button>
              <button
                onClick={() => {
                  setFolder((prev: boolean) => !prev);
                  setFolderModal(false);
                  setPopup(true);
                }}
                className={`checklist_folder_popup_btn ${!folder && "green"}`}
                style={{ border: "1px solid #b4d985" }}
              >
                {/* 체크리스트 생성 */}
                {t(`Create Checklist`)}
              </button>
            </div>

            {folder && (
              <div>
                <h4 style={{ margin: "20px 0 10px 0" }}>{t(`Folder name`)}</h4>
                <input
                  onChange={(e: any) => setFolderName(e.target.value)}
                  className="folder_input"
                />
              </div>
            )}

            <div className="display-f justify-center mt-30">
              <button onClick={handleAddFolder} className="mr-5 checklist_foler_btn">
                {/* 확인 */}
                {t(`Confirm`)}
              </button>
              <button
                onClick={() => {
                  setFolder(true);
                  setFolderModal(false);
                }}
                className="ml-5 checklist_foler_btn_cancel"
              >
                {/* 취소 */}
                {t(`Cancel`)}
              </button>
            </div>
          </div>
        </Modal>
      )}

      {/* popup */}
      {popup && (
        <div className="popup">
          <div className="modal-overlay" onClick={handleCloseModal}></div>
          <div ref={popupRef} className="project_add_popup scroll">
            <p className="font-23 mb-26 text-center">{t(`Create Checklist`)}</p>
            <p className="color-light-black mb-8 font-15">{t(`Title`)}</p>
            <div className="display-f align-center mb-22">
              <input
                value={checklistTitle}
                onChange={(e: any) => setChecklistTtile(e.target.value)}
                className="popup_input mr-18 pl-12 pr-12"
                type="text"
                maxLength={31}
              />
            </div>

            <div className="mb-22">
              <p className="color-light-black mb-8 font-15">
                {/* 폴더 */}
                {t(`Folder`)}
              </p>
              {/* folder selection */}
              <div
                onClick={() => setOpenSelection(true)}
                style={{ alignItems: "center", marginTop: 10 }}
                className="display-f"
              >
                <div className="repeat-selectbar cursor">
                  {/* <p className={`${popupFolderSelect?.title === "폴더 미지정" ? "font-gray" : ""}`}>  */}
                  <p
                    className={`${
                      popupFolderSelect?.title === t(`Unspecified folder`) ? "font-gray" : ""
                    }`}
                  >
                    {popupFolderSelect?.title}
                  </p>

                  <img src={down_b} style={{ width: 18, height: "auto" }} />
                </div>
              </div>

              {openSelection && (
                <OutsideClickHandler
                  onOutsideClick={() => {
                    setOpenSelection(false);
                  }}
                >
                  <div className="repeat-selectbar-open" style={{ marginLeft: 0 }}>
                    <div
                      onClick={() => {
                        setPopupFolderSelect({ title: t(`Unspecified folder`) }); // 폴더 미지정
                        setOpenSelection(false);
                      }}
                      className="repeat-select-item-n cursor font-gray"
                    >
                      {/* 폴더 미지정 */}
                      {t(`Unspecified folder`)}
                    </div>
                    {foldersData.map((folder: any, i: number) => (
                      <div
                        key={i}
                        onClick={() => {
                          setPopupFolderSelect(folder);
                          setOpenSelection(false);
                        }}
                        className="repeat-select-item-n cursor"
                      >
                        {folder.title}
                      </div>
                    ))}
                  </div>
                </OutsideClickHandler>
              )}
            </div>

            <p className="color-light-black font-15 mb-8">
              {/* 내용 */}
              {/* {t(`Detail`)} */}
            </p>
            <ReactQuill
              value={text}
              onChange={(e) => setText(e)}
              className="project-quill"
              modules={quillStyle}
              formats={formatStyle}
            />
            <div className="display-f" style={{ width: "100%", flexWrap: "wrap" }}>
              {files.map((file: any, index: number) => (
                <div className="position-r" style={{ width: "48%", marginRight: 10 }} key={index}>
                  <img
                    onClick={() => handleDeleteFile(file)}
                    src={close}
                    style={{ width: 15, height: "auto", top: 10, left: 10 }}
                    className="position-a cursor"
                  />
                  <img src={file.fileUrl} className="img-add mb-10" alt="file" />
                </div>
              ))}
            </div>
            <div className="popup__additional__project mb-30">
              <input
                multiple
                ref={fileRef}
                type="file"
                className="display-n"
                name="file"
                onChange={(e: any) => handleFile(e)}
              />
              <button
                onClick={() => {
                  fileRef.current !== null && fileRef.current.click();
                }}
                className="focus add__file__btn font-13 font-500 mr-11 mt-10"
              >
                {t(`Attach`)}
              </button>

              {files.length !== 0 && (
                <button
                  onClick={() => {
                    setFiles([]);
                    setFileUrls([]);
                  }}
                  className="focus add__file__btn font-13 font-500 mr-11 mt-10"
                >
                  {/* 파일 삭제 */}
                  {t(`Delete files`)}
                </button>
              )}

              <button
                onClick={() => setStudentsList(true)}
                className="focus target__student__btn font-13 font-500"
              >{`${t(`Select Recipient`)} (${
                students.filter((item: IStudent) => item.checked).length
              })`}</button>

              {studentsList === true && (
                <div className="studentsList__popup">
                  <div className="studentsList__contents">
                    <div className="overflow-h scroll mb-30">
                      <p className="font-23 text-center mb-20 pt-20">
                        {/* 대상학생 목록 */}
                        {t(`List of recipients`)}
                      </p>
                      {students
                        .sort((a: any, b: any) => a.num - b.num)
                        .map((data: IStudent, index: number) => (
                          <div key={index} className="managing__options__contents position-r">
                            <p className="mt-15 mb-15 font-15">{data.name}</p>
                            <input
                              onChange={() => handleStudentChecked(index)}
                              // checked={data.target}
                              type="checkbox"
                              id={JSON.stringify(index)}
                              className="display-n"
                            />
                            <div>
                              <label
                                className="target_label cursor"
                                htmlFor={JSON.stringify(index)}
                              >
                                {data.checked && <span className="studentList_check"></span>}
                              </label>
                            </div>
                          </div>
                        ))}
                      <div>
                        <div className="text-center mt-20 mb-20 pb-20">
                          <button
                            onClick={() => handleStudentsAllCheck(true)}
                            className="focus popup__bottom__btn mr-14"
                          >
                            {/* 모두 선택 */}
                            {t(`Select All`)}
                          </button>
                          <button
                            onClick={() => handleStudentsAllCheck(false)}
                            className="focus popup__bottom__btn mr-14"
                          >
                            {/* 모두해제 */}
                            {t(`Deselect all`)}
                          </button>
                          <button
                            onClick={() => setStudentsList(false)}
                            className="focus popup__bottom__btn mr-14"
                          >
                            {/* 확인 */}
                            {t(`Confirm`)}
                          </button>
                          <button
                            onClick={() => setStudentsList(false)}
                            className="focus popup__bottom__btn"
                          >
                            {/* 취소 */}
                            {t(`Cancel`)}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="text-center mb-20">
              <button onClick={handleSubmit} className="focus popup__bottom__btn mr-14">
                {/* 확인 */}
                {t(`Confirm`)}
              </button>
              <button
                onClick={() => {
                  setFolder(true);
                  setPopup(false);
                }}
                className="focus popup__bottom__btn"
              >
                {/* 취소 */}
                {t(`Cancel`)}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CheckList;
